import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { inscrever } from '../../utils/FilaDeMensagens'
import Alerta, { AlertaEsquerda } from '../Alerta'

export default function AlertaSnack() {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  useEffect(() => {
    function handleNewMessage({
      text, type, time, translate, esquerda,
    }) {
      if (esquerda) {
        AlertaEsquerda(enqueueSnackbar, translate ? t(text) : text, type, time)
      } else {
        Alerta(enqueueSnackbar, translate ? t(text) : text, type, time)
      }
    }

    inscrever(handleNewMessage, 'AlertaSnackbar')
  }, [enqueueSnackbar, t])

  return ''
}
