import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

export const LayoutGeralConsumoAgrupadoEstilo = makeStyles(() => ({
  containerPrincipal: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerSuperior: {
    marginBottom: 0,
  },
  radios: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radio: {
    flex: 1,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
}))

const LayoutEstilo = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  rootLista: {
    width: '100%',
    maxWidth: 360,
  },
  child: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF9E0',
    },
  },
  info: {
    cursor: 'pointer',
  },
  nested: {
    paddingLeft: theme.spacing(6),
    '&:hover': {
      backgroundColor: '#FFF9E0',
    },
  },
  nestedChild: {
    paddingLeft: theme.spacing(6),
    '&:hover': {
      backgroundColor: '#FFF9E0',
    },
    cursor: 'pointer',
  },
  paper: {
    padding: 24,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  corPrimaria: {
    color: COR_PRIMARIA,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },

  layoutMenuUserName: {
    textAlign: 'center',
    padding: 0,
    fontWeight: 'bold',
    marginBottom: '10px',
    color: COR_PRIMARIA,
  },

  layoutLinkOfuscator: {
    textDecoration: 'none',
    color: 'inherit',
  },

  layoutPointer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#FFF9E0',
    },
  },
}))

export default LayoutEstilo
