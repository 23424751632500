import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles({
  ocorrencias: {
    padding: 0,
  },
  borda: {
    border: '1px solid #ebe8e8',
    fontSize: '12.25px',
    height: '33px',
  },
})

const GraficoDesempenhoAgrupadoEstilo = styles

export default GraficoDesempenhoAgrupadoEstilo
