import React, { useContext } from 'react'
import Container from '@material-ui/core/Container'
import propTypes from 'prop-types'
import LayoutEstilo from '../LayoutEstilo'
import { FiltroContext } from '../LayoutContext'

const LayoutCorpoPadrao = (props) => {
  const classes = LayoutEstilo()
  const context = useContext(FiltroContext)
  const { children, ocultarFiltro } = props
  const { exibirGrafico } = context

  if (ocultarFiltro || (children && children.key && exibirGrafico === children.key)) {
    return (
      <Container maxWidth="xl" className={classes.container}>
        {children ? React.cloneElement(children, props) : ''}
      </Container>
    )
  }
  return ''
}

LayoutCorpoPadrao.propTypes = {
  children: propTypes.element,
}

LayoutCorpoPadrao.defaultProps = {
  children: null,
}

export default LayoutCorpoPadrao
