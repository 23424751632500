import { Subject } from 'rxjs'
import { error } from './Functions'

const subject = new Subject()
export const inscritos = []

export function inscrever(receberMensagem, id) {
  if (!id) {
    error('É necessário informar um identificador para a inscrição!')
  }
  if (!inscritos.includes(id)) {
    inscritos.push(id)
    subject.subscribe({ next: receberMensagem })
  }
}

export function publicar(mensagem) {
  subject.next(mensagem)
}

const FilaDeMensagens = { inscrever, publicar }

export default FilaDeMensagens
