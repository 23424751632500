const PtBr = {
  tabelaPrevisaoCompra: {
    LABEL_UNIDADE: 'Unidade',
    LABEL_ANALISES: 'Análises',
    LABEL_PRODUCAO: 'Produção',
    LABEL_CONSUMO: 'Consumo',
    LABEL_CONSUMO_PLAN: 'mm/kt Plan.',
    LABEL_CONSUMO_REAL: 'mm/kt Real',
    LABEL_ESTOQUE_CILINDROS: 'Estoque Cilindros',
    LABEL_ESTOQUE_UNIDADE_PLANEJADA: 'Unidade Plan.',
    LABEL_ESTOQUE_MM_PLANEJADA: 'mm Plan.',
    LABEL_ESTOQUE_UNIDADE_REAL: 'Unidade Real',
    LABEL_ESTOQUE_MM_REAL: 'mm Real',
    LABEL_ESTOQUE_MINIMO_OPERACIONAL: 'Mín. Operacional',
    LABEL_CORTE: 'Corte',
    LABEL_CORTE_PLANEJADO: 'mm Plan.',
    LABEL_CORTE_REAL: 'mm Real',
    LABEL_TOTAL: 'Consolidado',
    LABEL_EDITAR: 'Editar',
    LABEL_FECHAR: 'FECHAR',
    LABEL_REAL: 'Tonelada Real',
    LABEL_PLANEJADA: 'Tonelada Plan.',
    LABEL_ERRO: 'Houve um erro ao carregar a tabela de previsão de compra. Tente novamente mais tarde.',
    LABEL_ERRO_CONSUMO: 'Houve um erro ao carregar a tabela de previsão de consumo. Tente novamente mais tarde.',
    LABEL_ERRO_CORTE_PREVISAO_REAL: 'Houve um erro ao carregar a tabela de previsão de corte. Tente novamente mais tarde.',
    LABEL_TITULO_EDITAR: 'Previsão de Consumo',
    LABEL_COLUNA_VALOR: 'Previsão de Consumo',
    LABEL_MM_KT: ' mm/kt',
    LABEL_MEDIA_1: 'Consumo médio no ano de ',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_MEDIA_2: ', para o laminador ',
    LABEL_MEDIA_3: ' e tipo de cilindro ',
    LABEL_MEDIA_4: ': ',
    LABEL_PREVISAO_CONSUMO_VALOR_VAZIO: 'É necessário informar o consumo previsto para o ano informado.',
    LABEL_PREVISAO_CONSUMO_VALOR_INVALIDO: 'O valor informado precisa ser um número positivo.',
    LABEL_CILINDROS_RECEBIDOS: 'Cilindros Recebidos',
    LABEL_UNIDADES_PLANEJADAS: 'Unidade Plan.',
    LABEL_UNIDADES_REAL: 'Unidade Real',
    LABEL_MM_PLANEJADO: 'mm Plan.',
    LABEL_MM_REAL: 'mm Real',
    LABEL_EQUIVALENTE: 'Cilindros Equiv. a Novos',
    LABEL_MIN_OPERACIONAL: 'Mín. Operacional Equiv.',
    LABEL_PREVISAO_ESTOQUE_CILINDROS: 'Previsão de Estoque de Cilindros',
    LABEL_PREVISAO_ESTOQUE_PLANEJADO_UNI: 'Estoque Planejado (uni)',
    LABEL_PREVISAO_ESTOQUE_PLANEJADO_MM: 'Estoque Planejado (mm)',
    LABEL_MES_JANEIRO: 'Janeiro',
    LABEL_TIPO: 'Tipo',
    LABEL_SEM_INFORMACAO_ESTOQUE_ANO_ANTERIOR: 'Sem informações de estoque do ano anterior.',
    LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_1: 'O Estoque de Cilindros em',
    LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_2: 'Dezembro/',
    LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_3: ', para o Laminador',
    LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_4: ', cilindros do Tipo',
    LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_5: ' unidades e',
    LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_6: ' mm.',
    LABEL_TRABALHO: 'Trabalho',
    LABEL_ENCOSTO: 'Encosto',
    LABEL_PREVISAO_ESTOQUE_VALOR_MM_INVALIDO: 'O valor informado precisa ser um número válido.',
    LABEL_PREVISAO_ESTOQUE_VALOR_UNIDADE_INVALIDO: 'O valor informado precisa ser um número inteiro válido.',
    LABEL_REGISTRO_PREVISAO_ESTOQUE_ATUALIZADO_COM_SUCESSO: 'Registro de previsão de estoque atualizado com sucesso.',
    LABEL_MINIMO_OPERACIONAL_UNI: 'Mínimo Operacional (uni)',
    LABEL_MINIMO_OPERACIONAL_EQUIVALENTE_NOVOS_UNI: 'Mínimo Operacional Equivalente a Novos (uni)',
    LABEL_MINIMO_OPERACIONAL: 'Mínimo Operacional',
    LABEL_REGISTRO_MINIMO_OPERACIONAL_ATUALIZADO_COM_SUCESSO: 'Registro de Mínimo Operacioal atualizado com sucesso.',
    LABEL_TITULO_GRAFICO_UNIDADE: 'GRÁFICO 1 - ESTOQUE EM UNIDADE (CILINDROS)',
    LABEL_TITULO_GRAFICO_EQUIVALENTE: 'GRÁFICO 2 - ESTOQUE EQUIVALENTE A NOVOS (CILINDROS)',
    LABEL_TITULO_GRAFICO_MM: 'GRÁFICO 3 - ESTOQUE EM MILÍMETROS',
    LABEL_QUANTIDADE: 'Unidades',
    LABEL_MM: 'Milímetros',
    LABEL_UNIDADES_PLANEJADA: 'Unidade Planejada',
    LABEL_ESTOQUE_MM_PREVISTO: 'mm Planejados',
    LABEL_MINIMO_OPERACIONAL_EQUIV: 'Mín. Operacional Equivalente',
    LABEL_MINIMO_OPERACIONAL_MM: 'Mínimo Operacional mm',
    LABEL_TABELA: 'SIMULADOR PARA PREVISÃO DE COMPRA',
    LABEL_GRAFICOS: 'GRÁFICOS',
    LABEL_TOOLTIP_INICIO: 'Para datas ANTERIORES ao dia corrente, os valores representam a quantidade de cilindros que chegaram à fábrica.',
    LABEL_TOOLTIP_FIM: 'Para datas POSTERIORES ao dia corrente, os valores representam a quantidade de cilindros acordados com o fornecedor.',
    LABEL_ALT_INFORMATIVO: 'info',
  },
}

export default PtBr
