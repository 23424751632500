import React from 'react'
import LayoutContentList from './LayoutContentList'
import GraficoTCO from '../../graficoTCO'

const LayoutCustoTCO = () => (
  <LayoutContentList>
    <GraficoTCO key="GraficoTCO" />
  </LayoutContentList>
)

export default LayoutCustoTCO
