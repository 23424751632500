import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { MTableToolbar } from 'material-table'
import { propsConfigToolBar, valueConfigToolBar } from '../UtilsPropTypes'

const configToolBarStyle = makeStyles({
  root: {
    paddingLeft: 0,
    minHeight: 45,
  },
})

function ConfigToolbar(props) {
  const style = configToolBarStyle()
  return (<MTableToolbar {...props} classes={style} />)
}

ConfigToolbar.propTypes = propsConfigToolBar
ConfigToolbar.defaultProps = valueConfigToolBar

export default ConfigToolbar
