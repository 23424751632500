import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../utils'
import Job from '../../utils/Job'
import { checkForTokenUpdate } from '../../autenticacao/AutenticacaoUtils'
import { refreshToken } from '../../autenticacao/AutenticacaoService'
import { circleX } from '../../../assets/images'
import { modalOpen, modalClose, modalConfirm } from '../LayoutUtils'

let modalRevalidarSessaoIsOpen = false

function LayoutModalExpiracaoToken() {
  const { t } = useTranslation()
  const [modal, setModal] = useState(
    { isOpen: false, expirationTime: undefined, buttonDisabled: true },
  )

  useEffect(() => {
    Job(1000)(() => false, () => checkForTokenUpdate(
      modalRevalidarSessaoIsOpen, modalOpen, modalClose, setModal,
    ))
  }, [])

  useEffect(() => {
    modalRevalidarSessaoIsOpen = modal.isOpen
  }, [modal])

  return (
    <Modal
      modalIsOpen={modal.isOpen}
      handleClose={() => modalClose(setModal)}
      handleConfirm={() => modalConfirm(setModal, refreshToken, modalClose)}
      enableButton={modal.buttonDisabled}
      title={t('layout.layoutModalExpiracaoToken.TITULO')}
      header={t('layout.layoutModalExpiracaoToken.CABECALHO')}
      content={`${t('layout.layoutModalExpiracaoToken.CONTEUDO_1')} ${modal.expirationTime} ${t('layout.layoutModalExpiracaoToken.CONTEUDO_2')}`}
      icon={circleX}
      buttonText={t('layout.layoutModalExpiracaoToken.TEXTO_BOTAO')}
    />
  )
}

export default LayoutModalExpiracaoToken
