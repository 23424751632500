import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from './Configuracoes'

const UtilsColorButtonEstilo = withStyles(() => ({
  root: {
    color: '#FFFF',
    backgroundColor: COR_PRIMARIA,
    '&:hover': {
      backgroundColor: COR_PRIMARIA,
    },
  },
}))(Button)

export const UtilsColorButtonEstiloInverse = withStyles(() => ({
  root: {
    color: COR_PRIMARIA,
    backgroundColor: '#FFFF',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
}))(Button)

export default UtilsColorButtonEstilo
