const En = {
  autenticacaoForm: {
    LABEL_TITULO: 'Hello! Welcome to',
    LABEL_USUARIO: 'USER',
    LABEL_SENHA: 'PASSWORD',
    BOTAO_ENTRAR: 'LOGIN',
  },
  autenticacaoLogin: {
    LABEL_LOADING: 'Loading...',
    MESSAGE_USUARIO: 'Empty user!',
    MESSAGE_SENHA: 'Empty password',
    MESSAGE_LOGIN_ERRADO: 'Wrong user or password!',
    MESSAGE_ERRO: 'ERROR',
  },
}

export default En
