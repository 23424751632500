import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, InputLabel } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import FiltroEstilo from '../FiltroEstilo'
import DropDownMultiplo from './FiltroDropDownMultiplo'
import DropDownMultiploComFiltro from './FiltroDropDownMultiploComFiltro'
import DropDownSimples from './FiltroDropDownSimples'
import { propsFiltroBody, valueFiltroBody } from '../FiltroPropTypes'
import FiltroAutoComplete from './FiltroAutocomplete'
import { FiltroContext } from '../../layout/LayoutContext'
import DatePicker from './FiltroDatePicker'
import { close, limpar } from '../../../assets/images'
import {
  Botao, INTERVALO_0_A_25, INTERVALO_25_A_50, INTERVALO_50_A_75, INTERVALO_75_A_100,
  KEY_PAGINA_DESEMPENHO_INDIVIDUAL, KEY_PAGINA_DESEMPENHO_AGRUPADO, KEY_PAGINA_TCO,
  KEY_PAGINA_CUSTO_PREVISAO, SRRadioButton, KEY_PAGINA_DESEMPENHO_DISCRETIZADO,
  KEY_PAGINA_ESTOQUE_CORRENTE, KEY_PAGINA_ESTOQUE_HISTORICO,
} from '../../utils'
import {
  isValidId,
  filtraLaminadores,
  filtraCilindros,
  limparTudo,
  handleChangeRadio,
  handleChangeLinha,
  handleChangeLaminador,
  handleChangeConsumoDoCilindro,
  handleChangeCilindroSimples,
  handleChangeCilindros,
  handleChangeMateriais,
  aplicar,
  handleChangeFornecedor,
} from '../FiltroUtils'

const FiltroBody = ({
  erroTipo, state, setState, fornecedores, setTodosEstados, materiais,
  setMateriais, setErroTipo, setErroLaminador, setErroLinha, erroLaminador, erroLinha,
  limparFiltro, erroConsumoDoCilindro, setErroConsumoDoCilindro, pageKey, erroFornecedor,
  erroMaterial, erroCilindro, setErroCilindro, setErroFornecedor, setErroMaterial,
  dataInicio, dataFim, handleInicio, handleFim, handleFimCompleto, setAbrir,
}) => {
  const { t } = useTranslation()
  const classes = FiltroEstilo()
  const context = useContext(FiltroContext)
  const {
    setExibirGrafico, linhas, laminadores, mudarDataInicio, mudarDataFim,
    mudarLinhaDeProducao, mudarCilindros, mudarMaterial, mudarFornecedores,
    mudarLaminador, mudarTipoDeCilindro, setConsumoDoCilindro, mudarCilindro,
    todosCilindros, mudarTipoDeCilindroCheckedGlobal, mudarMaterialBKP, mudarMaterialWR,
    mudarFornecedoresBKP, mudarFornecedoresWR,
  } = context

  return (
    <div>
      <div className={pageKey === KEY_PAGINA_ESTOQUE_CORRENTE ? 'filtro-header-grid-container-corrente'
        : 'filtro-header-grid-container'}
      >
        <div className="filtro-header">
          <p className={classes.filtroLabel}>{t('filtro.filtroBody.LABEL_FILTRO')}</p>
        </div>
        <div className="filtro-header">
          <IconButton edge="start" color="inherit" aria-label="Menu" onClick={() => setAbrir(false)}>
            <img src={close} alt="fechar" />
          </IconButton>
        </div>
        <div
          className="filtro-header-inicio"
          hidden={pageKey === KEY_PAGINA_DESEMPENHO_DISCRETIZADO
        || pageKey === KEY_PAGINA_CUSTO_PREVISAO || pageKey === KEY_PAGINA_ESTOQUE_CORRENTE}
        >
          <DatePicker
            id="dataInicio"
            futuro={pageKey === KEY_PAGINA_ESTOQUE_HISTORICO}
            evento={handleInicio}
            defaultValue={dataInicio}
            label={t('filtro.filtroHeader.LABEL_DE')}
          />
        </div>
        <div className="filtro-header-inicio" hidden={pageKey !== KEY_PAGINA_DESEMPENHO_DISCRETIZADO}>
          <DatePicker
            completo
            id="dataInicioFull"
            evento={handleInicio}
            defaultValue={dataInicio}
            label={t('filtro.filtroHeader.LABEL_DE')}
          />
        </div>
        <div className="filtro-header-inicio" hidden={pageKey !== KEY_PAGINA_CUSTO_PREVISAO}>
          <DatePicker
            somenteAno
            id="anoInicio"
            evento={handleInicio}
            defaultValue={dataInicio}
            label={t('filtro.filtroHeader.LABEL_DE')}
          />
        </div>
        <div
          className="filtro-header-fim"
          hidden={pageKey === KEY_PAGINA_DESEMPENHO_DISCRETIZADO
        || pageKey === KEY_PAGINA_CUSTO_PREVISAO || pageKey === KEY_PAGINA_ESTOQUE_CORRENTE}
        >
          <DatePicker
            id="dataFim"
            futuro={pageKey === KEY_PAGINA_ESTOQUE_HISTORICO}
            label={t('filtro.filtroHeader.LABEL_ATE')}
            evento={handleFim}
            defaultValue={dataFim}
          />
        </div>
        <div className="filtro-header-fim" hidden={pageKey !== KEY_PAGINA_DESEMPENHO_DISCRETIZADO}>
          <DatePicker
            completo
            id="dataFimFull"
            label={t('filtro.filtroHeader.LABEL_ATE')}
            evento={handleFimCompleto}
            defaultValue={dataFim}
          />
        </div>
        <div className="filtro-header-fim" hidden={pageKey !== KEY_PAGINA_CUSTO_PREVISAO}>
          <DatePicker
            somenteAno
            id="anoFim"
            label={t('filtro.filtroHeader.LABEL_ATE')}
            evento={handleFim}
            defaultValue={dataFim}
          />
        </div>
        <div className={pageKey === KEY_PAGINA_ESTOQUE_CORRENTE ? 'filtro-header-linha-corrente'
          : 'filtro-header-linha'}
        >
          <DropDownSimples
            autoFocus={true || erroLinha}
            erro={erroLinha}
            todos={false}
            id="linha"
            label={t('filtro.filtroBody.LABEL_LINHA')}
            value={isValidId(state.linha) ? state.linha : -1}
            lista={linhas}
            itemId="codLinhaProducao"
            itemValue="codLinhaProducao"
            itemDisplay="siglaLinhaProducao"
            handleChange={event => handleChangeLinha(+event.target.value, setState, state,
              laminadores, {
                setErroLinha,
                setErroLaminador,
                setErroTipo,
                setErroConsumoDoCilindro,
                setErroFornecedor,
                setErroMaterial,
                setErroCilindro,
              })}
          />
        </div>
        <div className={pageKey === KEY_PAGINA_ESTOQUE_CORRENTE ? 'filtro-header-laminador-corrente'
          : 'filtro-header-laminador'}
        >
          <DropDownSimples
            disabled={state.linha === undefined}
            erro={erroLaminador}
            todos={false}
            id="laminador"
            label={t('filtro.filtroBody.LABEL_LAMINADOR')}
            value={isValidId(state.laminador) ? state.laminador : -1}
            lista={filtraLaminadores(laminadores, state.linha)}
            itemId="codLaminador"
            itemValue="codLaminador"
            itemDisplay="siglaLaminador"
            handleChange={event => handleChangeLaminador(+event.target.value, setState, state, {
              setErroLinha,
              setErroLaminador,
              setErroTipo,
              setErroConsumoDoCilindro,
              setErroFornecedor,
              setErroMaterial,
              setErroCilindro,
            })}
          />
        </div>
      </div>
      <Divider />
      <div className="filtro-tipo-grid-container">
        <div className="filtro-tipo-label">
          <InputLabel error={erroTipo}>
            {t('filtro.filtroBody.LABEL_TIPO')}
          </InputLabel>
        </div>
        <div className="filtro-tipo-item">
          <SRRadioButton
            disabled={state.laminador === undefined}
            handleChangeRadio={value => handleChangeRadio(+value, setState, state, setErroTipo,
              setErroConsumoDoCilindro, setErroFornecedor, setErroMaterial, setErroCilindro,
              todosCilindros, state.dataInicio, state.dataFim, setTodosEstados,
              t('filtro.filtroDropDown.LABEL_DIGITE'), pageKey)}
            value={state.tipoDeCilindro ? state.tipoDeCilindro.toString() : ''}
            erro={erroTipo}
          />
        </div>
      </div>
      <Divider />
      <div className="filtro-meio-grid-container" hidden={pageKey === KEY_PAGINA_CUSTO_PREVISAO}>
        <div className="filtro-meio-fornecedor">
          <DropDownMultiplo
            erro={erroFornecedor}
            disabled={state.tipoDeCilindro === undefined && !state.tipoDeCilindroCheck.bur
            && !state.tipoDeCilindroCheck.wr}
            id="fornecedor"
            label={t('filtro.filtroBody.LABEL_FORNECEDOR_DROPDOWN')}
            lista={fornecedores}
            itemValue="codFornecedor"
            itemDisplay="nomeFornecedor"
            handleChangeMultiplo={value => handleChangeFornecedor(value, todosCilindros, state,
              setState, state.dataInicio, state.dataFim, setMateriais, setErroFornecedor,
              setErroCilindro, setErroConsumoDoCilindro, setErroMaterial,
              t('filtro.filtroDropDown.LABEL_DIGITE'), pageKey)}
            valor={state.fornecedores}
          />
        </div>
        <div className="filtro-meio-material">
          <DropDownMultiplo
            erro={erroMaterial}
            disabled={state.fornecedores.length < 1}
            id="material"
            label={t('filtro.filtroBody.LABEL_MATERIAL_DROPDOWN')}
            lista={materiais}
            itemDisplay="descricaoMaterial"
            itemValue="codMaterial"
            handleChangeMultiplo={value => handleChangeMateriais(value, setState, state,
              todosCilindros, { setErroCilindro, setErroConsumoDoCilindro, setErroMaterial },
              { selecione: t('filtro.filtroDropDown.LABEL_DIGITE') }, pageKey)}
            valor={state.materiais}
          />
        </div>
      </div>
      <div className="footer">
        <div
          className="item"
          hidden={pageKey !== KEY_PAGINA_DESEMPENHO_INDIVIDUAL
          && pageKey !== KEY_PAGINA_DESEMPENHO_AGRUPADO
          && pageKey !== KEY_PAGINA_TCO}
        >
          <DropDownMultiploComFiltro
            id="dropDownCilindros"
            label={t('filtro.filtroBody.LABEL_CILINDRO_DROPDOWN')}
            erro={erroCilindro}
            itemValue="codCilindro"
            itemDisplay="numCilindro"
            pageKey={pageKey}
            lista={filtraCilindros(todosCilindros, state.laminador, state.tipoDeCilindro,
              state.fornecedores, state.materiais, state.dataInicio, state.dataFim)}
            handleChangeMultiplo={value => handleChangeCilindros(
              value, setState, state, { setErroCilindro },
            )}
            disabled={state.materiais.length < 1}
            valor={state.cilindros}
          />
        </div>
        <div className="item" hidden={pageKey !== KEY_PAGINA_DESEMPENHO_DISCRETIZADO}>
          <FiltroAutoComplete
            disabled={state.materiais.length < 1}
            erro={erroCilindro}
            todos={false}
            id="cilindroSimples"
            label={t('filtro.filtroBody.LABEL_CILINDRO_DROPDOWN')}
            value={state.cilindro}
            lista={filtraCilindros(todosCilindros, state.laminador, state.tipoDeCilindro,
              state.fornecedores, state.materiais, state.dataInicio, state.dataFim)}
            itemDisplay="numCilindro"
            handleChange={event => handleChangeCilindroSimples(
              event, setState, state, { setErroCilindro },
            )}
          />
        </div>
        <div className="item" hidden={pageKey !== KEY_PAGINA_DESEMPENHO_AGRUPADO && pageKey !== KEY_PAGINA_TCO}>
          <DropDownMultiplo
            erro={erroConsumoDoCilindro}
            disabled={state.materiais.length < 1}
            todos
            id="consumoCilindro"
            label={t('filtro.filtroBody.LABEL_CONSUMO_CILINDRO')}
            lista={[
              { codConsumo: INTERVALO_75_A_100, labelConsumo: t('filtro.filtroBody.LABEL_CONSUMO_100_75') },
              { codConsumo: INTERVALO_50_A_75, labelConsumo: t('filtro.filtroBody.LABEL_CONSUMO_75_50') },
              { codConsumo: INTERVALO_25_A_50, labelConsumo: t('filtro.filtroBody.LABEL_CONSUMO_50_25') },
              { codConsumo: INTERVALO_0_A_25, labelConsumo: t('filtro.filtroBody.LABEL_CONSUMO_25_0') }]}
            itemValue="codConsumo"
            itemDisplay="labelConsumo"
            handleChangeMultiplo={event => handleChangeConsumoDoCilindro(
              event, setState, state, { setErroConsumoDoCilindro },
            )}
            valor={state.consumoDoCilindro}
          />
        </div>
      </div>
      <div className="filtro-botoes-grid-container">
        <div className="filtro-botoes-limpar">
          <div
            id="limpar"
            disabled
            className={classes.limpar}
            onClick={() => limparTudo(limparFiltro, setExibirGrafico)}
            onKeyDown={() => { }}
            role="button"
          >
            <img src={limpar} alt="limpar" />
          </div>
        </div>
        <div className="filtro-botoes-filtrar">
          <Botao
            id="aplicar"
            nome={t('filtro.filtroBody.BOTAO_APLICAR')}
            tamanho="large"
            variant="outlined"
            action={() => aplicar(state, setErroLinha, setErroLaminador, setErroTipo,
              setErroFornecedor, setErroConsumoDoCilindro, setErroMaterial, setErroCilindro,
              mudarDataInicio, mudarDataFim, mudarLinhaDeProducao, mudarCilindros, mudarMaterial,
              mudarFornecedores, mudarLaminador, mudarTipoDeCilindro, setExibirGrafico,
              setConsumoDoCilindro, pageKey, mudarCilindro, t('filtro.filtroDropDown.LABEL_DIGITE'),
              mudarTipoDeCilindroCheckedGlobal, setAbrir, mudarMaterialBKP, mudarMaterialWR,
              mudarFornecedoresBKP, mudarFornecedoresWR)}
          />
        </div>
      </div>
    </div>
  )
}

FiltroBody.propTypes = propsFiltroBody
FiltroBody.defaultProps = valueFiltroBody

export default FiltroBody
