import propTypes from 'prop-types'

const propsTabela = {
  valores: propTypes.object,
}

export const valueTabela = {
  valores: undefined,
}

export default propsTabela
