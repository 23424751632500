export default function TabelaPrevisaoCompraRequisicao(filtro) {
  return (
    {
      codLaminador: filtro.laminador,
      codTipoCilindro: filtro.tipoDeCilindro,
      anoFim: filtro.dataFim ? filtro.dataFim.getFullYear() : '',
      anoInicio: filtro.dataInicio ? filtro.dataInicio.getFullYear() : '',
    }
  )
}

export function TabelaPrevisaoCompraProducaoEditarRequisicao(filtro) {
  return (
    {
      codLaminador: filtro.laminador,
      ano: filtro.ano,
    }
  )
}

export function TabelaPrevisaoCompraConsumoEditarRequisicao(filtro) {
  return (
    {
      codLaminador: filtro.laminador,
      codTipoCilindro: filtro.tipoDeCilindro,
      ano: filtro.ano,
    }
  )
}

export function TabelaPrevisaoCompraCorteRequisicao(filtro) {
  return (
    {
      dataInicio: filtro.dataInicio ? filtro.dataInicio.toISOString() : '',
      dataFim: filtro.dataFim ? filtro.dataFim.toISOString() : '',
      codLaminador: filtro.laminador,
      codTipoCilindro: filtro.tipoDeCilindro,
    }
  )
}

export function TabelaPrevisaoCompraEstoqueRequisicao(filtro) {
  return TabelaPrevisaoCompraCorteRequisicao(filtro)
}

export function TabelaPrevisaoCompraPrevisaoEstoqueCilindrosRequisicao(filtro) {
  return TabelaPrevisaoCompraRequisicao(filtro)
}

export function TabelaPrevisaoCompraPrevisaoEstoqueCilindrosEditarRequisicao(dadosPrevisaoEstoque) {
  return (
    {
      codPrevisaoEstoqueCilindro: dadosPrevisaoEstoque.codPrevisaoEstoqueCilindro,
      codLaminador: dadosPrevisaoEstoque.codLaminador,
      codTipoCilindro: dadosPrevisaoEstoque.codTipoCilindro,
      ano: dadosPrevisaoEstoque.ano,
      valorUnidade: dadosPrevisaoEstoque.valorUnidade,
      valorMm: dadosPrevisaoEstoque.valorMm,
    }
  )
}

export function TabelaPrevisaoCompraMinimoOperacionalRequisicao(
  filtro, dadosMinimoOperacional = {},
) {
  return (
    {
      codMinimoOperacional: dadosMinimoOperacional.codMinimoOperacional,
      codLaminador: dadosMinimoOperacional.codLaminador || filtro.laminador,
      codTipoCilindro: dadosMinimoOperacional.codTipoCilindro || filtro.tipoDeCilindro,
      ano: dadosMinimoOperacional.ano || 1,
      valor: dadosMinimoOperacional.valor,
      equivalente: dadosMinimoOperacional.equivalente,
    }
  )
}

export function TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosRequisicao(filtro) {
  return {
    codLaminador: filtro.laminador,
    anoFim: filtro.dataFim ? filtro.dataFim.getFullYear() : '',
    anoInicio: filtro.dataInicio ? filtro.dataInicio.getFullYear() : '',
  }
}

export function TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosRequisicao(filtro) {
  return TabelaPrevisaoCompraRequisicao(filtro)
}

export function TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosRequisicao(filtro) {
  return TabelaPrevisaoCompraRequisicao(filtro)
}
