import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoTCOService from '../GraficoTCOService'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import {
  ErroGrafico, useStateSafely, SRAccordion, procurarMenorValor, ordenarPorCampos,
} from '../../utils'
import GraficoTCOOpcoes from '../GraficoTCOOpcoes'
import { FiltroContext } from '../../layout/LayoutContext'
import { obterDados } from '../GraficoTCOUtils'
import GraficoTCOTabela from './GraficoTCOTabela'

let materiais
let setMateriais
let dados
let setDados
let loading
let setLoading
let valoresTabela
let setValoresTabela

const GraficoTCO = () => {
  [materiais, setMateriais] = useStateSafely([]);
  [dados, setDados] = useStateSafely([]);
  [valoresTabela, setValoresTabela] = useStateSafely([]);
  [loading, setLoading] = useStateSafely(false)
  const { t } = useTranslation()
  const filtro = useContext(FiltroContext)

  const labels = {
    titulo: t('graficoTCO.graficoTCO.LABEL_TITULO_GRAFICO'),
    coluna: t('graficoTCO.graficoTCO.LABEL_COLUNA'),
    materiais: t('graficoTCO.graficoTCO.LABEL_MATERIAIS'),
    tituloTabela: t('graficoTCO.graficoTCO.LABEL_TABELA'),
  }

  useEffect(() => {
    setLoading(true)
    setMateriais([])
    setDados([])
    setValoresTabela([])
    async function conectar() {
      try {
        const resposta = await GraficoTCOService(filtro)
        if (resposta && resposta.length > 0 && resposta[0] !== 'Erro') {
          procurarMenorValor(resposta, 'tco', 'nomeFornecedor')
          resposta.sort(ordenarPorCampos(['minimo', 'nomeFornecedor', 'tco']))
          const valores = obterDados(resposta, t)
          setMateriais(valores.materiais)
          setDados(valores.dados)
          setValoresTabela(valores)
        }
        if (resposta && resposta[0] === 'Erro') {
          setDados(resposta)
        }
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [filtro, t])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (dados && dados.length === 0 && materiais && materiais.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoTCO.graficoTCO.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoTCO.graficoTCO.LABEL_TITULO_GRAFICO')}
        />
      )
    }
    if (dados && materiais && dados.length > 0 && materiais.length > 0) {
      return (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '400px' } }}
            options={GraficoTCOOpcoes(dados, materiais, labels)}
          />
          <SRAccordion
            aberto
            titulo={labels.tituloTabela}
          >
            <GraficoTCOTabela valores={valoresTabela} />
          </SRAccordion>
        </>
      )
    } return (
      <AlertaModal
        url={t('geral.routes.URL_TCO')}
        mensagem={t('graficoTCO.graficoTCO.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}


export default GraficoTCO
