import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { LoadingLogin } from '../../loading'
import AutenticacaoForm from './AutenticacaoForm'
import AutenticacaoEstilo from '../AutenticacaoEstilo'
import handleChangeLogin, { logar } from '../AutenticacaoUtils'
import AutenticacaoAlterarSenha from './AutenticacaoAlterarSenha'

function AutenticacaoLogin(props) {
  const [usuario, setUsuario] = useState('')
  const [senha, setSenha] = useState('')
  const [carregando, setCarregando] = useState(false)
  const [erroUsuario, setErroUsuario] = useState(false)
  const [erroSenha, setErroSenha] = useState(false)
  const classes = AutenticacaoEstilo()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  if (carregando) {
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <LoadingLogin loading={carregando} message={t('autenticacao.autenticacaoLogin.LABEL_LOADING')} />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container component="main" className={classes.root}>
      {open ? (
        <AutenticacaoAlterarSenha login handleClose={setOpen} />
      ) : null}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <AutenticacaoForm
          id="autenticacaoForm"
          setUsuario={valor => handleChangeLogin(valor, usuario, setUsuario, setErroUsuario)}
          setSenha={valor => handleChangeLogin(valor, senha, setSenha, setErroSenha)}
          erroUsuario={erroUsuario}
          erroSenha={erroSenha}
          evento={event => logar(event, usuario,
            senha, setErroUsuario, setErroSenha, setCarregando, props, setOpen)}
        />
      </Grid>
    </Grid>
  )
}

export default withRouter(AutenticacaoLogin)
