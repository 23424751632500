const PtBr = {
  respostaServidor: {
    ANTIGA_INCORRETA: 'A senha antiga informada está errada.',
    ESTOQUE_MINIMO_DUPLICADO: 'Estoque mínimo já cadastrado.',
    ERRO_ACESSO_BANCO: 'Erro ao acessar o banco de dados.',
    undefined: 'Erro ao processar a requisição.',
    ERRO_INTERNO_API: 'Erro interno na API.',
    ERRO_REGISTRO_DUPLICADO: 'Erro: Registro duplicado!',
    ERRO_VIOLACAO_INTEGRIDADE: 'Erro de violação de integridade no banco de dados.',
    NOTA_FISCAL_SOMENTE_NUMEROS: 'O número de nota fiscal deve conter apenas números',
    ERRO_NOTA_FISCAL_JA_EM_USO: 'Erro: O número de nota fiscal informado já está em uso.',
    LOGIN_EXISTENTE: 'Erro: O nome de usuário informado já existe',
  },
  erroGrafico: {
    LABEL_TITULO: 'O gráfico não pode ser exibido.',
    LABEL_MENSAGEM: 'Corrija as informações e faça uma nova busca.',
  },
  imagemErroGrafico: {
    ALT_IMAGEM: 'erro',
  },
  graficoLegendaUtils: {
    LABEL_CAMPANHA: 'Campanha vencida',
    LABEL_NOVO: 'Novo',
    LABEL_OPERACAO: 'Em operação',
    LABEL_REPARO: 'Reparo externo',
    LABEL_RETIFICADO: 'Retificado',
    ALT_CAMPANHA: 'campanha vencida',
    ALT_NOVO: 'novo',
    ALT_OPERACAO: 'em operação',
    ALT_REPARO: 'reparo externo',
    ALT_RETIFICADO: 'retificado',
    LABEL_RANGE: 'Faixa de Diâmetro: Inicial = ',
    LABEL_RANGE_FIM: 'mm / Final = ',
    LABEL_MM: 'mm',
    LABEL_DIAMETRO: 'Diâmetros Inicial e Final',
    LABEL_OCORRENCIAS: 'Ocorrências',
    LABEL_MOTIVOS: 'Motivos do Desbaste',
  },
}

export default PtBr
