import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { ConfigTraducaoLocalizada, ConfigToolbar } from '../../utils'
import { materialTableStyle } from '../../config/ConfigEstilo'
import { FiltroContext } from '../../layout/LayoutContext'
import {
  getColunasFormatadasCadastroMinimoOperacional,
  stringsCadastroMinimoOperacional,
  optionsTabelaComUmaLinha,
  editarRegistroMinimoOperacional,
  filtraDadosApresentadosNoModalDeMinimoOperacional,
} from '../TabelaPrevisaoCompraUtils'
import { TabelaPrevisaoCompraMinimoOperacionalEditarService } from '../TabelaPrevisaoCompraService'
import {
  propsTabelaPrevisaoCompraEstoqueMinimoOperacional,
  valuesTabelaPrevisaoCompraEstoqueMinimoOperacional,
} from '../TabelaPrevisaoCompraPropTypes'

function TabelaPrevisaoCompraEstoqueMinimoOperacional({
  dados, setEditado, usarValorEquivalente, ano,
}) {
  const { t } = useTranslation()
  const localizacao = ConfigTraducaoLocalizada(t)
  const context = React.useContext(FiltroContext)
  const { laminadores, tipoDeCilindro, laminador } = context
  const { nomesColunas, msgs } = stringsCadastroMinimoOperacional(t)

  const [dadosModal, setDadosModal] = React.useState(
    filtraDadosApresentadosNoModalDeMinimoOperacional(dados, usarValorEquivalente),
  )

  return (
    <MaterialTable
      id="tabelaCadastroMinimoOperacional"
      style={materialTableStyle}
      title={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MINIMO_OPERACIONAL')}
      columns={getColunasFormatadasCadastroMinimoOperacional(
        laminadores.filter(l => l.codLaminador === laminador),
        nomesColunas, tipoDeCilindro, usarValorEquivalente, { [ano]: ano },
      )}
      data={dadosModal}
      options={optionsTabelaComUmaLinha}
      localization={localizacao}
      editable={{
        onRowAdd: (dadosModal && dadosModal.length > 0) ? undefined
          : newData => editarRegistroMinimoOperacional(
            newData, dadosModal, msgs, TabelaPrevisaoCompraMinimoOperacionalEditarService,
            setEditado, setDadosModal, usarValorEquivalente,
          ),
        onRowUpdate: newData => editarRegistroMinimoOperacional(
          newData, dadosModal, msgs, TabelaPrevisaoCompraMinimoOperacionalEditarService,
          setEditado, setDadosModal, usarValorEquivalente,
        ),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

TabelaPrevisaoCompraEstoqueMinimoOperacional.propTypes = (
  propsTabelaPrevisaoCompraEstoqueMinimoOperacional)
TabelaPrevisaoCompraEstoqueMinimoOperacional.defaultProps = (
  valuesTabelaPrevisaoCompraEstoqueMinimoOperacional)

export default TabelaPrevisaoCompraEstoqueMinimoOperacional
