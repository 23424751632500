const PtBr = {
  graficoDiametro: {
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de diâmetro dos pares de cilindros. Tente novamente mais tarde.',
    LABEL_TITULO: 'DIÂMETRO DOS PARES DE CILINDROS',
    LABEL_DIAMETRO: 'Diâmetro (mm)',
    LABEL_VIDA: 'Vida Útil (%)',
    LABEL_CILINDROS: 'Cilindro(s)',
  },
}

export default PtBr
