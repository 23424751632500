import React from 'react'
import { DialogContent, Dialog } from '@material-ui/core'
import { TituloModal } from '../../utils'
import TabelaPrevisaoCompraBotao from './TabelaPrevisaoCompraBotao'
import {
  propsTabelaPrevisaoCompraEstoqueModal,
  valuesTabelaPrevisaoCompraEstoqueModal,
} from '../TabelaPrevisaoCompraPropTypes'

function TabelaPrevisaoCompraEstoqueModal({
  closeHandle, children, titulo, ano,
}) {
  return (
    <Dialog onClose={closeHandle} aria-labelledby="ariaModalTabelaPrevisaoCompraEstoque" open fullWidth maxWidth="xl">
      <TituloModal id="idModalTabelaPrevisaoCompraEstoqueTitulo" onClose={closeHandle}>
        {titulo}
      </TituloModal>
      <DialogContent>
        {children}
      </DialogContent>
      <TabelaPrevisaoCompraBotao fecharTela={closeHandle} ano={ano} id="idModalTabelaPrevisaoCompraEstoqueBotaoFechar" />
    </Dialog>
  )
}

TabelaPrevisaoCompraEstoqueModal.propTypes = propsTabelaPrevisaoCompraEstoqueModal
TabelaPrevisaoCompraEstoqueModal.defaultProps = valuesTabelaPrevisaoCompraEstoqueModal

export default TabelaPrevisaoCompraEstoqueModal
