import React from 'react'
import UtilsEstilo from '../UtilsEstilo'
import { valueButton, propsButton } from '../UtilsPropTypes'
import UtilsColorButtonEstilo, { UtilsColorButtonEstiloInverse } from '../UtilsColorButtonEstilo'

const Botao = ({
  nome, tamanho, action, id, tipo, inverse, esconder,
}) => {
  const classes = UtilsEstilo()
  if (inverse) {
    return (
      <UtilsColorButtonEstiloInverse disabled={esconder} type={tipo} id={id} variant="contained" color="primary" size={tamanho} className={classes.margin} onClick={action}>
        {nome}
      </UtilsColorButtonEstiloInverse>
    )
  }
  return (
    <UtilsColorButtonEstilo disabled={esconder} type={tipo} id={id} variant="contained" color="primary" size={tamanho} className={classes.margin} onClick={action}>
      {nome}
    </UtilsColorButtonEstilo>
  )
}
Botao.propTypes = propsButton
Botao.defaultProps = valueButton

export default Botao
