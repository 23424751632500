import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { tabelaConfig } from '../ConfigEstilo'
import { propsConfigGeralCardTitulo, valuesConfigGeralCardTitulo } from '../ConfigPropTypes'

function ConfigGeralCardTitulo({
  flechaParaCima, titulo, clickHandle,
}) {
  const estilo = tabelaConfig()
  return (
    <div
      className={estilo.titulo}
      onClick={clickHandle}
      onKeyDown={() => undefined}
      role="button"
      tabIndex="-1"
    >
      {titulo}
      <IconButton style={{ paddingBottom: 3, paddingTop: 3 }}>
        {flechaParaCima ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </div>
  )
}

ConfigGeralCardTitulo.propTypes = propsConfigGeralCardTitulo
ConfigGeralCardTitulo.defaultProps = valuesConfigGeralCardTitulo

export default ConfigGeralCardTitulo
