import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import UseStateSafely from '../../utils/UseStateSafely'
import LoadingGrafico from '../../loading'
import { estilo } from '../TabelaPrevisaoCompraEstilo'
import TabelaPrevisaoCompraProducaoService, {
  TabelaPrevisaoCompraConsumoService,
  TabelaPrevisaoCompraCorteService, TabelaPrevisaoCompraRecebimentoCilindrosService,
  TabelaPrevisaoCompraEquivalentesService,
  TabelaPrevisaoCompraEstoquePrevisaoRealService,
  TabelaPrevisaoCompraPrevisaoEstoqueCilindroService,
  TabelaPrevisaoCompraMinimoOperacionalService,
  TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosService,
  TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosService,
  TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosService,
} from '../TabelaPrevisaoCompraService'
import TabelaPrevisaoCompraCorte from './TabelaPrevisaoCompraCorte'
import { AlertaModal } from '../../alerta'
import { FiltroContext } from '../../layout/LayoutContext'
import { SRAccordion, makeCancelable } from '../../utils'
import TabelaPrevisaoCompraProducao from './TabelaPrevisaoCompraProducao'
import TabelaPrevisaoCompraConsumo from './TabelaPrevisaoCompraConsumo'
import TabelaPrevisaoCompraRecebimentoCilindros from './TabelaPrevisaoCompraRecebimentoCilindros'
import TabelaPrevisaoCompraEquivalente from './TabelaPrevisaoCompraEquivalente'
import TabelaPrevisaoCompraEstoque from './TabelaPrevisaoCompraEstoque'
import TabelaPrevisaoCompraGrafico from './TabelaPrevisaoCompraGrafico'
import TabelaPrevisaoCompraCabecalho from './TabelaPrevisaoCompraCabecalho'

let loading
let setLoading
let producaoPrevistaReal
let setProducaoPrevistaReal
let cortePrevisaoReal
let setCortePrevisaoReal
let consumoPrevistoReal
let setConsumoPrevistoReal
let recebimentoCilindros
let setRecebimentoCilindros
let cilindrosEquivalentes
let setCilindrosEquivalentes
let estoquePrevistoReal
let setEstoquePrevistoReal
let dadosPrevisaoEstoqueCilindro
let setDadosPrevisaoEstoqueCilindro
let dadosMinimoOperacional
let setDadosMinimoOperacional
let dadosPrevisaoProducao
let setDadosPrevisaoProducao
let dadosPrevisaoConsumo
let setDadosPrevisaoConsumo
let dadosChegadaCilindro
let setDadosChegadaCilindro

const TabelaPrevisaoCompra = () => {
  [loading, setLoading] = UseStateSafely(false);
  [producaoPrevistaReal, setProducaoPrevistaReal] = UseStateSafely([]);
  [cortePrevisaoReal, setCortePrevisaoReal] = UseStateSafely([]);
  [consumoPrevistoReal, setConsumoPrevistoReal] = UseStateSafely([]);
  [recebimentoCilindros, setRecebimentoCilindros] = UseStateSafely([]);
  [cilindrosEquivalentes, setCilindrosEquivalentes] = UseStateSafely([]);
  [estoquePrevistoReal, setEstoquePrevistoReal] = UseStateSafely([]);
  [dadosPrevisaoEstoqueCilindro, setDadosPrevisaoEstoqueCilindro] = UseStateSafely([]);
  [dadosMinimoOperacional, setDadosMinimoOperacional] = UseStateSafely([]);
  [dadosPrevisaoProducao, setDadosPrevisaoProducao] = UseStateSafely({});
  [dadosPrevisaoConsumo, setDadosPrevisaoConsumo] = UseStateSafely({});
  [dadosChegadaCilindro, setDadosChegadaCilindro] = UseStateSafely({})

  const [itemAberto, setItemAberto] = useState({})
  const filtro = useContext(FiltroContext)
  const { t } = useTranslation()
  const classes = estilo()
  const anoAtual = new Date().getUTCFullYear()

  async function atualizarDados(ano) {
    setLoading(true)
    const cancelablePromise = makeCancelable(Promise.all([
      TabelaPrevisaoCompraProducaoService(filtro),
      TabelaPrevisaoCompraCorteService(filtro),
      TabelaPrevisaoCompraConsumoService(filtro),
      TabelaPrevisaoCompraRecebimentoCilindrosService(filtro),
      TabelaPrevisaoCompraEquivalentesService(filtro),
      TabelaPrevisaoCompraEstoquePrevisaoRealService(filtro),
      TabelaPrevisaoCompraPrevisaoEstoqueCilindroService(filtro),
      TabelaPrevisaoCompraMinimoOperacionalService(filtro),
      TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosService(filtro),
      TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosService(filtro),
      TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosService(filtro),
    ]))
    try {
      await cancelablePromise.promise.then((
        [
          dadosAPI,
          dadosCortePrevisaoRealAPI,
          dadosConsumo,
          dadosRecebimento,
          dadosEquivalentes,
          dadosEstoquePrevisaoRealAPI,
          dadosPrevisaoEstoqueCilindroAPI,
          dadosMinimoOperacionalAPI,
          dadosPrevisaoProducaoAPI,
          dadosPrevisaoConsumoAPI,
          dadosChegadaCilindroAPI,
        ],
      ) => {
        setProducaoPrevistaReal(dadosAPI)
        setCortePrevisaoReal(dadosCortePrevisaoRealAPI)
        setConsumoPrevistoReal(dadosConsumo)
        setRecebimentoCilindros(dadosRecebimento)
        setCilindrosEquivalentes(dadosEquivalentes)
        setEstoquePrevistoReal(dadosEstoquePrevisaoRealAPI)
        setDadosPrevisaoEstoqueCilindro(dadosPrevisaoEstoqueCilindroAPI)
        setDadosMinimoOperacional(dadosMinimoOperacionalAPI)
        setDadosPrevisaoProducao(dadosPrevisaoProducaoAPI)
        setDadosPrevisaoConsumo(dadosPrevisaoConsumoAPI)
        setDadosChegadaCilindro(dadosChegadaCilindroAPI)
        setLoading(false)
      })
      setItemAberto({
        ...itemAberto,
        [ano]: true,
      })
      setLoading(false)
    } catch (err) {
      if (!err.isCanceled) { console.log(err) }
    }
  }

  useEffect(() => {
    function conectar() {
      const cancelablePromise = makeCancelable(Promise.all([
        TabelaPrevisaoCompraProducaoService(filtro),
        TabelaPrevisaoCompraCorteService(filtro),
        TabelaPrevisaoCompraConsumoService(filtro),
        TabelaPrevisaoCompraRecebimentoCilindrosService(filtro),
        TabelaPrevisaoCompraEquivalentesService(filtro),
        TabelaPrevisaoCompraEstoquePrevisaoRealService(filtro),
        TabelaPrevisaoCompraPrevisaoEstoqueCilindroService(filtro),
        TabelaPrevisaoCompraMinimoOperacionalService(filtro),
        TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosService(filtro),
        TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosService(filtro),
        TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosService(filtro),
      ]))
      return cancelablePromise.promise.then((
        [
          dadosAPI,
          dadosCortePrevisaoRealAPI,
          dadosConsumo,
          dadosRecebimento,
          dadosEquivalentes,
          dadosEstoquePrevisaoRealAPI,
          dadosPrevisaoEstoqueCilindroAPI,
          dadosMinimoOperacionalAPI,
          dadosPrevisaoProducaoAPI,
          dadosPrevisaoConsumoAPI,
          dadosChegadaCilindroAPI,
        ],
      ) => {
        setProducaoPrevistaReal(dadosAPI)
        setCortePrevisaoReal(dadosCortePrevisaoRealAPI)
        setConsumoPrevistoReal(dadosConsumo)
        setRecebimentoCilindros(dadosRecebimento)
        setCilindrosEquivalentes(dadosEquivalentes)
        setEstoquePrevistoReal(dadosEstoquePrevisaoRealAPI)
        setDadosPrevisaoEstoqueCilindro(dadosPrevisaoEstoqueCilindroAPI)
        setDadosMinimoOperacional(dadosMinimoOperacionalAPI)
        setDadosPrevisaoProducao(dadosPrevisaoProducaoAPI)
        setDadosPrevisaoConsumo(dadosPrevisaoConsumoAPI)
        setDadosChegadaCilindro(dadosChegadaCilindroAPI)
        setLoading(false)
      }).catch((err) => {
        if (!err.isCanceled) { console.log(err) }
      })
    }

    setLoading(true)
    setItemAberto({})
    setProducaoPrevistaReal([])
    setCortePrevisaoReal([])
    setConsumoPrevistoReal([])
    setRecebimentoCilindros([])
    setCilindrosEquivalentes([])
    setEstoquePrevistoReal([])

    conectar().catch(err => console.log(err))
  }, [filtro])

  if (loading) {
    return <LoadingGrafico />
  }

  if (producaoPrevistaReal[0] === 'Erro' || cortePrevisaoReal[0] === 'Erro'
  || consumoPrevistoReal[0] === 'Erro' || recebimentoCilindros[0] === 'Erro'
  || cilindrosEquivalentes[0] === 'Erro' || estoquePrevistoReal[0] === 'Erro'
  || dadosPrevisaoEstoqueCilindro[0] === 'Erro' || dadosMinimoOperacional[0] === 'Erro'
  || dadosPrevisaoProducao[0] === 'Erro' || dadosPrevisaoConsumo[0] === 'Erro'
  || dadosChegadaCilindro[0] === 'Erro') {
    return (
      <AlertaModal
        url={t('geral.routes.URL_CUSTO_PREVISAO')}
        mensagem={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ERRO')}
      />
    )
  }

  return (
    <>
      {producaoPrevistaReal.map(item => (
        <SRAccordion
          aberto={itemAberto[item.ano]}
          centralizado
          titulo={item.ano.toString()}
          key={Math.random()}
          itemAberto={itemAberto}
          setItemAberto={setItemAberto}
          valorItemAberto={item.ano}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <SRAccordion
                aberto
                titulo={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TABELA')}
                key={Math.random()}
              >
                <table width="100%" className={classes.borda} key={Math.random()}>
                  <tbody>
                    <TabelaPrevisaoCompraCabecalho
                      item={item}
                      key={Math.random()}
                      editavel={anoAtual <= item.ano}
                    />
                  </tbody>
                  <tbody>
                    <TabelaPrevisaoCompraProducao
                      item={item}
                      key={Math.random()}
                      atualizarDados={ano => atualizarDados(ano)}
                      editavel={anoAtual <= item.ano}
                      dadosPrevisaoProducao={dadosPrevisaoProducao[item.ano]}
                    />
                  </tbody>
                  <tbody>
                    <TabelaPrevisaoCompraConsumo
                      item={consumoPrevistoReal.filter(
                        dados => dados.ano === item.ano,
                      ).reduce(a => a)}
                      key={Math.random()}
                      atualizarDados={ano => atualizarDados(ano)}
                      editavel={anoAtual <= item.ano}
                      dadosPrevisaoConsumo={dadosPrevisaoConsumo[item.ano]
                        ? [dadosPrevisaoConsumo[item.ano]] : undefined}
                    />
                  </tbody>
                  <tbody>
                    <TabelaPrevisaoCompraCorte
                      item={cortePrevisaoReal.find(
                        itemCortePrevisaoReal => itemCortePrevisaoReal.ano === item.ano,
                      )}
                      key={Math.random()}
                      atualizarDados={ano => atualizarDados(ano)}
                      editavel={anoAtual <= item.ano}
                    />
                  </tbody>
                  <tbody>
                    <TabelaPrevisaoCompraEstoque
                      dadosEstoquePrevistoReal={estoquePrevistoReal}
                      dadosPrevisaoEstoque={dadosPrevisaoEstoqueCilindro}
                      dadosMinimoOperacional={dadosMinimoOperacional}
                      ano={item.ano}
                      key={Math.random()}
                      atualizarDados={ano => atualizarDados(ano)}
                      editavel={anoAtual <= item.ano}
                    />
                  </tbody>
                  <tbody>
                    <TabelaPrevisaoCompraEquivalente
                      item={cilindrosEquivalentes.filter(
                        dados => dados.ano === item.ano,
                      ).reduce(a => a)}
                      key={Math.random()}
                      atualizarDados={ano => atualizarDados(ano)}
                      editavel={anoAtual <= item.ano}
                      ano={item.ano}
                      dadosMinimoOperacional={dadosMinimoOperacional}
                    />
                  </tbody>
                  <tbody>
                    <TabelaPrevisaoCompraRecebimentoCilindros
                      item={recebimentoCilindros.filter(dados => dados.ano === item.ano)
                        .reduce(a => a)}
                      key={Math.random()}
                      atualizarDados={ano => atualizarDados(ano)}
                      editavel={anoAtual <= item.ano}
                      dadosChegadaCilindro={dadosChegadaCilindro[item.ano]}
                    />
                  </tbody>
                </table>
              </SRAccordion>
            </Grid>
            <Grid item xs={12}>
              <SRAccordion
                aberto
                titulo={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_GRAFICOS')}
                key={Math.random()}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} />
                  <Grid item xs={6}>
                    <TabelaPrevisaoCompraGrafico dados={estoquePrevistoReal[item.ano]} tipo="unidade" />
                  </Grid>
                  <Grid item xs={6}>
                    <TabelaPrevisaoCompraGrafico dados={cilindrosEquivalentes.filter(dados => dados.ano === item.ano).reduce(a => a)} tipo="equivalente" />
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <TabelaPrevisaoCompraGrafico dados={estoquePrevistoReal[item.ano]} tipo="milimetro" />
                  </Grid>
                </Grid>
              </SRAccordion>
            </Grid>
          </Grid>
        </SRAccordion>
      ))}
    </>
  )
}

export default TabelaPrevisaoCompra
