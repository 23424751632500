import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { propsTabela, valueTabela } from '../GraficoDesempenhoAgrupadoPropTypes'
import { SRAccordion, unicoValor, obterQuantidadeFornecedor } from '../../utils'
import GraficoDesempenhoAgrupadoOcorrencias from './GraficoDesempenhoAgrupadoOcorrencias'
import { styles } from '../GraficoDesempenhoAgrupadoEstilo'

export default function GraficoDesempenhoAgrupadoTabela({
  nomes, consumoTotal, desempenhoTotal, ocorrencias,
  consumoNormal, desempenhoNormal, tipo, fornecedores,
  campanhas, pesos, labels, cortePadrao, corteTotal,
  mostrarSomenteOFornecedor,
}) {
  const classes = styles()
  let fornecedoresUnicos
  if (fornecedores) {
    fornecedoresUnicos = fornecedores.filter(unicoValor)
  }
  return (
    <TableContainer component={Paper}>
      <Table aria-label="tabelaDesempenhoAgrupado" size="small">
        <TableBody>
          <TableRow key={1}>
            <TableCell align="center" className={classes.borda}><b>{labels.fornecedor}</b></TableCell>
            {fornecedoresUnicos ? fornecedoresUnicos.map(row => (
              <TableCell
                align="center"
                key={Math.random()}
                className={classes.borda}
                colSpan={obterQuantidadeFornecedor(fornecedores, row)}
              >
                {row}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={2}>
            <TableCell align="center" className={classes.borda}><b>{labels.material}</b></TableCell>
            {nomes ? nomes.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                <b>{row}</b>
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={3}>
            <TableCell align="center" className={classes.borda}><b>{labels.campanhas}</b></TableCell>
            {campanhas ? campanhas.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row ? row.toLocaleString('pt-BR', { maximumFractionDigits: 0 }) : 0}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={4}>
            <TableCell align="center" className={classes.borda}><b>{labels.tonelagem}</b></TableCell>
            {pesos ? pesos.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {`${row.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}`}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={5}>
            <TableCell align="center" className={classes.borda}><b>{labels.mmTotal}</b></TableCell>
            {corteTotal ? corteTotal.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={6}>
            <TableCell align="center" className={classes.borda}><b>{labels.mmNormal}</b></TableCell>
            {cortePadrao ? cortePadrao.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={7} hidden={tipo === 'Consumo'}>
            <TableCell align="center" className={classes.borda}><b>{labels.total}</b></TableCell>
            {desempenhoTotal ? desempenhoTotal.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={8} hidden={tipo === 'Consumo'}>
            <TableCell align="center" className={classes.borda}><b>{labels.normal}</b></TableCell>
            {desempenhoNormal ? desempenhoNormal.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={9} hidden={tipo === 'Desempenho'}>
            <TableCell align="center" className={classes.borda}><b>{labels.totalConsumo}</b></TableCell>
            {consumoTotal ? consumoTotal.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={10} hidden={tipo === 'Desempenho'}>
            <TableCell align="center" className={classes.borda}><b>{labels.normalConsumo}</b></TableCell>
            {consumoNormal ? consumoNormal.map(row => (
              <TableCell align="center" key={Math.random()} className={classes.borda}>
                {row.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              </TableCell>
            )) : null}
          </TableRow>
          <TableRow key={11}>
            <TableCell align="center" className={classes.ocorrencias} colSpan={nomes ? nomes.length + 1 : 2}>
              <SRAccordion titulo={labels.ocorrencias} aberto={!!mostrarSomenteOFornecedor}>
                <GraficoDesempenhoAgrupadoOcorrencias
                  ocorrencias={ocorrencias}
                  fornecedores={fornecedores}
                  materiais={nomes}
                  labels={labels}
                />
              </SRAccordion>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

GraficoDesempenhoAgrupadoTabela.propTypes = propsTabela
GraficoDesempenhoAgrupadoTabela.defaultProps = valueTabela
