export function formatarTooltip(ponto, labels) {
  if (ponto && labels && (ponto.point.series.userOptions.name === labels.ritmo)) {
    if (ponto.y === 1 || ponto.y === -1) {
      return `${ponto.y} ${labels.cilindro}`
    }
    return `${ponto.y} ${labels.cilindros}`
  }
  return false
}

function formatarDatalabels(ponto, ritmo) {
  if (!ponto || !ritmo || (ponto.point.series.userOptions.name === ritmo)) {
    return null
  }
  return ponto.y === 0 ? null : ponto.y
}

export default formatarDatalabels
