import { publicar as ExibirAlerta } from '../utils/FilaDeMensagens'
import * as ConfigUtils from '../config/ConfigUtils'
import {
  isNumberPositive, contarDecimais, formatarDataMesApenas,
  formatarDataMesCompleto, formatarNumero, verificaStringDeNumeroPtBr,
} from '../utils'

export const PREVISTO_MM = 'previstoMm'
export const PREVISTO_UNIDADE = 'previstoUnidade'

export function getColunasFormatadasCadastroPrevisaoProducao(laminadores, labels, anoUnico) {
  return [
    ConfigUtils.getColunaLaminador(labels, laminadores, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, true),
    ConfigUtils.getColunaAno(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, ConfigUtils.comparadorParaOrdenarPorAnoEMes, anoUnico),
    ConfigUtils.getColunaMes(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, true),
    ConfigUtils.getColunaValor(labels, { textAlign: 'left' }, { textAlign: 'left' }),
  ]
}

export function getColunasFormatadasCadastroPrevisaoConsumo(laminadores, labels, anoUnico,
  tipoDeCilindro) {
  return [
    ConfigUtils.getColunaLaminador(labels, laminadores, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, true),
    ConfigUtils.getColunaTipoCilindro(labels, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }, tipoDeCilindro),
    ConfigUtils.getColunaAno(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, ConfigUtils.comparadorParaOrdenarPorAnoEMes, anoUnico),
    ConfigUtils.getColunaValor(labels, { textAlign: 'left' }, { textAlign: 'left' }),
  ]
}

export function getColunasFormatadasCadastroMinimoOperacional(
  laminadores, labels, tipoDeCilindro, usarValorEquivalente, anoUnico,
) {
  return [
    ConfigUtils.getColunaLaminador(labels, laminadores, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, true),
    ConfigUtils.getColunaTipoCilindro(labels, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }, tipoDeCilindro),
    ConfigUtils.getColunaAno(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, undefined, anoUnico),
    (usarValorEquivalente ? {
      title: labels.lblValorEquivalente,
      field: 'equivalente',
      defaultSort: 'asc',
      editable: 'always',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      render: informacao => formatarNumero(informacao.equivalente),
    } : ConfigUtils.getColunaValor(labels, { textAlign: 'left' }, { textAlign: 'left' })),
  ]
}

export function getColunasFormatadasCadastroPrevisaoEstoque(
  laminadores, labels, tipoDeCilindro, anoUnico, linha,
) {
  const {
    lblEstoquePlanejadoUnidade, lblEstoquePlanejadoMm,
  } = labels
  return [
    ConfigUtils.getColunaLaminador(labels, laminadores, { width: '12%', textAlign: 'left' }, { width: '12%', textAlign: 'left' }, true),
    ConfigUtils.getColunaTipoCilindro(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, tipoDeCilindro),
    ConfigUtils.getColunaAno(labels, { width: '12%', textAlign: 'left' }, { width: '12%', textAlign: 'left' }, undefined, anoUnico),
    ConfigUtils.getColunaMes(labels, { width: '12%', textAlign: 'left' }, { width: '12%', textAlign: 'left' }, true, 1),
    {
      title: lblEstoquePlanejadoUnidade,
      field: 'valorUnidade',
      defaultSort: 'asc',
      editable: linha === PREVISTO_UNIDADE ? 'always' : 'never',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      render: informacao => formatarNumero(informacao.valorUnidade),
    },
    {
      title: lblEstoquePlanejadoMm,
      field: 'valorMm',
      editable: linha === PREVISTO_MM ? 'always' : 'never',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      render: informacao => formatarNumero(informacao.valorMm, 2),
    },
  ]
}

export const optionsTabelaComUmaLinha = {
  actionsHeaderStyle: { width: '10%', textAlign: 'left', alignItems: 'left' },
  addRowPosition: 'first',
  pageSizeOptions: [],
  actionsColumnIndex: -1,
  search: false,
  paging: false,
}

export function stringsCadastroMinimoOperacional(t) {
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TIPO'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblAno: t('config.config.LABEL_ANO'),
    lblValor: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MINIMO_OPERACIONAL_UNI'),
    lblValorEquivalente: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MINIMO_OPERACIONAL_EQUIVALENTE_NOVOS_UNI'),
  }
  const msgs = {
    lblValorUnidadeInvalido: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_ESTOQUE_VALOR_UNIDADE_INVALIDO'),
    msgSucesso: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_REGISTRO_MINIMO_OPERACIONAL_ATUALIZADO_COM_SUCESSO'),
  }
  return { nomesColunas, msgs }
}

export function stringsCadastroPrevisaoEstoque(t) {
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TIPO'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblAno: t('config.config.LABEL_ANO'),
    lblMes: t('config.config.LABEL_MES'),
    lblMeses: {
      1: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MES_JANEIRO'),
    },
    lblEstoquePlanejadoUnidade: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_ESTOQUE_PLANEJADO_UNI'),
    lblEstoquePlanejadoMm: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_ESTOQUE_PLANEJADO_MM'),
  }
  const msgs = {
    lblValorUnidadeInvalido: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_ESTOQUE_VALOR_UNIDADE_INVALIDO'),
    lblValorInvalido: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_ESTOQUE_VALOR_MM_INVALIDO'),
    msgSucesso: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_REGISTRO_PREVISAO_ESTOQUE_ATUALIZADO_COM_SUCESSO'),
  }
  return { nomesColunas, msgs }
}

export function stringsConfigPrevisaoProducao(t) {
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblAno: t('config.config.LABEL_ANO'),
    lblMes: t('config.config.LABEL_MES'),
    lblValor: t('config.config.LABEL_PREVISAO_PRODUCAO_VALUE'),
    lblMeses: {
      1: t('config.config.LABEL_MES_JAN'),
      2: t('config.config.LABEL_MES_FEV'),
      3: t('config.config.LABEL_MES_MAR'),
      4: t('config.config.LABEL_MES_ABR'),
      5: t('config.config.LABEL_MES_MAI'),
      6: t('config.config.LABEL_MES_JUN'),
      7: t('config.config.LABEL_MES_JUL'),
      8: t('config.config.LABEL_MES_AGO'),
      9: t('config.config.LABEL_MES_SET'),
      10: t('config.config.LABEL_MES_OUT'),
      11: t('config.config.LABEL_MES_NOV'),
      12: t('config.config.LABEL_MES_DEZ'),
    },
  }
  const msgs = {
    msgLaminadorVazio: t('config.config.LABEL_LAMINADOR_VAZIO'),
    msgValorVazio: t('config.config.LABEL_PREVISAO_PRODUCAO_VALOR_VAZIO'),
    msgValorInvalido: t('config.config.LABEL_PREVISAO_PRODUCAO_VALOR_INVALIDO'),
    msgSucesso: t('config.config.LABEL_PREVISAO_PRODUCAO_SALVAR_SUCESSO'),
  }
  return { nomesColunas, msgs }
}

export function stringsConfigPrevisaoConsumo(t) {
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('config.config.LABEL_TIPO_CILINDRO'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblAno: t('config.config.LABEL_ANO'),
    lblValor: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_COLUNA_VALOR'),
    lblUnidadeDeMedida: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MM_KT'),
  }
  const msgs = {
    msgLaminadorVazio: t('config.config.LABEL_LAMINADOR_VAZIO'),
    msgValorVazio: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_CONSUMO_VALOR_VAZIO'),
    msgValorInvalido: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_CONSUMO_VALOR_INVALIDO'),
    msgSucesso: t('config.config.LABEL_PREVISAO_PRODUCAO_SALVAR_SUCESSO'),
  }
  return { nomesColunas, msgs }
}

export function validaDadosRegistroPrevisaoProducao(
  dadosDatabela,
  { msgLaminadorVazio, msgValorInvalido },
) {
  const { codLaminador, valor } = dadosDatabela
  if (!codLaminador) {
    ExibirAlerta({ text: msgLaminadorVazio, type: 'error', esquerda: true })
    return false
  }
  if (valor === '' || valor === undefined || valor === null) {
    return { ...dadosDatabela, codLaminador: +codLaminador, valor: null }
  }
  if (typeof valor === 'string' && !verificaStringDeNumeroPtBr(valor)) {
    ExibirAlerta({ text: msgValorInvalido, type: 'error', esquerda: true })
    return false
  }
  const valorComoNumero = Number(valor.toString().replace('.', '').replace(',', '.'))
  if (!Number.isSafeInteger(valorComoNumero) || valorComoNumero < 0) {
    ExibirAlerta({ text: msgValorInvalido, type: 'error', esquerda: true })
    return false
  }
  return { ...dadosDatabela, codLaminador: +codLaminador, valor: valorComoNumero }
}

export function updateDadosPrevisaoProducao(dados, registroNovo) {
  const linhas = [...dados]
  const index = linhas.findIndex(
    registro => registro.codPrevisaoProducao === registroNovo.codPrevisaoProducao,
  )
  if (index < 0) linhas.push(registroNovo)
  else linhas[index] = registroNovo
  return linhas
}

export function updateDadosPrevisaoConsumo(dados, registroNovo) {
  const linhas = [...dados]
  const index = linhas.findIndex(
    registro => registro.codPrevisaoConsumo === registroNovo.codPrevisaoConsumo,
  )
  if (index < 0) linhas.push(registroNovo)
  else linhas[index] = registroNovo
  return linhas
}

export default async function salvarRegistroPrevisaoProducao(
  newData, dados, setDados, msgs, salvarOuCriarRegistro, setEditado,
) {
  const registroPrevisaoProducao = validaDadosRegistroPrevisaoProducao(
    newData, msgs,
  )
  if (!registroPrevisaoProducao) {
    console.log('Problema com os dados de entrada')
    console.log(newData)
    return Promise.reject('Problema com os dados de entrada')
  }
  try {
    const resp = await salvarOuCriarRegistro(registroPrevisaoProducao)
    setDados(updateDadosPrevisaoProducao(dados, resp))
    ExibirAlerta({ text: msgs.msgSucesso, type: 'success', esquerda: true })
    setEditado(true)
    return Promise.resolve(resp)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

export function validaDadosRegistroPrevisaoEstoque(
  newData, linha, { lblValorUnidadeInvalido, lblValorInvalido },
) {
  const valor = linha === PREVISTO_MM ? newData.valorMm : newData.valorUnidade
  if (valor === null) return { dadosOk: true, valorDeEntrada: null }
  if (valor === undefined) return { dadosOk: true, valorDeEntrada: null }
  if (typeof valor === 'string') {
    if (!valor) {
      return { dadosOk: true, valorDeEntrada: null }
    }
    if (!verificaStringDeNumeroPtBr(valor)) {
      ExibirAlerta({ text: lblValorInvalido, type: 'error', esquerda: true })
      return { dadosOk: false, valorDeEntrada: valor }
    }
    const valorSemVirgula = valor.replace('.', '').replace(',', '.')
    if (isNaN(valorSemVirgula)) {
      ExibirAlerta({ text: lblValorInvalido, type: 'error', esquerda: true })
      return { dadosOk: false, valorDeEntrada: valorSemVirgula }
    }
    const valorNumerico = Number(valorSemVirgula)
    if (linha === PREVISTO_MM) {
      if (valorNumerico >= 0) {
        return { dadosOk: true, valorDeEntrada: valorNumerico }
      }
      ExibirAlerta({ text: lblValorInvalido, type: 'error', esquerda: true })
      return { dadosOk: false, valorDeEntrada: valorNumerico }
    }
    if (linha === PREVISTO_UNIDADE) {
      if (Number.isSafeInteger(valorNumerico) && valorNumerico >= 0) {
        return { dadosOk: true, valorDeEntrada: valorNumerico }
      }
      ExibirAlerta({ text: lblValorUnidadeInvalido, type: 'error', esquerda: true })
      return { dadosOk: false, valorDeEntrada: valorNumerico }
    }
  } else if (typeof valor === 'number') {
    if (linha === PREVISTO_MM && valor >= 0) {
      return { dadosOk: true, valorDeEntrada: valor }
    }
    if (linha === PREVISTO_UNIDADE) {
      if (Number.isSafeInteger(valor) && valor >= 0) {
        return { dadosOk: true, valorDeEntrada: valor }
      }
      ExibirAlerta({ text: lblValorUnidadeInvalido, type: 'error', esquerda: true })
      return { dadosOk: false, valorDeEntrada: valor }
    }
  }
  ExibirAlerta({ text: lblValorInvalido, type: 'error', esquerda: true })
  return { dadosOk: false, valorDeEntrada: valor }
}

export async function editarRegistroPrevisaoEstoque(
  newData, linha, msgs, salvarOuCriarRegistro, setEditado, setDados,
) {
  const { dadosOk, valorDeEntrada } = validaDadosRegistroPrevisaoEstoque(
    newData, linha, msgs,
  )
  if (dadosOk) {
    const registroPrevisaoEstoque = { ...newData }
    if (linha === PREVISTO_UNIDADE) {
      registroPrevisaoEstoque.valorUnidade = valorDeEntrada
    } else {
      registroPrevisaoEstoque.valorMm = valorDeEntrada
    }
    try {
      const resp = await salvarOuCriarRegistro(registroPrevisaoEstoque)
      ExibirAlerta({ text: msgs.msgSucesso, type: 'success', esquerda: true })
      setDados([registroPrevisaoEstoque])
      setEditado(true)
      return resp
    } catch (err) {
      console.log(err)
      return err
    }
  }
  console.log('Problema com os dados de entrada')
  console.log(newData)
  return Promise.reject('Problema com os dados de entrada')
}

export function validaDadosRegistroPrevisaoConsumo(
  dadosDatabela,
  { msgValorVazio, msgValorInvalido },
) {
  const { codLaminador, valor, codTipoCilindro } = dadosDatabela
  if (codTipoCilindro === '' || !codTipoCilindro) {
    ExibirAlerta({ text: msgValorVazio, type: 'error', esquerda: true })
    return false
  }
  if (valor === '' || valor === null || valor === undefined) {
    return { ...dadosDatabela, codLaminador: +codLaminador, valor: null }
  }
  if (typeof valor === 'string' && !verificaStringDeNumeroPtBr(valor)) {
    ExibirAlerta({ text: msgValorInvalido, type: 'error', esquerda: true })
    return false
  }
  const valorNumericoValidoComoString = valor.toString().replace('.', '').replace(',', '.')
  if (!isNumberPositive(valorNumericoValidoComoString)) {
    ExibirAlerta({ text: msgValorInvalido, type: 'error', esquerda: true })
    return false
  }
  if (contarDecimais(parseFloat(valorNumericoValidoComoString)) > 2) {
    ExibirAlerta({ text: msgValorInvalido, type: 'error', esquerda: true })
    return false
  }
  const valorComoNumero = Number(valorNumericoValidoComoString)
  return { ...dadosDatabela, codLaminador: +codLaminador, valor: valorComoNumero }
}

export async function salvarRegistroPrevisaoConsumo(
  newData, dados, setDados, msgs, salvarOuCriarRegistro, setEditado,
) {
  const registroPrevisaoConsumo = validaDadosRegistroPrevisaoConsumo(
    newData, msgs,
  )
  if (!registroPrevisaoConsumo) {
    console.log('Problema com os dados de entrada')
    console.log(newData)
    return Promise.reject('Problema com os dados de entrada')
  }
  try {
    const resp = await salvarOuCriarRegistro(registroPrevisaoConsumo)
    setDados(updateDadosPrevisaoConsumo(dados, resp))
    ExibirAlerta({ text: msgs.msgSucesso, type: 'success', esquerda: true })
    setEditado(true)
    return Promise.resolve(resp)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

export function zerarValoresPequenos(numero) {
  if (parseFloat(numero) <= 0.05 && parseFloat(numero) >= -0.05) {
    return 0.0
  }
  return numero
}

export function validaDadosRegistroMinimoOperacional(
  dados, { lblValorUnidadeInvalido }, usarValorEquivalente,
) {
  const [valor, attr] = usarValorEquivalente
    ? [dados.equivalente, 'equivalente'] : [dados.valor, 'valor']
  if (valor === null) return { ...dados, [attr]: null }
  if (valor === undefined) return { ...dados, [attr]: null }
  if (typeof valor === 'string') {
    if (!valor) return { ...dados, [attr]: null }
    if (!verificaStringDeNumeroPtBr(valor)) {
      ExibirAlerta({ text: lblValorUnidadeInvalido, type: 'error', esquerda: true })
      return undefined
    }
    const valorNumerico = Number(valor.replace('.', '').replace(',', '.'))
    if (valor && Number.isSafeInteger(valorNumerico) && valorNumerico >= 0) {
      return { ...dados, [attr]: valorNumerico }
    }
    ExibirAlerta({ text: lblValorUnidadeInvalido, type: 'error', esquerda: true })
    return undefined
  }
  if (typeof valor === 'number') {
    if (Number.isSafeInteger(valor) && valor >= 0) {
      return { ...dados, [attr]: valor }
    }
    ExibirAlerta({ text: lblValorUnidadeInvalido, type: 'error', esquerda: true })
    return undefined
  }
  ExibirAlerta({ text: lblValorUnidadeInvalido, type: 'error', esquerda: true })
  return undefined
}

export function updateDadosMinimoOperacional(dados, dadosMinimoOperacional) {
  const linhas = [...dados]
  const novaLinha = {
    codMinimoOperacional: dadosMinimoOperacional.codMinimoOperacional,
    codLaminador: dadosMinimoOperacional.codLaminador,
    codTipoCilindro: dadosMinimoOperacional.codTipoCilindro,
    ano: dadosMinimoOperacional.ano,
    valor: dadosMinimoOperacional.valor,
    equivalente: dadosMinimoOperacional.equivalente,
  }
  const index = linhas.findIndex(
    regMinOp => regMinOp.codMinimoOperacional === dadosMinimoOperacional.codMinimoOperacional,
  )
  if (index < 0) linhas.push(novaLinha)
  else linhas[index] = novaLinha
  return linhas
}

export async function editarRegistroMinimoOperacional(
  newData, dados, msgs, salvarOuCriarRegistro,
  setEditado, setDados, usarValorEquivalente,
) {
  const registroMinimoOperacional = validaDadosRegistroMinimoOperacional(
    newData, msgs, usarValorEquivalente,
  )
  if (!registroMinimoOperacional) {
    console.log('Problema com os dados de entrada')
    console.log(newData)
    return Promise.reject('Problema com os dados de entrada')
  }
  try {
    const resp = await salvarOuCriarRegistro({}, registroMinimoOperacional)
    if (resp[0] !== 'Erro') {
      setDados(updateDadosMinimoOperacional(dados, resp))
      ExibirAlerta({ text: msgs.msgSucesso, type: 'success', esquerda: true })
      setEditado(true)
    }
    return Promise.resolve(resp)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

export function formatarSeriesGrafico(dados, tipo) {
  let datas
  let primeiraColuna
  let segundaColuna
  let serieLinha

  if (tipo === 'unidade') {
    primeiraColuna = dados.estoque.map(item => item.previstoUnidade)
    segundaColuna = dados.estoque.map(item => item.realUnidade)
    serieLinha = dados.estoque.map(item => item.minimoOperacionalUnidade)
    datas = formatarDataMesApenas(
      dados.estoque.map(item => new Date(Date.UTC(dados.ano, item.mes, 1))),
    )
  } else if (tipo === 'milimetro') {
    primeiraColuna = dados.estoque.map(item => item.previstoMm)
    segundaColuna = dados.estoque.map(item => item.realMm)
    serieLinha = dados.estoque.map(item => item.minimoOperacionalMm)
    datas = formatarDataMesCompleto(
      dados.estoque.map(item => new Date(Date.UTC(dados.ano, item.mes, 1))),
    )
  } else {
    primeiraColuna = dados.equivalenteNovos.map(item => item.previstoUnidade)
    segundaColuna = dados.equivalenteNovos.map(item => item.realUnidade)
    serieLinha = dados.equivalenteNovos.map(() => dados.minimoOperacionalEquivalente)
    datas = formatarDataMesApenas(
      dados.equivalenteNovos.map(item => new Date(Date.UTC(dados.ano, item.mes, 1))),
    )
  }
  return {
    datas, primeiraColuna, segundaColuna, serieLinha,
  }
}

export function obterLabels(t, tipo) {
  if (tipo === 'unidade') {
    return {
      titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TITULO_GRAFICO_UNIDADE'),
      quantidade: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_QUANTIDADE'),
      previsto: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_PLANEJADA'),
      real: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_REAL'),
      minimo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MINIMO_OPERACIONAL'),
      datas: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_DATAS'),
    }
  }
  if (tipo === 'milimetro') {
    return {
      titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TITULO_GRAFICO_MM'),
      quantidade: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MM'),
      previsto: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_MM_PREVISTO'),
      real: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_MM_REAL'),
      minimo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MINIMO_OPERACIONAL_MM'),
      datas: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_DATAS'),
    }
  }
  return {
    titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TITULO_GRAFICO_EQUIVALENTE'),
    quantidade: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_QUANTIDADE'),
    previsto: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_PLANEJADA'),
    real: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_REAL'),
    minimo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MINIMO_OPERACIONAL_EQUIV'),
    datas: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_DATAS'),
  }
}

export function formatarTooltip(ponto, labels, tipo) {
  if (tipo === 'unidade') {
    return ponto.points.reduce((s, point) => `${s}<br/>${point.series.name}: ${
      point.y}`, `<b>${ponto.x}</b>`)
  }
  if (tipo === 'milimetro') {
    return ponto.points.reduce((s, point) => `${s}<br/>${point.series.name}: ${
      formatarNumero(point.y, 2)}`, `<b>${ponto.x}</b>`)
  }
  return ponto.points.reduce((s, point) => `${s}<br/>${point.series.name}: ${
    point.series.name === labels.minimo ? formatarNumero(point.y) : formatarNumero(point.y, 1)}`, `<b>${ponto.x}</b>`)
}

export function contarMesesComCampoInformado(array, campo) {
  let contador = 0
  for (let a = 0; a < array.length; a += 1) {
    if (array[a][campo] !== null && array[a][campo] !== undefined) {
      contador += 1
    }
  }
  return contador
}

export function somarArray(array, campo) {
  if (!array || !campo) {
    return null
  }
  let total = null

  for (let a = 0; a < array.length; a += 1) {
    if (array[a][campo] !== null && array[a][campo] !== undefined && !isNaN(array[a][campo])) {
      total += array[a][campo]
    }
  }
  return total
}

export function objGraficoVazio(dados) {
  if (!dados) {
    return false
  }
  for (let a = 0; a < dados.primeiraColuna.length; a += 1) {
    if (dados.primeiraColuna[a]) {
      return false
    }
  }
  for (let a = 0; a < dados.segundaColuna.length; a += 1) {
    if (dados.segundaColuna[a]) {
      return false
    }
  }
  return true
}

export function filtraDadosApresentadosNoModalDeMinimoOperacional(
  dados, usarValorEquivalente,
) {
  if (!Array.isArray(dados)) return []
  const [registroDeMinimoOperacional] = dados
  if (!registroDeMinimoOperacional) return []
  const valor = usarValorEquivalente
    ? registroDeMinimoOperacional.equivalente : registroDeMinimoOperacional.valor
  if (valor === undefined || valor === null) return []
  return dados
}
