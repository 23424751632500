import { DoLogout } from '../utils'
import Navigation from '../utils/Navigation'

export default function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export function sair() {
  DoLogout()
}

export function resetarPagina(url) {
  Navigation.navigateTo(url)
}

export function handleChange(evento, valor) {
  return evento(valor)
}
