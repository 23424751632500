import propTypes from 'prop-types'

export const propsGraficoDesempenhoAgrupado = {
  filtro: propTypes.object,
  classes: propTypes.object,
  formatacao: propTypes.object,
}

export const valueGraficoDesempenhoAgrupado = {
  filtro: undefined,
  classes: undefined,
  formatacao: {},
}

export const propsTabela = {
  nomes: propTypes.array,
  consumoTotal: propTypes.array,
  desempenhoTotal: propTypes.array,
  consumoNormal: propTypes.array,
  desempenhoNormal: propTypes.array,
  tipo: propTypes.string,
  fornecedores: propTypes.array,
  campanhas: propTypes.array,
  pesos: propTypes.array,
  labels: propTypes.object,
  cortePadrao: propTypes.array,
  corteTotal: propTypes.array,
  mostrarSomenteOFornecedor: propTypes.string,
}

export const valueTabela = {
  mostrarSomenteOFornecedor: undefined,
}

export const propsOcorrencias = {
  ocorrencias: propTypes.object,
  materiais: propTypes.array,
  fornecedores: propTypes.array,
  labels: propTypes.object,
}

export const valueGraficoDesempenhoAgrupadoModalPrint = {
  isOpen: false,
  modalClose: undefined,
  alterarFornecedor: undefined,
  fornecedores: [],
}

export const propsGraficoDesempenhoAgrupadoModalPrint = {
  isOpen: propTypes.bool,
  modalClose: propTypes.func,
  alterarFornecedor: propTypes.func,
  fornecedores: propTypes.array,
}

export const propsGraficoDesempenhoAgrupadoConteudoModalImprimir = {
  fornecedores: propTypes.array,
  fornecedorEspecifico: propTypes.string,
  setFornecedorEspecifico: propTypes.func,
  exibirFornecedorEspecifico: propTypes.string,
  setExibirFornecedorEspecifico: propTypes.func,
  opcoes: propTypes.object,
}

export const valueGraficoDesempenhoAgrupadoConteudoModalImprimir = {
  fornecedores: [],
  fornecedorEspecifico: '',
  setFornecedorEspecifico: undefined,
  exibirFornecedorEspecifico: undefined,
  setExibirFornecedorEspecifico: undefined,
  opcoes: {},
}
