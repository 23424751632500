import Highcharts from 'highcharts'
import {
  formatarData, Traducao, COR_PRIMARIA,
} from '../utils'
import { exportar } from '../../assets/images'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function GraficoEquivalenteNovoOpcoes(estoque, datas, estoqueMinimo, lblTitulo,
  lblEstoque, lblEquivalente, lblQtdPares, lblData) {
  return ({
    chart: {
      type: 'line',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          // eslint-disable-next-line no-param-reassign
          dadosDoGrafico.dataRows[0][0] = lblData
        },
      },
    },
    title: {
      text: lblTitulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: formatarData(datas),
    },
    yAxis: {
      title: {
        text: lblQtdPares,
      },
    },
    plotOptions: {
      series: {
        events: {
          legendItemClick() {
            return false
          },
        },
      },
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    legend: {
      itemStyle: {
        fontWeight: 'normal',
      },
      symbolWidth: 5,
    },
    series: [{
      name: lblEquivalente,
      data: estoque,
      color: '#00B0DB',
    }, {
      name: lblEstoque,
      data: estoqueMinimo,
      color: '#951B81',
    }],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoEquivalenteNovoOpcoes
