import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { SnackbarProvider } from 'notistack'
import LayoutInicio from './features/layout/views/LayoutInicio'
import 'bootstrap/dist/css/bootstrap.min.css'
import './features/i18n'

ReactDOM.render(
  <SnackbarProvider maxSnack={3}>
    <LayoutInicio />
  </SnackbarProvider>, document.getElementById('root'),
)
