export default function formatarDados(medida, cor, corPar, diametro, menorCilindro, pares) {
  if ((medida.length === cor.length) && (diametro.length === medida.length)) {
    const dados = []
    for (let i = 0; i < medida.length; i += 1) {
      dados.push({
        y: medida[i],
        color: cor[i],
        corPar: corPar[i] ? corPar[i] : null,
        diametroPar: diametro[i],
        menorCilindro,
        cilindros: pares[i],
      })
    }
    return dados
  }
  return null
}

export function formatarTooltip(ponto) {
  const indice = ponto.points[0].point.index
  const menorCilindro = ponto.points[0].point.menorCilindro[indice]
  const cilindroPar = ponto.points[0].point.cilindros[0] === menorCilindro
    ? ponto.points[0].point.cilindros[1] : ponto.points[0].point.cilindros[0]
  const { diametroPar } = ponto.points[0].point
  const { corPar } = ponto.points[0].point
  const segundaLinha = cilindroPar ? `${cilindroPar}:<span style="color: ${corPar}"> Ø </span>${diametroPar.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} mm` : ''
  return `${menorCilindro}:<span style="color: ${ponto.points[0].color}"> Ø </span>${ponto.y.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} mm<br>
  ${segundaLinha}`
}
