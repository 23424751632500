import moment from 'moment'
import { EXCEPTION_PREFIX, COR_PRIMARIA } from './Configuracoes'

export function formatarData(array) {
  return array.map(date => moment(date).format('MMM[/]YY'))
}

export function error(msg) {
  throw new Error(`${EXCEPTION_PREFIX} ${msg}`)
}

export function zipArray(primeiroArray, segundoArray, primeiroNome = 'fst', segundoNome = 'snd') {
  if (primeiroArray.length === segundoArray.length) {
    const dados = []
    for (let i = 0; i < primeiroArray.length; i += 1) {
      dados.push({ [primeiroNome]: primeiroArray[i], [segundoNome]: segundoArray[i] })
    }
    return dados
  }
  return null
}

export function corGraficoSituacao(situacao) {
  const cor = []
  for (let i = 0; i < situacao.length; i += 1) {
    if (situacao[i] === 'Campanha Vencida' || situacao[i] === 1) {
      cor.push('#951B81')
    }
    if (situacao[i] === 'Novo' || situacao[i] === 3) {
      cor.push('#4298D6')
    }
    if (situacao[i] === 'Retificado' || situacao[i] === 5) {
      cor.push('#F8A501')
    }
    if (situacao[i] === 'Reparo Externo' || situacao[i] === 4) {
      cor.push(COR_PRIMARIA)
    }
    if (situacao[i] === 'Em Operação' || situacao[i] === 2) {
      cor.push('#95C11C')
    }
    if (situacao[i] === null) {
      cor.push(null)
    }
  }
  return cor
}

export function obterJaneiroAtual() {
  const dataAtual = new Date()
  const anoAtual = dataAtual.getUTCFullYear()
  const janeiroAtual = new Date(anoAtual, 0, 1)
  return janeiroAtual
}

export function ordenarArrayPorChave(array, chave) {
  if (array && array.length > 0) {
    return array.sort((a, b) => {
      if (a[chave] > b[chave]) {
        return 1
      }
      if (a[chave] < b[chave]) {
        return -1
      }
      return 0
    })
  } return null
}

export function procurarMaiorValor(obj, campo, vlrAgrupado) {
  const dados = obj
  for (let i = 0; i < obj.length; i += 1) {
    let valor = obj[i][campo]
    const agrupado = obj[i][vlrAgrupado]
    for (let a = 0; a < obj.length; a += 1) {
      if (agrupado === obj[a][vlrAgrupado]) {
        if (valor < obj[a][campo]) {
          valor = obj[a][campo]
        }
      }
    }
    dados[i] = { ...dados[i], maximo: valor }
  }
  return dados
}

export function procurarMenorValor(obj, campo, vlrAgrupado) {
  const dados = obj
  for (let i = 0; i < obj.length; i += 1) {
    let valor = obj[i][campo]
    const agrupado = obj[i][vlrAgrupado]
    for (let a = 0; a < obj.length; a += 1) {
      if (agrupado === obj[a][vlrAgrupado]) {
        if (valor > obj[a][campo]) {
          valor = obj[a][campo]
        }
      }
    }
    dados[i] = { ...dados[i], minimo: valor }
  }
  return dados
}

export function objetoFormatado(array, id, valor) {
  return array.reduce((obj, item) => {
    const dados = obj
    dados[item[id]] = item[valor]
    return dados
  }, {})
}

export function montarObjetoAnos(inicial, final) {
  if (final < inicial) {
    return null
  }
  let dados = {}
  for (let i = inicial; i <= final; i += 1) {
    dados = { ...dados, [i]: i }
  }
  return dados
}

export function isNumberPositive(n) {
  return !isNaN(parseFloat(n)) && isFinite(n) && n >= 0
}

export function obterAnosProximos() {
  const dataAtual = new Date()
  const anoAtual = dataAtual.getUTCFullYear()
  return {
    anoAtual,
    proximoAno: anoAtual + 1,
    anoSubsequente: anoAtual + 2,
    anoAnterior: anoAtual - 1,
  }
}

export function formatarEstoqueAtual(array, individual) {
  let resposta
  if (individual) {
    resposta = array.map(item => parseFloat(item.toFixed(1)))
  } else {
    resposta = array.map(item => parseFloat((item / 2).toFixed(1)))
  }
  return resposta
}

export function formatarEstoqueMinimo(array, individual) {
  let resposta
  if (individual) {
    resposta = array.map(item => parseFloat((item * 2).toFixed(1)))
  } else {
    resposta = array.map(item => parseFloat(item.toFixed(1)))
  }
  return resposta
}

export function contarDecimais(valor) {
  const strValue = (typeof valor === 'string') ? valor.replace(',', '.') : valor.toString()
  const numValue = (typeof valor === 'number') ? valor : parseFloat(strValue)
  if (Number.isInteger(numValue)) return 0
  return strValue.includes('e-') ? +strValue.toString().split('e-')[1] : strValue.split('.')[1].length
}

export function formatarSituacao(valor, lblNovo, lblRetificado, lblOperacao,
  lblCampanha, lblReparo) {
  const dados = []
  if (valor.includes(3)) {
    dados.push(lblNovo)
  }
  if (valor.includes(5)) {
    dados.push(lblRetificado)
  }
  if (valor.includes(2)) {
    dados.push(lblOperacao)
  }
  if (valor.includes(1)) {
    dados.push(lblCampanha)
  }
  if (valor.includes(4)) {
    dados.push(lblReparo)
  }
  return dados
}

export function trocaPontoPorVirgula(numero, casasDecimais = 1) {
  if (typeof numero === 'number') { return numero.toFixed(casasDecimais).replace('.', ',') }
  return numero
}

export function makeCancelable(promise) {
  let hasCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      err => (hasCanceled ? reject({ isCanceled: true }) : reject(err)),
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    },
  }
}

export function formatarOpcoesAgrupado(tipoGrafico, valor) {
  const formato = tipoGrafico !== 'consumo' ? valor.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  return formato
}

export function converteMaiusculaMinuscula(texto) {
  const loweredText = texto.toLowerCase()
  const words = loweredText.split(' ')
  for (let a = 0; a < words.length; a += 1) {
    let w = words[a]
    const firstLetter = w[0]
    if (w.length > 2) {
      w = firstLetter.toUpperCase() + w.slice(1)
    } else {
      w = firstLetter + w.slice(1)
    }
    words[a] = w
  }
  return words.join(' ')
}

export function isFunction(func) {
  return (typeof func === 'function')
}

export function and(value) {
  if (Array.isArray(value)) return value.reduce((acc, x) => acc && !!x, true)
  return !!value
}

export function obterNomeMes(numeroMes, nomes) {
  if (!numeroMes || numeroMes < 1 || numeroMes > 12 || !nomes) {
    return null
  }
  switch (numeroMes) {
    case 1:
      return nomes.jan
    case 2:
      return nomes.fev
    case 3:
      return nomes.mar
    case 4:
      return nomes.abr
    case 5:
      return nomes.mai
    case 6:
      return nomes.jun
    case 7:
      return nomes.jul
    case 8:
      return nomes.ago
    case 9:
      return nomes.set
    case 10:
      return nomes.out
    case 11:
      return nomes.nov
    default:
      return nomes.dez
  }
}

export function formatarNumero(numero, casasDecimais) {
  if (numero == null) {
    return '-'
  }
  if (!casasDecimais) {
    return numero.toLocaleString('pt-BR', { maximumFractionDigits: 0 })
  }
  return numero.toLocaleString('pt-BR', { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais })
}

export function arrayZerado(array) {
  if (!array || array.length === 0) {
    return true
  }
  const itensZerados = array.filter(nr => nr <= 0).length
  if (itensZerados === array.length) {
    return true
  }
  return false
}

export function obterLaminadorCompletoPorCodigo(codigo, lista) {
  if (!codigo || !lista || lista.length <= 0 || codigo <= 0) {
    return null
  }
  const retorno = lista.filter(item => item.codLaminador === codigo)
  return retorno
}

export function formatarDataMesApenas(array) {
  return array.map(date => moment(date).format('MMM'))
}

export function formatarDataMesCompleto(array) {
  return array.map(date => moment(date).format('MMMM'))
}

export function obterQuantidadeFornecedor(fornecedores, nome) {
  let contador = 0
  for (let i = 0; i < fornecedores.length; i += 1) {
    if (fornecedores[i] === nome) {
      contador += 1
    }
  }
  return contador
}

export function unicoValor(value, index, self) {
  return self.indexOf(value) === index
}

export const ordenarPorCampos = fields => (a, b) => fields.map((o) => {
  let dir = 1
  let ob = o
  if (o[0] === '-') {
    dir = -1
    ob = o.substring(1)
  }
  return a[ob] > b[ob] ? dir : a[ob] < b[ob] ? -(dir) : 0
}).reduce((p, n) => (p || n), 0)

const reggexNumero = /^\s*-?(?:[1-9]\d{0,2}(?:\.\d{3})*|\d+|0)(?:,\d*)?$/

export function verificaStringDeNumeroPtBr(numero) {
  return typeof numero === 'string' && reggexNumero.test(numero)
}
