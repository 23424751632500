import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { Botao } from '../../utils'
import { valueBotao, propBotao } from '../TabelaPrevisaoCompraPropTypes'

const TabelaPrevisaoCompraBotao = ({ fecharTela, ano, id }) => {
  const { t } = useTranslation()

  return (
    <DialogActions disableSpacing id={`${id}actions`}>
      <Grid container justify="flex-end">
        <Grid item>
          <Botao
            id={`${id}botao`}
            nome={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_FECHAR')}
            action={() => fecharTela(ano)}
            tamanho="small"
          />
        </Grid>
      </Grid>
    </DialogActions>
  )
}

TabelaPrevisaoCompraBotao.propTypes = propBotao
TabelaPrevisaoCompraBotao.defaultProps = valueBotao

export default TabelaPrevisaoCompraBotao
