import React, { useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import LayoutListaSuspensa from './LayoutListaSuspensa'
import ImagemCabecalho from './LayoutImagemCabecalho'
import LayoutCabecalhoEstilo from '../LayoutCabecalhoEstilo'
import { toggleDrawer } from '../LayoutUtils'
import LayoutInformativo from './LayoutInformativo'
import LayoutExportarPDF from './LayoutExportarPDF'
import Security from '../../utils/Security'
import { KEY_PERMISSAO_INFORMATIVO } from '../../utils'

function ElevationScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

function LayoutCabecalho() {
  const classes = LayoutCabecalhoEstilo()
  const [state, setState] = useState(false)

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar className={classes.AppBar}>
          <Toolbar>
            <div className="cabecalho-grid-container">
              <div className="cabecalho-menu">
                {' '}
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={toggleDrawer(setState, true)}>
                  <MenuIcon />
                </IconButton>
              </div>
              <div className="cabecalho-titulo">
                {' '}
                <Typography variant="inherit" className={classes.title}>
                  <ImagemCabecalho />
                  <Drawer open={state} onClose={toggleDrawer(setState, false)}>
                    {LayoutListaSuspensa(toggleDrawer, setState)}
                  </Drawer>
                </Typography>
              </div>
              <div className="cabecalho-btn-exportar"><LayoutExportarPDF /></div>
              <div className="cabecalho-info">
                {Security.checkForPermissions(KEY_PERMISSAO_INFORMATIVO)
                  ? <LayoutInformativo /> : undefined}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  )
}

export default LayoutCabecalho
