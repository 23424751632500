import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import { useTranslation } from 'react-i18next'
import MenuItem from '@material-ui/core/MenuItem'
import { valueDropDown, propsDropDown } from '../FiltroPropTypes'
import FiltroEstilo from '../FiltroEstilo'

function FiltroDropDownSimples({
  id, label, value, lista, itemId, itemValue, itemDisplay, handleChange,
  hidden, disabled, erro, autoFocus,
}) {
  const classes = FiltroEstilo()
  const { t } = useTranslation()
  return (
    <FormControl className={classes.formControl} hidden={hidden} error={erro}>
      <InputLabel shrink htmlFor={id} className={classes.filtroDropdown}>
        {label}
      </InputLabel>
      <Select
        value={value}
        autoFocus={autoFocus}
        disabled={disabled}
        onChange={handleChange}
        input={<Input name={id} id={id} />}
      >
        <MenuItem value={-1} disabled hidden>{t('filtro.filtroDropDown.LABEL_SELECIONE')}</MenuItem>
        {!lista ? '' : lista.map(
          item => (
            <MenuItem key={item[itemId]} value={item[itemValue]}>
              {item[itemDisplay]}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  )
}
FiltroDropDownSimples.propTypes = propsDropDown
FiltroDropDownSimples.defaultProps = valueDropDown

export default FiltroDropDownSimples
