const PtBr = {
  graficoAnaliseCorte: {
    LABEL_TITULO_GRAFICO: 'ANÁLISE DE CORTE',
    LABEL_EXPORTACAO: 'analise-de-corte',
    LABEL_COLUNA: 'Milímetros',
    LABEL_INICIO: 'Início',
    LABEL_FIM: 'Fim',
    LABEL_CAMPANHA_INICIAL: 'Campanha Inicial',
    LABEL_CAMPANHA_FINAL: 'Campanha Final',
    LABEL_CAMPANHA_VENCIDA: 'Campanha Vencida',
    LABEL_DIAMETRO: 'Diâmetro',
    LABEL_CORTE: 'Corte',
    LABEL_CORTE_TOTAL: 'Corte Total',
    LABEL_DESBASTE: 'Desbaste',
    LABEL_DESGASTE: 'Desgaste',
    LABEL_MM: 'mm',
    LABEL_NUM_CAMPANHAS: 'Nº de Campanhas',
    LABEL_NUM_OCORRENCIAS: 'Nº de Ocorrências',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de análise de corte. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_CORTE_MEDIO: 'Corte Médio',
    LABEL_SITUACAO: 'Situação',
  },
}

export default PtBr
