import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getEstoqueMinimo,
  getPadraoCorte,
  getPrevisaoChegadaCilindros,
  getIdentificadorCilindrosSAP,
  getPrevisaoCustoRelativo,
} from '../ConfigService'
import { makeCancelable, ConfigTraducao, ConfigGeralModal } from '../../utils'
import { LoadingTabela } from '../../loading'
import { FiltroContext } from '../../layout/LayoutContext'
import ConfigEstoqueMinimo from './ConfigEstoqueMinimo'
import ConfigPadraoCorte from './ConfigPadraoCorte'
import ConfigPrevisaoChegadaCilindros from './ConfigPrevisaoChegadaCilindros'
import ConfigCadastroIdSAP from './ConfigCadastroIdSAP'
import ConfigPrevisaoCustoRelativo from './ConfigPrevisaoCustoRelativo'
import LayoutContentList from '../../layout/views/LayoutContentList'
import ConfigCard from './ConfigCard'
import { manipulaResultadoDaRequisicao } from '../ConfigUtils'

function ConfigGeral() {
  const [msgErroEstoqueMinimo, setMsgErroEstoqueMinimo] = useState('')
  const [msgErroPadraoCorte, setMsgErroPadraoCorte] = useState('')
  const [msgErroPrevisaoChegadaCilindros, setMsgErroPrevisaoChegadaCilindros] = useState('')
  const [msgErroIdentificadorCilindrosSAP, setMsgErroIdentificadorCilindrosSAP] = useState('')
  const [msgErroPrevisaoCustoRelativo, setMsgErroPrevisaoCustoRelativo] = useState('')

  const [tabelaEstoqueMinimoVisivel, setTabelaEstoqueMinimoVisivel] = useState(false)
  const [tabelaPadraoCorteVisivel, setTabelaPadraoCorteVisivel] = useState(false)
  const [tabelaPrevisaoChegadaCilindrosVisivel,
    setTabelaPrevisaoChegadaCilindrosVisivel] = useState(false)
  const [tabelaIdentificadorCilindrosSAPVisivel,
    setTabelaIdentificadorCilindrosSAPVisivel] = useState(false)
  const [tabelaPrevisaoCustoRelativoVisivel,
    setTabelaPrevisaoCustoRelativoVisivel] = useState(false)

  const [dadosEstoqueMinimo, setDadosEstoqueMinimo] = useState(undefined)
  const [dadosPadraoCorte, setDadosPadraoCorte] = useState(undefined)
  const [dadosPrevisaoChegadaCilindros, setDadosPrevisaoChegadaCilindros] = useState(undefined)
  const [dadosIdentificadorCilindrosSAP, setDadosIdentificadorCilindrosSAP] = useState([])
  const [dadosPrevisaoCustoRelativo, setDadosPrevisaoCustoRelativo] = useState([])

  const [carregando, setCarregando] = useState(false)
  const { t } = useTranslation()
  const context = useContext(FiltroContext)
  const { laminadores, todosCilindros } = context
  const localizacao = ConfigTraducao(
    t('config.config.LABEL_PAGINACAO'),
    t('config.config.LABEL_LINHAS_SELECIONADAS'),
    t('config.config.LABEL_LINHAS_PAGINA'),
    t('config.config.LABEL_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_PAGINA_ANTERIOR'),
    t('config.config.LABEL_TOOLTIP_PAGINA_ANTERIOR'),
    t('config.config.LABEL_PROXIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PROXIMA_PAGINA'),
    t('config.config.LABEL_ULTIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_ULTIMA_PAGINA'),
    t('config.config.LABEL_REGISTROS_SELECIONADOS'),
    t('config.config.LABEL_BUSCAR'),
    t('config.config.LABEL_TOOLTIP_BUSCAR'),
    t('config.config.LABEL_NENHUM_REGISTRO'),
    t('config.config.LABEL_TOOLTIP_FILTRO'),
    t('config.config.LABEL_TOOLTIP_CANCELAR'),
    t('config.config.LABEL_TOOLTIP_SALVAR'),
    t('config.config.LABEL_ACOES'),
    t('config.config.LABEL_ADICIONAR'),
    t('config.config.LABEL_EDITAR'),
  )

  useEffect(() => {
    setCarregando(true)
    const cancelablePromise = makeCancelable(Promise.all([
      getEstoqueMinimo(),
      getPadraoCorte(),
      getPrevisaoChegadaCilindros(),
      getIdentificadorCilindrosSAP(),
      getPrevisaoCustoRelativo(),
    ]))
    cancelablePromise.promise.then((
      [
        dadosEstoqueMinimoServidor,
        dadosPadraoCorteServidor,
        dadosPrevisaoChegadaCilindrosServidor,
        dadosIdentificadorCilindrosSAPServidor,
        dadosPrevisaoCustoRelativoServidor,
      ],
    ) => {
      manipulaResultadoDaRequisicao(dadosEstoqueMinimoServidor, setDadosEstoqueMinimo, setMsgErroEstoqueMinimo, t('config.config.LABEL_TITULO_TABELA_ESTOQUE_MINIMO'))
      manipulaResultadoDaRequisicao(dadosPadraoCorteServidor, setDadosPadraoCorte, setMsgErroPadraoCorte, t('config.config.LABEL_TITULO_TABELA_PADRAO_CORTE'))
      manipulaResultadoDaRequisicao(dadosPrevisaoChegadaCilindrosServidor, setDadosPrevisaoChegadaCilindros, setMsgErroPrevisaoChegadaCilindros, t('config.config.LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS'))
      manipulaResultadoDaRequisicao(dadosIdentificadorCilindrosSAPServidor, setDadosIdentificadorCilindrosSAP, setMsgErroIdentificadorCilindrosSAP, t('config.config.LABEL_TITULO_IDENTIFICADOR_CILINDRO_SAP'))
      manipulaResultadoDaRequisicao(dadosPrevisaoCustoRelativoServidor, setDadosPrevisaoCustoRelativo, setMsgErroPrevisaoCustoRelativo, t('config.config.LABEL_TITULO_PREVISAO_CUSTO_RELATIVO'))
      setCarregando(false)
    }).catch((err) => {
      if (!err.isCanceled) { console.log(err) }
    })
    return cancelablePromise.cancel
  }, [laminadores, t])

  if (carregando) {
    return (
      <div>
        <LoadingTabela loading={carregando} message={t('config.config.LABEL_LOADING')} />
      </div>
    )
  }

  return (
    <>
      <LayoutContentList>
        <ConfigCard
          expandido={tabelaEstoqueMinimoVisivel}
          clickHandle={() => setTabelaEstoqueMinimoVisivel(!tabelaEstoqueMinimoVisivel)}
          titulo={t('config.config.LABEL_TITULO_TABELA_ESTOQUE_MINIMO')}
          key="ConfigEstoqueMinimoId"
        >
          <ConfigEstoqueMinimo
            dados={dadosEstoqueMinimo}
            setDados={setDadosEstoqueMinimo}
            laminadores={laminadores}
            localizacao={localizacao}
            visivel={tabelaEstoqueMinimoVisivel}
            clickHandleTitulo={() => setTabelaEstoqueMinimoVisivel(!tabelaEstoqueMinimoVisivel)}
          />
        </ConfigCard>
        <ConfigCard
          expandido={tabelaPadraoCorteVisivel}
          clickHandle={() => setTabelaPadraoCorteVisivel(!tabelaPadraoCorteVisivel)}
          titulo={t('config.config.LABEL_TITULO_TABELA_PADRAO_CORTE')}
          key="ConfigPadraoCorteId"
        >
          <ConfigPadraoCorte
            dados={dadosPadraoCorte}
            setDados={setDadosPadraoCorte}
            localizacao={localizacao}
            laminadores={laminadores}
            visivel={tabelaPadraoCorteVisivel}
            clickHandleTitulo={() => setTabelaPadraoCorteVisivel(!tabelaPadraoCorteVisivel)}
          />
        </ConfigCard>
        <ConfigCard
          expandido={tabelaPrevisaoChegadaCilindrosVisivel}
          clickHandle={
            () => setTabelaPrevisaoChegadaCilindrosVisivel(!tabelaPrevisaoChegadaCilindrosVisivel)
          }
          titulo={t('config.config.LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS')}
          key="ConfigPrevisaoChegadaCilindrosId"
        >
          <ConfigPrevisaoChegadaCilindros
            dados={dadosPrevisaoChegadaCilindros}
            setDados={setDadosPrevisaoChegadaCilindros}
            localizacao={localizacao}
            laminadores={laminadores}
            visivel={tabelaPrevisaoChegadaCilindrosVisivel}
            clickHandleTitulo={
              () => setTabelaPrevisaoChegadaCilindrosVisivel(!tabelaPrevisaoChegadaCilindrosVisivel)
            }
          />
        </ConfigCard>
        <ConfigCard
          expandido={tabelaIdentificadorCilindrosSAPVisivel}
          clickHandle={
            () => setTabelaIdentificadorCilindrosSAPVisivel(!tabelaIdentificadorCilindrosSAPVisivel)
          }
          titulo={t('config.config.LABEL_TITULO_IDENTIFICADOR_CILINDRO_SAP')}
          key="ConfigCadastroIdSAPId"
        >
          <ConfigCadastroIdSAP
            dados={dadosIdentificadorCilindrosSAP}
            setDados={setDadosIdentificadorCilindrosSAP}
            localizacao={localizacao}
            laminadores={laminadores}
            cilindros={todosCilindros}
            visivel={tabelaIdentificadorCilindrosSAPVisivel}
            clickHandleTitulo={
              () => setTabelaIdentificadorCilindrosSAPVisivel(
                !tabelaIdentificadorCilindrosSAPVisivel,
              )
            }
          />
        </ConfigCard>
        <ConfigCard
          expandido={tabelaPrevisaoCustoRelativoVisivel}
          clickHandle={
            () => setTabelaPrevisaoCustoRelativoVisivel(!tabelaPrevisaoCustoRelativoVisivel)
          }
          titulo={t('config.config.LABEL_TITULO_PREVISAO_CUSTO_RELATIVO')}
          key="ConfigPrevisaoCustoRelativoId"
        >
          <ConfigPrevisaoCustoRelativo
            dados={dadosPrevisaoCustoRelativo}
            setDados={setDadosPrevisaoCustoRelativo}
            localizacao={localizacao}
            laminadores={laminadores}
            visivel={tabelaPrevisaoCustoRelativoVisivel}
            clickHandleTitulo={
              () => setTabelaPrevisaoCustoRelativoVisivel(!tabelaPrevisaoCustoRelativoVisivel)
            }
          />
        </ConfigCard>
      </LayoutContentList>
      {[
        [msgErroEstoqueMinimo, setMsgErroEstoqueMinimo],
        [msgErroPadraoCorte, setMsgErroPadraoCorte],
        [msgErroPrevisaoChegadaCilindros, setMsgErroPrevisaoChegadaCilindros],
        [msgErroIdentificadorCilindrosSAP, setMsgErroIdentificadorCilindrosSAP],
        [msgErroPrevisaoCustoRelativo, setMsgErroPrevisaoCustoRelativo]].map(
        ([msg, setMsg]) => (msg ? <ConfigGeralModal msg={msg} setMsg={setMsg} key={Math.random()} /> : ''),
      )}
    </>
  )
}

export default ConfigGeral
