import React from 'react'
import { Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import LayoutEstilo from '../LayoutEstilo'
import { isFunction } from '../../utils'

export default function LayoutContentList(props) {
  const classes = LayoutEstilo()
  const { children } = props
  if (!children || children.length <= 0) return ''
  if (isFunction(children.map)) {
    const contents = children.map(content => (
      <div className="graficos-flex-container-item" key={content.key}>
        <div className="graficos-flex-container-space-item" />
        <Paper className={classes.paper}>
          {content}
        </Paper>
      </div>
    ))
    return (
      <div className="graficos-flex-container">
        {contents}
      </div>
    )
  }
  return (
    <div className="graficos-flex-container-item">
      <Paper className={classes.paper}>
        {children}
      </Paper>
    </div>
  )
}

LayoutContentList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array,
    PropTypes.element]),
}
LayoutContentList.defaultProps = { children: [] }
