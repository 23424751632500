import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import inserirRegistro, { editarRegistro, opcoes, getColunasFormatadas } from '../ConfigUtils'
import propsConfigEstoqueMinimo, { valueConfigEstoqueMinimo } from '../ConfigPropTypes'
import { materialTableStyle } from '../ConfigEstilo'
import { criarNovoEstoqueMinimo, editarEstoqueMinimo } from '../ConfigService'
import ConfigGeralCardTitulo from './ConfigGeralCardTitulo'
import { ConfigToolbar } from '../../utils'

const ConfigEstoqueMinimo = ({
  dados, setDados, localizacao, visivel, clickHandleTitulo, laminadores,
}) => {
  const { t } = useTranslation()
  const nomesColunas = getColunasFormatadas(laminadores, {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('config.config.LABEL_TIPO_CILINDRO'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblAno: t('config.config.LABEL_ANO'),
    lblValor: t('config.config.LABEL_TOTAL_PARES'),
  })

  return (
    <MaterialTable
      id="tableEstoqueMinimo"
      style={materialTableStyle}
      title={<ConfigGeralCardTitulo titulo={t('config.config.LABEL_TITULO_TABELA_ESTOQUE_MINIMO')} flechaParaCima={visivel} clickHandle={clickHandleTitulo} />}
      columns={nomesColunas}
      data={dados}
      localization={localizacao}
      options={opcoes}
      editable={{
        onRowAdd: newData => new Promise((resolve, reject) => {
          inserirRegistro(newData, reject, resolve, dados, setDados,
            t('config.config.LABEL_LAMINADOR_VAZIO'), t('config.config.LABEL_TIPO_CILINDRO_VAZIO'),
            t('config.config.LABEL_VALOR_VAZIO'), t('config.config.LABEL_VALOR_ERRADO'),
            t('config.config.LABEL_GRAVAR_SUCESSO'), t('config.config.LABEL_QUANTIDADE_DECIMAIS'),
            criarNovoEstoqueMinimo)
        }),
        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          editarRegistro(newData, reject, resolve, dados, setDados,
            oldData, t('config.config.LABEL_VALOR_VAZIO'), t('config.config.LABEL_VALOR_ERRADO'),
            t('config.config.LABEL_GRAVAR_SUCESSO'), t('config.config.LABEL_QUANTIDADE_DECIMAIS'),
            editarEstoqueMinimo)
        }),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

ConfigEstoqueMinimo.propTypes = propsConfigEstoqueMinimo
ConfigEstoqueMinimo.defaultProps = valueConfigEstoqueMinimo

export default ConfigEstoqueMinimo
