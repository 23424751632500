import React from 'react'
import UtilsEstilo from '../UtilsEstilo'
import { propsCelula, valueCelula } from '../UtilsPropTypes'

const SRCelula = ({
  children, altura, largura, negrito, percentualLargura, background,
}) => {
  const classes = UtilsEstilo()
  let estilo
  if (background === 'azul') {
    estilo = classes.celulaAzul
  } else if (background === 'vermelho') {
    estilo = classes.celulaVermelha
  } else {
    estilo = classes.celulaNormal
  }
  return (
    <td
      key={Math.random()}
      className={estilo}
      rowSpan={altura}
      colSpan={largura}
      width={percentualLargura}
    >
      {negrito ? <strong>{children}</strong> : children}
    </td>
  )
}

SRCelula.propTypes = propsCelula
SRCelula.defaultProps = valueCelula

export default SRCelula
