
const Situacao = {
  CAMPANHA_VENCIDA: 1,
  EM_OPERACAO: 2,
  NOVO: 3,
  REPARO_EXTERNO: 4,
  RETIFICADO: 5,
  SUCATEADO: 6,

  properties: {
    1: { name: 'Campanha Vencida', value: 1 },
    2: { name: 'Em Operação', value: 2 },
    3: { name: 'Novo', value: 3 },
    4: { name: 'Reparo Externo', value: 4 },
    5: { name: 'Retificado', value: 5 },
    6: { name: 'Sucatado', value: 6 },
  },
}

export default Situacao
