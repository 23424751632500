import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useTranslation } from 'react-i18next'
import { valueDropDown, propsDropDown } from '../FiltroPropTypes'
import FiltroEstilo from '../FiltroEstilo'
import { selecao, valorFinalDaLista } from '../FiltroUtils'
import { COR_PRIMARIA } from '../../utils'

function FiltroDropDownMultiplo({
  id, label, lista, itemValue, itemDisplay, hidden, disabled, erro, valor,
  handleChangeMultiplo, autoFocus,
}) {
  const classes = FiltroEstilo()
  const { t } = useTranslation()
  return (
    <FormControl className={classes.formControlMaior} hidden={hidden} error={erro}>
      <InputLabel shrink htmlFor={id} className={classes.filtroDropdown}>
        {label}
      </InputLabel>
      <Select
        autoFocus={autoFocus}
        id={id}
        disabled={disabled}
        multiple
        displayEmpty
        value={disabled ? [t('filtro.filtroDropDown.LABEL_SELECIONE')] : valor}
        onChange={
            event => handleChangeMultiplo(valorFinalDaLista(event.target.value, lista, itemValue))
          }
        input={<Input id={id} />}
        renderValue={selected => selecao(selected, disabled, lista, itemValue, itemDisplay,
          t('filtro.filtroDropDown.LABEL_TODOS'),
          t('filtro.filtroDropDown.LABEL_ITENS_SELECIONADOS'), t('filtro.filtroDropDown.LABEL_SELECIONE'))}
      >
        {lista.length > 1 ? (
          <MenuItem key="Todos" value={-1}>
            <Checkbox checked={valor.length === lista.length} style={{ color: COR_PRIMARIA }} />
            <ListItemText primary={t('filtro.filtroDropDown.LABEL_TODOS')} />
          </MenuItem>
        ) : ''}
        {lista.map(item => (
          <MenuItem key={item[itemDisplay]} value={item[itemValue]}>
            <Checkbox
              checked={valor.includes(item[itemValue])}
              style={{ color: COR_PRIMARIA }}
            />
            <ListItemText primary={item[itemDisplay]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
FiltroDropDownMultiplo.propTypes = propsDropDown
FiltroDropDownMultiplo.defaultProps = valueDropDown

export default FiltroDropDownMultiplo
