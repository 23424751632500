export default function GraficoTCORequisicao(filtro) {
  return {
    codCilindroList: filtro.cilindros,
    dataFim: filtro.dataFim ? filtro.dataFim.toISOString() : '',
    dataInicio: filtro.dataInicio ? filtro.dataInicio.toISOString() : '',
    faixaConsumo: {
      de0a25: filtro.consumoDoCilindro.de0a25,
      de25a50: filtro.consumoDoCilindro.de25a50,
      de50a75: filtro.consumoDoCilindro.de50a75,
      de75a100: filtro.consumoDoCilindro.de75a100,
    },
  }
}
