import { error } from '../utils'

function printHandler() {
  if (this.printHandlerFn) {
    return this.printHandlerFn()
  }
  return false
}

function afterPrintHandler() {
  if (this.afterPrintHandlerFn) {
    return this.afterPrintHandlerFn()
  }
  return false
}

function setPrintHandler(fn) {
  if (typeof fn === 'undefined' || typeof fn === 'function') {
    this.printHandlerFn = fn
    return
  }
  error('Valor inválido para print handler.')
}

function setAfterPrintHandler(fn) {
  if (typeof fn === 'undefined' || typeof fn === 'function') {
    this.afterPrintHandlerFn = fn
    return
  }
  error('Valor inválido para o after print handler.')
}

const GraficoDesempenhoMateriaisContexto = {
  printHandlerFn: undefined,
  afterPrintHandlerFn: undefined,
  printHandler,
  afterPrintHandler,
  setPrintHandler,
  setAfterPrintHandler,
}

export default GraficoDesempenhoMateriaisContexto
