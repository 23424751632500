import React from 'react'
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { useTranslation } from 'react-i18next'
import { sucessoIMG } from '../../../assets/images'
import { Botao, TituloModal } from '../../utils'
import { propsSucesso, valueSucesso } from '../AutenticacaoPropTypes'

const AutenticacaoAlterarSenhaSucesso = ({ handleClose, login }) => {
  const { t } = useTranslation()
  return (
    <div>
      <Dialog onClose={() => handleClose(false)} aria-labelledby="customized-dialog-title" open maxWidth="md" fullWidth>
        <TituloModal id="customized-dialog-title" onClose={() => handleClose(false)}>
          {t('autenticacao.autenticacaoAlterarSenhaSucesso.LABEL_TITULO')}
        </TituloModal>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <img src={sucessoIMG} alt={t('autenticacao.autenticacaoAlterarSenhaSucesso.ALT_SUCESSO')} />
            </Grid>
            <Grid item>
              <span>
                <br />
                <b><h4>{t('autenticacao.autenticacaoAlterarSenhaSucesso.SENHA_ALTERADA')}</h4></b>
              </span>
            </Grid>
            <Grid item>
              <span>
                <b><h5>{login ? t('autenticacao.autenticacaoAlterarSenhaSucesso.LOGAR_NOVAMENTE') : ''}</h5></b>
                <br />
              </span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="center">
            <Grid item>
              <Botao
                id="botaoConfirmarSenha"
                nome={t('autenticacao.autenticacaoAlterarSenhaSucesso.BOTAO_CONFIRMAR')}
                tamanho="small"
                variant="outlined"
                action={() => handleClose(false)}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

AutenticacaoAlterarSenhaSucesso.propTypes = propsSucesso
AutenticacaoAlterarSenhaSucesso.defaultProps = valueSucesso

export default AutenticacaoAlterarSenhaSucesso
