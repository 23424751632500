import { ConectarApiPost, ConectarApiGet, ConectarApiPut } from '../utils/ConectarApi'
import {
  ENDPOINT_ESTOQUE_MINIMO,
  ENDPOINT_PADRAO_CORTE,
  ENDPOINT_PREVISAO_CHEGADA_CILINDROS,
  ENDPOINT_CILINDRO,
  ENDPOINT_PREVISAO_CUSTO_RELATIVO,
} from '../utils/Configuracoes'

export const STR_ERRO = 'Erro'

export async function getEstoqueMinimo() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_ESTOQUE_MINIMO)

    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function criarNovoEstoqueMinimo(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_ESTOQUE_MINIMO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return [STR_ERRO, err]
  }
}

export async function editarEstoqueMinimo(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_ESTOQUE_MINIMO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return [STR_ERRO, err]
  }
}

export async function getPadraoCorte() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_PADRAO_CORTE)

    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function criarNovoPadraoCorte(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PADRAO_CORTE, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function editarPadraoCorte(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_PADRAO_CORTE, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function getPrevisaoChegadaCilindros() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_PREVISAO_CHEGADA_CILINDROS)

    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function criarPrevisaoChegadaCilindros(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PREVISAO_CHEGADA_CILINDROS, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function editarPrevisaoChegadaCilindros(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_PREVISAO_CHEGADA_CILINDROS, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function getIdentificadorCilindrosSAP() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_CILINDRO)
    return resultado.map(cilindro => ({
      codCilindro: cilindro.codCilindro,
      numCilindro: cilindro.numCilindro,
      codTipoCilindro: cilindro.classeCilindro.tipoCilindro.bur ? 1 : 2,
      codLaminador: cilindro.classeCilindro.laminador.codLaminador,
      numeroNotaFiscal: cilindro.numeroNotaFiscal,
    }))
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function editarIdentificadorCilindrosSAP(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_CILINDRO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function getPrevisaoCustoRelativo() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_PREVISAO_CUSTO_RELATIVO)

    return resultado
  } catch (err) {
    console.log(err)
    return [STR_ERRO, err]
  }
}

export async function criarPrevisaoCustoRelativo(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PREVISAO_CUSTO_RELATIVO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    throw err
  }
}

export async function editarPrevisaoCustoRelativo(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_PREVISAO_CUSTO_RELATIVO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    throw err
  }
}

const ConfigService = {
  getEstoqueMinimo,
  criarNovoEstoqueMinimo,
  editarEstoqueMinimo,
  getPadraoCorte,
  criarNovoPadraoCorte,
  editarPadraoCorte,
  getPrevisaoChegadaCilindros,
  editarPrevisaoChegadaCilindros,
  criarPrevisaoChegadaCilindros,
  getIdentificadorCilindrosSAP,
  editarIdentificadorCilindrosSAP,
  getPrevisaoCustoRelativo,
  criarPrevisaoCustoRelativo,
  editarPrevisaoCustoRelativo,
}

export default ConfigService
