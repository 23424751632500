import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoEquivalenteNovo from '../../graficoEquivalenteNovo'
import propsLayoutEstoque, { valueLayoutEstoque } from '../LayoutPropTypes'
import LayoutContentList from './LayoutContentList'
import { SRSwitch } from '../../utils'
import GraficoRitmoChegadaCilindro from '../../graficoRitmoChegadaCilindro'

const LayoutEstoqueHistorico = () => {
  const { t } = useTranslation()
  const [habilitarEquivalenteNovo, setHabilitarEquivalenteNovo] = useState({
    pares: true,
    individual: false,
  })

  const handleHabilitaEquivalente = habilita => (event) => {
    setHabilitarEquivalenteNovo({ ...habilitarEquivalenteNovo, [habilita]: event.target.checked })
  }
  return (
    <LayoutContentList>
      <section style={{ position: 'relative' }}>
        <div style={{
          position: 'absolute', right: '4%', zIndex: 1, height: '0',
        }}
        >
          <SRSwitch
            habilitaDiametroTotal={habilitarEquivalenteNovo}
            onChange={handleHabilitaEquivalente('individual')}
            label={t('layout.layoutEstoque.LABEL_SWITCH')}
          />
        </div>
        {!habilitarEquivalenteNovo.individual
          ? <GraficoEquivalenteNovo key="GraficoEquivalenteNovoId" />
          : <GraficoEquivalenteNovo key="GraficoEquivalenteNovoId" individual />
        }
      </section>
      <GraficoRitmoChegadaCilindro key="GraficoRitmoChegadaCilindro" />
    </LayoutContentList>
  )
}

LayoutEstoqueHistorico.propTypes = propsLayoutEstoque
LayoutEstoqueHistorico.defaultProps = valueLayoutEstoque

export default LayoutEstoqueHistorico
