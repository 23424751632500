import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoCustoPorToneladaRequisicao from './GraficoCustoPorToneladaRequisicao'
import {
  ENDPOINT_REAIS_POR_TONELADA,
  ENDPOINT_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA,
} from '../utils/Configuracoes'

export default async function GraficoCustoPorToneladaService(filtro, graficoSimplificado = false) {
  try {
    const resposta = await ConectarApiPost(graficoSimplificado
      ? ENDPOINT_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA
      : ENDPOINT_REAIS_POR_TONELADA,
    GraficoCustoPorToneladaRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}
