import React, { useEffect, useContext } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import GraficoRitmoChegadaCilindroOpcoes from '../GraficoRitmoChegadaCilindroOpcoes'
import { FiltroContext } from '../../layout/LayoutContext'
import { ErroGrafico } from '../../utils'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import UseStateSafely from '../../utils/UseStateSafely'
import GraficoRitmoChegadaCilindroService from '../GraficoRitmoChegadaCilindroService'
import { infoCinza } from '../../../assets/images'
import Tooltip from '../../tooltip'

let dados
let setDados
let loading
let setLoading

function GraficoRitmoChegadaCilindro() {
  [loading, setLoading] = UseStateSafely(true);
  [dados, setDados] = UseStateSafely({})
  const filtro = useContext(FiltroContext)
  const { t } = useTranslation()

  const labels = {
    titulo: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_TITULO'),
    quantidade: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_QUANTIDADE'),
    previsto: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_PREVISTO'),
    real: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_REAL'),
    ritmo: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_RITMO'),
    datas: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_DATAS'),
    cilindros: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_CILINDROS'),
    cilindro: t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_CILINDRO'),
  }

  useEffect(() => {
    setLoading(true)
    setDados({})
    async function conectar() {
      const resposta = await GraficoRitmoChegadaCilindroService(filtro)
      setDados(resposta)
      setLoading(false)
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [filtro])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (dados && dados.data && dados.data.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_TITULO')}
        />
      )
    }
    if (dados && dados[0] !== 'Erro') {
      return (
        <>
          <div style={{
            position: 'absolute', right: '8%', zIndex: 1, marginTop: '2px',
          }}
          >
            <Tooltip
              cinza={1}
              title={(
                <>
                  <h6>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TOOLTIP_INICIO')}</h6>
                  <h6>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TOOLTIP_FIM')}</h6>
                </>
              )}
            >
              <img src={infoCinza} alt={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ALT_INFORMATIVO')} />
            </Tooltip>
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '410px' } }}
            options={GraficoRitmoChegadaCilindroOpcoes(dados, labels)}
          />
        </>
      )
    }
    return (
      <AlertaModal
        url={t('geral.routes.URL_ESTOQUE_HISTORICO')}
        mensagem={t('graficoRitmoChegadaCilindro.graficoRitmoChegadaCilindro.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

export default GraficoRitmoChegadaCilindro
