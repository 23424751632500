import propTypes from 'prop-types'

const propsLayoutEstoque = {
  filtro: propTypes.object,
  exibirGrafico: propTypes.bool,
}

export const valueLayoutEstoque = {
  exibirGrafico: false,
}

export const propsLayoutDesempenho = {
  filtro: propTypes.object,
  exibirGrafico: propTypes.bool,
}

export const valueLayoutDesempenho = {
  exibirGrafico: false,
}

export const propsElevationScroll = {
  children: propTypes.node.isRequired,
  window: propTypes.func,
}

export const valueElevationScroll = {
}

export const propsLayoutMenu = {
  filtro: propTypes.func,
}

export const valueLayoutMenu = {
}

export const propsPaginaPadrao = {
  ocultarFiltro: propTypes.bool,
}

export const valuePaginaPadrao = {
  ocultarFiltro: false,
}

export const propsImagemCabecalho = {
  testParam: propTypes.bool,
}

export const valueImagemCabecalho = {
  testParam: false,
}

export const propsProvider = {
  children: propTypes.any,
}

export const valueProvider = {

}

export const propsSubMenu = {
  open: propTypes.bool,
  evento: propTypes.func,
  link: propTypes.string,
  label: propTypes.string,
}

export const valueSubMenu = {
  open: false,
}

export const propsLayoutDiscretizado = {
  filtro: propTypes.object,
  exibirGrafico: propTypes.bool,
}

export const valueLayoutDiscretizado = {
  exibirGrafico: false,
}

export const propsLayoutCardRangeData = {
  filtro: propTypes.object,
  titulo: propTypes.string,
  children: propTypes.object,
}

export const valueLayoutCardRangeData = {
  filtro: undefined,
  titulo: '',
  children: undefined,
}

export const propsLayoutGeralConsumoAgrupado = {
  filtro: propTypes.object,
  siglaLaminador: propTypes.string,
}

export const valueLayoutGeralConsumoAgrupado = {
  filtro: undefined,
  siglaLaminador: '',
}

export const propsLayoutCustoRelativoHome = {
  filtro: propTypes.object,
  siglaLaminador: propTypes.string,
}

export const valueLayoutCustoRelativoHome = {
  filtro: undefined,
  siglaLaminador: '',
}

export default propsLayoutEstoque
