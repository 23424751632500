import React, { useState } from 'react'
import AutenticacaoAlterarSenhaSucesso from './AutenticacaoAlterarSenhaSucesso'
import AutenticacaoAlterarSenhaForm from './AutenticacaoAlterarSenhaForm'
import { propsAlterarSenha, valuesAlterarSenha } from '../AutenticacaoPropTypes'

export default function AutenticacaoAlterarSenha({ handleClose, login }) {
  const [sucesso, setSucesso] = useState(false)

  if (sucesso) {
    return (
      <AutenticacaoAlterarSenhaSucesso login={login} handleClose={handleClose} />
    )
  }

  return (
    <AutenticacaoAlterarSenhaForm
      setSucesso={setSucesso}
      handleClose={handleClose}
      login={login}
    />
  )
}

AutenticacaoAlterarSenha.propTypes = propsAlterarSenha
AutenticacaoAlterarSenha.defaultProps = valuesAlterarSenha
