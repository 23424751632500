import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { Botao, SRTextField, NOME_APP } from '../../utils'
import AutenticacaoEstilo from '../AutenticacaoEstilo'
import { propsForm, valueForm } from '../AutenticacaoPropTypes'
import { AlertaSnack } from '../../alerta'

const AutenticacaoForm = ({
  evento, setSenha, setUsuario, erroUsuario, erroSenha,
}) => {
  const classes = AutenticacaoEstilo()
  const { t } = useTranslation()
  return (
    <div className={classes.paper}>
      <form noValidate autoComplete="off">
        <div className="form-grid-container">
          <div className="form-header">
            <Typography component="h1" variant="h6" className={classes.subTitulo}>
              {t('autenticacao.autenticacaoForm.LABEL_TITULO')}
            </Typography>
            <Typography component="h1" variant="h3" className={classes.titulo}>
              {NOME_APP}
            </Typography>
          </div>
          <div className="form-conteudo">
            <div className="form-user">
              <SRTextField
                erro={erroUsuario}
                id="usuario"
                label={t('autenticacao.autenticacaoForm.LABEL_USUARIO')}
                evento={valor => setUsuario(valor)}
                foco
              />
            </div>
            <div className="form-pass">
              <SRTextField
                erro={erroSenha}
                id="senha"
                label={t('autenticacao.autenticacaoForm.LABEL_SENHA')}
                evento={valor => setSenha(valor)}
                tipo="password"
              />
            </div>
          </div>
          <div className="form-botao">
            <Botao
              id="loginEntrarBotao"
              nome={t('autenticacao.autenticacaoForm.BOTAO_ENTRAR')}
              tipo="submit"
              tamanho="medium"
              action={evento}
            />
          </div>
        </div>
      </form>
      <AlertaSnack />
    </div>
  )
}

AutenticacaoForm.propTypes = propsForm
AutenticacaoForm.defaultProps = valueForm

export default AutenticacaoForm
