import React from 'react'
import { redefinirSenha } from '../../../assets/images'

const CadastroUsuariosRedefinirSenha = () => (
  <img
    alt="redefinirSenha"
    src={redefinirSenha}
    style={{ cursor: 'pointer' }}
    width="21px"
    height="17px"
  />
)

export default CadastroUsuariosRedefinirSenha
