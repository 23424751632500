import React, { useEffect, useContext } from 'react'
import Highcharts from 'highcharts'
import { PaginaContext } from '../LayoutContext'
import { exportatPDF } from '../../../assets/images'

const LayoutExportarPDF = () => {
  const contexto = useContext(PaginaContext)

  function exportarPDF() {
    if (contexto.printHandler && contexto.printHandler()) return
    window.print()
  }

  useEffect(() => {
    window.onbeforeprint = () => {
      Highcharts.charts.forEach((chart) => {
        if (!chart) return
        if (chart.options && chart.options.customResizeValue) {
          chart.setSize(chart.options.customResizeValue)
        } else {
          chart.setSize(null)
        }
        chart.reflow()
      })
    }
    window.onafterprint = () => {
      Highcharts.charts.forEach((chart) => {
        if (!chart) return
        chart.setSize(null)
        chart.reflow()
      })
      if (contexto.afterPrintHandler) contexto.afterPrintHandler()
    }
    return () => {
      window.onbeforeprint = undefined
      window.onafterprint = undefined
    }
  }, [contexto])

  return (
    <>
      <div
        onClick={exportarPDF}
        onKeyDown={() => undefined}
        role="button"
        tabIndex="-1"
        style={{
          cursor: 'pointer',
        }}
      >
        <img src={exportatPDF} alt="" />
      </div>
    </>
  )
}

export default LayoutExportarPDF
