import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

export const graficoEstoqueCilindroEquivalenteNovoEstilo = makeStyles({
  titulo: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: COR_PRIMARIA,
    margin: '10px 10px 20px 10px',
    fontSize: 14,
  },
  subTitulo: {
    textAlign: 'left',
    color: '#434A54',
    margin: '10px 10px 10px 10px',
    fontSize: 14,
  },
  grafico: {
    margin: '10px 0px 10px 0px',
  },
  legenda: {
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: '20px 0px 0px 0px',
    '& div': {
      fontSize: 14,
    },
  },
  dot: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px 3px 0px 0px',
  },
})

const GraficoEstoqueCilindroEquivalenteNovoEstilo = { graficoEstoqueCilindroEquivalenteNovoEstilo }

export default GraficoEstoqueCilindroEquivalenteNovoEstilo
