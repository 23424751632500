import propTypes from 'prop-types'

const props = {
  filtro: propTypes.object,
}

const values = {
  filtro: undefined,
}

const HomePropTypes = { props, values }

export default HomePropTypes
