import GraficoEstoqueCilindroEquivalenteNovo from '../graficoEstoqueCilindroEquivalenteNovo'
import { LayoutGeralConsumoAgrupado, CustoRelativoHome } from '../layout'
import {
  Security,
  KEY_PAGINA_HOME_GRAFICO_EQUIVALENTE,
  KEY_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO,
  KEY_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA,
} from '../utils'

export function getEquivalenteNovosWidgets(
  laminadores, todosCodFornecedores, todosCodMateriais, dataCorrente,
) {
  return Security.checkForPermissions(KEY_PAGINA_HOME_GRAFICO_EQUIVALENTE)
    ? laminadores.map(x => (
      {
        key: `Home.EqNovo.${x.siglaLaminador}${x.codLaminador}`,
        component: GraficoEstoqueCilindroEquivalenteNovo,
        props: {
          laminador: x.siglaLaminador,
          filtro: {
            laminador: x.codLaminador,
            fornecedores: todosCodFornecedores,
            materiais: todosCodMateriais,
            dataFim: dataCorrente,
            dataInicio: dataCorrente,
          },
        },
      })) : []
}

export function getConsumoAgrupadoWidgets(
  laminadores, todosCodFornecedores, todosCodMateriais, dataMaisRecente,
) {
  return Security.checkForPermissions(KEY_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO)
    ? laminadores.map(laminador => (
      {
        key: `Home.Consumo.Agrupado.${laminador.siglaLaminador}${laminador.codLaminador}`,
        component: LayoutGeralConsumoAgrupado,
        props: {
          siglaLaminador: laminador.siglaLaminador,
          filtro: {
            fornecedores: todosCodFornecedores,
            laminador: laminador.codLaminador,
            materiais: todosCodMateriais,
            dataFim: dataMaisRecente,
            dataInicio: undefined,
            tipoDeCilindro: 2,
            consumoDoCilindro: {
              de0a25: true,
              de25a50: true,
              de50a75: true,
              de75a100: true,
            },
          },
        },
      })) : []
}

export function getReaisPorToneladaWidgets(
  laminadores, todosCodFornecedores, todosCodMateriais, dataMaisRecente,
) {
  return Security.checkForPermissions(KEY_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA)
    ? laminadores.map(laminador => (
      {
        key: `Home.Reais.Tonelada.${laminador.siglaLaminador}${laminador.codLaminador}`,
        component: CustoRelativoHome,
        props: {
          siglaLaminador: laminador.siglaLaminador,
          filtro: {
            fornecedores: todosCodFornecedores,
            laminador: laminador.codLaminador,
            tipoDeCilindro: 2,
            materiais: todosCodMateriais,
            dataFim: dataMaisRecente,
            dataInicio: undefined,
          },
        },
      })) : []
}

const HomeUtils = {
  getEquivalenteNovosWidgets,
  getConsumoAgrupadoWidgets,
  getReaisPorToneladaWidgets,
}

export default HomeUtils
