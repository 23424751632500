import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoEquivalenteNovoRequisicao from './GraficoEquivalenteNovoRequisicao'
import {
  ENDPOINT_GRAFICO_EQUIVALENTE,
  ENDPOINT_PAGINA_HOME_GRAFICO_EQUIVALENTE,
} from '../utils/Configuracoes'

export default async function GraficoEquivalenteNovoService(filtro, graficoSimplificado = false) {
  try {
    const resposta = await ConectarApiPost(
      graficoSimplificado
        ? ENDPOINT_PAGINA_HOME_GRAFICO_EQUIVALENTE
        : ENDPOINT_GRAFICO_EQUIVALENTE,
      GraficoEquivalenteNovoRequisicao(filtro),
    )
    const { resultado } = resposta.data
    const { estoqueMinimo } = resultado
    const estoque = resultado.equivalenteNovos
    const datas = resultado.data

    return { estoque, datas, estoqueMinimo }
  } catch (err) {
    return ['Erro', err]
  }
}
