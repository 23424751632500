import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

const LayoutCabecalhoEstilo = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    margin: 1,
    color: '#fff',
    backgroundColor: 'inherit',
  },
  AppBar: {
    backgroundColor: COR_PRIMARIA,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 280,
  },
  listAdm: {
    width: 180,
  },
  fullList: {
    width: 'auto',
  },

  layoutCabecalhoGrid: {
    flexWrap: 'nowrap',
  },
}))

export default LayoutCabecalhoEstilo
