import React, { useEffect, useContext } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import GraficoCustoPorToneladaOpcoes from '../GraficoCustoPorToneladaOpcoes'
import { FiltroContext } from '../../layout/LayoutContext'
import { ErroGrafico, makeCancelable } from '../../utils'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import GraficoCustoPorToneladaService from '../GraficoCustoPorToneladaService'
import { formatarDadosCustoRelativo } from '../GraficoCustoPorToneladaUtils'
import { propsGraficoCustoPorTonelada, valueGraficoCustoPorTonelada } from '../GraficoCustoPorToneladaPropTypes'

function GraficoCustoPorTonelada({
  filtro, graficoSimplificado, subtitulo, formatacao, filtroAnual,
}) {
  const [loading, setLoading] = React.useState(true)
  const [dados, setDados] = React.useState({})
  const [resposta, setResposta] = React.useState({})
  const filtroContext = useContext(FiltroContext)
  const dadosDoFiltro = filtro || filtroContext
  const { t } = useTranslation()

  const labels = {
    titulo: graficoSimplificado ? subtitulo : t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_TITULO'),
    quantidade: graficoSimplificado ? t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_QUANTIDADE_HOME') : t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_QUANTIDADE'),
    custoRelativo: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_CUSTO_RELATIVO'),
    datas: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_DATAS'),
    custoEfetivo: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_CUSTO_EFETIVO'),
    previsto: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_PREVISTO'),
    real: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_REAL'),
    peso: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_PESO'),
    tonelada: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_TONELADA'),
    acumulado: t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_ACUMULADO'),
  }

  useEffect(() => {
    setLoading(true)
    setDados({})
    async function conectar() {
      const cancelablePromise = makeCancelable(
        GraficoCustoPorToneladaService(dadosDoFiltro, graficoSimplificado),
      )
      const dadosAPI = await cancelablePromise.promise
      setResposta(dadosAPI)
      const valores = formatarDadosCustoRelativo(dadosAPI)
      setDados(valores)
      setLoading(false)
    }
    try {
      conectar()
    } catch (err) {
      if (!err.isCanceled) { console.log(err) }
    }
  }, [dadosDoFiltro, graficoSimplificado])

  try {
    if (loading) {
      return <LoadingGrafico graficoSimplificado={graficoSimplificado} />
    }
    if (resposta && resposta[0] !== 'Erro' && resposta.length > 0) {
      const options = GraficoCustoPorToneladaOpcoes(dados, labels, filtroAnual, graficoSimplificado)
      options.customResizeValue = (formatacao || {}).customResizeValue
      return (
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: '410px' } }}
          options={options}
        />
      )
    }
    if (resposta && resposta.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_NENHUM_REGISTRO')}
          nome={graficoSimplificado ? '' : t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_TITULO')}
          graficoSimplificado={graficoSimplificado}
        />
      )
    }
    return (
      <AlertaModal
        url={t('geral.routes.URL_CUSTO_RELATIVO')}
        mensagem={t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

GraficoCustoPorTonelada.propTypes = propsGraficoCustoPorTonelada
GraficoCustoPorTonelada.defaultProps = valueGraficoCustoPorTonelada

export default GraficoCustoPorTonelada
