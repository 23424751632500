import Highcharts from 'highcharts'
import { Traducao, COR_PRIMARIA } from '../utils'
import { exportar } from '../../assets/images'
import { formatarTooltip } from './TabelaPrevisaoCompraUtils'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function TabelaPrevisaoCompraGraficoOpcoes(dados, labels, tipo) {
  return ({
    chart: {
      type: 'column',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          const dadosExportacao = dadosDoGrafico
          dadosExportacao.dataRows[0][0] = labels.datas
        },
      },
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      split: false,
      shared: true,
      formatter() {
        return formatarTooltip(this, labels, tipo)
      },
    },
    legend: {
      itemStyle: {
        color: '#333333',
        fontWeight: 'normal',
      },
    },
    title: {
      text: labels.titulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: dados.datas,
      labels: {
        style: {
          fontSize: '11px',
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      title: {
        text: labels.quantidade,
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
      series: {
        animation: false,
        borderWidth: 0,
        dataLabels: {
          enabled: false,
        },
      },
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
    series: [{
      type: 'column',
      name: labels.previsto,
      data: dados.primeiraColuna,
      color: tipo === 'unidade' ? '#8FCEFF' : tipo === 'equivalente' ? '#D6CE06' : '#FFC875',
    }, {
      type: 'column',
      name: labels.real,
      data: dados.segundaColuna,
      color: tipo === 'unidade' ? '#4298D6' : tipo === 'equivalente' ? '#95C11C' : '#F8A501',
    },
    {
      type: 'line',
      name: labels.minimo,
      data: dados.serieLinha,
      color: tipo === 'unidade' ? '#003365' : tipo === 'equivalente' ? '#597301' : '#CC4D49',
    },
    ],
    credits: {
      enabled: false,
    },
  })
}

Highcharts.setOptions(Traducao())

export default TabelaPrevisaoCompraGraficoOpcoes
