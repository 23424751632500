export default function GraficoCustoPorToneladaRequisicao(filtro) {
  return (
    {
      codLaminador: filtro.laminador,
      codFornecedoresList: filtro.fornecedores,
      codMateriaisList: filtro.materiais,
      codTipoCilindro: filtro.tipoDeCilindro,
      dataFim: filtro.dataFim ? filtro.dataFim.toISOString() : '',
      dataInicio: filtro.dataInicio ? filtro.dataInicio.toISOString() : '',
    }
  )
}
