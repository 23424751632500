import React from 'react'
import '../LoadingEstilo.css'
import Spinner from 'react-spinkit'
import propsLoading, { valueLoading } from '../LoadingPropTypes'
import { COR_PRIMARIA } from '../../utils'

const LoadingLogin = ({ loading, message }) => (loading ? (
  <div className="grid-container-loading">
    <div className="spinner">
      <Spinner
        name="line-spin-fade-loader"
        fadeIn="none"
        color={COR_PRIMARIA}
      />
    </div>
    <div className="label">
      {message}
    </div>
  </div>
) : null)

LoadingLogin.propTypes = propsLoading
LoadingLogin.defaultProps = valueLoading

export default LoadingLogin
