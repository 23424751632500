import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  PaginaPadrao, Erro, Estoque, EstoqueHistorico, Desempenho, DesempenhoAgrupado, Discretizado,
  CustoRelativo, CustoPrevisao, Perfil, TCO,
} from './features/layout'
import { GraficoDesempenhoMateriaisContexto } from './features/graficoDesempenhoAgrupado'
import Configuracoes from './features/config'
import AutenticacaoLogin from './features/autenticacao'
import Home from './features/home'
import {
  KEY_PAGINA_HOME, KEY_PAGINA_DESEMPENHO_INDIVIDUAL, KEY_PAGINA_DESEMPENHO_AGRUPADO,
  KEY_PAGINA_CONFIGURACOES, KEY_PAGINA_ESTOQUE_CORRENTE, KEY_PAGINA_DESEMPENHO_DISCRETIZADO,
  KEY_PAGINA_CUSTO_RELATIVO, KEY_PAGINA_CUSTO_PREVISAO, KEY_PAGINA_ESTOQUE_HISTORICO,
  KEY_PAGINA_CADASTRO_USUARIOS, KEY_PAGINA_ERRO, KEY_PAGINA_TCO,
} from './features/utils'
import Security from './features/utils/Security'

const Routes = () => {
  const { t } = useTranslation()
  const [autenticado, setAutenticado] = React.useState(false)

  const pagina = (page, ocultarFiltro, titulo, contexto = {}) => {
    if (ocultarFiltro) {
      return (
        <PaginaPadrao ocultarFiltro titulo={titulo} contexto={contexto}>{page}</PaginaPadrao>
      )
    }
    return (
      <PaginaPadrao titulo={titulo} contexto={contexto}>{page}</PaginaPadrao>
    )
  }

  Security.setDoLogoutHandler(() => setAutenticado(!autenticado))
  Security.setDoLoginHandler(() => setAutenticado(!autenticado))
  const usuarioAutenticado = Security.authorizationTokenExists

  return (
    <BrowserRouter>
      {usuarioAutenticado() ? (
        <Switch>
          {Security.checkForPermissions(KEY_PAGINA_HOME) ? <Route exact path={`/${t('geral.routes.URL_HOME')}`} render={() => pagina(<Home key={KEY_PAGINA_HOME} />, 'ocultarFiltro', '')} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_HOME) ? <Route exact path={`/${t('geral.routes.URL_LOGIN')}`} render={() => pagina(<Home key={KEY_PAGINA_HOME} />, 'ocultarFiltro', '')} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_INDIVIDUAL) ? <Route exact path={`/${t('geral.routes.URL_DESEMPENHO_INDIVIDUAL')}`} render={() => pagina(<Desempenho key={KEY_PAGINA_DESEMPENHO_INDIVIDUAL} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_DESEMPENHO_INDIVIDUAL'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_AGRUPADO) ? <Route exact path={`/${t('geral.routes.URL_DESEMPENHO_AGRUPADO')}`} render={() => pagina(<DesempenhoAgrupado key={KEY_PAGINA_DESEMPENHO_AGRUPADO} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_DESEMPENHO_AGRUPADO'), GraficoDesempenhoMateriaisContexto)} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_DISCRETIZADO) ? <Route exact path={`/${t('geral.routes.URL_DISCRETIZADO')}`} render={() => pagina(<Discretizado key={KEY_PAGINA_DESEMPENHO_DISCRETIZADO} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_DESEMPENHO_DISCRETIZADO'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_CONFIGURACOES) ? <Route exact path={`/${t('geral.routes.URL_CONFIGURACAO')}`} render={() => pagina(<Configuracoes key={KEY_PAGINA_CONFIGURACOES} />, 'ocultarFiltro', t('config.config.LABEL_TITULO'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_ESTOQUE_CORRENTE) ? <Route exact path={`/${t('geral.routes.URL_ESTOQUE_CORRENTE')}`} render={() => pagina(<Estoque key={KEY_PAGINA_ESTOQUE_CORRENTE} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_ESTOQUE_CORRENTE'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_ESTOQUE_HISTORICO) ? <Route exact path={`/${t('geral.routes.URL_ESTOQUE_HISTORICO')}`} render={() => pagina(<EstoqueHistorico key={KEY_PAGINA_ESTOQUE_HISTORICO} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_ESTOQUE_HISTORICO'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_CUSTO_RELATIVO) ? <Route exact path={`/${t('geral.routes.URL_CUSTO_RELATIVO')}`} render={() => pagina(<CustoRelativo key={KEY_PAGINA_CUSTO_RELATIVO} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_CUSTO_RELATIVO'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_CUSTO_PREVISAO) ? <Route exact path={`/${t('geral.routes.URL_CUSTO_PREVISAO')}`} render={() => pagina(<CustoPrevisao key={KEY_PAGINA_CUSTO_PREVISAO} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_CUSTO_PREVISAO'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_CADASTRO_USUARIOS) ? <Route exact path={`/${t('geral.routes.URL_CADASTRO_USUARIOS')}`} render={() => pagina(<Perfil key={KEY_PAGINA_CADASTRO_USUARIOS} />, 'ocultarFiltro', t('layout.layouPaginaPadrao.LABEL_TITULO_CADASTRO_USUARIOS'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_TCO) ? <Route exact path={`/${t('geral.routes.URL_TCO')}`} render={() => pagina(<TCO key={KEY_PAGINA_TCO} />, '', t('layout.layouPaginaPadrao.LABEL_TITULO_CUSTO_TCO'))} /> : undefined}
          {Security.checkForPermissions(KEY_PAGINA_ERRO) ? <Route path="*" render={() => <Erro />} /> : undefined}
        </Switch>
      ) : <Route path="*" render={() => <AutenticacaoLogin />} />}
    </BrowserRouter>
  )
}

export default Routes
