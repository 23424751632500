const PtBr = {
  config: {
    LABEL_TITULO: 'CONFIGURAÇÕES',
    LABEL_TITULO_TABELA_ESTOQUE_MINIMO: 'Estoque Mínimo',
    LABEL_TITULO_TABELA_PADRAO_CORTE: 'Padrão de Corte Normal',
    LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS: 'Previsão de Chegada de Cilindros (PEX)',
    LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS_SIMULADOR: 'Previsão de Chegada de Cilindros',
    LABEL_BACKUP_ROLL: 'Backup Roll',
    LABEL_WORK_ROLL: 'Work Roll',
    LABEL_LAMINADOR: 'Laminador',
    LABEL_TIPO_CILINDRO: 'Tipo de Cilindro',
    LABEL_TOTAL_PARES: 'Total de Pares',
    LABEL_ANO: 'Ano',
    LABEL_MES: 'Mês',
    LABEL_LOADING: 'Carregando...',
    LABEL_PAGINACAO: '{from}-{to} de {count}',
    LABEL_LINHAS_SELECIONADAS: 'linhas',
    LABEL_LINHAS_PAGINA: 'Linhas por página',
    LABEL_PRIMEIRA_PAGINA: 'Primeira Página',
    LABEL_TOOLTIP_PRIMEIRA_PAGINA: 'Primeira Página',
    LABEL_PAGINA_ANTERIOR: 'Página Anterior',
    LABEL_TOOLTIP_PAGINA_ANTERIOR: 'Página Anterior',
    LABEL_PROXIMA_PAGINA: 'Próxima Página',
    LABEL_TOOLTIP_PROXIMA_PAGINA: 'Próxima Página',
    LABEL_ULTIMA_PAGINA: 'Última Página',
    LABEL_TOOLTIP_ULTIMA_PAGINA: 'Última Página',
    LABEL_REGISTROS_SELECIONADOS: '{0} registro(s) selecionado(s)',
    LABEL_BUSCAR: 'Buscar',
    LABEL_TOOLTIP_BUSCAR: 'Buscar',
    LABEL_NENHUM_REGISTRO: 'Nenhum registro encontrado',
    LABEL_TOOLTIP_FILTRO: 'Filtro',
    LABEL_TOOLTIP_CANCELAR: 'Cancelar',
    LABEL_TOOLTIP_SALVAR: 'Salvar',
    LABEL_ACOES: 'Ações',
    LABEL_ADICIONAR: 'Adicionar',
    LABEL_EDITAR: 'Editar',
    LABEL_DELETAR: 'Deletar',
    LABEL_DELETAR_CONFIRMACAO: 'Tem certeza que deseja deletar esse usuário?',
    LABEL_LAMINADOR_VAZIO: 'Laminador não informado.',
    LABEL_LINHA_VAZIA: 'Linha de produção não informada.',
    LABEL_LAMINADOR_ERRADO: 'O laminador selecionado não pertence a linha de produção informada.',
    LABEL_TIPO_CILINDRO_VAZIO: 'Tipo de cilindro não informado.',
    LABEL_VALOR_VAZIO: 'Total de pares não informado.',
    LABEL_VALOR_ERRADO: 'Total de pares precisa ser um número válido.',
    LABEL_GRAVAR_SUCESSO: 'Registro de estoque mínimo salvo com sucesso.',
    LABEL_QUANTIDADE_DECIMAIS: 'O valor informado só pode ter uma casa decimal.',
    LABEL_PREVISAO_CHEGADA_CILINDROS_CASAS_DECIMAIS: 'O valor informado não pode conter casas decimais.',
    LABEL_PADRAO_CORTE_VALOR_VAZIO: 'Padrão de Corte Normal não informado.',
    LABEL_NUMERO_CILINDROS_PREVISTO_VAZIO: 'Número de Cilindros Previstos não informados.',
    LABEL_PADRAO_CORTE_VALOR_ERRADO: 'Padrão de Corte Normal precisa ser um número válido.',
    LABEL_NUMERO_CILINDROS_PREVISTO_VALOR_ERRADO: 'Número de Cilindros Previstos precisa ser um número inteiro válido.',
    LABEL_PADRAO_CORTE_GRAVAR_SUCESSO: 'Registro de Padrão de Corte Normal salvo com sucesso.',
    LABEL_PREVISAO_CHEGADA_CILINDROS_GRAVAR_SUCESSO: 'Registro de Previsão de Chegada de Cilindros salvo com sucesso.',
    LABEL_NUMERO_CILINDROS_PREVISTO: 'Quantidade',
    LABEL_PADRAO_CORTE_QUANTIDADE_DECIMAIS: 'O valor informado pode ter no máximo 3 casas decimais.',
    LABEL_MES_JAN: 'JAN',
    LABEL_MES_FEV: 'FEV',
    LABEL_MES_MAR: 'MAR',
    LABEL_MES_ABR: 'ABR',
    LABEL_MES_MAI: 'MAI',
    LABEL_MES_JUN: 'JUN',
    LABEL_MES_JUL: 'JUL',
    LABEL_MES_AGO: 'AGO',
    LABEL_MES_SET: 'SET',
    LABEL_MES_OUT: 'OUT',
    LABEL_MES_NOV: 'NOV',
    LABEL_MES_DEZ: 'DEZ',
    LABEL_NUMERO_CILINDRO_OCP: 'Número do Cilindro (OCP)',
    LABEL_IDENTIFICADOR_CILINDRO_SAP: 'Nº Nota Fiscal (ou Texto de Cabeçalho de Documento)',
    LABEL_TITULO_IDENTIFICADOR_CILINDRO_SAP: 'Cadastro de Identificador de Cilindros no SAP',
    LABEL_IDENTIFICADOR_VAZIO_SAP: 'É necessário informar um valor para o número da nota fiscal',
    LABEL_VALOR_ERRADO_SAP: 'O valor informado para identificador do SAP deve ser composto apenas por números.',
    LABEL_SUCESSO_SAP: 'A associação foi feita com sucesso.',
    LABEL_CILINDRO_VAZIO: 'Cilindro não informado.',
    LABEL_CILINDRO_NAO_EXISTENTE: 'Não foi encontrado um registro de cilindro com esse número.',
    LABEL_TIPO_CILINDRO_ERRADO: 'O cilindro informado possui um tipo diferente.',
    LABEL_TIPO_LAMINADOR_ERRADO: 'O cilindro informado possui um laminador diferente.',
    LABEL_PREVISAO_PRODUCAO_VALUE: 'Produção Prevista (em toneladas)',
    LABEL_TITULO_PREVISAO_PRODUCAO: 'Previsão de Produção',
    LABEL_PREVISAO_PRODUCAO_VALOR_VAZIO: 'É necessário informar a Produção Prevista para a data informada.',
    LABEL_PREVISAO_PRODUCAO_VALOR_INVALIDO: 'O valor informado precisa ser um número inteiro positivo.',
    LABEL_PREVISAO_PRODUCAO_SALVAR_SUCESSO: 'Registro salvo com sucesso.',
    LABEL_TITULO_PREVISAO_CUSTO_RELATIVO: 'Previsão de Custo Relativo',
    LABEL_PREVISAO_DE_CUSTO_RELATIVO: 'Previsão de Custo Relativo (em R$/t)',
    LABEL_PREVISAO_DE_CUSTO_RELATIVO_VAZIO: 'Previsão de Custo Relativo não informada.',
    LABEL_PREVISAO_DE_CUSTO_RELATIVO_VALOR_ERRADO: 'O valor de Previsão de Custo Relativo deve ser um número.',
    LABEL_PREVISAO_DE_CUSTO_RELATIVO_SUCESSO: 'O registro salvo com sucesso.',
    LABEL_PREVISAO_DE_CUSTO_RELATIVO_CASAS_DECIMAIS: 'O valor informado pode conter no máximo duas casas decimais.',
    LABEL_ERRO_API_AO_CARREGAR: 'Houve um erro ao carregar as configurações de: ',
    LABEL_ERRO_API_TENTE_MAIS_TARDE: 'Por favor, tente novamente mais tarde.',
    LABEL_BOTAO_OK: 'Ok',
    LABEL_ERRO: 'Erro',
  },
}

export default PtBr
