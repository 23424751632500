import React from 'react'
import { useTranslation } from 'react-i18next'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  propsLayoutCardRangeData,
  valueLayoutCardRangeData,
} from '../LayoutPropTypes'
import {
  getIntervaloDeDataParaRadioNoHome,
  ULTIMO_MES,
  ULTIMOS_3_MESES,
  ULTIMOS_6_MESES,
  ANUAL,
} from '../LayoutUtils'
import { graficoEstoqueCilindroEquivalenteNovoEstilo } from '../../graficoEstoqueCilindroEquivalenteNovo'
import { LayoutGeralConsumoAgrupadoEstilo } from '../LayoutEstilo'
import { useStateSafely } from '../../utils'

export default function LayoutCardRangeData(props) {
  const {
    filtro, titulo, children, marcarFiltroComoAnual,
  } = props
  const [intervaloDeTempo, setIntervaloDeTempo] = useStateSafely(ULTIMOS_3_MESES)
  const dataMaisAntiga = getIntervaloDeDataParaRadioNoHome(intervaloDeTempo, filtro.dataFim)
  const { t } = useTranslation()
  const classesGraficoEstoqCilindroEqNovos = graficoEstoqueCilindroEquivalenteNovoEstilo()
  const classes = LayoutGeralConsumoAgrupadoEstilo()
  const novasProps = { ...props, filtro: { ...filtro, dataInicio: dataMaisAntiga } }

  if (intervaloDeTempo === ANUAL && marcarFiltroComoAnual) {
    marcarFiltroComoAnual(true)
  } else if (marcarFiltroComoAnual) {
    marcarFiltroComoAnual(false)
  }

  return (
    <div className={classes.containerPrincipal}>
      <div className={classesGraficoEstoqCilindroEqNovos.titulo}>
        {titulo}
      </div>
      <div className={classes.radios}>
        <span>
          {t('home.visualizacoes.consumoAgrupado.LABEL_ULTIMO_S')}
        </span>
        <RadioGroup
          value={intervaloDeTempo}
          onChange={event => setIntervaloDeTempo(event.target.value)}
          row
        >
          {[{ value: ULTIMO_MES, label: t('home.visualizacoes.consumoAgrupado.RADIO_ULTIMO_MES') },
            { value: ULTIMOS_3_MESES, label: t('home.visualizacoes.consumoAgrupado.RADIO_ULTIMOS_3_MESES') },
            { value: ULTIMOS_6_MESES, label: t('home.visualizacoes.consumoAgrupado.RADIO_ULTIMOS_6_MESES') },
            { value: ANUAL, label: t('home.visualizacoes.consumoAgrupado.RADIO_ANUAL') }].map(radioInfo => (
              <FormControlLabel
                value={radioInfo.value}
                control={<Radio color="default" style={{ padding: 4 }} />}
                label={radioInfo.label}
                labelPlacement="end"
                key={radioInfo.value}
                className={classes.radio}
                style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}
              />
          ))}
        </RadioGroup>
      </div>
      <div className={classes.containerSuperior}>
        {children ? React.cloneElement(children, novasProps) : ''}
      </div>
    </div>
  )
}

LayoutCardRangeData.propTypes = propsLayoutCardRangeData
LayoutCardRangeData.defaultProps = valueLayoutCardRangeData
