import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoTCORequisicao from './GraficoTCORequisicao'
import { ENDPOINT_TCO } from '../utils/Configuracoes'

export default async function GraficoTCOService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_TCO,
      GraficoTCORequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}
