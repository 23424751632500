import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatarNumero, SRCelula, BotaoEditar,
} from '../../utils'
import propsTabela, { valuesTabela } from '../TabelaPrevisaoCompraPropTypes'
import { zerarValoresPequenos } from '../TabelaPrevisaoCompraUtils'
import TabelaPrevisaoCompraEstoqueModal from './TabelaPrevisaoCompraEstoqueModal'
import TabelaPrevisaoCompraEstoqueMinimoOperacional from './TabelaPrevisaoCompraEstoqueMinimoOperacional'

const TabelaPrevisaoCompraEquivalente = (
  {
    ano, item, editavel, atualizarDados, dadosMinimoOperacional,
  },
) => {
  const { t } = useTranslation()
  const [conteudoModal, setConteudoModal] = React.useState(undefined)
  const [editado, setEditado] = React.useState(false)

  const conteudoModalMinimoOperacional = {
    titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR'),
    ano,
    componente: React.createElement(
      TabelaPrevisaoCompraEstoqueMinimoOperacional,
      {
        ano,
        dados: dadosMinimoOperacional.filter(
          dados => dados.ano === ano,
        ),
        setEditado,
        usarValorEquivalente: true,
      },
      null,
    ),
  }

  function fecharModal(anoCorrente) {
    if (editado) {
      atualizarDados(anoCorrente)
    }
    setConteudoModal(undefined)
  }

  return (
    <>
      {
        conteudoModal ? (
          <TabelaPrevisaoCompraEstoqueModal
            closeHandle={() => fecharModal(ano)}
            titulo={conteudoModal.titulo}
            ano={conteudoModal.ano}
          >
            {conteudoModal.componente}
          </TabelaPrevisaoCompraEstoqueModal>
        )
          : null
      }
      <tr>
        <SRCelula percentualLargura="6%" negrito altura="3">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EQUIVALENTE')}</SRCelula>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_PLANEJADAS')}</SRCelula>
        {item.equivalenteNovos.map(equivalenteNovos => (
          <SRCelula
            background={equivalenteNovos.previstoUnidade < item.minimoOperacionalEquivalente ? 'vermelho'
              : ''}
            percentualLargura="6%"
            key={Math.random()}
          >
            {zerarValoresPequenos(formatarNumero(equivalenteNovos.previstoUnidade, 1))}
          </SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {zerarValoresPequenos(formatarNumero(item.equivalenteNovos[
            item.equivalenteNovos.length - 1].previstoUnidade, 1))}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()} />
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_REAL')}</SRCelula>
        {item.equivalenteNovos.map(equivalenteNovos => (
          <SRCelula background={equivalenteNovos.realUnidade < item.minimoOperacionalEquivalente ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(equivalenteNovos.realUnidade, 1)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {formatarNumero(item.equivalenteNovos[item.equivalenteNovos.length - 1].realUnidade, 1)}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}> </SRCelula>
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MIN_OPERACIONAL')}</SRCelula>
        {item.equivalenteNovos.map(() => (
          <SRCelula background="azul" percentualLargura="6%" key={Math.random()}>{formatarNumero(item.minimoOperacionalEquivalente)}</SRCelula>
        ))}
        <SRCelula background="azul" percentualLargura="6%" key={Math.random()}>{formatarNumero(item.minimoOperacionalEquivalente)}</SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}>
            <BotaoEditar evento={() => setConteudoModal(conteudoModalMinimoOperacional)} />
          </SRCelula>
        ) : null}
      </tr>
    </>
  )
}

TabelaPrevisaoCompraEquivalente.propTypes = propsTabela
TabelaPrevisaoCompraEquivalente.defaultProps = valuesTabela

export default TabelaPrevisaoCompraEquivalente
