import { createMuiTheme } from '@material-ui/core'
import moment from 'moment'
import { propsSelecao, valueSelecao } from './FiltroPropTypes'
import {
  INTERVALO_0_A_25, INTERVALO_25_A_50, INTERVALO_50_A_75, INTERVALO_75_A_100,
  error, ARGS_OBRIGATORIOS, isFunction, and, KEY_PAGINA_CUSTO_PREVISAO,
  KEY_PAGINA_DESEMPENHO_INDIVIDUAL, KEY_PAGINA_DESEMPENHO_DISCRETIZADO,
  KEY_PAGINA_DESEMPENHO_AGRUPADO, ordenarArrayPorChave,
  KEY_PAGINA_ESTOQUE_CORRENTE, KEY_PAGINA_TCO,
} from '../utils'
import { publicar } from '../utils/FilaDeMensagens'
// eslint-disable-next-line import/no-self-import
import * as FiltroUtils from './FiltroUtils'

export function isValidId(id) {
  return (typeof id === 'number' && !Number.isNaN(id) && id >= 0)
}

export function filtraLaminadores(listaDeLaminadores, linha) {
  if (!listaDeLaminadores || !isValidId(linha)) {
    return []
  }
  return listaDeLaminadores.filter(laminador => laminador.codLinhaProducao === linha)
}

export function filtraCilindros(listaDeCilindros, codLaminador,
  codTipoDeCilindro, listaDeFornecedores, listaDeMateriais, dataInicio, dataFim) {
  if (!listaDeFornecedores || !isValidId(codLaminador) || !isValidId(codTipoDeCilindro)
      || !listaDeMateriais || !listaDeCilindros || !dataInicio || !dataFim) {
    return []
  }
  const resp = listaDeCilindros.filter(
    cilindro => cilindro.codLaminador === codLaminador
        && cilindro.codTipoCilindro === codTipoDeCilindro
        && cilindro.dataChegada < dataFim
        && (!cilindro.dataSucatado || cilindro.dataSucatado > dataInicio)
        && listaDeFornecedores.includes(cilindro.codFornecedor)
        && listaDeMateriais.includes(cilindro.codMaterial),
  )
  return resp
}

export function filtraCilindrosData(listaDeCilindros, codLaminador,
  codTipoDeCilindro, dataInicio, dataFim) {
  if (!isValidId(codLaminador) || !isValidId(codTipoDeCilindro)
      || !listaDeCilindros || !dataInicio || !dataFim) {
    return []
  }
  const resp = listaDeCilindros.filter(
    cilindro => cilindro.codLaminador === codLaminador
        && cilindro.codTipoCilindro === codTipoDeCilindro
        && cilindro.dataChegada < dataFim
        && (!cilindro.dataSucatado || cilindro.dataSucatado > dataInicio),
  )
  return resp
}

export function filtraCilindrosFornecedor(listaDeCilindros, codLaminador,
  codTipoDeCilindro, dataInicio, dataFim, fornecedor) {
  if (!isValidId(codLaminador) || !isValidId(codTipoDeCilindro)
      || !listaDeCilindros || !dataInicio || !dataFim) {
    return []
  }
  const resp = listaDeCilindros.filter(
    cilindro => cilindro.codLaminador === codLaminador
        && cilindro.codTipoCilindro === codTipoDeCilindro
        && cilindro.dataChegada < dataFim
        && fornecedor.includes(cilindro.codFornecedor)
        && (!cilindro.dataSucatado || cilindro.dataSucatado > dataInicio),
  )
  return resp
}

export function idsDosFornecedores(listaDeFornecedores) {
  return listaDeFornecedores.map(
    fornecedor => fornecedor.codFornecedor,
  )
}

export function idsDosMateriais(listaDeMateriais) {
  return listaDeMateriais.map(material => material.codMaterial)
}

export const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: { 500: '#004070' },
  },
})
export function selecao(selected, disabled, lista, itemValue, itemDisplay, todos, itens, nenhum) {
  if (disabled || selected[1] === nenhum || selected.length === 0) {
    return nenhum
  }
  if (selected.length === 1) {
    const itemSelecionado = lista.find(item => item[itemValue] === selected[0])
    return itemSelecionado[itemDisplay]
  }
  if (selected.length === lista.length) {
    return todos
  }

  return selected.length + itens
}

export function valorFinalDaLista(listaCorrente, listaComTodos, key) {
  if (listaCorrente.length > listaComTodos.length) return []
  if (listaCorrente.includes(-1)) {
    return listaComTodos.map(item => item[key])
  }
  return listaCorrente
}

export function limparTudo(limparFiltro, exibirGrafico) {
  limparFiltro()
  exibirGrafico('')
}

export function focoErro(erroLaminador, erroLinha, laminador, linha) {
  if (erroLaminador && laminador) {
    laminador.focus()
  }
  if (erroLinha && linha) {
    linha.focus()
  }
}

export function handleChangeData(valor, setState, state, estado, selecione, pageKey) {
  const diaAtual = new Date()
  let data = new Date(moment(valor).format())
  data.setDate(data.getUTCDate() + 1)
  data.setUTCHours(2)
  data.setUTCMinutes(59)
  data.setUTCSeconds(59)
  data.setUTCMilliseconds(59)
  if ((diaAtual > data) || (pageKey === KEY_PAGINA_ESTOQUE_CORRENTE && estado === 'dataFim')) {
    data = new Date(data.getFullYear(), data.getMonth() + 1, 0, 20, 59, 59, 59)
  } else if (pageKey !== KEY_PAGINA_ESTOQUE_CORRENTE) {
    data = new Date(valor)
    data.setUTCDate(diaAtual.getUTCDate())
  }
  if (estado === 'dataFimCompleto') {
    setState({
      ...state,
      tipoDeCilindro: undefined,
      fornecedores: [],
      materiais: [],
      fornecedoresBKP: [],
      materiaisBKP: [],
      fornecedoresWR: [],
      materiaisWR: [],
      tipoDeCilindroCheck: {
        bur: false,
        wr: false,
      },
      cilindros: [{ numCilindro: selecione }],
      cilindro: { numCilindro: selecione },
      dataFim: new Date(valor),
    })
  } else if ((pageKey === KEY_PAGINA_CUSTO_PREVISAO)
    && estado === 'dataFim') {
    setState({
      ...state,
      dataFim: data,
    })
  } else if (pageKey === KEY_PAGINA_CUSTO_PREVISAO) {
    setState({
      ...state,
      [estado]: new Date(valor),
    })
  } else if (estado === 'dataFim') {
    setState({
      ...state,
      tipoDeCilindro: undefined,
      fornecedores: [],
      materiais: [],
      fornecedoresBKP: [],
      materiaisBKP: [],
      fornecedoresWR: [],
      materiaisWR: [],
      tipoDeCilindroCheck: {
        bur: false,
        wr: false,
      },
      cilindros: [{ numCilindro: selecione }],
      cilindro: { numCilindro: selecione },
      dataFim: data,
    })
  } else {
    setState({
      ...state,
      tipoDeCilindro: undefined,
      fornecedores: [],
      materiais: [],
      fornecedoresBKP: [],
      materiaisBKP: [],
      fornecedoresWR: [],
      materiaisWR: [],
      tipoDeCilindroCheck: {
        bur: false,
        wr: false,
      },
      cilindros: [{ numCilindro: selecione }],
      cilindro: { numCilindro: selecione },
      [estado]: new Date(valor),
    })
  }
}

export function validaHandleChangeLinhaLaminador(setState, state, {
  setErroLinha, setErroLaminador, setErroTipo, setErroCilindro,
  setErroConsumoDoCilindro, setErroFornecedor, setErroMaterial,
}) {
  if (!state || !and([setState, setErroLinha, setErroLaminador, setErroTipo, setErroCilindro,
    setErroConsumoDoCilindro, setErroFornecedor, setErroMaterial].map(isFunction))) {
    error(ARGS_OBRIGATORIOS)
  }
}

export function removeIndicadoresDeErroLinhaLaminador({
  setErroLinha, setErroLaminador, setErroTipo, setErroCilindro,
  setErroConsumoDoCilindro, setErroFornecedor, setErroMaterial,
}) {
  setErroLinha(false)
  setErroLaminador(false)
  setErroTipo(false)
  setErroCilindro(false)
  setErroConsumoDoCilindro(false)
  setErroFornecedor(false)
  setErroMaterial(false)
}

export function handleChangeLinha(value, setState, state, laminadores, removeIndicadorDeErros) {
  FiltroUtils.validaHandleChangeLinhaLaminador(setState, state, removeIndicadorDeErros)
  FiltroUtils.removeIndicadoresDeErroLinhaLaminador(removeIndicadorDeErros)
  const laminadoresLinha = FiltroUtils.filtraLaminadores(laminadores, value)
  setState({
    ...state,
    linha: value,
    laminador: laminadoresLinha.length === 1 ? laminadoresLinha[0].codLaminador : undefined,
    tipoDeCilindro: undefined,
    tipoDeCilindroCheck: {
      bur: false,
      wr: false,
    },
    fornecedores: [],
    materiais: [],
  })
}

export function handleChangeLaminador(value, setState, state, indicadoresDeErro) {
  FiltroUtils.validaHandleChangeLinhaLaminador(setState, state, indicadoresDeErro)
  FiltroUtils.removeIndicadoresDeErroLinhaLaminador(indicadoresDeErro)
  setState({
    ...state,
    laminador: value,
    tipoDeCilindro: undefined,
    tipoDeCilindroCheck: {
      bur: false,
      wr: false,
    },
    fornecedores: [],
    materiais: [],
  })
}

export function validaHandleChangeMateriais(setState, state, indicadoresDeErro, labels) {
  if (!state || !indicadoresDeErro || !labels || !labels.selecione || !and([setState,
    indicadoresDeErro.setErroMaterial, indicadoresDeErro.setErroCilindro,
    indicadoresDeErro.setErroConsumoDoCilindro].map(isFunction))) {
    error(ARGS_OBRIGATORIOS)
  }
}

export function removeIndicadoresDeErroMateriais({
  setErroMaterial, setErroCilindro, setErroConsumoDoCilindro,
}) {
  setErroCilindro(false)
  setErroConsumoDoCilindro(false)
  setErroMaterial(false)
}

export function handleChangeMateriais(
  value, setState, state, cilindros, indicadoresDeErro, labels, pageKey,
) {
  FiltroUtils.validaHandleChangeMateriais(setState, state, indicadoresDeErro, labels)
  FiltroUtils.removeIndicadoresDeErroMateriais(indicadoresDeErro)
  const cilindrosMaterial = FiltroUtils.filtraCilindros(cilindros, state.laminador,
    state.tipoDeCilindro, state.fornecedores, value, state.dataInicio, state.dataFim)
  setState({
    ...state,
    materiais: value,
    cilindros: cilindrosMaterial.length === 1 ? cilindrosMaterial
      : pageKey === KEY_PAGINA_DESEMPENHO_AGRUPADO || pageKey === KEY_PAGINA_TCO
        ? cilindrosMaterial : [{ numCilindro: labels.selecione }],
    cilindro: cilindrosMaterial.length === 1 ? cilindrosMaterial[0]
      : { numCilindro: labels.selecione },
  })
}

export function validaHandleChangeCilindros(setState, state, indicadoresDeErro) {
  if (!isFunction(setState) || !state || !indicadoresDeErro
  || !isFunction(indicadoresDeErro.setErroCilindro)) {
    error(ARGS_OBRIGATORIOS)
  }
}

export function handleChangeCilindros(value, setState, state, indicadoresDeErro) {
  FiltroUtils.validaHandleChangeCilindros(setState, state, indicadoresDeErro)
  indicadoresDeErro.setErroCilindro(false)
  setState({
    ...state,
    cilindros: value,
  })
}

export function handleChangeCilindroSimples(value, setState, state, indicadoresDeErro) {
  FiltroUtils.validaHandleChangeCilindros(setState, state, indicadoresDeErro)
  indicadoresDeErro.setErroCilindro(false)
  setState({
    ...state,
    cilindro: value,
  })
}

export function handleChangeConsumoDoCilindro(value, setState, state, indicadoresDeErro) {
  if (!isFunction(setState) || !state || !indicadoresDeErro
  || !isFunction(indicadoresDeErro.setErroConsumoDoCilindro)) {
    error(ARGS_OBRIGATORIOS)
  }
  indicadoresDeErro.setErroConsumoDoCilindro(false)
  setState({
    ...state,
    consumoDoCilindro: value,
  })
}

export function handleChangeRadio(value, setState, state, setErroTipo,
  setErroConsumoDoCilindro, setErroFornecedor, setErroMaterial, setErroCilindro,
  cilindros, dataInicio, dataFim, setTodosEstados, selecione, pageKey) {
  const cilindrosFiltrados = filtraCilindrosData(cilindros, state.laminador,
    value, dataInicio, dataFim)

  const fornecedoresFiltrados = cilindrosFiltrados.map(cilindro => (
    {
      nomeFornecedor: cilindro.nomeFornecedor,
      codFornecedor: cilindro.codFornecedor,
    })).filter(function removeDuplicado(a) {
    const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
    return valores
  }, Object.create(null))

  ordenarArrayPorChave(fornecedoresFiltrados, 'nomeFornecedor')

  const idsFornecedores = idsDosFornecedores(fornecedoresFiltrados)

  const cilindrosFiltrados2 = filtraCilindrosFornecedor(cilindrosFiltrados, state.laminador,
    value, dataInicio, dataFim, idsFornecedores)

  const materiaisFiltrados = cilindrosFiltrados2.map(cilindro => (
    {
      descricaoMaterial: cilindro.descMaterial,
      codMaterial: cilindro.codMaterial,
    })).filter(function removeDuplicado(a) {
    const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
    return valores
  }, Object.create(null))

  setTodosEstados(materiaisFiltrados, fornecedoresFiltrados)

  const idsMateriais = idsDosMateriais(materiaisFiltrados)

  const cilindrosMaterial = filtraCilindros(cilindrosFiltrados2, state.laminador,
    value, idsFornecedores, idsMateriais, dataInicio, dataFim)

  setErroTipo(false)
  setErroCilindro(false)
  setErroConsumoDoCilindro(false)
  setErroFornecedor(false)
  setErroMaterial(false)
  setState({
    ...state,
    tipoDeCilindro: value,
    fornecedores: fornecedoresFiltrados.length === 1 ? [fornecedoresFiltrados[0].codFornecedor]
      : [],
    materiais: materiaisFiltrados.length === 1 ? [materiaisFiltrados[0].codMaterial] : [],
    cilindros: cilindrosMaterial.length === 1 ? [cilindrosMaterial[0]]
      : ((pageKey === KEY_PAGINA_DESEMPENHO_AGRUPADO || pageKey === KEY_PAGINA_TCO)
       && fornecedoresFiltrados.length === 1
      && materiaisFiltrados.length === 1) ? cilindrosMaterial : [{ numCilindro: selecione }],
    cilindro: cilindrosMaterial.length === 1 ? cilindrosMaterial[0].codCilindro
      : { numCilindro: selecione },
  })
}

export function handleChangeFornecedor(value, cilindros, state, setState, dataInicio, dataFim,
  setMateriais, setErroFornecedor, setErroCilindro, setErroConsumoDoCilindro, setErroMaterial,
  selecione, pageKey) {
  const cilindrosFiltrados = filtraCilindrosFornecedor(cilindros, state.laminador,
    state.tipoDeCilindro, dataInicio, dataFim, value)
  const materiaisFiltrados = cilindrosFiltrados.map(cilindro => (
    {
      descricaoMaterial: cilindro.descMaterial,
      codMaterial: cilindro.codMaterial,
    })).filter(function removeDuplicado(a) {
    const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
    return valores
  }, Object.create(null))

  ordenarArrayPorChave(materiaisFiltrados, 'descricaoMaterial')

  setMateriais(materiaisFiltrados)

  const idsMateriais = idsDosMateriais(materiaisFiltrados)

  const cilindrosMaterial = filtraCilindros(cilindros, state.laminador,
    state.tipoDeCilindro, value, idsMateriais, dataInicio, dataFim)

  setErroFornecedor(false)
  setErroCilindro(false)
  setErroConsumoDoCilindro(false)
  setErroMaterial(false)
  setState({
    ...state,
    fornecedores: value,
    materiais: materiaisFiltrados.length === 1 ? [materiaisFiltrados[0].codMaterial] : [],
    cilindros: cilindrosMaterial.length === 1 ? [cilindrosMaterial[0]]
      : ((pageKey === KEY_PAGINA_DESEMPENHO_AGRUPADO || pageKey === KEY_PAGINA_TCO)
      && materiaisFiltrados.length === 1)
        ? cilindrosMaterial : [{ numCilindro: selecione }],
    cilindro: cilindrosMaterial.length === 1 ? cilindrosMaterial[0].codCilindro
      : { numCilindro: selecione },
  })
}

export function tratarDadosDeConsumoDosCilindros(dados) {
  const newConsumoDoCilindro = {
    [INTERVALO_0_A_25]: false,
    [INTERVALO_25_A_50]: false,
    [INTERVALO_50_A_75]: false,
    [INTERVALO_75_A_100]: false,
  }
  dados.forEach((propriedade) => { newConsumoDoCilindro[propriedade] = true })
  return newConsumoDoCilindro
}

export function aplicar(state, setErroLinha, setErroLaminador, setErroTipo, setErroFornecedor,
  setErroConsumoDoCilindro, setErroMaterial, setErroCilindro, mudarDataInicio, mudarDataFim,
  mudarLinhaDeProducao, mudarCilindros, mudarMaterial, mudarFornecedores, mudarLaminador,
  mudarTipoDeCilindro, exibirGrafico, setConsumoDoCilindro, pageKey, mudarCilindro, selecione,
  mudarTipoDeCilindroChecked, setAbrir, mudarMaterialBKP, mudarMaterialWR,
  mudarFornecedoresBKP, mudarFornecedoresWR) {
  if (state.dataFim < state.dataInicio) {
    publicar({ text: 'filtro.filtroBody.LABEL_DATA_INCORRETA', type: 'error', translate: true })
  } else if (state.linha === undefined) {
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
    setErroLinha(true)
  } else if (state.laminador === undefined) {
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
    setErroLinha(false)
    setErroLaminador(true)
  } else if ((state.tipoDeCilindro === undefined) && ((state.tipoDeCilindroCheck === undefined)
    || ((!state.tipoDeCilindroCheck.bur) && (!state.tipoDeCilindroCheck.wr)))) {
    setErroLaminador(false)
    setErroTipo(true)
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if ((state.fornecedores.includes(undefined) || state.fornecedores.length === 0)
  && (pageKey !== KEY_PAGINA_CUSTO_PREVISAO)) {
    setErroTipo(false)
    setErroConsumoDoCilindro(false)
    setErroFornecedor(true)
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if ((state.materiais.includes(undefined) || state.materiais.length === 0)
  && (pageKey !== KEY_PAGINA_CUSTO_PREVISAO)) {
    setErroTipo(false)
    setErroConsumoDoCilindro(false)
    setErroFornecedor(false)
    setErroMaterial(true)
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if (((pageKey === KEY_PAGINA_DESEMPENHO_INDIVIDUAL
    || pageKey === KEY_PAGINA_DESEMPENHO_AGRUPADO || pageKey === KEY_PAGINA_TCO)
  && (state.cilindros.includes(undefined) || state.cilindros.length === 0
  || (state.cilindros[0].numCilindro === selecione)))
  || (pageKey === KEY_PAGINA_DESEMPENHO_DISCRETIZADO
    && (state.cilindro.numCilindro === selecione))) {
    setErroTipo(false)
    setErroFornecedor(false)
    setErroMaterial(false)
    setErroCilindro(true)
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if ((pageKey === KEY_PAGINA_DESEMPENHO_AGRUPADO || pageKey === KEY_PAGINA_TCO)
  && state.consumoDoCilindro.length === 0) {
    setErroConsumoDoCilindro(true)
    setErroFornecedor(false)
    setErroMaterial(false)
    publicar({ text: 'filtro.filtroBody.LABEL_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else {
    if (document.getElementById('aplicar')) {
      document.getElementById('aplicar').blur()
    }
    setErroLinha(false)
    setErroLaminador(false)
    setErroMaterial(false)
    setErroTipo(false)
    setErroFornecedor(false)
    setErroCilindro(false)
    setErroConsumoDoCilindro(false)
    mudarDataInicio(state.dataInicio)
    mudarDataFim(state.dataFim)
    mudarLinhaDeProducao(state.linha)
    mudarLaminador(state.laminador)
    mudarTipoDeCilindro(state.tipoDeCilindro)
    mudarTipoDeCilindroChecked(state.tipoDeCilindroCheck)
    mudarFornecedores(state.fornecedores)
    mudarMaterial(state.materiais)
    mudarMaterialBKP(state.materiaisBKP)
    mudarMaterialWR(state.materiaisWR)
    mudarFornecedoresBKP(state.fornecedoresBKP)
    mudarFornecedoresWR(state.fornecedoresWR)
    mudarCilindros(state.cilindros.map(cilindro => cilindro.codCilindro))
    mudarCilindro(state.cilindro)
    setConsumoDoCilindro(tratarDadosDeConsumoDosCilindros(state.consumoDoCilindro))
    setAbrir(false)
    exibirGrafico(pageKey)
  }
}

export function obterNomeLinha(lista, id) {
  if (!lista || !id) {
    return null
  }
  const retorno = lista.filter(dado => dado.codLinhaProducao === id)
  return retorno.length === 0 ? null : retorno
}

export function obterNomeLaminador(lista, id) {
  if (!lista || !id) {
    return null
  }
  const retorno = lista.filter(dado => dado.codLaminador === id)
  return retorno.length === 0 ? null : retorno
}

export function obterConsumoCilindros(consumos, labels) {
  if (!consumos || !labels || !labels.intervaloZero || !labels.intervaloVinteCinco
     || !labels.intervaloCinquenta || !labels.intervaloCem) {
    return null
  }
  const {
    intervaloZero, intervaloVinteCinco, intervaloCinquenta, intervaloCem,
  } = labels
  const retorno = []
  if (consumos.includes(INTERVALO_75_A_100)) {
    retorno.push(intervaloCem)
  }
  if (consumos.includes(INTERVALO_50_A_75)) {
    retorno.push(intervaloCinquenta)
  }
  if (consumos.includes(INTERVALO_25_A_50)) {
    retorno.push(intervaloVinteCinco)
  }
  if (consumos.includes(INTERVALO_0_A_25)) {
    retorno.push(intervaloZero)
  }
  return retorno.join(' / ')
}

export function obterNumCilindros(valores) {
  if (!valores || valores.length === 0) {
    return null
  }
  const retorno = []
  for (let a = 0; a < valores.length; a += 1) {
    retorno.push(valores[a].numCilindro)
  }
  return retorno.join(' / ')
}

export function obterNomeFornecedores(lista, valores) {
  if (!lista || !valores || lista.length === 0 || valores.length === 0) {
    return null
  }
  const fornecedores = []
  for (let a = 0; a < valores.length; a += 1) {
    fornecedores.push(lista.find(item => item.codFornecedor === valores[a]))
  }
  const nomes = fornecedores.map(item => item.nomeFornecedor)
  const retorno = []
  for (let a = 0; a < nomes.length; a += 1) {
    retorno.push(nomes[a])
  }
  return retorno.join(' / ')
}

export function obterNomeMateriais(lista, valores) {
  if (!lista || !valores || lista.length === 0 || valores.length === 0) {
    return null
  }
  const materiais = []
  for (let a = 0; a < valores.length; a += 1) {
    materiais.push(lista.find(item => item.codMaterial === valores[a]))
  }
  const nomes = materiais.map(item => item.descMaterial)
  const retorno = []
  for (let a = 0; a < nomes.length; a += 1) {
    retorno.push(nomes[a])
  }
  return retorno.join(' / ')
}

selecao.propTypes = propsSelecao
selecao.defaultProps = valueSelecao
