const GraficosTraducao = () => ({
  lang: {
    drillUpText: 'Voltar',
    resetZoom: 'Limpar Zoom',
    resetZoomTitle: 'Visualizar o gráfico no tamanho original',
    decimalPoint: ',',
    downloadCSV: 'Baixar como CSV',
    downloadJPEG: 'Baixar como JPEG',
    downloadPDF: 'Baixar como PDF',
    downloadPNG: 'Baixar como PNG',
    downloadSVG: 'Baixar como SVG',
    downloadXLS: 'Baixar como XLS',
    printChart: 'Imprimir',
    viewFullscreen: 'Visualizar em tela cheia',
    contextButtonTitle: 'Exportar Gráfico',
    shortMonths: [
      '01', '02', '03', '04',
      '05', '06', '07', '08',
      '09', '10', '11', '12',
    ],
  },
}
)

export default GraficosTraducao
