import React, { useState, useContext } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'
import {
  desempenho, estoque, home, logout, profile, config, custo, perfil,
} from '../../../assets/images'
import {
  LocalStorageProxy,
  KEY_PAGINA_HOME, KEY_PAGINA_DESEMPENHO_INDIVIDUAL, KEY_PAGINA_DESEMPENHO_AGRUPADO,
  KEY_PAGINA_CONFIGURACOES, KEY_PAGINA_ESTOQUE_CORRENTE, KEY_PAGINA_DESEMPENHO_DISCRETIZADO,
  KEY_PAGINA_CUSTO_RELATIVO, KEY_PAGINA_CUSTO_PREVISAO, KEY_PAGINA_ESTOQUE_HISTORICO,
  KEY_PAGINA_CADASTRO_USUARIOS, KEY_PERMISSAO_TROCAR_SENHA, KEY_PAGINA_TCO,
} from '../../utils'
import { handleClick, onClickHandle, abrirAlterarSenha } from '../LayoutUtils'
import LayoutEstilo from '../LayoutEstilo'
import { propsLayoutMenu, valueLayoutMenu } from '../LayoutPropTypes'
import { AlterarSenhaContext } from '../LayoutContext'
import LayoutSubMenu from './LayoutSubMenu'

import Security from '../../utils/Security'

function LayoutMenu({ evento }) {
  const [openDesempenho, setOpenDesempenho] = useState(false)
  const [openCusto, setOpenCusto] = useState(false)
  const [openEstoque, setOpenEstoque] = useState(false)
  const [openPerfil, setOpenPerfil] = useState(false)
  const classes = LayoutEstilo()
  const { t } = useTranslation()
  const context = useContext(AlterarSenhaContext)

  return (
    <nav className="grid-container">
      <div className="profile">
        <img src={profile} alt={t('layout.layoutMenu.ALT_PERFIL')} />
      </div>
      <div className="menu">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.rootLista}
        >
          <p className={classes.layoutMenuUserName}>
            {LocalStorageProxy.getItem('NomeUsuario')}
          </p>
          {Security.checkForPermissions(KEY_PAGINA_HOME) ? (
            <Link to={`/${t('geral.routes.URL_HOME')}`} style={{ textDecoration: 'none', color: 'inherit' }} onClick={evento}>
              <ListItem className={classes.child}>
                <ListItemIcon><img src={home} alt={t('layout.layoutMenu.ALT_HOME')} /></ListItemIcon>
                <ListItemText primary={t('layout.layoutMenu.LABEL_HOME')} />
              </ListItem>
            </Link>
          ) : undefined}
          {(Security.checkForPermissions(KEY_PAGINA_ESTOQUE_CORRENTE)
            || Security.checkForPermissions(KEY_PAGINA_ESTOQUE_HISTORICO))
            ? (
              <>
                <ListItem
                  onClick={() => handleClick(setOpenEstoque, openEstoque, setOpenCusto,
                    setOpenDesempenho, setOpenPerfil)}
                  className={classes.child}
                >
                  <ListItemIcon>
                    <img src={estoque} alt={t('layout.layoutMenu.ALT_ESTOQUE')} />
                  </ListItemIcon>
                  <ListItemText primary={t('layout.layoutMenu.LABEL_ESTOQUE')} />
                  {openEstoque ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {Security.checkForPermissions(KEY_PAGINA_ESTOQUE_CORRENTE) ? (
                  <LayoutSubMenu
                    open={openEstoque}
                    evento={evento}
                    link={`/${t('geral.routes.URL_ESTOQUE_CORRENTE')}`}
                    label={t('layout.layoutMenu.LABEL_CORRENTE')}
                  />
                ) : undefined}
                {Security.checkForPermissions(KEY_PAGINA_ESTOQUE_HISTORICO) ? (
                  <LayoutSubMenu
                    open={openEstoque}
                    evento={evento}
                    link={`/${t('geral.routes.URL_ESTOQUE_HISTORICO')}`}
                    label={t('layout.layoutMenu.LABEL_HISTORICO')}
                  />
                ) : undefined}
              </>
            ) : undefined}
          {(Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_INDIVIDUAL)
            || Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_AGRUPADO)
            || Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_DISCRETIZADO))
            ? (
              <>
                <ListItem
                  onClick={() => handleClick(setOpenDesempenho, openDesempenho, setOpenCusto,
                    setOpenEstoque, setOpenPerfil)}
                  className={classes.child}
                >
                  <ListItemIcon>
                    <img src={desempenho} alt={t('layout.layoutMenu.ALT_DESEMPENHO')} />
                  </ListItemIcon>
                  <ListItemText primary={t('layout.layoutMenu.LABEL_DESEMPENHO')} />
                  {openDesempenho ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_INDIVIDUAL) ? (
                  <LayoutSubMenu
                    open={openDesempenho}
                    evento={evento}
                    link={`/${t('geral.routes.URL_DESEMPENHO_INDIVIDUAL')}`}
                    label={t('layout.layoutMenu.LABEL_DESEMPENHO_INDIVIDUAL')}
                  />
                ) : undefined}
                {Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_AGRUPADO) ? (
                  <LayoutSubMenu
                    open={openDesempenho}
                    evento={evento}
                    link={`/${t('geral.routes.URL_DESEMPENHO_AGRUPADO')}`}
                    label={t('layout.layoutMenu.LABEL_DESEMPENHO_AGRUPADO')}
                  />
                ) : undefined}
                {Security.checkForPermissions(KEY_PAGINA_DESEMPENHO_DISCRETIZADO) ? (
                  <LayoutSubMenu
                    open={openDesempenho}
                    evento={evento}
                    link={`/${t('geral.routes.URL_DISCRETIZADO')}`}
                    label={t('layout.layoutMenu.LABEL_DISCRETIZADO')}
                  />
                ) : undefined}
              </>
            ) : undefined}
          {(Security.checkForPermissions(KEY_PAGINA_CUSTO_RELATIVO)
            || Security.checkForPermissions(KEY_PAGINA_CUSTO_PREVISAO))
            ? (
              <>
                <ListItem
                  onClick={() => handleClick(setOpenCusto, openCusto, setOpenDesempenho,
                    setOpenEstoque, setOpenPerfil)}
                  className={classes.child}
                >
                  <ListItemIcon>
                    <img src={custo} alt={t('layout.layoutMenu.ALT_CUSTO')} />
                  </ListItemIcon>
                  <ListItemText primary={t('layout.layoutMenu.LABEL_CUSTO')} />
                  {openCusto ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {Security.checkForPermissions(KEY_PAGINA_CUSTO_RELATIVO) ? (
                  <LayoutSubMenu
                    open={openCusto}
                    evento={evento}
                    link={`/${t('geral.routes.URL_CUSTO_RELATIVO')}`}
                    label={t('layout.layoutMenu.LABEL_CUSTO_RELATIVO')}
                  />
                ) : undefined}
                {Security.checkForPermissions(KEY_PAGINA_CUSTO_PREVISAO) ? (
                  <LayoutSubMenu
                    open={openCusto}
                    evento={evento}
                    link={`/${t('geral.routes.URL_CUSTO_PREVISAO')}`}
                    label={t('layout.layoutMenu.LABEL_CUSTO_PREVISAO')}
                  />
                ) : undefined}
                {Security.checkForPermissions(KEY_PAGINA_TCO) ? (
                  <LayoutSubMenu
                    open={openCusto}
                    evento={evento}
                    link={`/${t('geral.routes.URL_TCO')}`}
                    label={t('layout.layoutMenu.LABEL_TCO')}
                  />
                ) : undefined}
              </>
            ) : undefined}
          {Security.checkForPermissions(KEY_PAGINA_CONFIGURACOES) ? (
            <Link to={`/${t('geral.routes.URL_CONFIGURACAO')}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem onClick={evento} className={classes.child}>
                <ListItemIcon>
                  <img src={config} alt={t('layout.layoutMenu.ALT_CONFIG')} />
                </ListItemIcon>
                <ListItemText primary={t('layout.layoutMenu.LABEL_CONFIG')} />
              </ListItem>
            </Link>
          ) : undefined}
          {(Security.checkForPermissions(KEY_PERMISSAO_TROCAR_SENHA)
            || Security.checkForPermissions(KEY_PAGINA_CADASTRO_USUARIOS))
            ? (
              <>
                <ListItem
                  onClick={() => handleClick(setOpenPerfil, openPerfil, setOpenCusto,
                    setOpenDesempenho, setOpenEstoque)}
                  className={classes.child}
                >
                  <ListItemIcon>
                    <img src={perfil} alt={t('layout.layoutMenu.ALT_PERFIL')} />
                  </ListItemIcon>
                  <ListItemText primary={t('layout.layoutMenu.LABEL_PERFIL')} />
                  {openPerfil ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                {Security.checkForPermissions(KEY_PERMISSAO_TROCAR_SENHA) ? (
                  <LayoutSubMenu
                    open={openPerfil}
                    evento={event => abrirAlterarSenha(event, evento, context)}
                    label={t('layout.layoutMenu.LABEL_SENHA')}
                  />
                ) : undefined}
                {Security.checkForPermissions(KEY_PAGINA_CADASTRO_USUARIOS) ? (
                  <LayoutSubMenu
                    open={openPerfil}
                    evento={evento}
                    link={`/${t('geral.routes.URL_CADASTRO_USUARIOS')}`}
                    label={t('layout.layoutMenu.LABEL_USUARIOS')}
                  />
                ) : undefined}
              </>
            ) : undefined}
        </List>
      </div>
      <div className="logout">
        <List>
          <ListItem onClick={onClickHandle} className={classes.layoutPointer}>
            <ListItemIcon><img src={logout} alt={t('layout.layoutMenu.ALT_LOGOUT')} /></ListItemIcon>
            <ListItemText primary={t('layout.layoutMenu.LABEL_LOGOUT')} />
          </ListItem>
        </List>
      </div>
    </nav>
  )
}

LayoutMenu.propTypes = propsLayoutMenu
LayoutMenu.defaultProps = valueLayoutMenu

export default LayoutMenu
