import React, { useEffect, useContext } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import GraficoCustoPorMmOpcoes from '../GraficoCustoPorMmOpcoes'
import { FiltroContext } from '../../layout/LayoutContext'
import { ErroGrafico } from '../../utils'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import UseStateSafely from '../../utils/UseStateSafely'
import GraficoCustoPorMmService from '../GraficoCustoPorMmService'
import { formatarDadosCustoEfetivo } from '../GraficoCustoPorMmUtils'

let dados
let setDados
let loading
let setLoading
let resposta
let setResposta

function GraficoCustoPorMm() {
  [loading, setLoading] = UseStateSafely(true);
  [dados, setDados] = UseStateSafely({});
  [resposta, setResposta] = UseStateSafely({})
  const filtro = useContext(FiltroContext)
  const { t } = useTranslation()

  const labels = {
    titulo: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_TITULO'),
    quantidade: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_QUANTIDADE'),
    custoRelativo: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_CUSTO_RELATIVO'),
    agrupado: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_AGRUPADO'),
    datas: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_DATAS'),
    custoAgrupado: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_CUSTO_EFETIVO_AGRUPADO'),
    corte: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_CORTE'),
    reaisMM: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_REAIS_MM'),
    mm: t('graficoCustoPorMm.graficoCustoPorMm.LABEL_MM'),
  }

  useEffect(() => {
    setLoading(true)
    setDados({})
    async function conectar() {
      const dadosAPI = await GraficoCustoPorMmService(filtro)
      setResposta(dadosAPI)
      const valores = formatarDadosCustoEfetivo(dadosAPI)
      setDados(valores)
      setLoading(false)
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [filtro])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (resposta && resposta.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoCustoPorMm.graficoCustoPorMm.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoCustoPorMm.graficoCustoPorMm.LABEL_TITULO')}
        />
      )
    }
    if (resposta && resposta[0] !== 'Erro') {
      return (
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: '410px' } }}
          options={GraficoCustoPorMmOpcoes(dados, labels)}
        />
      )
    }
    return (
      <AlertaModal
        url={t('geral.routes.URL_CUSTO_RELATIVO')}
        mensagem={t('graficoCustoPorMm.graficoCustoPorMm.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

export default GraficoCustoPorMm
