import React from 'react'
import '../LoadingEstilo.css'
import Spinner from 'react-spinkit'
import propsLoading, { valueLoading } from '../LoadingPropTypes'

const LoadingFiltro = ({ loading, message }) => (loading ? (
  <div className="overlay-content" data-html2canvas-ignore="true">
    <div className="wrapper">
      <Spinner
        name="line-spin-fade-loader"
        fadeIn="none"
        color="#edf0f4"
      />
      <span className="message">
        <br />
        {message}
      </span>
    </div>
  </div>
) : null)

LoadingFiltro.propTypes = propsLoading
LoadingFiltro.defaultProps = valueLoading

export default LoadingFiltro
