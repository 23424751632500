import Highcharts from 'highcharts'
import { Traducao, COR_PRIMARIA, formatarData } from '../utils'
import { exportar } from '../../assets/images'
import formatarTooltip from './GraficoCustoPorMmUtils'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function GraficoCustoPorMmOpcoes(dados, labels) {
  const graficoCompleto = [{
    type: 'column',
    name: labels.custoRelativo,
    data: dados.custoPorMm,
    color: '#BF1997',
  },
  ]

  return ({
    chart: {
      type: 'column',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          const dadosExportacao = dadosDoGrafico
          dadosExportacao.dataRows[0][0] = labels.datas
        },
      },
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      split: false,
      shared: false,
      formatter() {
        return formatarTooltip(this, dados, labels)
      },
    },
    legend: {
      itemStyle: {
        color: '#333333',
        fontWeight: 'normal',
      },
    },
    title: {
      text: labels.titulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: formatarData(dados.datas),
      labels: {
        style: {
          fontSize: '11px',
        },
      },
    },
    yAxis: {
      labels: {
        formatter() {
          return this.value.toLocaleString('pt-BR', { maximumFractionDigits: 0 })
        },
        style: {
          fontSize: '14px',
        },
      },
      title: {
        text: labels.quantidade,
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
      series: {
        animation: false,
        borderWidth: 0,
      },
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
    series: graficoCompleto,

    credits: {
      enabled: false,
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoCustoPorMmOpcoes
