import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatarNumero, SRCelula, BotaoEditar,
} from '../../utils'
import TabelaPrevisaoCompraEstoquePrevisaoEstoque from './TabelaPrevisaoCompraEstoquePrevisaoEstoque'
import TabelaPrevisaoCompraEstoqueMinimoOperacional from './TabelaPrevisaoCompraEstoqueMinimoOperacional'
import { PREVISTO_UNIDADE, PREVISTO_MM } from '../TabelaPrevisaoCompraUtils'
import TabelaPrevisaoCompraEstoqueModal from './TabelaPrevisaoCompraEstoqueModal'
import { propsTabelaPrevisaoCompraEstoque, valuesTabelaPrevisaoCompraEstoque } from '../TabelaPrevisaoCompraPropTypes'
import { estilo } from '../TabelaPrevisaoCompraEstilo'

const TabelaPrevisaoCompraEstoque = ({
  dadosEstoquePrevistoReal, dadosPrevisaoEstoque, dadosMinimoOperacional,
  ano, editavel, atualizarDados,
}) => {
  const { t } = useTranslation()
  const [conteudoModal, setConteudoModal] = React.useState(undefined)
  const [editado, setEditado] = React.useState(false)
  const classes = estilo()

  const dadosEstoquePrevistoRealDoAnoAtual = dadosEstoquePrevistoReal[ano]
  const dadosEstoquePrevistoRealDoAnoAnterior = dadosEstoquePrevistoReal[ano - 1]
  const dadosPrevisaoEstoqueCilindroDoAnoAtual = dadosPrevisaoEstoque[ano]
  const dadosEstoquePrevistoRealDeDezembroDoAnoAtual = dadosEstoquePrevistoRealDoAnoAtual
    .estoque.find(({ mes }) => mes === 12)

  const totalRealUnidade = formatarNumero(dadosEstoquePrevistoRealDeDezembroDoAnoAtual.realUnidade)
  const totalPrevistoUnidade = formatarNumero(
    dadosEstoquePrevistoRealDeDezembroDoAnoAtual.previstoUnidade,
  )
  const totalMinimoOperacionalUnidade = formatarNumero(
    dadosEstoquePrevistoRealDeDezembroDoAnoAtual.minimoOperacionalUnidade,
  )
  const totalPrevistoMm = formatarNumero(dadosEstoquePrevistoRealDeDezembroDoAnoAtual.previstoMm, 2)
  const totalRealMm = formatarNumero(dadosEstoquePrevistoRealDeDezembroDoAnoAtual.realMm, 2)
  const totalMinimoOperacionalMm = formatarNumero(
    dadosEstoquePrevistoRealDeDezembroDoAnoAtual.minimoOperacionalMm, 2,
  )

  const conteudoModalPrevistoUnidade = {
    titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR'),
    ano,
    componente: React.createElement(
      TabelaPrevisaoCompraEstoquePrevisaoEstoque,
      {
        ano,
        dados: [{ ...dadosPrevisaoEstoqueCilindroDoAnoAtual, mes: 1 }],
        setEditado,
        linha: PREVISTO_UNIDADE,
        dadosEstoquePrevistoRealDoAnoAnterior,
      },
      null,
    ),
  }

  const conteudoModalPrevistoMm = {
    titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR'),
    ano,
    componente: React.createElement(
      TabelaPrevisaoCompraEstoquePrevisaoEstoque,
      {
        ano,
        dados: [{ ...dadosPrevisaoEstoqueCilindroDoAnoAtual, mes: 1 }],
        setEditado,
        linha: PREVISTO_MM,
        dadosEstoquePrevistoRealDoAnoAnterior,
      },
      null,
    ),
  }

  const conteudoModalMinimoOperacionalUnidade = {
    titulo: t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR'),
    ano,
    componente: React.createElement(
      TabelaPrevisaoCompraEstoqueMinimoOperacional,
      {
        ano,
        dados: dadosMinimoOperacional.filter(
          dados => dados.ano === ano,
        ),
        setEditado,
      },
      null,
    ),
  }

  function fecharModal(anoCorrente) {
    if (editado) {
      atualizarDados(anoCorrente)
    }
    setConteudoModal(undefined)
  }

  return (
    <>
      {
        conteudoModal ? (
          <TabelaPrevisaoCompraEstoqueModal
            closeHandle={() => fecharModal(ano)}
            titulo={conteudoModal.titulo}
            ano={conteudoModal.ano}
          >
            {conteudoModal.componente}
          </TabelaPrevisaoCompraEstoqueModal>
        )
          : null
      }
      <>
        <tr>
          <SRCelula percentualLargura="6%" negrito altura="6">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_CILINDROS')}</SRCelula>
          <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_UNIDADE_PLANEJADA')}</SRCelula>
          {dadosEstoquePrevistoRealDoAnoAtual.estoque.map((
            { previstoUnidade, minimoOperacionalUnidade },
          ) => (
            <SRCelula background={previstoUnidade < minimoOperacionalUnidade ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(previstoUnidade, 0)}</SRCelula>
          ))}
          <SRCelula percentualLargura="6%" negrito key={Math.random()}>
            {totalPrevistoUnidade}
          </SRCelula>
          {editavel ? (
            <SRCelula percentualLargura="5%" key={Math.random()}>
              <BotaoEditar evento={() => setConteudoModal(conteudoModalPrevistoUnidade)} />
            </SRCelula>
          ) : null}
        </tr>
        <tr>
          <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_UNIDADE_REAL')}</SRCelula>
          {dadosEstoquePrevistoRealDoAnoAtual.estoque.map((
            { realUnidade, minimoOperacionalUnidade },
          ) => (
            <SRCelula background={realUnidade < minimoOperacionalUnidade ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(realUnidade, 0)}</SRCelula>
          ))}
          <SRCelula percentualLargura="6%" negrito key={Math.random()}>
            {totalRealUnidade}
          </SRCelula>
          {editavel ? (
            <SRCelula percentualLargura="5%" key={Math.random()} />
          ) : null}
        </tr>
        <tr>
          <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_MINIMO_OPERACIONAL')}</SRCelula>
          {dadosEstoquePrevistoRealDoAnoAtual.estoque.map(({ minimoOperacionalUnidade }) => (
            <SRCelula background="azul" percentualLargura="6%" key={Math.random()}>{formatarNumero(minimoOperacionalUnidade, 0)}</SRCelula>
          ))}
          <SRCelula background="azul" percentualLargura="6%" negrito key={Math.random()}>
            {totalMinimoOperacionalUnidade}
          </SRCelula>
          {editavel ? (
            <SRCelula percentualLargura="5%" key={Math.random()}>
              <BotaoEditar evento={() => setConteudoModal(conteudoModalMinimoOperacionalUnidade)} />
            </SRCelula>
          ) : null}
        </tr>
      </>
      <>
        <tr className={classes.bordaSuperior}>
          <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_MM_PLANEJADA')}</SRCelula>
          {dadosEstoquePrevistoRealDoAnoAtual.estoque.map(({ previstoMm, minimoOperacionalMm }) => (
            <SRCelula background={previstoMm < minimoOperacionalMm ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(previstoMm, 2)}</SRCelula>
          ))}
          <SRCelula percentualLargura="6%" negrito key={Math.random()}>
            {totalPrevistoMm}
          </SRCelula>
          {editavel ? (
            <SRCelula percentualLargura="5%" key={Math.random()}>
              <BotaoEditar evento={() => setConteudoModal(conteudoModalPrevistoMm)} />
            </SRCelula>
          ) : null}
        </tr>
        <tr>
          <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_MM_REAL')}</SRCelula>
          {dadosEstoquePrevistoRealDoAnoAtual.estoque.map(({ realMm, minimoOperacionalMm }) => (
            <SRCelula background={realMm < minimoOperacionalMm ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(realMm, 2)}</SRCelula>
          ))}
          <SRCelula percentualLargura="6%" negrito key={Math.random()}>
            {totalRealMm}
          </SRCelula>
          {editavel ? (
            <SRCelula percentualLargura="5%" key={Math.random()} />
          ) : null}
        </tr>
        <tr>
          <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_MINIMO_OPERACIONAL')}</SRCelula>
          {dadosEstoquePrevistoRealDoAnoAtual.estoque.map(({ minimoOperacionalMm }) => (
            <SRCelula background="azul" percentualLargura="6%" key={Math.random()}>{formatarNumero(minimoOperacionalMm, 2)}</SRCelula>
          ))}
          <SRCelula background="azul" percentualLargura="6%" negrito key={Math.random()}>
            {totalMinimoOperacionalMm}
          </SRCelula>
          {editavel ? (
            <SRCelula percentualLargura="5%" key={Math.random()} />
          ) : null}
        </tr>
      </>
    </>
  )
}

TabelaPrevisaoCompraEstoque.propTypes = propsTabelaPrevisaoCompraEstoque
TabelaPrevisaoCompraEstoque.defaultProps = valuesTabelaPrevisaoCompraEstoque

export default TabelaPrevisaoCompraEstoque
