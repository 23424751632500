import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'
import login from '../../assets/images'

const AutenticacaoEstilo = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${login})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: COR_PRIMARIA,
  },
  titulo: {
    color: COR_PRIMARIA,
    fontWeight: 'bold',
  },
  subTitulo: {
    color: '#b2b7bf',
  },
}))

export const styleDialogTitle = theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E9EE',
  },
  closeButton: {
    position: 'relative',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    color: '#004070',
    fontSize: '18px',
    fontWeight: 'bold',
  },
})

export default AutenticacaoEstilo
