export function formatarDadosCustoEfetivo(dados) {
  if (!dados) {
    return null
  }
  const datas = dados.map(item => item.data)
  const custoEfetivo = dados.map(item => item.custoEfetivo)
  const corte = dados.map(item => item.corte)
  const custoPorMm = dados.map(item => item.custoPorMm)
  return {
    datas, custoEfetivo, corte, custoPorMm,
  }
}

function formatarTooltip(ponto, dados, labels) {
  if (!ponto || !dados || !labels) {
    return false
  }
  const posicao = ponto.point.index
  return `${labels.custoRelativo}: ${ponto.y.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.quantidade}
  <br>${labels.corte} ${dados.corte[posicao].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.mm}
  <br>${labels.reaisMM} R$ ${dados.custoEfetivo[posicao].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export default formatarTooltip
