import React from 'react'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { Botao } from '../../utils'
import { propsModal, valueModal } from '../AlertaPropTypes'
import AlertaEstilo from '../AlertaEstilo'
import getModalStyle, { sair, resetarPagina, handleChange } from '../AlertaUtils'

export default function AlertaModal({
  exibir, titulo, mensagem, url,
}) {
  const { t } = useTranslation()
  const classes = AlertaEstilo()
  const [modalStyle] = React.useState(getModalStyle)
  const [open, setOpen] = React.useState(false)

  if (exibir && !open) {
    handleChange(setOpen, true)
  }

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        id="telaModalAlerta"
        open={open}
        onClose={() => handleChange(setOpen, false)}
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="modal-title">{titulo}</h2>
          <p id="simple-modal-description">
            {mensagem}
          </p>
          <Grid container justify="center">
            <Grid item>
              <Botao
                id="botaoConfirmarModal"
                nome={t('alerta.alertaModal.BOTAO_CONFIRMAR')}
                tamanho="small"
                variant="outlined"
                action={url || url === '' ? () => resetarPagina(url) : () => sair()}
              />
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  )
}

AlertaModal.propTypes = propsModal
AlertaModal.defaultProps = valueModal
