import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

export function arrowGenerator(color) {
  return {
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  }
}

const TooltipEstilo = makeStyles(() => ({
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: arrowGenerator(COR_PRIMARIA),
  tooltip: {
    position: 'relative',
    backgroundColor: COR_PRIMARIA,
    color: '#FFFFFF',
    fontFamily: 'Helvetica',
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'center',
    padding: '8px',
  },
  tooltipPlacementLeft: {
    margin: '0 0px',
  },
  tooltipPlacementRight: {
    margin: '0 0px',
  },
  tooltipPlacementTop: {
    margin: '0px 0',
  },
  tooltipPlacementBottom: {
    margin: '0px 0',
  },
}))

export const TooltipEstiloCinza = makeStyles(() => ({
  tooltip: {
    position: 'relative',
    backgroundColor: '#2b3036',
    color: 'rgba(255, 255, 255, 255)',
    maxWidth: 300,
    fontSize: '12px',
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: arrowGenerator('#2b3036'),
}))

export default TooltipEstilo
