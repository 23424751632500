import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { tituloModal } from '../UtilsEstilo'

const TituloModal = withStyles(tituloModal)((props) => {
  const {
    children, classes, onClose, id,
  } = props
  return (
    <MuiDialogTitle disableTypography className={classes.header} id={id}>
      <div className={props.classes.typography}>{children}</div>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export default TituloModal
