import { ConectarApiGet } from '../utils'
import { ENDPOINT_FILTRO } from '../utils/Configuracoes'

export default async function FiltroService() {
  try {
    const {
      data: {
        resultado: {
          cilindros,
          dataRetificaInicial,
        },
      },
    } = await ConectarApiGet(ENDPOINT_FILTRO)

    const fornecedoresDuplicados = cilindros.map(cilindro => (
      {
        nomeFornecedor: cilindro.nomeFornecedor,
        codFornecedor: cilindro.codFornecedor,
        codTipoCilindro: cilindro.codTipoCilindro,
        codLaminador: cilindro.codLaminador,
      }))

    const linhasProducaoDuplicados = cilindros.map(cilindro => (
      {
        siglaLinhaProducao: cilindro.siglaLinhaProducao,
        codLinhaProducao: cilindro.codLinhaProducao,
      }))

    const materiaisDuplicados = cilindros.map(cilindro => (
      {
        descricaoMaterial: cilindro.descMaterial,
        codMaterial: cilindro.codMaterial,
        codFornecedor: cilindro.codFornecedor,
        codTipoCilindro: cilindro.codTipoCilindro,
        codLaminador: cilindro.codLaminador,
        nomeFornecedor: cilindro.nomeFornecedor,
      }))

    const laminadoresDuplicados = cilindros.map(cilindro => (
      {
        siglaLaminador: cilindro.siglaTipoLaminador,
        codLaminador: cilindro.codLaminador,
        codLinhaProducao: cilindro.codLinhaProducao,
      }))

    const cilindrosComData = cilindros.map(
      cilindro => ({
        ...cilindro,
        dataChegada: new Date(cilindro.dataChegada),
        dataSucatado: cilindro.dataSucatado ? new Date(cilindro.dataSucatado) : undefined,
      }),
    )

    const fornecedores = fornecedoresDuplicados.filter(function removeDuplicado(a) {
      const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
      return valores
    }, Object.create(null))

    const linhasProducao = linhasProducaoDuplicados.filter(function removeDuplicado(a) {
      const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
      return valores
    }, Object.create(null))

    const materiais = materiaisDuplicados.filter(function removeDuplicado(a) {
      const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
      return valores
    }, Object.create(null))

    const laminadores = laminadoresDuplicados.filter(function removeDuplicado(a) {
      const valores = !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true)
      return valores
    }, Object.create(null))

    return {
      linhasProducao, cilindros: cilindrosComData, fornecedores, materiais, laminadores, dataRetificaInicial,
    }
  } catch (err) {
    console.log(err)
    return {
      linhasProducao: [],
      cilindros: [],
      cilindrosComData: [],
      fornecedores: [],
      materiais: [],
      laminadores: [],
    }
  }
}
