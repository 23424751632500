import React from 'react'
import '../LayoutInicioEstilo.scss'
import Routes from '../../../Routes'

function LayoutInicio() {
  return (
    <div className="App">
      <Routes />
    </div>
  )
}

export default LayoutInicio
