import React, { useState, useEffect, useContext } from 'react'
import { propsLayoutDesempenho, valueLayoutDesempenho } from '../LayoutPropTypes'
import GraficoDesempenhoMateriais, {
  GraficoDesempenhoAgrupadoModalPrint,
  GraficoDesempenhoAgrupadoService,
  GraficoDesempenhoMateriaisContexto,
} from '../../graficoDesempenhoAgrupado'
import LayoutContentList from './LayoutContentList'
import { FiltroContext } from '../LayoutContext'
import { makeCancelable } from '../../utils'
import LoadingGrafico from '../../loading'

function LayoutDesempenhoAgrupado() {
  const [ESTADO_MODAL_ABERTO, ESTADO_MODAL_FECHADO] = [true, false]
  const [dados, setDados] = useState(undefined)
  const [estadoModal, setEstadoModal] = useState(ESTADO_MODAL_FECHADO)
  const [fornecedorEspecifico, setFornecedorEspecifico] = useState('')
  const filtro = useContext(FiltroContext)

  useEffect(() => {
    function mostrarModalParaImprimir() {
      setEstadoModal(ESTADO_MODAL_ABERTO)
      return true
    }
    function voltarComNomesDosFornecedoresAposAImpressao() {
      setFornecedorEspecifico('')
    }
    GraficoDesempenhoMateriaisContexto.setPrintHandler(mostrarModalParaImprimir)
    GraficoDesempenhoMateriaisContexto
      .setAfterPrintHandler(voltarComNomesDosFornecedoresAposAImpressao)
  }, [ESTADO_MODAL_ABERTO])

  useEffect(() => {
    setDados(undefined)
    const cancelablePromise = makeCancelable(GraficoDesempenhoAgrupadoService(filtro))
    cancelablePromise.promise.then((dadosDoServidor) => {
      setDados(dadosDoServidor)
    }).catch((err) => {
      if (!err.isCanceled) { console.log(err) }
    })
    return cancelablePromise.cancel
  }, [filtro])

  return (
    <>
      <LayoutContentList>
        {!dados ? <LoadingGrafico /> : [
          <GraficoDesempenhoMateriais
            tipo="Desempenho"
            key="GraficoDesempenhoAgrupadoDesempenhoId"
            mostrarSomenteOFornecedor={fornecedorEspecifico}
            dados={dados}
          />,
          <GraficoDesempenhoMateriais
            tipo="Consumo"
            key="GraficoDesempenhoAgrupadoConsumoId"
            mostrarSomenteOFornecedor={fornecedorEspecifico}
            dados={dados}
          />,
        ]}
      </LayoutContentList>
      {dados ? (
        <GraficoDesempenhoAgrupadoModalPrint
          isOpen={estadoModal}
          modalClose={() => setEstadoModal(ESTADO_MODAL_FECHADO)}
          alterarFornecedor={setFornecedorEspecifico}
          fornecedores={dados.map(dado => dado.nomeFornecedor)}
        />
      ) : ''}
    </>
  )
}

LayoutDesempenhoAgrupado.propTypes = propsLayoutDesempenho
LayoutDesempenhoAgrupado.defaultProps = valueLayoutDesempenho

export default LayoutDesempenhoAgrupado
