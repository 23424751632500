import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { COR_PRIMARIA } from '../Configuracoes'
import { propsRadio, valueRadio } from '../UtilsPropTypes'
import { useStyles } from '../UtilsEstilo'

const SRRadio = withStyles({
  root: {
    color: COR_PRIMARIA,
    '&$checked': {
      color: COR_PRIMARIA,
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />)

export default function SRRadioButton({
  disabled, value, handleChangeRadio,
}) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RadioGroup aria-label="tipoCilindro" name="tipoCilindro" value={value} onChange={event => handleChangeRadio(event.target.value)}>
        <FormControlLabel value="1" control={<SRRadio disabled={disabled} />} label={t('filtro.filtroCheckBox.LABEL_TOOLTIP_BACKUP')} />
        <FormControlLabel value="2" control={<SRRadio disabled={disabled} />} label={t('filtro.filtroCheckBox.LABEL_TOOLTIP_WORK')} />
      </RadioGroup>
    </FormControl>
  )
}

SRRadioButton.propTypes = propsRadio
SRRadioButton.defaultProps = valueRadio
