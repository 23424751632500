import propTypes from 'prop-types'

const propsConfigEstoqueMinimo = {
  coluna: propTypes.array,
  dados: propTypes.array,
  setDados: propTypes.func,
  visivel: propTypes.bool,
}

export const valueConfigEstoqueMinimo = {
  coluna: [],
  dados: [],
  setDados: () => console.log('valueConfigEstoqueMinimo is undefined'),
  visivel: false,
}

export const propsConfigPadraoCorte = {
  localizacao: propTypes.object,
  laminadores: propTypes.array,
  dados: propTypes.array,
  setDados: propTypes.func,
}

export const valueConfigPadraoCorte = {
  localizacao: {},
  laminadores: [],
  dados: [],
  setDados: () => console.log('valueConfigPadraoCorte is undefined'),
}

export const propsConfigPrevisaoChegadaCilindros = {
  localizacao: propTypes.object,
  laminadores: propTypes.array,
  dados: propTypes.array,
  setDados: propTypes.func,
  carregando: propTypes.bool,
}

export const valueConfigPrevisaoChegadaCilindros = {
  localizacao: {},
  laminadores: [],
  dados: [],
  setDados: () => console.log('valueConfigPadraoCorte is undefined'),
  carregando: false,
}

export const propsConfigCadastroIdSAP = {
  laminadores: propTypes.array,
  cilindros: propTypes.array,
  dados: propTypes.array,
  setDados: propTypes.func,
}

export const valueConfigCadastroIdSAP = {
  laminadores: [],
  cilindros: [],
  dados: [],
  setDados: () => console.log('valueConfigCadastroIdSAP is undefined'),
}

export const propsConfigPrevisaoCustoRelativo = {
  localizacao: propTypes.object,
  dados: propTypes.array,
  laminadores: propTypes.array,
  setDados: propTypes.func,
}

export const valueConfigPrevisaoCustoRelativo = {
  localizacao: {},
  dados: [],
  laminadores: [],
  setDados: () => console.log('valueConfigPrevisaoCustoRelativo is undefined'),
}

export const propsConfigCard = {
  children: propTypes.object,
  expandido: propTypes.bool,
  clickHandle: propTypes.func,
  titulo: propTypes.string,
}

export const valuesConfigCard = {
  children: '',
  expandido: false,
  titulo: 'sem título',
}

export const propsConfigGeralCardTitulo = {
  flechaParaCima: propTypes.bool,
  titulo: propTypes.string,
}

export const valuesConfigGeralCardTitulo = {
  flechaParaCima: false,
  titulo: 'sem título',
}

export default propsConfigEstoqueMinimo
