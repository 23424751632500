import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  campanha, novo, operacao, reparo, retificado, range,
} from '../../../assets/images'
import Situacao from '../enums/Situacao'

export default function GraficoLegendaUtils(valor, vlrRange, motivo) {
  let dados = []
  const { t } = useTranslation()
  function preencherLegendas() {
    if (valor.includes(3)) {
      dados.push(<img
        src={novo}
        alt={t('utils.graficoLegendaUtils.ALT_NOVO')}
        id="novoLgd"
        key="3"
      />, t('utils.graficoLegendaUtils.LABEL_NOVO'))
    }
    if (valor.includes(5)) {
      dados.push(<img
        src={retificado}
        alt={t('utils.graficoLegendaUtils.ALT_RETIFICADO')}
        id="retificadoLgd"
        key="5"
      />, t('utils.graficoLegendaUtils.LABEL_RETIFICADO'))
    }
    if (valor.includes(2)) {
      dados.push(<img
        src={operacao}
        alt={t('utils.graficoLegendaUtils.ALT_OPERACAO')}
        id="operacaoLgd"
        key="2"
      />, t('utils.graficoLegendaUtils.LABEL_OPERACAO'))
    }
    if (valor.includes(1)) {
      dados.push(<img
        src={campanha}
        alt={t('utils.graficoLegendaUtils.ALT_CAMPANHA')}
        id="campanhaLgd"
        key="1"
      />, t('utils.graficoLegendaUtils.LABEL_CAMPANHA'))
    }
    if (valor.includes(4)) {
      dados.push(<img
        src={reparo}
        alt={t('utils.graficoLegendaUtils.ALT_REPARO')}
        id="reparoLgd"
        key="4"
      />, t('utils.graficoLegendaUtils.LABEL_REPARO'))
    }
  }
  if (vlrRange && !motivo) {
    dados = [<img
      src={range}
      alt="range"
      id="range"
      key="0"
    />, t('utils.graficoLegendaUtils.LABEL_RANGE') + vlrRange[0]
  + t('utils.graficoLegendaUtils.LABEL_RANGE_FIM') + vlrRange[1]
  + t('utils.graficoLegendaUtils.LABEL_MM')]
    preencherLegendas()
  } else if (valor) {
    preencherLegendas()
  } else {
    dados = [<img
      src={range}
      alt="range"
      id="range"
      key="0"
    />, t('utils.graficoLegendaUtils.LABEL_RANGE') + vlrRange[0]
  + t('utils.graficoLegendaUtils.LABEL_RANGE_FIM') + vlrRange[1]
  + t('utils.graficoLegendaUtils.LABEL_MM')]
    dados.push(<img
      src={novo}
      alt={t('utils.graficoLegendaUtils.LABEL_DIAMETRO')}
      id="diametroLgd"
      key="1"
    />, t('utils.graficoLegendaUtils.LABEL_DIAMETRO'),
      <img
        src={retificado}
        alt={t('utils.graficoLegendaUtils.LABEL_OCORRENCIAS')}
        id="ocorrenciasLgd"
        key="2"
      />, t('utils.graficoLegendaUtils.LABEL_OCORRENCIAS'))
    if (motivo && motivo.length > 1) {
      dados.push(<img
        src={operacao}
        alt={t('utils.graficoLegendaUtils.LABEL_MOTIVOS')}
        id="motivosLgd"
        key="3"
      />, t('utils.graficoLegendaUtils.LABEL_MOTIVOS'))
    }
  }
  return dados
}

export function ordenaClasseDeCilindro(listaDeCilindros, codSituacao) {
  return (listaDeCilindros.filter(e => e.codSituacao === codSituacao)
    .sort((c1, c2) => c2.diametroAtual - c1.diametroAtual))
}

export function ordenaCilindros(listaDeCilindros) {
  const cilindrosCampanhaVencida = ordenaClasseDeCilindro(listaDeCilindros,
    Situacao.CAMPANHA_VENCIDA)
  const cilindrosEmOperacao = ordenaClasseDeCilindro(listaDeCilindros, Situacao.EM_OPERACAO)
  const cilindrosNovos = ordenaClasseDeCilindro(listaDeCilindros, Situacao.NOVO)
  const cilindrosReparoExterno = ordenaClasseDeCilindro(listaDeCilindros, Situacao.REPARO_EXTERNO)
  const cilindrosRetificados = ordenaClasseDeCilindro(listaDeCilindros, Situacao.RETIFICADO)
  const cilindrosSucateados = ordenaClasseDeCilindro(listaDeCilindros, Situacao.SUCATEADO)

  return (
    []
      .concat(cilindrosNovos)
      .concat(cilindrosRetificados)
      .concat(cilindrosEmOperacao)
      .concat(cilindrosCampanhaVencida)
      .concat(cilindrosReparoExterno)
      .concat(cilindrosSucateados)
  )
}
