import { propsLogar, valueLogar } from './AutenticacaoPropTypes'
import Security from '../utils/Security'
import { AUTHORIZATION_TOKEN_TIME_TO_REFRESH } from '../utils/Configuracoes'
import { alterarSenha, DoLogin, TOKEN_SEM_PERMISSOES_BASICAS } from './AutenticacaoService'
import LocalStorageProxy from '../utils/LocalStorageProxy'
import { DoLogout } from '../utils'
import { publicar as ExibirAlerta } from '../utils/FilaDeMensagens'

const LOG_INFORMACOES_BASICAS = 'Informações básicas: '

export default function handleChangeLogin(valor, estado, setEstado, setErro) {
  if (estado === '') {
    setErro(false)
  }
  setEstado(valor)
}

export async function logar(event, usuario, senha, setErroUsuario, setErroSenha,
  setCarregando, props, setOpen) {
  event.preventDefault()
  if (usuario === '') {
    setErroSenha(false)
    setErroUsuario(true)
    ExibirAlerta({ text: 'autenticacao.autenticacaoLogin.MESSAGE_USUARIO', type: 'error', translate: true })
  } else if (senha === '') {
    setErroUsuario(false)
    setErroSenha(true)
    ExibirAlerta({ text: 'autenticacao.autenticacaoLogin.MESSAGE_SENHA', type: 'error', translate: true })
  } else {
    setErroSenha(false)
    setErroUsuario(false)
    setCarregando(true)
    event.preventDefault()
    const success = await DoLogin(usuario, senha)
    setCarregando(false)
    if (success[0]) {
      if (success[1]) {
        setOpen(true)
      }
      if (!success[1]) {
        Security.doLogin()
        props.history.push('/')
      }
    } else if (success[1] === TOKEN_SEM_PERMISSOES_BASICAS) {
      ExibirAlerta({ text: 'autenticacao.autenticacaoLogin.TOKEN_SEM_PERMISSOES_BASICAS', type: 'error', translate: true })
      console.log(LOG_INFORMACOES_BASICAS)
      console.log(Security.getBasicPermissions())
    } else if (success[1].response) {
      if (success[1].response.status === 403) {
        ExibirAlerta({ text: 'autenticacao.autenticacaoLogin.MESSAGE_LOGIN_ERRADO', type: 'error', translate: true })
      }
    } else {
      ExibirAlerta({ text: 'autenticacao.autenticacaoLogin.MESSAGE_ERRO', type: 'error', translate: true })
    }
  }
}

export function checkForTokenUpdate(modalIsOpen, openModal, closeModal, setModal) {
  const currentTime = Date.now()
  const tokenExpirationTime = Security.getTokenExpirationTimeValue()
  const timeToExpireInMiliSeconds = tokenExpirationTime - currentTime
  const timeToExpireInSeconds = Math.floor(timeToExpireInMiliSeconds / 1000)

  if (timeToExpireInMiliSeconds >= 0
    && timeToExpireInMiliSeconds <= AUTHORIZATION_TOKEN_TIME_TO_REFRESH) {
    openModal(timeToExpireInSeconds, setModal)
  }

  if (modalIsOpen && timeToExpireInMiliSeconds > AUTHORIZATION_TOKEN_TIME_TO_REFRESH) {
    closeModal(setModal)
  }

  if (timeToExpireInMiliSeconds < 0) DoLogout()
}

export async function validarSenhas(antiga, nova, confirmacao, setErroSenhaAntiga,
  setErroSenhaNova, setErroSenhaConfirmacao, setLoading, setSucesso) {
  const regex = /^(?=.*[<>-_=?:;+.,@!#$%^&*()/\\])(?=.*[0-9])(?=.*[a-zA-Z])[<>-_=?:;+.,@!#$%^&*()/\\a-zA-Z0-9]{6,20}$/
  if (!antiga) {
    setErroSenhaAntiga(true)
    setErroSenhaNova(false)
    setErroSenhaConfirmacao(false)
    try {
      document.getElementById('senha-antiga').focus()
    } catch (err) {
      console.log(err)
    }
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if (!regex.test(antiga)) {
    setErroSenhaAntiga(true)
    setErroSenhaNova(false)
    setErroSenhaConfirmacao(false)
    try {
      document.getElementById('senha-antiga').focus()
    } catch (err) {
      console.log(err)
    }
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_FORMATO', type: 'error', translate: true })
  } else if (!nova) {
    setErroSenhaAntiga(false)
    setErroSenhaNova(true)
    setErroSenhaConfirmacao(false)
    try {
      document.getElementById('nova-senha').focus()
    } catch (err) {
      console.log(err)
    }
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if (!regex.test(nova)) {
    setErroSenhaAntiga(false)
    setErroSenhaNova(true)
    setErroSenhaConfirmacao(false)
    try {
      document.getElementById('nova-senha').focus()
    } catch (err) {
      console.log(err)
    }
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_FORMATO', type: 'error', translate: true })
  } else if (!confirmacao) {
    setErroSenhaNova(false)
    setErroSenhaAntiga(false)
    setErroSenhaConfirmacao(true)
    try {
      document.getElementById('confirmar-senha').focus()
    } catch (err) {
      console.log(err)
    }
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_CAMPO_OBRIGATORIO', type: 'error', translate: true })
  } else if (!regex.test(confirmacao)) {
    setErroSenhaNova(false)
    setErroSenhaAntiga(false)
    setErroSenhaConfirmacao(true)
    try {
      document.getElementById('confirmar-senha').focus()
    } catch (err) {
      console.log(err)
    }
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_FORMATO', type: 'error', translate: true })
  } else if (confirmacao !== nova) {
    setErroSenhaAntiga(false)
    setErroSenhaNova(true)
    setErroSenhaConfirmacao(true)
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_SENHA_DIFERENTE', type: 'error', translate: true })
  } else if (antiga === nova) {
    setErroSenhaAntiga(false)
    setErroSenhaNova(true)
    setErroSenhaConfirmacao(true)
    ExibirAlerta({ text: 'autenticacao.autenticacaoAlterarSenhaForm.MESSAGE_SENHA_ANTIGA_IGUAL', type: 'error', translate: true })
  } else {
    setLoading(true)
    setErroSenhaAntiga(false)
    setErroSenhaNova(false)
    setErroSenhaConfirmacao(false)
    const codigoUsuario = parseInt(LocalStorageProxy.getItem('CodigoUsuario'), 10)
    const dados = {
      codUsuario: codigoUsuario,
      senhaAntiga: antiga,
      senhaNova: nova,
    }
    const resposta = await alterarSenha(dados)
    setLoading(false)
    if (resposta.codUsuario) {
      setSucesso(true)
    }
  }
}

export const handleMouseDownPassword = (event) => {
  event.preventDefault()
}

logar.propTypes = propsLogar
logar.defaultProps = valueLogar
