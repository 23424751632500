import React from 'react'
import GraficoDiametro from '../../graficoDiametro'
import GraficoEstoqueSituacao from '../../graficoEstoqueSituacao'
import propsLayoutEstoque, { valueLayoutEstoque } from '../LayoutPropTypes'
import LayoutContentList from './LayoutContentList'

const LayoutEstoqueCorrente = () => (
  <LayoutContentList>
    <GraficoDiametro key="GraficoDiametroId" />
    <GraficoEstoqueSituacao key="GraficoEstoqueSituacaoId" />
  </LayoutContentList>
)

LayoutEstoqueCorrente.propTypes = propsLayoutEstoque
LayoutEstoqueCorrente.defaultProps = valueLayoutEstoque

export default LayoutEstoqueCorrente
