import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoAnaliseCorteService from '../GraficoAnaliseCorteService'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import { ErroGrafico, useStateSafely } from '../../utils'
import GraficoAnaliseCorteOpcoes from '../GraficoAnaliseCorteOpcoes'
import formatarMotivos from '../GraficoAnaliseCorteUtils'
import GraficoLegenda from '../../utils/views/GraficoLegenda'
import { FiltroContext } from '../../layout/LayoutContext'

let motivos
let setMotivos
let dados
let setDados
let loading
let setLoading

const GraficoAnaliseCorte = () => {
  [motivos, setMotivos] = useStateSafely([]);
  [dados, setDados] = useStateSafely([]);
  [loading, setLoading] = useStateSafely(true)
  const { t } = useTranslation()
  const context = useContext(FiltroContext)

  const labels = {
    titulo: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_TITULO_GRAFICO'),
    coluna: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_COLUNA'),
    inicio: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_INICIO'),
    fim: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_FIM'),
    desbaste: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_DESBASTE'),
    desgaste: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_DESGASTE'),
    campVencida: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CAMPANHA_VENCIDA'),
    campInicial: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CAMPANHA_INICIAL'),
    diametro: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_DIAMETRO'),
    corte: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CORTE'),
    corteTotal: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CORTE_TOTAL'),
    mm: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_MM'),
    ocorrencias: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_NUM_OCORRENCIAS'),
    campFinal: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CAMPANHA_FINAL'),
    campanhas: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_NUM_CAMPANHAS'),
    corteMedio: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CORTE_MEDIO'),
    situacao: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_SITUACAO'),
    exportacao: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_EXPORTACAO'),
  }

  useEffect(() => {
    setLoading(true)
    setMotivos([])
    setDados([])
    const labelsGrafico = {
      inicio: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_INICIO'),
      desbaste: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_DESBASTE'),
      desgaste: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_DESGASTE'),
      fim: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_FIM'),
      corteTotal: t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_CORTE_TOTAL'),
    }
    async function conectar() {
      try {
        const resposta = await GraficoAnaliseCorteService(context)
        if (resposta && resposta[0] !== 'Erro') {
          setMotivos(formatarMotivos(resposta, labelsGrafico))
        }
        setDados(resposta)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [context, t])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (dados === null && motivos && motivos.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_TITULO_GRAFICO')}
        />
      )
    }
    if (dados && motivos && dados[0] !== 'Erro') {
      return (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '400px' } }}
            options={GraficoAnaliseCorteOpcoes(dados, motivos, labels)}
          />
          <GraficoLegenda
            corte
            motivo={dados.motivos}
            range={[dados.diametroNovo, dados.diametroSucatado]}
          />
        </>
      )
    } return (
      <AlertaModal
        url={t('geral.routes.URL_DISCRETIZADO')}
        mensagem={t('graficoAnaliseCorte.graficoAnaliseCorte.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}


export default GraficoAnaliseCorte
