import React from 'react'
import { styleHeatBar } from '../UtilsEstilo'
import { valuesHeatBar, propsHeatBar } from '../UtilsPropTypes'
import { trocaPontoPorVirgula } from '../Functions'

export default function HeatBar({
  indicadorPrincipal, indicadorBase, limiteInferior,
}) {
  const classes = styleHeatBar()

  const limiteSuperior = Math.max(indicadorBase * 3, indicadorPrincipal)
  const intervalo = limiteSuperior - limiteInferior
  const alturaDaBarraIndicadorBase = indicadorBase / intervalo * 100
  const alturaDaBarraIndicadorPrincipal = indicadorPrincipal / intervalo * 100

  function escolheCor(indicadorPrincipalEmPorcentagem) {
    if (indicadorPrincipalEmPorcentagem > 49) { return '#95c11c' }
    if (indicadorPrincipalEmPorcentagem > 34) { return '#f2ca03' }
    return '#e9573e'
  }

  return (
    <div className={classes.heatBar}>
      <div className={`${classes.textoLimiteInferior} ${classes.fontIndicador}`}>
        {trocaPontoPorVirgula(limiteInferior)}
      </div>
      <div className={classes.grafico}>
        <div className={classes.indicadores}>
          <div className={classes.barras}>
            <div className={classes.barra} style={{ backgroundColor: escolheCor(alturaDaBarraIndicadorPrincipal) }}> </div>
          </div>
          <div className={classes.indicador}>
            <div className={classes.barraVertical} style={{ width: `${alturaDaBarraIndicadorPrincipal.toFixed(2)}%` }}>
              <div className={`${classes.fontAbsoluta} ${classes.fontIndicador} ${classes.fontIndicadorBarraVertical}`}>
                {trocaPontoPorVirgula(indicadorPrincipal)}
              </div>
            </div>
            <div className={classes.transparencia}> </div>
          </div>
          <div className={classes.indicador2} style={{ width: `${alturaDaBarraIndicadorBase.toFixed(2)}%` }}>
            <div className={`${classes.fontAbsoluta} ${classes.fontIndicador} ${classes.fontIndicador2}`}>
              {trocaPontoPorVirgula(indicadorBase)}
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.fontIndicador} ${classes.textoLimiteSuperior}`}>
        {trocaPontoPorVirgula(limiteSuperior)}
      </div>
    </div>
  )
}

HeatBar.propTypes = propsHeatBar
HeatBar.defaultProps = valuesHeatBar
