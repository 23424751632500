import React from 'react'
import TextField from '@material-ui/core/TextField'
import UtilsEstilo from '../UtilsEstilo'
import { propsTextField, valueTextField } from '../UtilsPropTypes'

export default function SRTextField({
  id, label, obrigatorio, erro, tipo, foco, evento,
}) {
  const classes = UtilsEstilo()

  return (
    <TextField
      autoFocus={foco}
      required={obrigatorio}
      error={erro}
      fullWidth
      type={tipo}
      color="secondary"
      id={id}
      label={label}
      className={classes.textField}
      margin="normal"
      onChange={event => evento(event.target.value)}
    />
  )
}

SRTextField.propTypes = propsTextField
SRTextField.defaultProps = valueTextField
