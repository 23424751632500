const PtBr = {
  GraficoEquivalenteNovo: {
    LABEL_TITULO_GRAFICO: 'EQUIVALENTE A NOVOS',
    LABEL_COLUNA_EQUIVALENTE: 'Pares de cilindros equivalente a novos',
    LABEL_COLUNA_CILINDROS: 'Número de cilindros equivalente a novos',
    LABEL_COLUNA_ESTOQUE_MINIMO: 'Estoque mínimo',
    LABEL_QTD_PARES: 'Número de Pares',
    LABEL_QTD_CILINDROS: 'Número de Cilindros',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de equivalente a novos. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_DATA: 'Data',
  },
}

export default PtBr
