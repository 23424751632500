import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoRitmoChegadaCilindroRequisicao from './GraficoRitmoChegadaCilindroRequisicao'
import { ENDPOINT_RITMO_CHEGADA_CILINDRO } from '../utils/Configuracoes'

export default async function GraficoRitmoChegadaCilindroService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_RITMO_CHEGADA_CILINDRO,
      GraficoRitmoChegadaCilindroRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}
