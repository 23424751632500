import { fade, makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

export const FiltroDropDownMultiploComFiltroEstilo = makeStyles(theme => ({
  formControlMaior: {
    margin: theme.spacing(0),
  },
  filtroDropdown: {
    fontSize: '16px',
  },
  popper: {
    border: '1px solid rgba(27,31,35,.15)',
    boxShadow: '0 3px 12px rgba(27,31,35,.15)',
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: '#586069',
    backgroundColor: '#f6f8fa',
  },
  header: {
    borderBottom: '1px solid #e1e4e8',
    padding: '8px 10px',
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: '100%',
    borderBottom: '1px solid #dfe2e5',
    '& input': {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      border: '1px solid #ced4da',
      fontSize: 14,
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: 'none',
    margin: 0,
    color: '#586069',
    fontSize: 13,
  },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  optionItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  popperDisablePortal: {
    position: 'relative',
  },
  text: {
    flexGrow: 1,
  },
  buttonContainerText: {
    flexGrow: 1,
    textTransform: 'none',
  },
}))

const FiltroEstilo = makeStyles(theme => ({

  drawer: {
    width: '400px',
  },

  filtroWrapper: {
    margin: '8px 0px',
  },

  formControl: {
    margin: theme.spacing(0),
  },

  formControlAutoComplete: {
    margin: theme.spacing(0),
    maxHeight: 45,
  },

  formControlMaior: {
    margin: theme.spacing(0),
  },

  formControlTipoCilindro: {
    textAlign: 'center',
    maxHeight: '62px',
  },

  formControlTipoCilindroCheck: {
    maxHeight: '61px',
  },

  filtroTipoCilindroImageWrapper: {
    margin: '0px',
  },

  filtroTipoCilindroImage: {
    padding: '9px 0px',
  },

  filtroTipoCilindroInputLabel: {
    position: 'relative',
    margin: '0px',
    fontSize: '16px',
    textAlign: 'left',
  },

  filtroDatePicker: {
    marginRight: 10,
  },

  filtroDropdown: {
    fontSize: '16px',
  },

  filtroCursorAsPointer: {
    cursor: 'pointer',
  },

  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },

  corPrimaria: {
    color: COR_PRIMARIA,
  },

  limpar: {
    fontSize: '16px',
    color: '#7facf5',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'moz-none',
    msUserSelect: 'none',
    userSelect: 'none',
    marginTop: '16px',
  },

  marcar: {
    color: COR_PRIMARIA,
    fontWeight: 'bold',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'moz-none',
    msUserSelect: 'none',
    userSelect: 'none',
    marginTop: '4px',
  },

  desmarcar: {
    color: '#7facf5',
    cursor: 'pointer',
    webkitUserSelect: 'none',
    mozUserSelect: 'moz-none',
    msUserSelect: 'none',
    userSelect: 'none',
    marginTop: '4px',
  },

  filtroLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: COR_PRIMARIA,
  },

  margin: {
    margin: theme.spacing(1),
    color: COR_PRIMARIA,
  },

}))

export default FiltroEstilo
