import React from 'react'
import { useTranslation } from 'react-i18next'
import GraficoDesempenhoMateriais from '../../graficoDesempenhoAgrupado'
import LayoutCardRangeData from './LayoutCardRangeData'
import {
  propsLayoutGeralConsumoAgrupado,
  valueLayoutGeralConsumoAgrupado,
} from '../LayoutPropTypes'

export default function LayoutGeralConsumoAgrupado({ filtro, siglaLaminador }) {
  const { t } = useTranslation()

  return (
    <LayoutCardRangeData
      titulo={`${t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_CONSUMO_TITULO')} - ${siglaLaminador}`}
      filtro={filtro}
    >
      <GraficoDesempenhoMateriais
        tipo="Consumo"
        formatacao={{ customResizeValue: 250, tituloComNegrito: false }}
        graficoSimplificado
        subtitulo={t('config.config.LABEL_WORK_ROLL')}
      />
    </LayoutCardRangeData>
  )
}

LayoutGeralConsumoAgrupado.propTypes = propsLayoutGeralConsumoAgrupado
LayoutGeralConsumoAgrupado.defaultProps = valueLayoutGeralConsumoAgrupado
