import React from 'react'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'
import { seta } from '../../../assets/images'
import LayoutEstilo from '../LayoutEstilo'
import { valueSubMenu, propsSubMenu } from '../LayoutPropTypes'

const LayoutSubMenu = ({
  open, evento, link, label,
}) => {
  const classes = LayoutEstilo()
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {link ? (
          <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItem className={classes.nested} onClick={evento}>
              <ListItemIcon><img src={seta} alt={label} /></ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          </Link>
        ) : (
          <ListItem className={classes.nestedChild} onClick={evento}>
            <ListItemIcon><img src={seta} alt={label} /></ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        )}
      </List>
    </Collapse>
  )
}

LayoutSubMenu.propTypes = propsSubMenu
LayoutSubMenu.defaultProps = valueSubMenu

export default LayoutSubMenu
