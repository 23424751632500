import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { valueAccordion, propsAccordion } from '../UtilsPropTypes'

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    textAlign: 'center',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails)

const SRAccordion = (props) => {
  const {
    children, titulo, aberto, centralizado, itemAberto, setItemAberto, valorItemAberto,
  } = props
  const [expanded, setExpanded] = useState(aberto)

  function handleChange() {
    if (!expanded && itemAberto && setItemAberto && valorItemAberto) {
      setItemAberto({
        ...itemAberto,
        [valorItemAberto]: true,
      })
    }
    if (expanded && itemAberto && setItemAberto && valorItemAberto) {
      setItemAberto({
        ...itemAberto,
        [valorItemAberto]: false,
      })
    }
    setExpanded(!expanded)
  }
  return (
    <ExpansionPanel
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      onChange={() => handleChange()}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
      >
        <b style={centralizado ? { width: '100%' } : undefined}>{titulo}</b>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default SRAccordion

SRAccordion.propTypes = propsAccordion
SRAccordion.defaultProps = valueAccordion
