import propTypes from 'prop-types'

const props = {
  laminador: propTypes.string,
  filtro: propTypes.object,
}

const values = {
  laminador: '',
  filtro: undefined,
}

const GraficoEstoqueCilindroEquivalenteNovoPropTypes = { props, values }

export default GraficoEstoqueCilindroEquivalenteNovoPropTypes
