// eslint-disable-next-line import/no-self-import
import * as Traducao from './ConfigTraducao'

export function ConfigTraducao(labelDisplayedRows, labelRowsSelect, labelRowsPerPage,
  firstAriaLabel, firstTooltip, previousAriaLabel, previousTooltip, nextAriaLabel, nextTooltip,
  lastAriaLabel, lastTooltip, nRowsSelected, searchPlaceholder, searchTooltip,
  emptyDataSourceMessage, filterTooltip, cancelTooltip, saveTooltip, actions, addTooltip,
  editTooltip, deleteTooltip, deleteText) {
  return ({
    pagination: {
      labelDisplayedRows,
      labelRowsSelect,
      labelRowsPerPage,
      firstAriaLabel,
      firstTooltip,
      previousAriaLabel,
      previousTooltip,
      nextAriaLabel,
      nextTooltip,
      lastAriaLabel,
      lastTooltip,
    },
    toolbar: {
      nRowsSelected,
      searchPlaceholder,
      searchTooltip,
    },
    body: {
      emptyDataSourceMessage,
      addTooltip,
      editTooltip,
      deleteTooltip,
      filterRow: {
        filterTooltip,
      },
      editRow: {
        cancelTooltip,
        saveTooltip,
        deleteText,
      },
    },
    header: {
      actions,
    },
  })
}

export function ConfigTraducaoLocalizada(t) {
  return Traducao.ConfigTraducao(
    t('config.config.LABEL_PAGINACAO'),
    t('config.config.LABEL_LINHAS_SELECIONADAS'),
    t('config.config.LABEL_LINHAS_PAGINA'),
    t('config.config.LABEL_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_PAGINA_ANTERIOR'),
    t('config.config.LABEL_TOOLTIP_PAGINA_ANTERIOR'),
    t('config.config.LABEL_PROXIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PROXIMA_PAGINA'),
    t('config.config.LABEL_ULTIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_ULTIMA_PAGINA'),
    t('config.config.LABEL_REGISTROS_SELECIONADOS'),
    t('config.config.LABEL_BUSCAR'),
    t('config.config.LABEL_TOOLTIP_BUSCAR'),
    t('config.config.LABEL_NENHUM_REGISTRO'),
    t('config.config.LABEL_TOOLTIP_FILTRO'),
    t('config.config.LABEL_TOOLTIP_CANCELAR'),
    t('config.config.LABEL_TOOLTIP_SALVAR'),
    t('config.config.LABEL_ACOES'),
    t('config.config.LABEL_ADICIONAR'),
    t('config.config.LABEL_EDITAR'),
  )
}

export default ConfigTraducao
