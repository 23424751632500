import React from 'react'
import { propsGraficoLegenda, valueGraficoLegenda } from '../UtilsPropTypes'
import GraficoLegendaUtils from './GraficoLegendaUtils'

const GraficoLegenda = ({
  situacoes, range, corte, motivo,
}) => {
  if (range && !motivo) {
    return (
      <div id="legendas">
        {GraficoLegendaUtils(situacoes, range)}
      </div>
    )
  }
  if (corte) {
    return (
      <div id="legendas">
        {GraficoLegendaUtils(null, range, motivo)}
      </div>
    )
  }
  return (
    <div id="legendas">
      {GraficoLegendaUtils(situacoes)}
    </div>
  )
}
GraficoLegenda.propTypes = propsGraficoLegenda
GraficoLegenda.defaultProps = valueGraficoLegenda

export default GraficoLegenda
