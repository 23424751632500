import React from 'react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { erro } from '../../../assets/images'
import { propsImagemErroGrafico, valueImagemErroGrafico } from '../UtilsPropTypes'


export default function ImagemErroGrafico({ graficoSimplificado }) {
  const { t } = useTranslation()
  const telaPequena = useMediaQuery('(min-width:500px)')
  if (graficoSimplificado) {
    return <img src={erro} alt={t('utils.imagemErroGrafico.LABEL_TITULO')} height="auto" width="100%" />
  }
  if (telaPequena) {
    return <img src={erro} alt={t('utils.imagemErroGrafico.LABEL_TITULO')} height="223px" width="309px" />
  }
  return <img src={erro} alt={t('utils.imagemErroGrafico.LABEL_TITULO')} height="78px" width="108px" />
}

ImagemErroGrafico.propTypes = propsImagemErroGrafico
ImagemErroGrafico.defaultProps = valueImagemErroGrafico
