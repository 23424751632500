import React from 'react'
import TooltipMaterial from '@material-ui/core/Tooltip'
import TooltipEstilo, { TooltipEstiloCinza } from '../TooltipEstilo'
import propsTooltip, { valueTooltip } from '../TooltipPropTypes'

export default function Tooltip(props) {
  const { cinza, title } = props
  const classes = cinza ? TooltipEstiloCinza() : TooltipEstilo()

  return (
    <TooltipMaterial
      arrow
      placement="bottom"
      classes={classes}
      {...props}
      title={(
        <React.Fragment>
          {title}
        </React.Fragment>
      )}
    />
  )
}

Tooltip.propTypes = propsTooltip
Tooltip.defaultProps = valueTooltip
