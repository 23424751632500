import propTypes from 'prop-types'

const propsLoading = {
  loading: propTypes.bool,
  message: propTypes.string,
  elevation: propTypes.number,
}

export const valueLoading = {
  loading: false,
  message: '',
  elevation: undefined,
}

export default propsLoading
