import React from 'react'
import { valueTabela, propsOcorrencias } from '../GraficoDesempenhoAgrupadoPropTypes'
import { mergeArrays } from '../GraficoDesempenhoAgrupadoUtils'
import { styles } from '../GraficoDesempenhoAgrupadoEstilo'
import { unicoValor, obterQuantidadeFornecedor } from '../../utils'

export default function GraficoDesempenhoAgrupadoOcorrencias({
  ocorrencias, fornecedores,
  materiais, labels,
}) {
  let fornecedoresUnicos
  if (fornecedores && ocorrencias) {
    fornecedoresUnicos = fornecedores.filter(unicoValor)
  }

  if (ocorrencias && fornecedores && materiais && labels) {
    const classes = styles()
    const valores = mergeArrays(ocorrencias.valorFinal, ocorrencias.quantidadeFinal)
    return (
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td align="center" className={classes.borda} colSpan={2}><b>{labels.fornecedor}</b></td>
            {fornecedoresUnicos ? fornecedoresUnicos.map(row => (
              <td
                align="center"
                key={Math.random()}
                className={classes.borda}
                colSpan={obterQuantidadeFornecedor(fornecedores, row) * 2}
              >
                {row}
              </td>
            )) : null}
          </tr>
          <tr key={2}>
            <td align="center" className={classes.borda} colSpan={2}><b>{labels.material}</b></td>
            {materiais ? materiais.map(row => (
              <td align="center" key={Math.random()} className={classes.borda} colSpan={2}>
                <b>{row}</b>
              </td>
            )) : null}
          </tr>
          {valores ? valores.map(row => (
            <tr key={Math.random()}>
              {row.map(item => (
                <td align="center" key={Math.random()} rowSpan={item.tamanho} className={classes.borda}>
                  {item.dado === 'qtd' ? labels.quantidades : item.dado === 'mm' ? labels.milimetros
                    : typeof item.dado === 'number' ? item.dado.toLocaleString('pt-BR') : item.dado}
                </td>
              ))}
            </tr>
          )) : null}
        </tbody>
      </table>
    )
  }
  return null
}

GraficoDesempenhoAgrupadoOcorrencias.propTypes = propsOcorrencias
GraficoDesempenhoAgrupadoOcorrencias.defaultProps = valueTabela
