import Highcharts from 'highcharts'
import Traducao from '../utils/GraficosTraducao'
import { COR_PRIMARIA } from '../utils/Configuracoes'
import { formatarTooltip, formatarOpcoesDesempenho } from './GraficoDesempenhoIndividualUtils'
import { exportar } from '../../assets/images'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function valorMinimoY(series) {
  const menoresValores = series.map(serie => Math.min(...(serie.data.map(dados => dados[1]))))
  return Math.min(...menoresValores)
}

function GraficoDesempenhoIndividualOpcoes(tipo, series, lblTitulo, lblColuna, lblCorte,
  lblDiametro, lblCampanha, lblData, lblMm, lblPeso, lblDesempenho, lblTonMm, lblKG, tipoDeCilindro,
  lblTempo, lblMinuto, lblCilindro) {
  const valorMinimo = tipo === 'Diametro' ? valorMinimoY(series) : 0

  const { intervalo, formato } = formatarOpcoesDesempenho(tipo, tipoDeCilindro)
  return ({
    chart: {
      type: 'line',
      zoomType: 'xy',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          // eslint-disable-next-line no-param-reassign
          dadosDoGrafico.dataRows[0][0] = lblData
          for (let i = 1; i < dadosDoGrafico.dataRows.length; i += 1) {
            const data = new Date(dadosDoGrafico.dataRows[i][0])
            // eslint-disable-next-line no-param-reassign
            dadosDoGrafico.dataRows[i][0] = (
              `${data.getDate()}/${data.getMonth() + 1}/${data.getFullYear()}`)
          }
        },
      },
    },
    title: {
      text: lblTitulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    legend: {
      margin: 16,
      itemStyle: {
        fontWeight: 'normal',
      },
      symbolWidth: 5,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter() {
          const data = new Date(this.value)
          const dia = (`0${data.getUTCDate()}`).slice(-2)
          const mes = (`0${data.getUTCMonth() + 1}`).slice(-2)
          const ano = (`0${data.getUTCFullYear()}`).slice(-2)
          return `${dia}/${mes}/${ano}`
        },
      },
    },
    yAxis: {
      title: {
        text: lblColuna,
      },
      labels: {
        format: formato,
        style: {
          fontSize: '14px',
        },
      },
      min: valorMinimo,
      tickInterval: intervalo,
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      split: false,
      shared: false,
      formatter() {
        return formatarTooltip(tipo, this.point, lblCorte, lblDiametro, lblCampanha, lblData, lblMm,
          lblPeso, lblDesempenho, lblTonMm, lblKG, lblTempo, lblMinuto, lblCilindro)
      },
    },
    plotOptions: {
      line: {
        findNearestPointBy: 'xy',
      },
      series: {
        marker: {
          enabled: true,
        },
      },
    },

    colors: ['#951B81', '#4298D6', '#F8A501', COR_PRIMARIA, '#95C11C', '#00B0DB', '#FFCC00', '#FFC875',
      '#A68E16', '#597301', '#BF1997', '#CC4D49', '#FFC2B5', '#4C0073', '#61D608', '#8FCEFF', '#D6CE06', '#8004BF'],

    series,

    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          plotOptions: {
            series: {
              marker: {
                radius: 2.5,
              },
            },
          },
        },
      }],
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoDesempenhoIndividualOpcoes
