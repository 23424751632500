import React from 'react'
import { useTranslation } from 'react-i18next'
import { Paper } from '@material-ui/core'
import { imgPaginaNaoEncontrada } from '../../../assets/images'
import { COR_PRIMARIA } from '../../utils'

function LayoutErro() {
  const { t } = useTranslation()
  return (
    <div className="app flex-row align-items-center">
      <Paper style={{
        margin: 24,
        height: 'calc(100vh - 48px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <img
          src={imgPaginaNaoEncontrada}
          alt="imagemPaginaNaoEncontrada"
          style={{
            width: '45%', minWidth: 175, height: 'auto', overflow: 'auto',
          }}
        />
        <p style={{
          color: COR_PRIMARIA, fontSize: 'max(4vw, 24px)', textAlign: 'center', fontWeight: 'bold',
        }}
        >
          {t('layout.layoutErro.LABEL_TITULO')}
        </p>
        <p style={{ color: '#656D68', fontSize: 'max(1.5vw, 14px)', textAlign: 'center' }}>
          {t('layout.layoutErro.LABEL_MENSAGEM')}
        </p>
      </Paper>
    </div>
  )
}

export default LayoutErro
