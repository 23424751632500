import React, { useState } from 'react'
import { obterJaneiroAtual } from '../../utils'
import { AlterarSenhaContext, FiltroContext, PaginaContext } from '../LayoutContext'
import { AutenticacaoAlterarSenha } from '../../autenticacao'
import { propsProvider, valueProvider } from '../LayoutPropTypes'

const LayoutProvider = (props) => {
  const [open, setOpen] = useState(false)
  const janeiroAtual = obterJaneiroAtual()
  const [dataInicio, setDataInicio] = useState(new Date(janeiroAtual))
  const [dataFim, setDataFim] = useState(new Date())
  const [fornecedores, setFornecedores] = useState(undefined)
  const [fornecedoresBKP, setFornecedoresBKP] = useState(undefined)
  const [fornecedoresWR, setFornecedoresWR] = useState(undefined)
  const [todosFornecedores, setTodosFornecedores] = useState([])
  const [linhaDeProducao, setLinhaDeProducao] = useState(undefined)
  const [linhas, setLinhas] = useState([])
  const [tipoDeCilindro, setTipoDeCilindro] = useState(undefined)
  const [materiais, setMateriais] = useState(undefined)
  const [materiaisBKP, setMateriaisBKP] = useState(undefined)
  const [materiaisWR, setMateriaisWR] = useState(undefined)
  const [todosMateriais, setTodosMateriais] = useState([])
  const [cilindros, setCilindros] = useState(undefined)
  const [cilindro, setCilindro] = useState(undefined)
  const [todosCilindros, setTodosCilindros] = useState([])
  const [laminador, setLaminador] = useState(undefined)
  const [laminadores, setLaminadores] = useState([])
  const [exibirGrafico, setExibirGrafico] = useState('')
  const [consumoDoCilindro, setConsumoDoCilindro] = useState(undefined)
  const [dataInicioRetifica, setDataInicioRetifica] = useState(undefined)
  const [tipoDeCilindroCheck, setTipoDeCilindroCheck] = useState({
    bur: undefined,
    wr: undefined,
  })
  const { children, contexto } = props
  return (
    <PaginaContext.Provider value={contexto}>
      <AlterarSenhaContext.Provider value={{
        open,
        setOpen,
      }}
      >
        {open ? (
          <AutenticacaoAlterarSenha handleClose={setOpen} />
        ) : null}
        <FiltroContext.Provider value={{
          dataInicio,
          dataFim,
          mudarDataInicio: setDataInicio,
          mudarDataFim: setDataFim,
          mudarLinhaDeProducao: setLinhaDeProducao,
          mudarFornecedores: setFornecedores,
          mudarFornecedoresBKP: setFornecedoresBKP,
          mudarFornecedoresWR: setFornecedoresWR,
          mudarTipoDeCilindro: setTipoDeCilindro,
          mudarMaterial: setMateriais,
          mudarMaterialBKP: setMateriaisBKP,
          mudarMaterialWR: setMateriaisWR,
          mudarCilindro: setCilindro,
          mudarCilindros: setCilindros,
          mudarLaminador: setLaminador,
          setExibirGrafico,
          linhas,
          setTodasLinhas: setLinhas,
          laminadores,
          setTodosLaminadores: setLaminadores,
          todosFornecedores,
          setTodosFornecedores,
          todosMateriais,
          setTodosMateriais,
          todosCilindros,
          setTodosCilindros,
          setConsumoDoCilindro,
          materiais,
          materiaisBKP,
          materiaisWR,
          fornecedores,
          fornecedoresBKP,
          fornecedoresWR,
          linhaDeProducao,
          tipoDeCilindro,
          cilindro,
          cilindros,
          laminador,
          consumoDoCilindro,
          exibirGrafico,
          tipoDeCilindroCheckGlobal: tipoDeCilindroCheck,
          mudarTipoDeCilindroCheckedGlobal: setTipoDeCilindroCheck,
          dataInicioRetifica,
          setDataInicioRetifica,
        }}
        >
          {children}
        </FiltroContext.Provider>
      </AlterarSenhaContext.Provider>
    </PaginaContext.Provider>
  )
}

LayoutProvider.propTypes = propsProvider
LayoutProvider.defaultProps = valueProvider

export default LayoutProvider
