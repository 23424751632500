import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoDesempenhoIndividualService from '../GraficoDesempenhoIndividualService'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import { ErroGrafico, makeCancelable } from '../../utils'
import GraficosDesempenhoIndividualOpcoes from '../GraficoDesempenhoIndividualOpcoes'
import formatarDadosDesempenhoIndividual, { dadosGrafico } from '../GraficoDesempenhoIndividualUtils'
import { FiltroContext } from '../../layout/LayoutContext'

const GraficoDesempenhoIndividual = ({ tipo }) => {
  const [serieCorte, setSerieCorte] = useState(undefined)
  const [serieDiametro, setSerieDiametro] = useState(undefined)
  const [serieDesempenho, setSerieDesempenho] = useState(undefined)
  const [serieConsumo, setSerieConsumo] = useState(undefined)
  const [serieQuilometragem, setSerieQuilometragem] = useState(undefined)
  const [serieTonelagem, setSerieTonelagem] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  const filtro = useContext(FiltroContext)

  useEffect(() => {
    setLoading(true)
    setSerieDesempenho(undefined)
    setSerieConsumo(undefined)
    setSerieDiametro(undefined)
    setSerieCorte(undefined)
    setSerieQuilometragem(undefined)
    setSerieTonelagem(undefined)
    const cancelablePromise = makeCancelable(GraficoDesempenhoIndividualService(filtro, tipo))
    cancelablePromise.promise.then((dados) => {
      if (dados && dados[0] !== 'Erro') {
        if (tipo === 'Desempenho') {
          setSerieDesempenho(formatarDadosDesempenhoIndividual(dados, tipo))
        }
        if (tipo === 'Consumo') {
          setSerieConsumo(formatarDadosDesempenhoIndividual(dados, tipo))
        }
        if (tipo === 'Tonelagem') {
          setSerieTonelagem(formatarDadosDesempenhoIndividual(dados, tipo))
        }
        if (tipo === 'Quilometragem') {
          setSerieQuilometragem(formatarDadosDesempenhoIndividual(dados, tipo))
        }
        if (tipo === 'Corte') {
          setSerieCorte(formatarDadosDesempenhoIndividual(dados))
        }
        if (tipo === 'Diametro') {
          setSerieDiametro(formatarDadosDesempenhoIndividual(dados, tipo))
        }
      }
      setLoading(false)
    }).catch((err) => {
      if (!err.isCanceled) {
        console.log(err)
      }
    })
    return cancelablePromise.cancel
  }, [filtro, tipo])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    const { grafico } = dadosGrafico(tipo, serieDesempenho, serieConsumo, serieTonelagem,
      serieQuilometragem, serieCorte, serieDiametro)
    if ((serieDesempenho && serieDesempenho.length === 0)
    || (serieConsumo && serieConsumo.length === 0)
    || (serieTonelagem && serieTonelagem.length === 0)
    || (serieQuilometragem && serieQuilometragem.length === 0)
    || (serieCorte && serieCorte.length === 0)
    || (serieDiametro && serieDiametro.length === 0)) {
      return (
        <ErroGrafico
          mensagem={t(`graficoDesempenhoIndividual.${grafico}.LABEL_NENHUM_REGISTRO`)}
          nome={t(`graficoDesempenhoIndividual.${grafico}.LABEL_TITULO_GRAFICO`)}
        />
      )
    }
    if (serieDesempenho || serieConsumo || serieTonelagem || serieQuilometragem || serieCorte
      || serieDiametro) {
      const { serie } = dadosGrafico(tipo, serieDesempenho, serieConsumo, serieTonelagem,
        serieQuilometragem, serieCorte, serieDiametro)

      return (
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: '410px', overflow: 'visible' } }}
          options={GraficosDesempenhoIndividualOpcoes(tipo, serie,
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_TITULO_GRAFICO`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_COLUNA`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_CORTE`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_DIAMETRO`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_CAMPANHA`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_DATA`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_MM`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_PESO_PRODUZIDO`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_DESEMPENHO`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_TON_MM`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_TON`), filtro.tipoDeCilindro,
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_TEMPO`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_MINUTOS`),
            t(`graficoDesempenhoIndividual.${grafico}.LABEL_CILINDRO`))}
        />
      )
    } return (
      <AlertaModal
        url={t('geral.routes.URL_DESEMPENHO_INDIVIDUAL')}
        mensagem={t(`graficoDesempenhoIndividual.${grafico}.LABEL_ERRO`)}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

export default GraficoDesempenhoIndividual
