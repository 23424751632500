import React from 'react'
import LayoutContentList from './LayoutContentList'
import { propsLayoutDiscretizado, valueLayoutDiscretizado } from '../LayoutPropTypes'
import GraficoAnaliseCorte from '../../graficoAnaliseCorte/views/GraficoAnaliseCorte'

const LayoutDiscretizado = () => (
  <LayoutContentList>
    <GraficoAnaliseCorte key="GraficoAnaliseCorte" />
  </LayoutContentList>
)

LayoutDiscretizado.propTypes = propsLayoutDiscretizado
LayoutDiscretizado.defaultProps = valueLayoutDiscretizado

export default LayoutDiscretizado
