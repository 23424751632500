const PtBr = {
  graficoEstoqueSituacao: {
    LABEL_TITULO_GRAFICO: 'ESTOQUE DE CILINDROS POR SITUAÇÃO',
    LABEL_COLUNA_QUANTIDADE: 'Quantidade',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de estoque de cilindros por situação. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_CAMPANHA: 'Campanha vencida',
    LABEL_NOVO: 'Novo',
    LABEL_OPERACAO: 'Em operação',
    LABEL_REPARO: 'Reparo externo',
    LABEL_RETIFICADO: 'Retificado',
    LABEL_SITUACAO: 'Situação',
  },
}

export default PtBr
