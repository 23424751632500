import React, { useContext, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { FiltroContext } from '../../layout/LayoutContext'
import UseStateSafely from '../../utils/UseStateSafely'
import { TabelaPrevisaoCompraProducaoEditarService, criarPrevisaoProducao, editarPrevisaoProducao } from '../TabelaPrevisaoCompraService'
import { propsConfigPrevisaoProducao, valueConfigPrevisaoProducao } from '../TabelaPrevisaoCompraPropTypes'
import {
  COR_PRIMARIA, obterLaminadorCompletoPorCodigo, ConfigTraducao, ConfigToolbar, ConfigGeralModal,
} from '../../utils'
import salvarRegistroPrevisaoProducao, { stringsConfigPrevisaoProducao, getColunasFormatadasCadastroPrevisaoProducao } from '../TabelaPrevisaoCompraUtils'

let dados
let setDados

function TabelaPrevisaoCompraProducaoEditar({
  ano, setEditado, abrir, dadosPrevisaoProducao,
}) {
  [dados, setDados] = UseStateSafely(undefined)
  const { t } = useTranslation()
  const context = useContext(FiltroContext)
  const { laminadores, laminador } = context
  const { nomesColunas, msgs } = stringsConfigPrevisaoProducao(t)

  const localizacao = ConfigTraducao(
    t('config.config.LABEL_PAGINACAO'),
    t('config.config.LABEL_LINHAS_SELECIONADAS'),
    t('config.config.LABEL_LINHAS_PAGINA'),
    t('config.config.LABEL_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_PAGINA_ANTERIOR'),
    t('config.config.LABEL_TOOLTIP_PAGINA_ANTERIOR'),
    t('config.config.LABEL_PROXIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PROXIMA_PAGINA'),
    t('config.config.LABEL_ULTIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_ULTIMA_PAGINA'),
    t('config.config.LABEL_REGISTROS_SELECIONADOS'),
    t('config.config.LABEL_BUSCAR'),
    t('config.config.LABEL_TOOLTIP_BUSCAR'),
    t('config.config.LABEL_NENHUM_REGISTRO'),
    t('config.config.LABEL_TOOLTIP_FILTRO'),
    t('config.config.LABEL_TOOLTIP_CANCELAR'),
    t('config.config.LABEL_TOOLTIP_SALVAR'),
    t('config.config.LABEL_ACOES'),
    t('config.config.LABEL_ADICIONAR'),
    t('config.config.LABEL_EDITAR'),
  )

  useEffect(() => {
    if (dadosPrevisaoProducao) {
      Promise.resolve().then(() => setDados(dadosPrevisaoProducao))
      return
    }
    setDados(undefined)
    const filtro = { ano, laminador }
    async function conectar() {
      const dadosAPI = await TabelaPrevisaoCompraProducaoEditarService(filtro)
      setDados(dadosAPI)
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [ano, laminador, dadosPrevisaoProducao])

  function callback() {
    setDados(false)
    abrir(false)
  }

  if (dados && dados[0] === 'Erro') {
    return (
      <ConfigGeralModal msg={t('config.config.LABEL_TITULO_PREVISAO_PRODUCAO')} setMsg={() => callback()} key={Math.random()} />
    )
  }

  return (
    <MaterialTable
      id="tabelaCadastroPrevisaoProducaoId"
      style={{
        color: COR_PRIMARIA,
        boxShadow: 'none',
      }}
      title={t('config.config.LABEL_TITULO_PREVISAO_PRODUCAO')}
      columns={getColunasFormatadasCadastroPrevisaoProducao(
        obterLaminadorCompletoPorCodigo(laminador, laminadores), nomesColunas, { [ano]: ano },
      )}
      data={dados}
      isLoading={!dados}
      options={{
        actionsHeaderStyle: { width: '10%', textAlign: 'left', alignItems: 'left' },
        addRowPosition: 'first',
        pageSizeOptions: [],
        pageSize: 6,
        actionsColumnIndex: -1,
        search: false,
      }}
      localization={localizacao}
      editable={dados && dados.length < 12 ? {
        onRowAdd: newData => salvarRegistroPrevisaoProducao(
          newData, dados, setDados, msgs, criarPrevisaoProducao, setEditado,
        ),
        onRowUpdate: newData => salvarRegistroPrevisaoProducao(
          newData, dados, setDados, msgs, editarPrevisaoProducao, setEditado,
        ),
      } : {
        onRowUpdate: newData => salvarRegistroPrevisaoProducao(
          newData, dados, setDados, msgs, editarPrevisaoProducao, setEditado,
        ),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

TabelaPrevisaoCompraProducaoEditar.propTypes = propsConfigPrevisaoProducao
TabelaPrevisaoCompraProducaoEditar.defaultProps = valueConfigPrevisaoProducao

export default TabelaPrevisaoCompraProducaoEditar
