import propTypes from 'prop-types'

const propsFiltro = {
  dataFim: propTypes.any,
  dataInicio: propTypes.any,
  mudarMaterial: propTypes.func,
  mudarTipoDeCilindro: propTypes.func,
  mudarLinhaDeProducao: propTypes.func,
  mudarFornecedores: propTypes.func,
  mudarDataFim: propTypes.func,
  mudarDataInicio: propTypes.func,
  titulo: propTypes.string,
  mudarCilindros: propTypes.func,
  mudarLaminador: propTypes.func,
  exibirGrafico: propTypes.func,
  ocultarFiltro: propTypes.bool,
  linhas: propTypes.array,
  setTodasLinhas: propTypes.func,
  todosFornecedores: propTypes.array,
  setTodosFornecedores: propTypes.func,
  laminadores: propTypes.array,
  setTodosLaminadores: propTypes.func,
  todosMateriais: propTypes.array,
  setTodosMateriais: propTypes.func,
  todosCilindros: propTypes.array,
  setTodosCilindros: propTypes.func,
  setConsumoDoCilindro: propTypes.func,
}

export const valueFiltro = {
  dataFim: '',
  dataInicio: '',
  mudarMaterial: undefined,
  mudarTipoDeCilindro: undefined,
  mudarLinhaDeProducao: undefined,
  mudarFornecedores: undefined,
  mudarDataFim: undefined,
  mudarDataInicio: undefined,
  titulo: '',
  mudarCilindros: undefined,
  mudarLaminador: undefined,
  exibirGrafico: undefined,
  ocultarFiltro: undefined,
  linhas: undefined,
  setTodasLinhas: undefined,
  todosFornecedores: undefined,
  setTodosFornecedores: undefined,
  laminadores: undefined,
  setTodosLaminadores: undefined,
  todosMateriais: undefined,
  setTodosMateriais: undefined,
  todosCilindros: undefined,
  setTodosCilindros: undefined,
  setConsumoDoCilindro: undefined,
}

export const propsDatePicker = {
  id: propTypes.string,
  label: propTypes.string,
  defaultValue: propTypes.any,
  hidden: propTypes.bool,
  evento: propTypes.func,
  dia: propTypes.bool,
}

export const valueDatePicker = {
  id: '',
  label: '',
  defaultValue: '',
  hidden: null,
  evento: null,
  dia: false,
}

export const propsDropDown = {
  id: propTypes.any,
  label: propTypes.any,
  value: propTypes.any,
  lista: propTypes.array,
  itemId: propTypes.any,
  itemValue: propTypes.any,
  itemDisplay: propTypes.any,
  handleChange: propTypes.func,
  hidden: propTypes.bool,
  todos: propTypes.bool,
  valor: propTypes.array,
  all: propTypes.bool,
  handleChangeMultiplo: propTypes.func,
  autoFocus: propTypes.bool,
}

export const valueDropDown = {
  id: null,
  label: null,
  value: null,
  lista: [],
  itemId: null,
  itemValue: null,
  itemDisplay: null,
  handleChange: null,
  hidden: false,
  todos: true,
  all: true,
  valor: [null],
  autoFocus: false,
}

export const propsFiltroDropDownMultiploComFiltro = {
  id: propTypes.any,
  label: propTypes.any,
  erro: propTypes.bool,
  disabled: propTypes.bool,
  lista: propTypes.array,
  itemValue: propTypes.any,
  itemDisplay: propTypes.any,
  valor: propTypes.array,
  handleChangeMultiplo: propTypes.func,
}

export const valueFiltroDropDownMultiploComFiltro = {
  id: null,
  label: null,
  erro: true,
  disabled: false,
  lista: [],
  itemValue: null,
  itemDisplay: null,
  valor: [null],
  handleChangeMultiplo: undefined,
}

export const propsHeader = {
  id: propTypes.string,
  titulo: propTypes.string,
  dataFim: propTypes.any,
  dataInicio: propTypes.any,
}

export const valueHeader = {
  id: null,
  titulo: null,
  dataFim: '',
  dataInicio: '',
}


export const propsSelecao = {
  selected: propTypes.array,
  disabled: propTypes.bool,
  lista: propTypes.array,
  itemValue: propTypes.string,
  itemDisplay: propTypes.string,
}

export const valueSelecao = {

}

export const propsFiltroBody = {
  cilindros: propTypes.array,
  erroTipo: propTypes.bool,
  state: propTypes.object,
  setState: propTypes.func,
  fornecedores: propTypes.array,
  materiais: propTypes.array,
  setErroTipo: propTypes.func,
  setErroLaminador: propTypes.func,
  setErroLinha: propTypes.func,
  linhas: propTypes.array,
  laminadores: propTypes.array,
  erroLaminador: propTypes.bool,
  erroLinha: propTypes.bool,
  exibirGrafico: propTypes.func,
  limparFiltro: propTypes.func,
  mudarMaterial: propTypes.func,
  mudarCilindros: propTypes.func,
  mudarFornecedores: propTypes.func,
  mudarDataFim: propTypes.func,
  mudarDataInicio: propTypes.func,
  mudarLaminador: propTypes.func,
  mudarLinhaDeProducao: propTypes.func,
  mudarTipoDeCilindro: propTypes.func,
  setConsumoDoCilindro: propTypes.func,
  erroConsumoDoCilindro: propTypes.bool,
  setErroConsumoDoCilindro: propTypes.func,
}

export const valueFiltroBody = {
  setConsumoDoCilindro: undefined,
  erroConsumoDoCilindro: false,
  setErroConsumoDoCilindro: undefined,
}

export const propsTabela = {
  pageKey: propTypes.string,
  filtro: propTypes.object,
}

export const valueTabela = {

}

export default propsFiltro
