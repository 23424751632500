import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FiltroContext } from '../../layout/LayoutContext'
import {
  KEY_PAGINA_ESTOQUE_CORRENTE, KEY_PAGINA_DESEMPENHO_AGRUPADO, KEY_PAGINA_TCO,
  KEY_PAGINA_DESEMPENHO_DISCRETIZADO, KEY_PAGINA_CUSTO_PREVISAO, KEY_PAGINA_CUSTO_RELATIVO,
} from '../../utils'
import {
  obterNomeLinha, obterNomeLaminador, obterConsumoCilindros, obterNumCilindros,
  obterNomeFornecedores, obterNomeMateriais,
} from '../FiltroUtils'
import { propsTabela, valueTabela } from '../FiltroPropTypes'

const FiltroTabelaImpressao = ({ pageKey, filtro }) => {
  const { t } = useTranslation()
  const [linha, setLinha] = useState(undefined)
  const [laminador, setLaminador] = useState(undefined)
  const context = useContext(FiltroContext)
  const { linhas, laminadores, todosCilindros } = context
  const labels = {
    intervaloCem: t('filtro.filtroBody.LABEL_CONSUMO_100_75'),
    intervaloCinquenta: t('filtro.filtroBody.LABEL_CONSUMO_75_50'),
    intervaloVinteCinco: t('filtro.filtroBody.LABEL_CONSUMO_50_25'),
    intervaloZero: t('filtro.filtroBody.LABEL_CONSUMO_25_0'),
  }

  useEffect(() => {
    setLinha(obterNomeLinha(linhas, filtro.linha))
    setLaminador(obterNomeLaminador(laminadores, filtro.laminador))
  }, [filtro, linhas, laminadores])

  if (linhas && laminadores && todosCilindros && filtro.linha) {
    return (
      <>
        <b><h4>{t('filtro.filtroBody.LABEL_FILTRO')}</h4></b>
        <table width="130%">
          <tbody>
            <tr>
              <td width="20%">
                <b>{t('filtro.filtroBody.LABEL_LINHA')}</b>
                :
                {' '}
                {linha ? linha[0].siglaLinhaProducao : null}
              </td>
              <td colSpan={2}>
                <b>{t('filtro.filtroHeader.LABEL_DE')}</b>
                :
                {' '}
                {new Date(filtro.dataInicio).toLocaleDateString([])}
              </td>
            </tr>
            <tr>
              <td width="20%">
                <b>{t('filtro.filtroBody.LABEL_LAMINADOR')}</b>
                :
                {' '}
                {laminador ? laminador[0].siglaLaminador : null}
              </td>
              <td>
                <b>{t('filtro.filtroHeader.LABEL_ATE')}</b>
                :
                {' '}
                {new Date(filtro.dataFim).toLocaleDateString([])}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>{t('filtro.filtroBody.LABEL_TIPO')}</b>
                :
                {' '}
                {filtro.tipoDeCilindro ? filtro.tipoDeCilindro === 1 ? t('filtro.filtroCheckBox.LABEL_TOOLTIP_BACKUP') : t('filtro.filtroCheckBox.LABEL_TOOLTIP_WORK') : null}
              </td>
            </tr>
            <tr hidden={pageKey === KEY_PAGINA_CUSTO_PREVISAO}>
              <td colSpan={2}>
                <b>{t('filtro.filtroBody.LABEL_FORNECEDOR_DROPDOWN')}</b>
                :
                {' '}
                {filtro.fornecedores.length > 0
                  ? obterNomeFornecedores(todosCilindros, filtro.fornecedores) : null}
              </td>
            </tr>
            <tr hidden={pageKey === KEY_PAGINA_CUSTO_PREVISAO}>
              <td colSpan={2}>
                <b>{t('filtro.filtroBody.LABEL_MATERIAL_DROPDOWN')}</b>
                :
                {' '}
                {filtro.materiais.length > 0
                  ? obterNomeMateriais(todosCilindros, filtro.materiais) : null}
              </td>
            </tr>
            <tr hidden={pageKey !== KEY_PAGINA_DESEMPENHO_DISCRETIZADO}>
              <td colSpan={2}>
                <b>{t('filtro.filtroBody.LABEL_CILINDRO_DROPDOWN')}</b>
                :
                {' '}
                {filtro.cilindro ? filtro.cilindro.numCilindro : null}
              </td>
            </tr>
            <tr hidden={pageKey === KEY_PAGINA_ESTOQUE_CORRENTE
          || pageKey === KEY_PAGINA_DESEMPENHO_DISCRETIZADO || pageKey === KEY_PAGINA_CUSTO_RELATIVO
          || pageKey === KEY_PAGINA_CUSTO_PREVISAO}
            >
              <td colSpan={2}>
                <b>{t('filtro.filtroBody.LABEL_CILINDRO_DROPDOWN')}</b>
                :
                {' '}
                {obterNumCilindros(filtro.cilindros)}
              </td>
            </tr>
            <tr hidden={pageKey !== KEY_PAGINA_DESEMPENHO_AGRUPADO && pageKey !== KEY_PAGINA_TCO}>
              <td colSpan={2}>
                <b>{t('filtro.filtroBody.LABEL_CONSUMO_CILINDRO')}</b>
                :
                {obterConsumoCilindros(filtro.consumoDoCilindro, labels)}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  } return null
}

FiltroTabelaImpressao.propTypes = propsTabela
FiltroTabelaImpressao.defaultProps = valueTabela

export default FiltroTabelaImpressao
