const GraficoEquivalenteNovoRequisicao = filtro => (
  {
    codFornecedorList: filtro.fornecedores,
    codLaminador: filtro.laminador,
    codMaterialList: filtro.materiais,
    codTipoCilindro: filtro.tipoDeCilindro,
    dataFim: filtro.dataFim ? filtro.dataFim.toISOString() : '',
    dataInicio: filtro.dataInicio ? filtro.dataInicio.toISOString() : '',
  }
)
export default GraficoEquivalenteNovoRequisicao
