import React, {
  useState, useEffect, useContext, useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import Drawer from '@material-ui/core/Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FiltroService from '../FiltroService'
import propsFiltro, { valueFiltro } from '../FiltroPropTypes'
import { LoadingFiltro } from '../../loading'
import { AlertaModal } from '../../alerta'
import { obterJaneiroAtual } from '../../utils/Functions'
import { toggleDrawer } from '../../layout/LayoutUtils'
import Tooltip from '../../tooltip'
import {
  INTERVALO_0_A_25,
  INTERVALO_25_A_50,
  INTERVALO_50_A_75,
  INTERVALO_75_A_100,
  KEY_PAGINA_ESTOQUE_CORRENTE,
  LocalStorageProxy,
} from '../../utils'
import {
  focoErro, handleChangeData,
} from '../FiltroUtils'
import FiltroBody from './FiltroBody'
import { FiltroContext } from '../../layout/LayoutContext'
import { filtro } from '../../../assets/images'
import FiltroTabelaImpressao from './FiltroTabelaImpressao'
import FiltroEstilo from '../FiltroEstilo'

function Filtro({ titulo, ocultarFiltro, pageKey }) {
  const telaGrande = useMediaQuery('(min-width:450px)')
  const classes = FiltroEstilo()
  const { t } = useTranslation()
  const [abrir, setAbrir] = useState(true)
  const context = useContext(FiltroContext)
  const [carregando, setCarregando] = useState(false)
  const [erroLinha, setErroLinha] = useState(false)
  const [erroLaminador, setErroLaminador] = useState(false)
  const [erroTipo, setErroTipo] = useState(false)
  const [erroFornecedor, setErroFornecedor] = useState(false)
  const [erroMaterial, setErroMaterial] = useState(false)
  const [erroCilindro, setErroCilindro] = useState(false)
  const [erroConsumoDoCilindro, setErroConsumoDoCilindro] = useState(false)
  const [title, setTitle] = useState(titulo)
  const {
    dataFim, dataInicio, setTodosCilindros, setTodasLinhas, setTodosMateriais,
    setTodosFornecedores, setTodosLaminadores, setExibirGrafico, laminadores,
    setDataInicioRetifica,
  } = context
  const [todosEstados, setTodosEstados] = useState({
    linhas: [],
    laminadores: [],
    fornecedores: [],
    materiais: [],
    cilindros: [],
  })
  const [state, setState] = useState({
    linha: undefined,
    laminador: undefined,
    tipoDeCilindro: undefined,
    tipoDeCilindroCheck: {
      bur: false,
      wr: false,
    },
    cilindro: { numCilindro: t('filtro.filtroDropDown.LABEL_DIGITE') },
    consumoDoCilindro: [INTERVALO_0_A_25, INTERVALO_25_A_50, INTERVALO_50_A_75, INTERVALO_75_A_100],
    fornecedores: [],
    materiais: [],
    cilindros: [{ numCilindro: t('filtro.filtroDropDown.LABEL_DIGITE') }],
    dataInicio,
    dataFim,
  })

  function handleChangeTodos(materiais, fornecedores) {
    setTodosEstados({
      ...todosEstados,
      materiais,
      fornecedores,
    })
  }

  function handleChangeMateriais(materiais) {
    setTodosEstados({
      ...todosEstados,
      materiais,
    })
  }

  const limparFiltro = useCallback(() => {
    setErroTipo(false)
    setErroLinha(false)
    setErroLaminador(false)
    setErroConsumoDoCilindro(false)
    setErroFornecedor(false)
    setErroMaterial(false)
    setErroCilindro(false)
    setState({
      dataInicio: new Date(obterJaneiroAtual()),
      dataFim: new Date(),
      linha: undefined,
      laminador: undefined,
      tipoDeCilindro: undefined,
      tipoDeCilindroCheck: {
        bur: false,
        wr: false,
      },
      consumoDoCilindro: [INTERVALO_0_A_25, INTERVALO_25_A_50, INTERVALO_50_A_75,
        INTERVALO_75_A_100],
      fornecedores: [],
      materiais: [],
      cilindros: [{ numCilindro: t('filtro.filtroDropDown.LABEL_DIGITE') }],
      cilindro: { numCilindro: t('filtro.filtroDropDown.LABEL_DIGITE') },
    })
  }, [t])

  useEffect(() => {
    setAbrir(true)
    if (title !== titulo) {
      setExibirGrafico('')
      setTitle(titulo)
      limparFiltro()
    }
  }, [pageKey, title, titulo, setExibirGrafico, limparFiltro])

  useEffect(() => {
    const conectar = async () => {
      try {
        setCarregando(true)
        const resposta = await FiltroService()
        setTodosCilindros(resposta.cilindros)
        setTodasLinhas(resposta.linhasProducao)
        setTodosMateriais(resposta.materiais)
        setTodosFornecedores(resposta.fornecedores)
        setTodosLaminadores(resposta.laminadores)
        setDataInicioRetifica(resposta.dataRetificaInicial)
        LocalStorageProxy.setItem('AnoRetificaInicial', new Date(resposta.dataRetificaInicial).getUTCFullYear())
        setTodosEstados({
          linhas: resposta.linhasProducao,
          laminadores: resposta.laminadores,
          fornecedores: resposta.fornecedores,
          materiais: resposta.materiais,
          cilindros: resposta.cilindros,
        })
        setCarregando(false)
      } catch (err) {
        console.log(err)
      }
    }

    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [setTodosCilindros, setTodosMateriais, setTodosFornecedores,
    setTodasLinhas, setTodosLaminadores, setDataInicioRetifica])

  function abrirFiltro(event) {
    toggleDrawer(setAbrir, true)(event)
    if (document.getElementById('abrirFiltro')) {
      document.getElementById('abrirFiltro').blur()
    }
  }

  focoErro(erroLaminador, erroLinha, document.getElementById('laminador'),
    document.getElementById('linha'))

  if (carregando) {
    return (
      <LoadingFiltro loading={carregando} message={t('filtro.filtro.LABEL_CARREGANDO')} />
    )
  }
  if (!laminadores) {
    return (
      <AlertaModal exibir mensagem={t('filtro.filtro.LABEL_ERRO')} />
    )
  }

  return (
    <div className="lt-grid-container">
      <div>
        <b><h2 id="titulo" className="pageTitle">{titulo}</h2></b>
      </div>
      <div className="filtroOculto" id="filtroOculto" hidden={ocultarFiltro}>
        <FiltroTabelaImpressao pageKey={pageKey} filtro={state} />
      </div>
      <div className="lt-imagem" hidden={ocultarFiltro}>
        <Tooltip title="Exibir Filtro">
          <div style={{ cursor: 'pointer' }} onClick={event => abrirFiltro(event)} onKeyDown={() => {}} role="button" id="abrirFiltro" tabIndex={0}>
            <img src={filtro} height="54px" width="48px" alt="filtro" id="filtroBotao" />
          </div>
        </Tooltip>
      </div>
      <Drawer anchor="right" open={!ocultarFiltro ? abrir : false} onClose={toggleDrawer(setAbrir, false)}>
        <form className={pageKey === KEY_PAGINA_ESTOQUE_CORRENTE && telaGrande
          ? classes.drawer : undefined}
        >
          <FiltroBody
            erroTipo={erroTipo}
            state={state}
            setState={setState}
            fornecedores={todosEstados.fornecedores}
            fornecedoresBKP={todosEstados.fornecedoresBKP}
            fornecedoresWR={todosEstados.fornecedoresWR}
            setTodosEstados={handleChangeTodos}
            materiais={todosEstados.materiais}
            materiaisBKP={todosEstados.materiaisBKP}
            materiaisWR={todosEstados.materiaisWR}
            setMateriais={handleChangeMateriais}
            setErroTipo={setErroTipo}
            setErroLaminador={setErroLaminador}
            setErroLinha={setErroLinha}
            erroLaminador={erroLaminador}
            erroLinha={erroLinha}
            limparFiltro={limparFiltro}
            erroConsumoDoCilindro={erroConsumoDoCilindro}
            setErroConsumoDoCilindro={setErroConsumoDoCilindro}
            pageKey={pageKey}
            erroFornecedor={erroFornecedor}
            erroMaterial={erroMaterial}
            erroCilindro={erroCilindro}
            setErroCilindro={setErroCilindro}
            setErroFornecedor={setErroFornecedor}
            setErroMaterial={setErroMaterial}
            tipoCilindroChecked={state.tipoDeCilindroCheck}
            titulo={titulo}
            dataInicio={state.dataInicio}
            dataFim={state.dataFim}
            handleInicio={valor => handleChangeData(valor, setState, state, 'dataInicio', t('filtro.filtroDropDown.LABEL_DIGITE'), pageKey)}
            handleFim={valor => handleChangeData(valor, setState, state, 'dataFim', t('filtro.filtroDropDown.LABEL_DIGITE'), pageKey)}
            handleFimCompleto={valor => handleChangeData(valor, setState, state, 'dataFimCompleto', t('filtro.filtroDropDown.LABEL_DIGITE'))}
            setAbrir={setAbrir}
          />
        </form>
      </Drawer>
    </div>
  )
}
Filtro.propTypes = propsFiltro
Filtro.defaultProps = valueFiltro

export default Filtro
