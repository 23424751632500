import React, { useState, useEffect, useContext } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import GraficoDesempenhoMateriaisOpcoes from '../GraficoDesempenhoMateriaisOpcoes'
import { FiltroContext } from '../../layout/LayoutContext'
import { SRAccordion, makeCancelable, ErroGrafico } from '../../utils'
import GraficoDesempenhoAgrupadoService from '../GraficoDesempenhoAgrupadoService'
import LoadingGrafico from '../../loading'
import { obterdadosGRaficoMateriais } from '../GraficoDesempenhoAgrupadoUtils'
import { AlertaModal } from '../../alerta'
import GraficoDesempenhoAgrupadoTabela from './GraficoDesempenhoAgrupadoTabela'

function GraficoDesempenhoMateriais({
  tipo, mostrarSomenteOFornecedor, dados, filtro, graficoSimplificado, subtitulo, formatacao,
}) {
  const [loading, setLoading] = useState(true)
  const [materiais, setMateriais] = useState([])
  const [campanhas, setCampanhas] = useState([])
  const [desempenhoNormal, setDesempenhoNormal] = useState([])
  const [desempenhoTotal, setDesempenhoTotal] = useState([])
  const [consumoNormal, setConsumoNormal] = useState([])
  const [consumoTotal, setConsumoTotal] = useState([])
  const [fornecedores, setFornecedores] = useState([])
  const [pesos, setPesos] = useState([])
  const [cortePadrao, setCortePadrao] = useState([])
  const [corteTotal, setCorteTotal] = useState([])
  const [resposta, setResposta] = useState([])
  const [ocorrencias, setOcorrencias] = useState([])
  const filtroContext = useContext(FiltroContext)
  const dadosDoFiltro = filtro || filtroContext
  const { t } = useTranslation()

  const labels = {
    tituloDesempenho: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_DESEMPENHO_TITULO'),
    tituloConsumo: (graficoSimplificado
      ? `${t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_DESEMPENHO_HORIZONTAL2')} - ${subtitulo}`
      : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_CONSUMO_TITULO')),
    labelVerticalDesempenho: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_DESEMPENHO_VERTICAL'),
    labelVerticalConsumo: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_CONSUMO_VERTICAL'),
    materiais: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_DESEMPENHO_HORIZONTAL2'),
    quantidade: graficoSimplificado ? '' : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TOOLTIP_MATERIAIS'),
    normal: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_NORMAL'),
    total: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TOTAL'),
    todos: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TODOS'),
    normalConsumo: (graficoSimplificado
      ? t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_NORMAL_CONSUMO_SIMPLIFICADO')
      : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_NORMAL_CONSUMO')),
    totalConsumo: (graficoSimplificado
      ? t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TOTAL_CONSUMO_SIMPLIFICADO')
      : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TOTAL_CONSUMO')),
    totalMaterial: (graficoSimplificado
      ? t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_ACUMULADO_SIMPLIFICADO')
      : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TOTAL_MATERIAIS')),
    campanhas: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_CAMPANHAS'),
    material: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_MATERIAL'),
    fornecedor: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_FORNECEDOR'),
    tonelagem: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TONELAGEM'),
    mmTotal: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_MM_TOTAL'),
    mmNormal: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_MM_NORMAL'),
    mm: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_MM'),
    ocorrencias: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_OCORRENCIAS'),
    milimetros: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_MILIMETROS'),
    quantidades: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_QUANTIDADE'),
  }

  useEffect(() => {
    setLoading(true)
    setResposta(undefined)
    setOcorrencias(undefined)
    setMateriais(undefined)
    setCampanhas(undefined)
    setFornecedores(undefined)
    setDesempenhoNormal(undefined)
    setDesempenhoTotal(undefined)
    setPesos(undefined)
    setCorteTotal(undefined)
    setCortePadrao(undefined)
    setConsumoNormal(undefined)
    setConsumoTotal(undefined)
    const cancelablePromise = makeCancelable(dados
      ? Promise.resolve(dados)
      : GraficoDesempenhoAgrupadoService(dadosDoFiltro, graficoSimplificado))
    cancelablePromise.promise.then((dadosDoServidor) => {
      setResposta(dadosDoServidor)
      const valores = obterdadosGRaficoMateriais(dadosDoServidor,
        labels.totalMaterial, labels.todos, labels.fornecedor, mostrarSomenteOFornecedor)
      setFornecedores(valores.fornecedoresFinais)
      setPesos(valores.pesosFinais)
      setCorteTotal(valores.corteTotalFinal)
      setCortePadrao(valores.cortePadraoFinal)
      setOcorrencias(valores.ocorrencias)

      // se o gráfico for exibido na Home, só deve ser mostrada a sua última coluna
      const tamanhoLista = valores.nomesFinais.length
      setMateriais(graficoSimplificado
        ? [valores.nomesFinais[tamanhoLista - 1]] : valores.nomesFinais)
      setCampanhas(graficoSimplificado
        ? [valores.campanhasFinais[tamanhoLista - 1]] : valores.campanhasFinais)
      setDesempenhoNormal(graficoSimplificado
        ? [valores.desempenhosNormaisFinais[tamanhoLista - 1]] : valores.desempenhosNormaisFinais)
      setDesempenhoTotal(graficoSimplificado
        ? [valores.desempenhosTotaisFinais[tamanhoLista - 1]] : valores.desempenhosTotaisFinais)
      setConsumoNormal(graficoSimplificado
        ? [valores.consumosNormaisFinais[tamanhoLista - 1]] : valores.consumosNormaisFinais)
      setConsumoTotal(graficoSimplificado
        ? [valores.consumosTotaisFinais[tamanhoLista - 1]] : valores.consumosTotaisFinais)

      setLoading(false)
    }).catch((err) => {
      if (!err.isCanceled) { console.log(err) }
    })
    return cancelablePromise.cancel
  }, [dadosDoFiltro, labels.totalMaterial, labels.todos, labels.fornecedor,
    mostrarSomenteOFornecedor, dados, graficoSimplificado])

  try {
    if (loading) {
      return <LoadingGrafico graficoSimplificado={graficoSimplificado} />
    }
    if (resposta && resposta.length === 0) {
      return (
        <div style={graficoSimplificado ? { marginBottom: 20 } : {}}>
          <ErroGrafico
            mensagem={t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_NENHUM_REGISTRO')}
            nome={tipo === 'Desempenho'
              ? t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_DESEMPENHO_TITULO')
              : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_CONSUMO_TITULO')}
            graficoSimplificado={graficoSimplificado}
          />
        </div>
      )
    }
    if (resposta && resposta[0] !== 'Erro') {
      const options = GraficoDesempenhoMateriaisOpcoes(materiais, campanhas, desempenhoNormal,
        desempenhoTotal, consumoNormal, consumoTotal, tipo, labels, graficoSimplificado)
      options.customResizeValue = (formatacao || {}).customResizeValue
      return (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '410px' } }}
            options={options}
          />
          {graficoSimplificado ? (
            <div style={{
              textAlign: 'center',
            }}
            >
              <div style={{
                position: 'relative', fontWeight: 'bold', bottom: 12, color: '#2B3036',
              }}
              >
                {`${t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TOTAL_CAMPANHAS')} ${campanhas[0]}`}
              </div>
            </div>
          ) : null}
          {graficoSimplificado ? null : (
            <SRAccordion
              aberto
              titulo={tipo === 'Desempenho'
                ? t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TITULO_TABELA_DESEMPENHO')
                : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_TITULO_TABELA_CONSUMO')}
            >
              <GraficoDesempenhoAgrupadoTabela
                nomes={materiais}
                consumoTotal={consumoTotal}
                desempenhoTotal={desempenhoTotal}
                consumoNormal={consumoNormal}
                desempenhoNormal={desempenhoNormal}
                tipo={tipo}
                fornecedores={fornecedores}
                pesos={pesos}
                campanhas={campanhas}
                labels={labels}
                cortePadrao={cortePadrao}
                corteTotal={corteTotal}
                ocorrencias={ocorrencias}
                mostrarSomenteOFornecedor={mostrarSomenteOFornecedor}
              />
            </SRAccordion>
          )}
        </>
      )
    }
    return (
      <AlertaModal
        url={t('geral.routes.URL_DESEMPENHO_AGRUPADO')}
        mensagem={tipo === 'Desempenho'
          ? t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_ERRO_DESEMPENHO')
          : t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_ERRO_CONSUMO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

export default GraficoDesempenhoMateriais
