import propTypes from 'prop-types'

export const propsGraficoCustoPorTonelada = {
  filtro: propTypes.object,
}

export const valueGraficoCustoPorTonelada = {
  filtro: undefined,
}

export default { propsGraficoCustoPorTonelada, valueGraficoCustoPorTonelada }
