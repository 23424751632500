import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import En from './En'
import PtBr from './PtBr'

const resources = {
  En,
  PtBr,
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'PtBr',

    keySeparator: '.',

    fallbackLng: 'PtBr',

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
