import React from 'react'
import { useTranslation } from 'react-i18next'
import { propsErroGrafico, valueErroGrafico } from '../UtilsPropTypes'
import UtilsEstilo from '../UtilsEstilo'
import ImagemErroGrafico from './ImagemErroGrafico'

const ErroGrafico = ({ nome, mensagem, graficoSimplificado }) => {
  const classes = UtilsEstilo()
  const { t } = useTranslation()
  return (
    <div className={graficoSimplificado
      ? classes.erroGraficoHeaderSimplificado : classes.erroGraficoHeader}
    >
      <h5 className={classes.erroGraficoTitle}>{nome}</h5>
      <ImagemErroGrafico graficoSimplificado={graficoSimplificado} />
      <h5 style={{ marginTop: '32px' }}>{t('utils.erroGrafico.LABEL_TITULO')}</h5>
      <h4 className={classes.erroGraficoBody}>{mensagem}</h4>
      <h5>{t('utils.erroGrafico.LABEL_MENSAGEM')}</h5>
    </div>
  )
}

ErroGrafico.propTypes = propsErroGrafico
ErroGrafico.defaultProps = valueErroGrafico

export default ErroGrafico
