import { ordenarArrayPorChave, LocalStorageProxy, converteMaiusculaMinuscula } from '../utils'
import {
  resetarSenhaUsuario, criarUsuario, editarUsuarioInformado, deletarUsuarioInformado,
} from './CadastroUsuariosService'
import { publicar as ExibirAlerta } from '../utils/FilaDeMensagens'

export function acharPosicaoUsuario(usuarios, usuario) {
  if (!usuarios || !usuario || usuarios.length === 0) {
    return null
  }
  for (let a = 0; a < usuarios.length; a += 1) {
    if (usuarios[a].nome === usuario) {
      return a
    }
  }
  return null
}

export function mudarPosicao(usuarios, posicao) {
  if (!usuarios || posicao === undefined || usuarios.length === 0 || posicao > usuarios.length - 1
    || posicao < 0) {
    return null
  }
  if (usuarios.length === 1) {
    return usuarios
  }
  const itemRemovido = usuarios.splice(posicao, 1)
  usuarios.unshift(itemRemovido.reduce(a => a))
  return usuarios
}

export default function ordenarUsuarios(usuarios) {
  if (!usuarios) {
    return null
  }
  const usuarioAtual = LocalStorageProxy.getItem('NomeUsuario')
  const arrayOrdemAlfabetica = ordenarArrayPorChave(usuarios, 'nome')
  return mudarPosicao(arrayOrdemAlfabetica, acharPosicaoUsuario(arrayOrdemAlfabetica, usuarioAtual))
}

export async function resetarSenha(usuario, aberto, carregando) {
  if (usuario && aberto && carregando) {
    aberto(false)
    carregando(true)
    const resultado = await resetarSenhaUsuario(usuario)
    carregando(false)
    if (resultado && resultado.nome && resultado.trocarSenha) {
      ExibirAlerta({ text: 'cadastroUsuarios.cadastroUsuarios.LABEL_SENHA_ALTERADA', type: 'success', translate: true })
    }
  }
}

export function dadosValidos(dados, labels) {
  if (!dados || !labels) {
    return false
  }
  if (dados.codPerfil === '' || !dados.codPerfil) {
    ExibirAlerta({ text: labels.msgPerfil, type: 'error' })
    return false
  }
  if (dados.nome === '' || !dados.nome) {
    ExibirAlerta({ text: labels.msgNome, type: 'error' })
    return false
  }
  if (dados.login === '' || !dados.login) {
    ExibirAlerta({ text: labels.msgUsuario, type: 'error' })
    return false
  }
  if (dados.email === '' || !dados.email) {
    ExibirAlerta({ text: labels.msgEmail, type: 'error' })
    return false
  }
  if (dados.email && dados.email.indexOf('@') === -1) {
    ExibirAlerta({ text: labels.msgEmailErrado, type: 'error' })
    return false
  }
  return true
}

export function inserirUsuario(newData, resolve, reject, labels, usuarios, setUsuarios) {
  if (!dadosValidos(newData, labels)) {
    reject('Erro ao gravar registro')
  } else {
    setTimeout(async () => {
      const perfil = LocalStorageProxy.getItem('CodigoPerfil')
      const usuario = LocalStorageProxy.getItem('CodigoUsuario')
      const novoItem = {
        usuarioDetalhe: {
          ...newData,
          nome: converteMaiusculaMinuscula(newData.nome),
          email: newData.email.toLowerCase(),
          login: newData.login.toLowerCase(),
        },
        codPerfilRequerente: perfil,
        codUsuarioRequerente: usuario,
      }
      const resposta = await criarUsuario(novoItem)
      if (resposta && resposta.codUsuario) {
        const data = [...usuarios]
        data.push(resposta)
        setUsuarios(ordenarUsuarios(data))
        ExibirAlerta({ text: labels.msgSucesso, type: 'success' })
        resolve()
      } else {
        reject('Erro ao gravar registro')
      }
    }, 500)
  }
}

export function editarUsuario(newData, oldData, resolve, reject, labels, usuarios, setUsuarios) {
  if (!dadosValidos(newData, labels)) {
    reject('Erro ao gravar registro')
  } else {
    setTimeout(async () => {
      const perfil = LocalStorageProxy.getItem('CodigoPerfil')
      const usuario = LocalStorageProxy.getItem('CodigoUsuario')
      const novoItem = {
        usuarioDetalhe: {
          ...newData,
          nome: converteMaiusculaMinuscula(newData.nome),
          email: newData.email.toLowerCase(),
          login: newData.login.toLowerCase(),
        },
        codPerfilRequerente: perfil,
        codUsuarioRequerente: usuario,
      }
      const resposta = await editarUsuarioInformado(novoItem)
      const usuarioAtual = LocalStorageProxy.getItem('NomeUsuario')
      if (resposta && resposta.codUsuario) {
        if (usuarioAtual === oldData.nome && novoItem.usuarioDetalhe.nome !== oldData.nome) {
          LocalStorageProxy.setItem('NomeUsuario', novoItem.usuarioDetalhe.nome)
        }
        const data = [...usuarios]
        data[data.indexOf(oldData)] = resposta
        setUsuarios(ordenarUsuarios(data))
        ExibirAlerta({ text: labels.msgSucesso, type: 'success' })
        resolve()
      } else {
        reject('Erro ao gravar registro')
      }
    }, 500)
  }
}

export function deletarUsuario(oldData, resolve, reject, labels, usuarios, setUsuarios) {
  const usuarioAtual = LocalStorageProxy.getItem('NomeUsuario')
  if (!oldData || !oldData.codUsuario) {
    ExibirAlerta({ text: labels.msgErro, type: 'error' })
    reject('Erro ao excluir registro')
  }
  if (usuarioAtual === oldData.nome) {
    ExibirAlerta({ text: labels.msgExcluirUsuarioLogado, type: 'error' })
    reject('Erro ao excluir registro')
  } else {
    setTimeout(async () => {
      const resposta = await deletarUsuarioInformado(oldData.codUsuario)
      if (resposta === 1) {
        const data = [...usuarios]
        const index = data.indexOf(oldData)
        data.splice(index, 1)
        setUsuarios(data)
        ExibirAlerta({ text: labels.msgExclusao, type: 'success' })
        resolve()
      } else {
        reject('Erro ao excluir registro')
      }
    }, 500)
  }
}
