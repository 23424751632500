import React from 'react'
import ContentLoader from 'react-content-loader'

export const Loading = ({ graficoSimplificado }) => (
  <ContentLoader
    id="loadingGrafico"
    speed={1}
    primaryColor="#edf0f4"
    secondaryColor="#ffff"
    ariaLabel={false}
    style={graficoSimplificado ? { height: '40vh', width: '100%' } : { height: '66vh' }}
  >
    <rect x="0" y="20" rx="0" ry="0" width="20" height="135" />
    <rect x="50" y="40" rx="0" ry="0" width="20" height="95" />
    <rect x="100" y="60" rx="0" ry="0" width="20" height="75" />
    <rect x="150" y="40" rx="0" ry="0" width="20" height="95" />
    <rect x="200" y="20" rx="0" ry="0" width="20" height="135" />
    <rect x="250" y="40" rx="0" ry="0" width="20" height="95" />
    <rect x="300" y="60" rx="0" ry="0" width="20" height="75" />
    <rect x="350" y="40" rx="0" ry="0" width="20" height="95" />
  </ContentLoader>
)

const LoadingGrafico = ({ graficoSimplificado }) => (
  <Loading graficoSimplificado={graficoSimplificado} />
)

export default LoadingGrafico
