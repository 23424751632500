import { ConectarApiGet } from '../utils/ConectarApi'
import { ENDPOINT_INFORMATIVO } from '../utils/Configuracoes'

export default async function LayoutInformativoService() {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiGet(ENDPOINT_INFORMATIVO)

    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}
