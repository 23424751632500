import { COR_PRIMARIA, formatarNumero } from '../utils'
import { exportar } from '../../assets/images'

function GraficoTCOOpcoes(dados, materiais, labels) {
  return ({
    chart: {
      type: 'column',
      events: {
        exportData({ dataRows: [nomeDaColuna] = [[]], target: { yAxis } } = {}) {
          // eslint-disable-next-line no-param-reassign
          nomeDaColuna[0] = labels.materiais
          // eslint-disable-next-line no-param-reassign
          nomeDaColuna[1] = yAxis[0].axisTitle.textStr
        },
      },
    },
    title: {
      text: labels.titulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: materiais,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: labels.coluna,
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter() {
            return formatarNumero(this.y, 2)
          },
        },
        enableMouseTracking: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [{
      data: dados,
      color: '#95C11C',
      name: labels.materiais,
    }],
    credits: {
      enabled: false,
    },
    exporting: {
      filename: labels.titulo,
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

export default GraficoTCOOpcoes
