import propTypes from 'prop-types'

export const propsBotao = {
  label: propTypes.string,
  tipo: propTypes.string,
  variant: propTypes.string,
  cor: propTypes.string,
}

export const valueBotao = {
  tipo: 'button',
  variant: 'contained',
  cor: 'primary',
}

export const propsInput = {
  id: propTypes.string,
  label: propTypes.string,
  tipo: propTypes.string,
  evento: propTypes.func,
  autoFocus: propTypes.bool,
}

export const valueInput = {
  tipo: 'text',
  autoFocus: false,
}

export const propsForm = {
  setUsuario: propTypes.func,
  setSenha: propTypes.func,
  evento: propTypes.func,
  erroUsuario: propTypes.bool,
  erroSenha: propTypes.bool,
}

export const valueForm = {
  setUsuario: null,
  setSenha: null,
  evento: null,
  erroUsuario: false,
  erroSenha: false,
}

export const propsLogar = {
  history: propTypes.shape().isRequired,
  event: propTypes.object,
  enqueueSnackbar: propTypes.object,
  usuario: propTypes.string,
  senha: propTypes.string,
  setErroUsuario: propTypes.func,
  setErroSenha: propTypes.func,
  setCarregando: propTypes.func,
  props: propTypes.any,
  DoLogin: propTypes.func,
  Alerta: propTypes.func,
}

export const valueLogar = {

}

export const propsSucesso = {
  handleClose: propTypes.func,
  login: propTypes.bool,
}

export const valueSucesso = {
  login: false,
}

export const propsModal = {
  handleClose: propTypes.func,
  setSucesso: propTypes.func,
  login: propTypes.bool,
}

export const valueModal = {
  login: false,
}

export const propsAlterarSenha = {
  handleClose: propTypes.func,
  login: propTypes.bool,
}

export const valuesAlterarSenha = {
  login: false,
}
