import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoAnaliseCorteRequisicao from './GraficoAnaliseCorteRequisicao'
import { ENDPOINT_ANALISE_CORTE } from '../utils/Configuracoes'

export default async function GraficoAnaliseCorteService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_ANALISE_CORTE,
      GraficoAnaliseCorteRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}
