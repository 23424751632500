const PtBr = {
  graficoCustoPorTonelada: {
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de custo relativo em reais por tonelada. Tente novamente mais tarde.',
    LABEL_TITULO: 'CUSTO RELATIVO - R$/TONELADA',
    LABEL_QUANTIDADE: 'R$/t',
    LABEL_QUANTIDADE_HOME: 'R$/tonelada',
    LABEL_DATAS: 'Data',
    LABEL_CUSTO_RELATIVO: 'Custo Relativo',
    LABEL_CUSTO_EFETIVO: 'Custo Efetivo:',
    LABEL_PREVISTO: 'Previsto',
    LABEL_REAL: 'Real',
    LABEL_PESO: 'Peso Produzido:',
    LABEL_TONELADA: ' t',
    LABEL_ACUMULADO: 'Acumulado',
  },
}

export default PtBr
