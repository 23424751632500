const PtBr = {
  graficoDesempenhoIndividual: {
    LABEL_TITULO_GRAFICO: 'DESEMPENHO INDIVIDUAL',
    LABEL_COLUNA: 'Tonelada / mm',
    LABEL_CAMPANHA: 'Campanha: ',
    LABEL_MM: ' mm',
    LABEL_CORTE: 'Corte: ',
    LABEL_DATA: 'Data Fim: ',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de desempenho individual. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_PESO_PRODUZIDO: 'Peso Produzido: ',
    LABEL_DESEMPENHO: 'Desempenho: ',
    LABEL_TON_MM: ' t/mm',
    LABEL_TON: ' t',
    LABEL_TEMPO: 'Tempo Operação: ',
    LABEL_MINUTOS: ' min',
    LABEL_CILINDRO: 'Nº Cilindro: ',
  },

  graficoConsumoIndividual: {
    LABEL_TITULO_GRAFICO: 'CONSUMO INDIVIDUAL',
    LABEL_COLUNA: 'mm / kt',
    LABEL_CAMPANHA: 'Campanha: ',
    LABEL_MM: ' mm',
    LABEL_CORTE: 'Corte: ',
    LABEL_DATA: 'Data Fim: ',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de consumo individual. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_PESO_PRODUZIDO: 'Peso Produzido: ',
    LABEL_DESEMPENHO: 'Consumo: ',
    LABEL_TON_MM: ' mm/kt',
    LABEL_TON: ' t',
    LABEL_TEMPO: 'Tempo Operação: ',
    LABEL_MINUTOS: ' min',
    LABEL_CILINDRO: 'Nº Cilindro: ',
  },
  tonelagemLaminada: {
    LABEL_TITULO_GRAFICO: 'TONELAGEM LAMINADA',
    LABEL_COLUNA: 'Tonelada',
    LABEL_CAMPANHA: 'Campanha: ',
    LABEL_MM: ' t',
    LABEL_CORTE: 'Tonelagem: ',
    LABEL_DATA: 'Data Fim: ',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de tonelagem laminada. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_PESO_PRODUZIDO: 'Peso Produzido: ',
    LABEL_DESEMPENHO: 'Desempenho: ',
    LABEL_TON_MM: ' t/mm',
    LABEL_TON: ' t',
    LABEL_TEMPO: 'Tempo Operação: ',
    LABEL_MINUTOS: ' min',
    LABEL_CILINDRO: 'Nº Cilindro: ',
  },
  quilometragemLaminada: {
    LABEL_TITULO_GRAFICO: 'QUILOMETRAGEM LAMINADA',
    LABEL_COLUNA: 'Km',
    LABEL_CAMPANHA: 'Campanha: ',
    LABEL_MM: ' km',
    LABEL_CORTE: 'Quilometragem: ',
    LABEL_DATA: 'Data Fim: ',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de quilometragem laminada. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_PESO_PRODUZIDO: 'Peso Produzido: ',
    LABEL_DESEMPENHO: 'Desempenho: ',
    LABEL_TON_MM: ' t/mm',
    LABEL_TON: ' t',
    LABEL_TEMPO: 'Tempo Operação: ',
    LABEL_MINUTOS: ' min',
    LABEL_CILINDRO: 'Nº Cilindro: ',
  },
  graficoCorteTotal: {
    LABEL_TITULO_GRAFICO: 'CORTE TOTAL DOS CILINDROS',
    LABEL_COLUNA: 'Corte (mm)',
    LABEL_CAMPANHA: 'Campanha: ',
    LABEL_MM: ' mm',
    LABEL_CORTE: 'Corte: ',
    LABEL_DATA: 'Data Fim: ',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de corte total dos cilindros. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_TEMPO: 'Tempo Operação: ',
    LABEL_MINUTOS: ' min',
    LABEL_CILINDRO: 'Nº Cilindro: ',
  },
  graficoDiametroTotal: {
    LABEL_TITULO_GRAFICO: 'DIÂMETRO TOTAL DOS CILINDROS POR CAMPANHA',
    LABEL_COLUNA: 'Diâmetro (mm)',
    LABEL_CAMPANHA: 'Campanha: ',
    LABEL_MM: ' mm',
    LABEL_CORTE: 'Corte: ',
    LABEL_DIAMETRO: 'Diâmetro: ',
    LABEL_DATA: 'Data Fim: ',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de corte total dos cilindros. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_TEMPO: 'Tempo Operação: ',
    LABEL_MINUTOS: ' min',
    LABEL_CILINDRO: 'Nº Cilindro: ',
  },
}

export default PtBr
