const PtBr = {
  graficoCustoPorMm: {
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de reais por milímetro. Tente novamente mais tarde.',
    LABEL_TITULO: 'CUSTO RELATIVO - R$/MILÍMETRO',
    LABEL_QUANTIDADE: 'R$/mm',
    LABEL_AGRUPADO: 'Agrupado',
    LABEL_DATAS: 'Data',
    LABEL_CUSTO_RELATIVO: 'Custo Relativo',
    LABEL_CUSTO_EFETIVO_AGRUPADO: 'R$/mm Agrupado:',
    LABEL_CORTE: 'Corte:',
    LABEL_REAIS_MM: 'Custo Efetivo:',
    LABEL_MM: 'mm',
  },
}

export default PtBr
