const PtBr = {
  filtroHeader: {
    LABEL_DE: 'DE',
    LABEL_ATE: 'ATÉ',
  },
  filtroDropDown: {
    LABEL_TODOS: 'Todos',
    LABEL_NENHUM_REGISTRO: 'Nenhum registro encontrado',
    LABEL_SELECIONE: 'Selecione...',
    LABEL_ABRIR: 'Abrir',
    LABEL_LIMPAR: 'Limpar',
    LABEL_FECHAR: 'Fechar',
    LABEL_DIGITE: 'Digite/Selecione...',
    LABEL_ITENS_SELECIONADOS: ' itens selecionados',
    LABEL_MARCAR: 'Marcar Todos',
    LABEL_DESMARCAR: 'Desmarcar Todos',
  },
  filtroDatePicker: {
    BOTAO_CANCELAR: 'Cancelar',
    LABEL_DATA_INVALIDA: 'Formato de data inválido',
    LABEL_DATA_INFERIOR: 'Data inferior ao permitido',
    LABEL_DATA_SUPERIOR: 'Data superior ao permitido',
  },
  filtroCheckBox: {
    LABEL_TOOLTIP_BACKUP: 'Backup Roll',
    LABEL_TOOLTIP_WORK: 'Work Roll',
    ALT_BKP_INATIVO: '-bkp-inativo',
    ALT_BKP_ATIVO: '-bkp-ativo',
    ALT_WRK_INATIVO: '-wrk-inativo',
    ALT_WRK_ATIVO: '-wrk-ativo',
  },
  filtro: {
    LABEL_CARREGANDO: 'Carregando...',
    LABEL_ERRO: 'Houve um problema ao carregar o Agile Rolls. Por favor, tente novamente mais tarde.',
  },
  filtroBody: {
    LABEL_DATA_INCORRETA: 'Data final não pode ser inferior à data inicial!',
    LABEL_CAMPO_OBRIGATORIO: 'Todos os campos são obrigatórios!',
    LABEL_CARREGANDO: 'Carregando...',
    LABEL_ERRO: 'Houve um problema ao carregar o Agile Rolls. Por favor, tente novamente mais tarde.',
    LABEL_LIMPAR_FILTRO: 'Limpar Filtro',
    LABEL_LINHA: 'LINHA DE PRODUÇÃO',
    LABEL_LAMINADOR: 'LAMINADOR',
    LABEL_FILTRO: 'FILTRO',
    LABEL_CILINDRO: 'CILINDRO',
    LABEL_TIPO: 'TIPO DE CILINDRO',
    LABEL_FORNECEDOR_DROPDOWN: 'FORNECEDOR DO CILINDRO',
    LABEL_FORNECEDOR_BACKUP: 'FORNECEDOR BUR',
    LABEL_FORNECEDOR_WORK: 'FORNECEDOR WR',
    LABEL_MATERIAL_DROPDOWN: 'MATERIAL DO CILINDRO',
    LABEL_MATERIAL_BACKUP: 'MATERIAL BUR',
    LABEL_MATERIAL_WORK: 'MATERIAL WR',
    LABEL_CILINDRO_DROPDOWN: 'NÚMERO DO CILINDRO',
    LABEL_SEM_OPCOES: 'Nenhum cilindro encontrado',
    LABEL_CONSUMO_CILINDRO: 'FAIXA DE CONSUMO',
    LABEL_CONSUMO_100_75: '100% - 75%',
    LABEL_CONSUMO_75_50: '75% - 50%',
    LABEL_CONSUMO_50_25: '50% - 25%',
    LABEL_CONSUMO_25_0: '25% - 0%',
    BOTAO_APLICAR: 'Aplicar',
  },
}

export default PtBr
