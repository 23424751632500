import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { materialTableStyle } from '../ConfigEstilo'
import inserirRegistro, {
  editarRegistro,
  opcoes,
  getColunasFormatadasCadastroPrevisaoCustoRelativo,
  stringsConfigPrevisaoCustoRelativo,
} from '../ConfigUtils'
import {
  propsConfigPrevisaoCustoRelativo,
  valueConfigPrevisaoCustoRelativo,
} from '../ConfigPropTypes'
import {
  criarPrevisaoCustoRelativo,
  editarPrevisaoCustoRelativo,
} from '../ConfigService'
import ConfigGeralCardTitulo from './ConfigGeralCardTitulo'
import { ConfigToolbar } from '../../utils'

function ConfigPrevisaoCustoRelativo({
  laminadores, dados, setDados, localizacao, visivel, clickHandleTitulo,
}) {
  const { t } = useTranslation()
  const { nomesColunas } = stringsConfigPrevisaoCustoRelativo(t)

  return (
    <MaterialTable
      id="tabelaCadastroPrevisaoCustoRelativo"
      style={materialTableStyle}
      title={<ConfigGeralCardTitulo titulo={t('config.config.LABEL_TITULO_PREVISAO_CUSTO_RELATIVO')} flechaParaCima={visivel} clickHandle={clickHandleTitulo} />}
      columns={getColunasFormatadasCadastroPrevisaoCustoRelativo(laminadores, nomesColunas)}
      data={dados}
      options={opcoes}
      localization={localizacao}
      editable={{
        onRowAdd: newData => new Promise((resolve, reject) => {
          inserirRegistro(newData, reject, resolve, dados, setDados,
            t('config.config.LABEL_LAMINADOR_VAZIO'), t('config.config.LABEL_TIPO_CILINDRO_VAZIO'),
            t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_VAZIO'), t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_VALOR_ERRADO'),
            t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_SUCESSO'), t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_CASAS_DECIMAIS'),
            criarPrevisaoCustoRelativo, false, null, 2)
        }),
        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          editarRegistro(newData, reject, resolve, dados, setDados,
            oldData, t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_VAZIO'), t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_VALOR_ERRADO'),
            t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_SUCESSO'), t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO_CASAS_DECIMAIS'),
            editarPrevisaoCustoRelativo, false, null, 2)
        }),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

ConfigPrevisaoCustoRelativo.propTypes = propsConfigPrevisaoCustoRelativo
ConfigPrevisaoCustoRelativo.defaultProps = valueConfigPrevisaoCustoRelativo

export default ConfigPrevisaoCustoRelativo
