import React from 'react'
import { converteMaiusculaMinuscula } from '../utils'

export function unicoValor(value, index, self) {
  return self.indexOf(value) === index
}

function calculaPorcentagem(valorItem, total) {
  return `${(valorItem / total * 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
}

export default function obterDadosMotivos(dados, nomesMateriais, corteTotalFinal) {
  const motivosCompletos = dados.map(item => item.map(dado => [{
    nome: dado.nomeMaterial,
    motivos: dado.motivos,
  }])).reduce((acc, x) => acc.concat(x), []).reduce((acc, x) => acc.concat(x), [])

  const motivos = motivosCompletos.map(item => item.motivos).reduce((acc, x) => acc.concat(x), [])
  const nomeMotivos = motivos.map(item => item.descMotivo)

  let nomeMotivosUnicos = []
  if (nomeMotivos) {
    nomeMotivosUnicos = nomeMotivos.filter(unicoValor)
  }
  const valorFinal = []
  const quantidadeFinal = []
  let total = 0
  let qtdTotal = 0

  for (let count = 0; count < nomeMotivosUnicos.length; count += 1) {
    const valor = []
    const quantidade = []
    total = 0
    qtdTotal = 0
    for (let i = 0; i < nomesMateriais.length - 1; i += 1) {
      const material = motivosCompletos.find(item => item.nome === nomesMateriais[i])
      if (material) {
        const registro = material.motivos.find(item => item.descMotivo === nomeMotivosUnicos[count])
        if (registro) {
          total += parseFloat(registro.corteTotal.toFixed(2))
          qtdTotal += registro.qtdRetificas
        }
      }
    }

    for (let i = 0; i < nomesMateriais.length - 1; i += 1) {
      const material = motivosCompletos.find(item => item.nome === nomesMateriais[i])
      if (material) {
        if (valor.length === 0) {
          valor.push({
            dado: <b>{converteMaiusculaMinuscula(nomeMotivosUnicos[count])}</b>,
            tamanho: 2,
          })
          valor.push({ dado: 'mm', tamanho: 1 })
          quantidade.push({ dado: 'qtd', tamanho: 1 })
        }
        const registro = material.motivos.find(item => item.descMotivo === nomeMotivosUnicos[count])
        const valorItem = registro ? registro.corteTotal : 0

        valor.push({ dado: valorItem.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), tamanho: 1 })
        valor.push({
          dado: <b>{calculaPorcentagem(valorItem, corteTotalFinal[i])}</b>,
          tamanho: 1,
        })
        quantidade.push({ dado: registro ? registro.qtdRetificas : 0, tamanho: 1 })
        quantidade.push({ dado: '-', tamanho: 1 })
      }
    }

    valor.push({ dado: parseFloat(total.toFixed(2)), tamanho: 1 })
    quantidade.push({ dado: qtdTotal, tamanho: 1 })
    valorFinal.push(valor)
    quantidadeFinal.push(quantidade)
  }
  return { valorFinal, nomeMotivosUnicos, quantidadeFinal }
}
