const PtBr = {
  autenticacaoForm: {
    LABEL_TITULO: 'Olá! Seja bem-vindo ao',
    LABEL_USUARIO: 'USUÁRIO',
    LABEL_SENHA: 'SENHA',
    BOTAO_ENTRAR: 'Entrar',
  },
  autenticacaoLogin: {
    LABEL_LOADING: 'Aguarde...',
    MESSAGE_USUARIO: 'Usuário não informado!',
    MESSAGE_SENHA: 'Senha não informada!',
    MESSAGE_LOGIN_ERRADO: 'Usuário ou senha inválidos!',
    MESSAGE_ERRO: 'Houve um problema ao acessar o Agile Rolls. Por favor, tente novamente mais tarde.',
    TOKEN_SEM_PERMISSOES_BASICAS: 'Usuário não possui permissões as básicas para acessar o sistema.',
  },
  autenticacaoAlterarSenhaForm: {
    LABEL_TITULO: 'REDEFINIR SENHA',
    LABEL_INSTRUCOES: 'Preencha o formulário abaixo para alterar sua senha (Deverá conter no mínimo 6 dígitos, letras, números e caracteres especiais):',
    LABEL_INSTRUCOES_LOGIN: 'Para sua maior segurança, altere sua senha abaixo (Deverá conter no mínimo 6 dígitos, letras, números e caracteres especiais):',
    LOADING: 'Carregando',
    LABEL_ANTIGA: 'Senha Antiga',
    LABEL_NOVA: 'Nova Senha',
    LABEL_CONFIRMACAO: 'Confirmar Senha',
    LABEL_HELP: 'Deve conter ao menos 6 dígitos, letra, número e caractere especial',
    BOTAO_CANCELAR: 'CANCELAR',
    BOTAO_CONFIRMAR: 'CONFIRMAR',
    MESSAGE_CAMPO_OBRIGATORIO: 'Campo obrigatório!',
    MESSAGE_ERRO: 'Erro ao alterar a senha.',
    MESSAGE_FORMATO: 'O valor informado para o campo está em um formato inválido.',
    MESSAGE_SENHA_DIFERENTE: 'A confirmação da senha precisa ser igual a senha nova.',
    MESSAGE_SENHA_ANTIGA_IGUAL: 'A senha nova não pode ser igual a senha antiga.',
    MESSAGE_SENHA_ANTIGA_ERRADA: 'A senha antiga informada está errada',
    LABEL_PLACEHOLDER: 'Digite aqui...',
  },
  autenticacaoAlterarSenhaSucesso: {
    LABEL_TITULO: 'REDEFINIR SENHA',
    ALT_SUCESSO: 'sucesso',
    SENHA_ALTERADA: 'Sua senha foi alterada com sucesso.',
    LOGAR_NOVAMENTE: 'Para acessar o sistema, faça o login novamente.',
    BOTAO_CONFIRMAR: 'CONFIRMAR',
  },
}

export default PtBr
