const PtBr = {
  layoutMenu: {
    ALT_PERFIL: 'perfil',
    LABEL_PERFIL: 'PERFIL',
    LABEL_HOME: 'HOME',
    ALT_HOME: 'home',
    LABEL_ESTOQUE: 'ESTOQUE',
    ALT_ESTOQUE: 'estoque',
    LABEL_CORRENTE: 'Corrente',
    LABEL_HISTORICO: 'Histórico',
    LABEL_DESEMPENHO: 'DESEMPENHO',
    ALT_DESEMPENHO: 'desempenho',
    LABEL_CUSTO: 'CUSTO',
    LABEL_CUSTO_RELATIVO: 'Relativo',
    LABEL_CUSTO_PREVISAO: 'Previsão de Compra',
    ALT_CUSTO: 'custo',
    LABEL_TCO: 'TCO',
    ALT_TCO: 'tco',
    LABEL_SENHA: 'Alterar Senha',
    ALT_SENHA: 'alterar senha',
    LABEL_LOGOUT: 'LOGOUT',
    ALT_LOGOUT: 'logout',
    ALT_CONFIG: 'configurações',
    LABEL_CONFIG: 'CONFIGURAÇÕES',
    LABEL_ESTOQUE_MINIMO: 'Estoque Mínimo',
    ALT_ESTOQUE_MIN: 'estoque minimo',
    ALT_DESEMPENHO_INDIVIDUAL: 'desempenho individual',
    LABEL_DESEMPENHO_INDIVIDUAL: 'Individual',
    LABEL_DESEMPENHO_AGRUPADO: 'Agrupado',
    LABEL_EXPORTAR: 'EXPORTAR',
    LABEL_EXPORTAR_PDF: 'EXPORTAR PDF',
    LABEL_IMPRIMINDO_PDF: 'Exportando PDF...',
    LABEL_DISCRETIZADO: 'Discretizado',
    LABEL_USUARIOS: 'Usuários',
    LABEL_VER_REDUCAO_DO_DIAMETRO: 'ver redução do diâmetro',
    TEXTO_SIM: 'Sim',
    TEXTO_NAO: 'Não',
    TEXTO_MODAL_EXPORTAR_CONTEUDO: 'Você deseja exportar os gráficos para algum fornecedor específico?',
    TEXTO_TODOS_OCULTOS: 'Todos Ocultos',
    TEXTO_SELECIONE_O_FORNECEDOR: 'Selecione o fornecedor...',
  },
  layoutErro: {
    LABEL_TITULO: 'Erro, não encontrado.',
    LABEL_MENSAGEM: 'A página pela qual você procura não existe. Por favor, insira uma informação válida e tente novamente.',
  },
  layoutImagemCabecalho: {
    ALT_LOGO: 'logo',
  },
  layouPaginaPadrao: {
    LABEL_TITULO_ESTOQUE_CORRENTE: 'ESTOQUE - Corrente',
    LABEL_TITULO_ESTOQUE_HISTORICO: 'ESTOQUE - Histórico',
    LABEL_TITULO_CUSTO_RELATIVO: 'CUSTO - Relativo',
    LABEL_TITULO_CUSTO_PREVISAO: 'CUSTO - Previsão de Compra',
    LABEL_TITULO_CUSTO_TCO: 'CUSTO - TCO',
    LABEL_TITULO_DESEMPENHO_INDIVIDUAL: 'DESEMPENHO - Individual',
    LABEL_TITULO_DESEMPENHO_AGRUPADO: 'DESEMPENHO - Agrupado',
    LABEL_TITULO_DESEMPENHO_DISCRETIZADO: 'DESEMPENHO - Discretizado',
    LABEL_TITULO_CADASTRO_USUARIOS: 'PERFIL - Usuários',
  },
  layoutModalExpiracaoToken: {
    TITULO: 'REVALIDAR SESSÃO',
    CABECALHO: 'Sua sessão expirou. Deseja continuar no sistema?',
    CONTEUDO_1: 'Expira em:',
    CONTEUDO_2: 'segundos...',
    TEXTO_BOTAO: 'Sim',
  },
  layoutInformativo: {
    ALT_INFO: 'informações',
    LABEL_ATZ: ': Registros atualizados até ',
  },
  layoutEstoque: {
    LABEL_SWITCH: 'ver equivalente a novos individual',
  },
}

export default PtBr
