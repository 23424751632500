import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoDesempenhoAgrupadoRequisicao from './GraficoDesempenhoAgrupadoRequisicao'
import {
  ENDPOINT_DESEMPENHO_CONSUMO_AGRUPADO,
  ENDPOINT_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO,
} from '../utils/Configuracoes'

export default async function GraficoDesempenhoAgrupadoService(
  filtro, graficoSimplificado = false,
) {
  try {
    const resposta = await ConectarApiPost(graficoSimplificado
      ? ENDPOINT_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO : ENDPOINT_DESEMPENHO_CONSUMO_AGRUPADO,
    GraficoDesempenhoAgrupadoRequisicao(filtro))

    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}
