export default function formatarDadosDesempenhoIndividual(array, grafico = 'Corte') {
  if (!array) {
    return null
  }
  const resposta = []
  let count = 0
  let dados
  function retornaData(ano, mes, dia, lista) {
    if (grafico === 'Desempenho') {
      dados = [Date.UTC(ano, mes, dia), lista.desempenho[count], lista.codCampanha[count],
        lista.pesoProduzido[count], lista.corteTotal[count], lista.tempoCampanha[count]]
    } else if (grafico === 'Consumo') {
      dados = [Date.UTC(ano, mes, dia), lista.consumo[count], lista.codCampanha[count],
        lista.pesoProduzido[count], lista.corteTotal[count], lista.tempoCampanha[count]]
    } else if (grafico === 'Quilometragem') {
      dados = [Date.UTC(ano, mes, dia), lista.kmCampanha[count], lista.codCampanha[count],
        lista.corteTotal[count], lista.tempoCampanha[count]]
    } else if (grafico === 'Tonelagem') {
      dados = [Date.UTC(ano, mes, dia), lista.toneladaCampanha[count], lista.codCampanha[count],
        lista.corteTotal[count], lista.tempoCampanha[count]]
    } else if (grafico === 'Diametro') {
      dados = [Date.UTC(ano, mes, dia), lista.diametroTotal[count],
        lista.codCampanha[count], lista.tempoCampanha[count], lista.corteTotal[count]]
    } else {
      dados = [Date.UTC(ano, mes, dia), lista.corteTotal[count], lista.codCampanha[count],
        lista.tempoCampanha[count]]
    }
    count += 1
    if (count === lista.corteTotal.length) {
      count = 0
    }
    return dados
  }

  for (let i = 0; i < array.length; i += 1) {
    resposta.push({
      name: array[i].numCilindro,
      data: array[i].dataFimCampanha.map((item) => {
        const data = new Date(item)
        const dia = data.getUTCDate()
        const mes = data.getUTCMonth()
        const ano = data.getUTCFullYear()
        return retornaData(ano, mes, dia, array[i])
      }),
    })
  }
  return resposta
}

export function formatarTooltip(tipo, ponto, lblCorte, lblDiametro, lblCampanha, lblData, lblMm,
  lblPeso, lblDesempenho, lblTonMm, lblKG, lblTempo, lblMinuto, lblCilindro) {
  const data = new Date(ponto.x)
  const dia = (`0${data.getUTCDate()}`).slice(-2)
  const mes = (`0${data.getUTCMonth() + 1}`).slice(-2)
  const ano = (`0${data.getUTCFullYear()}`).slice(-2)
  if (tipo === 'Desempenho') {
    return `<b>${lblCilindro}</b>${ponto.series.name}<br>
    <b>${lblCampanha}</b>${ponto.series.userOptions.data[ponto.index][2]}<br>
    <b>${lblData}</b>${dia}/${mes}/${ano}<br>
    <b>${lblCorte}</b>${ponto.series.userOptions.data[ponto.index][4].toFixed(2).toString().replace('.', ',')}${lblMm}<br>
    <b>${lblPeso}</b>${ponto.series.userOptions.data[ponto.index][3].toLocaleString('pt-BR', { maximumFractionDigits: 0 })}${lblKG}<br>
    <b>${lblDesempenho}</b>${ponto.y.toLocaleString('pt-BR')}${lblTonMm}<br>
    <b>${lblTempo}</b>${ponto.series.userOptions.data[ponto.index][5].toLocaleString('pt-BR')}${lblMinuto}`
  }
  if (tipo === 'Consumo') {
    return `<b>${lblCilindro}</b>${ponto.series.name}<br>
    <b>${lblCampanha}</b>${ponto.series.userOptions.data[ponto.index][2]}<br>
    <b>${lblData}</b>${dia}/${mes}/${ano}<br>
    <b>${lblCorte}</b>${ponto.series.userOptions.data[ponto.index][4].toFixed(2).toString().replace('.', ',')}${lblMm}<br>
    <b>${lblPeso}</b>${ponto.series.userOptions.data[ponto.index][3].toLocaleString('pt-BR', { maximumFractionDigits: 0 })}${lblKG}<br>
    <b>${lblDesempenho}</b>${ponto.y.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${lblTonMm}<br>
    <b>${lblTempo}</b>${ponto.series.userOptions.data[ponto.index][5].toLocaleString('pt-BR')}${lblMinuto}`
  }
  if (tipo === 'Tonelagem') {
    return `<b>${lblCilindro}</b>${ponto.series.name}<br>
    <b>${lblCampanha}</b>${ponto.series.userOptions.data[ponto.index][2]}<br>
    <b>${lblData}</b>${dia}/${mes}/${ano}<br>
    <b>${lblCorte}</b>${ponto.y.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}${lblMm}<br>
    <b>${lblTempo}</b>${ponto.series.userOptions.data[ponto.index][4].toLocaleString('pt-BR')}${lblMinuto}`
  }
  if (tipo === 'Quilometragem') {
    return `<b>${lblCilindro}</b>${ponto.series.name}<br>
    <b>${lblCampanha}</b>${ponto.series.userOptions.data[ponto.index][2]}<br>
    <b>${lblData}</b>${dia}/${mes}/${ano}<br>
    <b>${lblCorte}</b>${ponto.y.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}${lblMm}<br>
    <b>${lblTempo}</b>${ponto.series.userOptions.data[ponto.index][4].toLocaleString('pt-BR')}${lblMinuto}`
  }
  if (tipo === 'Diametro') {
    return `<b>${lblCilindro}</b>${ponto.series.name}<br>
    <b>${lblCampanha}</b>${ponto.series.userOptions.data[ponto.index][2]}<br>
    <b>${lblCorte}</b>${(ponto.series.userOptions.data[ponto.index][4])
  .toFixed(2).toString().replace('.', ',')}${lblMm}<br>
    <b>${lblDiametro}</b>${ponto.y.toFixed(2).toString().replace('.', ',')}${lblMm}<br>
    <b>${lblData}</b>${dia}/${mes}/${ano}<br>
    <b>${lblTempo}</b>${ponto.series.userOptions.data[ponto.index][3].toLocaleString('pt-BR')}${lblMinuto}`
  }
  return `<b>${lblCilindro}</b>${ponto.series.name}<br>
  <b>${lblCampanha}</b>${ponto.series.userOptions.data[ponto.index][2]}<br>
  <b>${lblCorte}</b>${ponto.y.toFixed(2).toString().replace('.', ',')}${lblMm}<br>
  <b>${lblData}</b>${dia}/${mes}/${ano}<br>
  <b>${lblTempo}</b>${ponto.series.userOptions.data[ponto.index][3].toLocaleString('pt-BR')}${lblMinuto}`
}

export function formatarOpcoesDesempenho(tipoGrafico, tipoDeCilindro) {
  const intervalo = tipoGrafico !== 'corte' && tipoGrafico !== 'diametro' ? undefined : tipoDeCilindro === 1 ? 1 : 0.5
  const formato = tipoGrafico !== 'corte' && tipoGrafico !== 'diametro' ? undefined : tipoDeCilindro === 1 ? undefined : '{value:.1f}'

  return { intervalo, formato }
}

export function dadosGrafico(tipo, serieDesempenho, serieConsumo, serieTonelagem,
  serieQuilometragem, serieCorte, serieDiametro) {
  let serie = null
  let grafico = null
  if (tipo === 'Desempenho') {
    serie = serieDesempenho
    grafico = 'graficoDesempenhoIndividual'
  }
  if (tipo === 'Consumo') {
    serie = serieConsumo
    grafico = 'graficoConsumoIndividual'
  }
  if (tipo === 'Tonelagem') {
    serie = serieTonelagem
    grafico = 'tonelagemLaminada'
  }
  if (tipo === 'Quilometragem') {
    serie = serieQuilometragem
    grafico = 'quilometragemLaminada'
  }
  if (tipo === 'Corte') {
    serie = serieCorte
    grafico = 'graficoCorteTotal'
  }
  if (tipo === 'Diametro') {
    serie = serieDiametro
    grafico = 'graficoDiametroTotal'
  }
  return { serie, grafico }
}
