import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatarNumero, SRCelula,
} from '../../utils'
import propsTabela, { valuesTabela } from '../TabelaPrevisaoCompraPropTypes'
import { contarMesesComCampoInformado, somarArray } from '../TabelaPrevisaoCompraUtils'

const TabelaPrevisaoCompraCorte = ({ item, editavel }) => {
  const { t } = useTranslation()
  const cortesPrevistosPreenchidos = contarMesesComCampoInformado(item.corte, 'previsto')
  const cortesRealPreenchidos = contarMesesComCampoInformado(item.corte, 'real')
  const somaPrevisto = somarArray(item.corte, 'previsto')
  const somaReal = somarArray(item.corte, 'real')

  return (
    <>
      <tr>
        <SRCelula percentualLargura="6%" negrito altura="2">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CORTE')}</SRCelula>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CORTE_PLANEJADO')}</SRCelula>
        {item.corte.map(({ previsto }) => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(previsto, 2)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaPrevisto !== null ? somaPrevisto === 0 ? '0,00'
            : formatarNumero(somaPrevisto / cortesPrevistosPreenchidos, 2) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()} />
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CORTE_REAL')}</SRCelula>
        {item.corte.map(({ real, previsto }) => (
          <SRCelula background={real > previsto ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(real, 2)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaReal !== null ? somaReal === 0 ? '0,00'
            : formatarNumero(somaReal / cortesRealPreenchidos, 2) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()} />
        ) : null}
      </tr>
    </>
  )
}

TabelaPrevisaoCompraCorte.propTypes = propsTabela
TabelaPrevisaoCompraCorte.defaultProps = valuesTabela

export default TabelaPrevisaoCompraCorte
