import propTypes from 'prop-types'

const propsTabela = {
  item: propTypes.object,
}

export const valuesTabela = {
  item: [],
}

export const propsConfigPrevisaoProducao = {
  ano: propTypes.number,
  setEditado: propTypes.func,
  abrir: propTypes.func,
}

export const valueConfigPrevisaoProducao = {
  ano: null,
  setEditado: null,
  abrir: null,
}

export const propsConfigPrevisaoConsumo = {
  ano: propTypes.number,
  setEditado: propTypes.func,
  abrir: propTypes.func,
}

export const valueConfigPrevisaoConsumo = {
  ano: null,
  setEditado: null,
  abrir: null,
}

export const propBotao = {
  id: propTypes.string,
  ano: propTypes.number,
  fecharTela: propTypes.func,
}

export const valueBotao = {
  id: null,
  ano: null,
  fecharTela: null,
}

export const propsGrafico = {
  dados: propTypes.object,
  tipo: propTypes.string,
}

export const valueGrafico = {
  dados: null,
  tipo: null,
}

export const propsTabelaPrevisaoCompraEstoque = {
  dadosEstoquePrevistoReal: propTypes.object,
  dadosPrevisaoEstoque: propTypes.object,
  dadosMinimoOperacional: propTypes.array,
  ano: propTypes.number,
  editavel: propTypes.bool,
  atualizarDados: propTypes.func,
}

export const valuesTabelaPrevisaoCompraEstoque = {
  dadosEstoquePrevistoReal: {},
  dadosPrevisaoEstoque: {},
  dadosMinimoOperacional: [],
  ano: 0,
  editavel: false,
  atualizarDados: () => console.log('valuesTabelaPrevisaoCompraEstoque atualizarDados não implementado'),
}

export const propsTabelaPrevisaoCompraEstoqueMinimoOperacional = {
  dados: propTypes.array,
  setEditado: propTypes.func,
  usarValorEquivalente: propTypes.bool,
}

export const valuesTabelaPrevisaoCompraEstoqueMinimoOperacional = {
  dados: [],
  setEditado: () => console.log('valuesTabelaPrevisaoCompraEstoqueMinimoOperacional setEditado não implementado'),
  usarValorEquivalente: false,
}

export const propsTabelaPrevisaoCompraEstoqueModal = {
  closeHandle: propTypes.func,
  children: propTypes.object,
  titulo: propTypes.string,
  ano: propTypes.number,
}

export const valuesTabelaPrevisaoCompraEstoqueModal = {
  closeHandle: () => console.log('valuesTabelaPrevisaoCompraEstoqueModal closeHandle não implementado'),
  children: {},
  titulo: '',
  ano: 0,
}

export const propsTabelaPrevisaoCompraEstoquePrevisaoEstoque = {
  dados: propTypes.array,
  ano: propTypes.number,
  dadosEstoquePrevistoRealDoAnoAnterior: propTypes.object,
  linha: propTypes.string,
  setEditado: propTypes.func,
}

export const valuesTabelaPrevisaoCompraEstoquePrevisaoEstoque = {
  dados: [],
  ano: 0,
  dadosEstoquePrevistoRealDoAnoAnterior: { estoque: [] },
  linha: '',
  setEditado: () => console.log('valuesTabelaPrevisaoCompraEstoquePrevisaoEstoque setEditado não implementado'),
}

export default propsTabela
