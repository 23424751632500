import React from 'react'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'

function ConfigGeralModal({
  msg, setMsg,
}) {
  const { t } = useTranslation()
  return (
    <Modal
      modalIsOpen
      handleClose={() => setMsg('')}
      handleConfirm={() => setMsg('')}
      title={t('config.config.LABEL_ERRO')}
      buttonText={t('config.config.LABEL_BOTAO_OK')}
      maxWidth="sm"
      onExited={() => undefined}
      content={(
        <div style={{ marginTop: 10, textAlign: 'center' }}>
          <p>
            {t('config.config.LABEL_ERRO_API_AO_CARREGAR')}
            <b>{msg}</b>
          </p>
          <p>{t('config.config.LABEL_ERRO_API_TENTE_MAIS_TARDE')}</p>
        </div>
      )}
    />
  )
}

ConfigGeralModal.propTypes = { msg: propTypes.string, setMsg: propTypes.func }
ConfigGeralModal.defaultProps = { msg: '', setMsg: () => console.log('ConfigGeralModal::setMsg não implementeado.') }

export default ConfigGeralModal
