import React from 'react'
import LayoutContentList from './LayoutContentList'
import GraficoCustoPorMm from '../../graficoCustoPorMm'
import GraficoCustoPorTonelada from '../../graficoCustoPorTonelada'

const LayoutCustoRelativo = () => (
  <LayoutContentList>
    <GraficoCustoPorMm key="GraficoCustoPorMm" />
    <GraficoCustoPorTonelada key="GraficoCustoPorTonelada" />
  </LayoutContentList>
)

export default LayoutCustoRelativo
