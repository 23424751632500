const PtBr = {
  Home: {
    OLA: 'Olá',
    SEJA_BEM_VINDOA: 'seja bem-vindo(a) ao Agile Rolls',
  },
  visualizacoes: {
    consumoAgrupado: {
      LABEL_ULTIMO_S: 'Último(s):',
      RADIO_ULTIMO_MES: '30 dias',
      RADIO_ULTIMOS_3_MESES: '3 meses',
      RADIO_ULTIMOS_6_MESES: '6 meses',
      RADIO_ANUAL: 'ano',
    },
  },
}

export default PtBr
