import propTypes from 'prop-types'

export const propsButton = {
  nome: propTypes.oneOfType([
    propTypes.string,
    propTypes.object]),
  tamanho: propTypes.string,
  variant: propTypes.oneOfType([
    propTypes.string,
    propTypes.bool]),
  action: propTypes.func,
}

export const valueButton = {
  nome: '',
  tamanho: '',
  variant: null,
  action: null,
}

export const propsTextField = {
  id: propTypes.string,
  label: propTypes.string,
  obrigatorio: propTypes.bool,
  erro: propTypes.bool,
  tipo: propTypes.string,
  foco: propTypes.bool,
  evento: propTypes.func,
}

export const valueTextField = {
  foco: false,
  tipo: 'text',
  erro: false,
  obrigatorio: true,
}

export const propsErroGrafico = {
  mensagem: propTypes.string,
}

export const valueErroGrafico = {
  mensagem: '',
}

export const propsImagemErroGrafico = {
  testParam: propTypes.bool,
}

export const valueImagemErroGrafico = {
  testParam: false,
}

export const propsGraficoLegenda = {
  situacoes: propTypes.array,
}

export const valueGraficoLegenda = {
  situacoes: [1, 2, 3, 4, 5],
}

export const propsModal = {
  modalIsOpen: propTypes.bool,
  handleClose: propTypes.func,
  handleConfirm: propTypes.func,
  enableButton: propTypes.bool,
  title: propTypes.string,
  header: propTypes.string,
  content: propTypes.oneOfType([
    propTypes.string,
    propTypes.object]),
  icon: propTypes.string,
  buttonText: propTypes.string,
  maxWidth: propTypes.string,
}

export const valuesModal = {
  modalIsOpen: false,
  handleClose: undefined,
  handleConfirm: undefined,
  enableButton: false,
  title: '',
  header: '',
  content: undefined,
  icon: undefined,
  buttonText: '',
  maxWidth: 'md',
}

export const propsHeatBar = {
  indicadorPrincipal: propTypes.number,
  indicadorBase: propTypes.number,
  limiteInferior: propTypes.number,
}

export const valuesHeatBar = {
  indicadorPrincipal: 0,
  indicadorBase: 0,
  limiteInferior: 0,
}
export const propsIntegratedSwitch = {
  habilitaDiametroTotal: propTypes.shape(
    { diametroTotal: propTypes.bool, corteTotal: propTypes.bool },
  ),
  onChange: propTypes.func,
  label: propTypes.string,
}
export const valueIntegratedSwitch = {
  habilitaDiametroTotal: { diametroTotal: false, corteTotal: false },
  onChange() {
    return null
  },
  label: '',
}

export const propsCheckbox = {
  label: propTypes.string,
  titulo: propTypes.string,
  valor: propTypes.string,
  handleChange: propTypes.func,
  estado: propTypes.object,
  erro: propTypes.bool,
  disabled: propTypes.bool,
}

export const valueCheckbox = {
  erro: false,
  disabled: false,
}

export const propsAccordion = {
  children: propTypes.element,
  titulo: propTypes.string,
  aberto: propTypes.bool,
}

export const valueAccordion = {
  aberto: false,
  titulo: 'Consumo',
}

export const propsRadio = {
  disabled: propTypes.bool,
  handleChangeRadio: propTypes.func,
  value: propTypes.string,
}

export const valueRadio = {
  disabled: false,
  handleChangeRadio: null,
  value: '',
}

export const propsCelula = {
  children: propTypes.oneOfType([propTypes.string,
    propTypes.number, propTypes.element]),
  altura: propTypes.oneOfType([propTypes.string, propTypes.number]),
  largura: propTypes.oneOfType([propTypes.string, propTypes.number]),
  negrito: propTypes.bool,
  percentualLargura: propTypes.string,
  background: propTypes.string,
}

export const valueCelula = {
  children: '',
  altura: '1',
  largura: '1',
  negrito: false,
  percentualLargura: undefined,
  background: 'normal',
}

export const propsBotaoEditar = {
  evento: propTypes.func,
}

export const valueBotaoEditar = {
  evento: null,
}

export const propsConfigToolBar = {
}

export const valueConfigToolBar = {
}

export default propsButton
