import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { materialTableStyle } from '../ConfigEstilo'
import inserirRegistro, { editarRegistro, opcoes, getColunasFormatadas } from '../ConfigUtils'
import { criarNovoPadraoCorte, editarPadraoCorte } from '../ConfigService'
import { UNIDADE_MEDIDA_PADRAO_CORTE, ConfigToolbar } from '../../utils'
import { propsConfigPadraoCorte, valueConfigPadraoCorte } from '../ConfigPropTypes'
import ConfigGeralCardTitulo from './ConfigGeralCardTitulo'


const ConfigPadraoCorte = ({
  localizacao, laminadores, dados, setDados, visivel, clickHandleTitulo,
}) => {
  const { t } = useTranslation()
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('config.config.LABEL_TIPO_CILINDRO'),
    lblAno: t('config.config.LABEL_ANO'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblValor: t('config.config.LABEL_TITULO_TABELA_PADRAO_CORTE'),
    lblUnidadeDeMedida: UNIDADE_MEDIDA_PADRAO_CORTE,
  }

  return (
    <MaterialTable
      id="tablePadraoCorte"
      style={materialTableStyle}
      title={<ConfigGeralCardTitulo titulo={t('config.config.LABEL_TITULO_TABELA_PADRAO_CORTE')} flechaParaCima={visivel} clickHandle={clickHandleTitulo} />}
      columns={getColunasFormatadas(laminadores, nomesColunas)}
      data={dados}
      localization={localizacao}
      options={opcoes}
      editable={{
        onRowAdd: newData => new Promise((resolve, reject) => {
          inserirRegistro(newData, reject, resolve, dados, setDados,
            t('config.config.LABEL_LAMINADOR_VAZIO'), t('config.config.LABEL_TIPO_CILINDRO_VAZIO'),
            t('config.config.LABEL_PADRAO_CORTE_VALOR_VAZIO'), t('config.config.LABEL_PADRAO_CORTE_VALOR_ERRADO'),
            t('config.config.LABEL_PADRAO_CORTE_GRAVAR_SUCESSO'), t('config.config.LABEL_PADRAO_CORTE_QUANTIDADE_DECIMAIS'),
            criarNovoPadraoCorte, false, null, 3)
        }),
        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          editarRegistro(newData, reject, resolve, dados, setDados,
            oldData, t('config.config.LABEL_PADRAO_CORTE_VALOR_VAZIO'), t('config.config.LABEL_PADRAO_CORTE_VALOR_ERRADO'),
            t('config.config.LABEL_PADRAO_CORTE_GRAVAR_SUCESSO'), t('config.config.LABEL_PADRAO_CORTE_QUANTIDADE_DECIMAIS'),
            editarPadraoCorte, false, null, 3)
        }),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

ConfigPadraoCorte.propTypes = propsConfigPadraoCorte
ConfigPadraoCorte.defaultProps = valueConfigPadraoCorte

export default ConfigPadraoCorte
