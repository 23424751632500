import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { Divider } from '@material-ui/core'
import { ConfigTraducaoLocalizada, ConfigToolbar, formatarNumero } from '../../utils'
import { materialTableStyle } from '../../config/ConfigEstilo'
import { FiltroContext } from '../../layout/LayoutContext'
import {
  getColunasFormatadasCadastroPrevisaoEstoque,
  stringsCadastroPrevisaoEstoque,
  optionsTabelaComUmaLinha,
  PREVISTO_UNIDADE,
  PREVISTO_MM,
  editarRegistroPrevisaoEstoque,
} from '../TabelaPrevisaoCompraUtils'
import { TabelaPrevisaoCompraPrevisaoEstoqueCilindroEditarService } from '../TabelaPrevisaoCompraService'
import {
  propsTabelaPrevisaoCompraEstoquePrevisaoEstoque,
  valuesTabelaPrevisaoCompraEstoquePrevisaoEstoque,
} from '../TabelaPrevisaoCompraPropTypes'

function computaDadosDeEstoqueDeDezembroDoAnoAnterior(dados, laminadorSelecionado, tipoDeCilindro) {
  const dadosDeDezembro = (dados.estoque.find(d => d.mes === 12)
    || { [PREVISTO_UNIDADE]: 0, [PREVISTO_MM]: 0 })
  return {
    ano: dados.ano || 0,
    laminador: laminadorSelecionado ? laminadorSelecionado.siglaLaminador : '',
    tipo: tipoDeCilindro || '',
    valorUnidade: dadosDeDezembro[PREVISTO_UNIDADE],
    valorMm: dadosDeDezembro[PREVISTO_MM],
  }
}

function TabelaPrevisaoCompraEstoquePrevisaoEstoque({
  dados, ano, dadosEstoquePrevistoRealDoAnoAnterior, linha, setEditado,
}) {
  const [dadosModal, setDadosModal] = React.useState(dados)
  const { t } = useTranslation()
  const localizacao = ConfigTraducaoLocalizada(t)
  const context = React.useContext(FiltroContext)
  const { laminadores, laminador, tipoDeCilindro } = context
  const { nomesColunas, msgs } = stringsCadastroPrevisaoEstoque(t)

  const laminadorSelecionado = laminadores.find(l => l.codLaminador === laminador)
  const dadosDeEstoqueDeDezembroDoAnoAnterior = computaDadosDeEstoqueDeDezembroDoAnoAnterior(
    dadosEstoquePrevistoRealDoAnoAnterior, laminadorSelecionado, tipoDeCilindro,
  )

  return (
    <>
      <MaterialTable
        id="tabelaCadastroPrevisaoEstoque"
        style={materialTableStyle}
        title={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PREVISAO_ESTOQUE_CILINDROS')}
        columns={getColunasFormatadasCadastroPrevisaoEstoque(
          [laminadorSelecionado], nomesColunas, tipoDeCilindro, { [ano]: ano }, linha,
        )}
        data={dadosModal}
        options={optionsTabelaComUmaLinha}
        localization={localizacao}
        editable={{
          onRowUpdate: newData => editarRegistroPrevisaoEstoque(
            newData, linha, msgs, TabelaPrevisaoCompraPrevisaoEstoqueCilindroEditarService,
            setEditado, setDadosModal,
          ),
        }}
        components={{
          Toolbar: ConfigToolbar,
        }}
      />
      <br />
      { dadosDeEstoqueDeDezembroDoAnoAnterior ? (
        <p>
          {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_1')}
          {' '}
          <b>
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_2')}
            {dadosDeEstoqueDeDezembroDoAnoAnterior.ano}
          </b>
          {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_3')}
          {' '}
          <b>{dadosDeEstoqueDeDezembroDoAnoAnterior.laminador}</b>
          {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_4')}
          {' '}
          <b>
            {dadosDeEstoqueDeDezembroDoAnoAnterior.tipo === 1 ? t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ENCOSTO') : t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TRABALHO') }
            {' = '}
            {dadosDeEstoqueDeDezembroDoAnoAnterior.valorUnidade}
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_5')}
            {' '}
            {formatarNumero(dadosDeEstoqueDeDezembroDoAnoAnterior.valorMm, 1)}
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ESTOQUE_EM_DEZEMBRO_PASSADO_6')}
          </b>
        </p>
      ) : t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_SEM_INFORMACAO_ESTOQUE_ANO_ANTERIOR')}
      <Divider />
    </>
  )
}

TabelaPrevisaoCompraEstoquePrevisaoEstoque.propTypes = (
  propsTabelaPrevisaoCompraEstoquePrevisaoEstoque)
TabelaPrevisaoCompraEstoquePrevisaoEstoque.defaultProps = (
  valuesTabelaPrevisaoCompraEstoquePrevisaoEstoque)

export default TabelaPrevisaoCompraEstoquePrevisaoEstoque
