import React from 'react'
import EditRounded from '@material-ui/icons/EditRounded'
import { propsBotaoEditar, valueBotaoEditar } from '../UtilsPropTypes'

const BotaoEditar = ({ evento }) => (
  <EditRounded fontSize="small" color="action" onClick={evento} style={{ cursor: 'pointer' }} />
)

BotaoEditar.propTypes = propsBotaoEditar
BotaoEditar.defaultProps = valueBotaoEditar

export default BotaoEditar
