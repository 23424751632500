export function obterDados(array, t) {
  if (!array || !t || array.length === 0) {
    return null
  }

  const materiais = []
  const dados = []
  const reaisMM = []
  const fornecedores = []
  const campanhas = []
  const pesoProduzido = []
  const corteTotal = []
  const corteNormal = []
  const consumoNormal = []
  const consumoTotal = []
  const custoEfetivo = []

  for (let a = 0; a < array.length; a += 1) {
    materiais.push(array[a].nomeMaterial)
    fornecedores.push(array[a].nomeFornecedor)
    campanhas.push(array[a].qtdCampanhas)
    pesoProduzido.push(Math.round(array[a].pesoProduzido))
    corteTotal.push(array[a].corteTotal ? array[a].corteTotal : 0)
    corteNormal.push(array[a].corteNormal ? array[a].corteNormal : 0)
    consumoTotal.push(array[a].consumoTotal ? array[a].consumoTotal : 0)
    consumoNormal.push(array[a].consumoNormal ? array[a].consumoNormal : 0)
    reaisMM.push(array[a].reaisMM ? array[a].reaisMM : 0)
    dados.push(array[a].tco ? array[a].tco : 0)
    custoEfetivo.push(array[a].custoEfetivo ? array[a].custoEfetivo : 0)
  }

  const totalPesoProduzido = pesoProduzido.reduce((a, b) => a + b)
  const totalCorteTotal = corteTotal.reduce((a, b) => a + b)
  const totalCorteNormal = corteNormal.reduce((a, b) => a + b)
  const totalCustoEfetivo = custoEfetivo.reduce((a, b) => a + b)
  const totalReaisMM = totalCustoEfetivo / totalCorteTotal

  materiais.push(t('graficoTCO.graficoTCO.LABEL_TOTAL'))
  fornecedores.push(t('graficoTCO.graficoTCOTabela.LABEL_TODOS'))
  campanhas.push(campanhas.reduce((a, b) => a + b))
  pesoProduzido.push(totalPesoProduzido)
  corteTotal.push(totalCorteTotal)
  corteNormal.push(totalCorteNormal)
  consumoTotal.push(totalCorteTotal / totalPesoProduzido * 1000)
  consumoNormal.push(totalCorteNormal / totalPesoProduzido * 1000)
  reaisMM.push(totalReaisMM)
  dados.push(totalReaisMM && totalPesoProduzido && totalCorteTotal
    ? totalReaisMM / (totalPesoProduzido / totalCorteTotal) : 0)
  custoEfetivo.push(totalCustoEfetivo)

  return {
    materiais,
    dados,
    fornecedores,
    campanhas,
    pesoProduzido,
    corteTotal,
    corteNormal,
    consumoTotal,
    consumoNormal,
    reaisMM,
    custoEfetivo,
  }
}

const GraficoTCOUtils = obterDados

export default GraficoTCOUtils
