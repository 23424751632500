import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigPrevisaoChegadaCilindros } from '../../config'
import {
  ConfigTraducao, makeCancelable, ConfigGeralModal, obterLaminadorCompletoPorCodigo,
} from '../../utils'
import { FiltroContext } from '../../layout/LayoutContext'
import { manipulaResultadoDaRequisicao } from '../../config/ConfigUtils'
import { propsConfigPrevisaoProducao, valueConfigPrevisaoProducao } from '../TabelaPrevisaoCompraPropTypes'
import { TabelaPrevisaoCompraRecebimentoCilindrosEditarService } from '../TabelaPrevisaoCompraService'

const TabelaPrevisaoCompraRecebimentoCilindrosEditar = ({
  ano, setEditado, abrir, dadosChegadaCilindro,
}) => {
  const { t } = useTranslation()
  const [carregando, setCarregando] = useState(false)
  const [msgErroPrevisaoChegadaCilindros, setMsgErroPrevisaoChegadaCilindros] = useState('')
  const [dadosPrevisaoChegadaCilindros, setDadosPrevisaoChegadaCilindros] = useState(undefined)
  const context = useContext(FiltroContext)
  const { laminadores, laminador, tipoDeCilindro } = context

  const localizacao = ConfigTraducao(
    t('config.config.LABEL_PAGINACAO'),
    t('config.config.LABEL_LINHAS_SELECIONADAS'),
    t('config.config.LABEL_LINHAS_PAGINA'),
    t('config.config.LABEL_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_PAGINA_ANTERIOR'),
    t('config.config.LABEL_TOOLTIP_PAGINA_ANTERIOR'),
    t('config.config.LABEL_PROXIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PROXIMA_PAGINA'),
    t('config.config.LABEL_ULTIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_ULTIMA_PAGINA'),
    t('config.config.LABEL_REGISTROS_SELECIONADOS'),
    t('config.config.LABEL_BUSCAR'),
    t('config.config.LABEL_TOOLTIP_BUSCAR'),
    t('config.config.LABEL_NENHUM_REGISTRO'),
    t('config.config.LABEL_TOOLTIP_FILTRO'),
    t('config.config.LABEL_TOOLTIP_CANCELAR'),
    t('config.config.LABEL_TOOLTIP_SALVAR'),
    t('config.config.LABEL_ACOES'),
    t('config.config.LABEL_ADICIONAR'),
    t('config.config.LABEL_EDITAR'),
  )

  useEffect(() => {
    if (dadosChegadaCilindro) {
      Promise.resolve().then(() => setDadosPrevisaoChegadaCilindros(dadosChegadaCilindro))
      return () => undefined
    }
    setCarregando(true)
    const filtro = { ano, laminador, tipoDeCilindro }
    const cancelablePromise = makeCancelable(Promise.all([
      TabelaPrevisaoCompraRecebimentoCilindrosEditarService(filtro),
    ]))
    cancelablePromise.promise.then((
      [
        dadosPrevisaoChegadaCilindrosServidor,
      ],
    ) => {
      manipulaResultadoDaRequisicao(dadosPrevisaoChegadaCilindrosServidor, setDadosPrevisaoChegadaCilindros, setMsgErroPrevisaoChegadaCilindros, t('config.config.LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS_SIMULADOR'))
      setCarregando(false)
    }).catch((err) => {
      if (!err.isCanceled) { console.log(err) }
    })
    return cancelablePromise.cancel
  }, [ano, laminador, tipoDeCilindro, t, dadosChegadaCilindro])


  function callback() {
    setDadosPrevisaoChegadaCilindros(false)
    abrir(false)
  }

  return (
    <>
      <ConfigPrevisaoChegadaCilindros
        dados={dadosPrevisaoChegadaCilindros}
        setDados={setDadosPrevisaoChegadaCilindros}
        localizacao={localizacao}
        laminadores={obterLaminadorCompletoPorCodigo(laminador, laminadores)}
        carregando={carregando}
        simulador
        setEditado={setEditado}
        tipoDeCilindro={tipoDeCilindro}
        ano={{ [ano]: ano }}
      />
      {[
        [msgErroPrevisaoChegadaCilindros, setMsgErroPrevisaoChegadaCilindros]].map(
        ([msg]) => (msg ? <ConfigGeralModal msg={msg} setMsg={() => callback()} key={Math.random()} /> : ''),
      )}
    </>
  )
}

TabelaPrevisaoCompraRecebimentoCilindrosEditar.propTypes = propsConfigPrevisaoProducao
TabelaPrevisaoCompraRecebimentoCilindrosEditar.defaultProps = valueConfigPrevisaoProducao

export default TabelaPrevisaoCompraRecebimentoCilindrosEditar
