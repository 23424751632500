import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {
  formatarNumero, SRCelula, BotaoEditar, TituloModal,
} from '../../utils'
import propsTabela, { valuesTabela } from '../TabelaPrevisaoCompraPropTypes'
import TabelaPrevisaoCompraConsumoEditar from './TabelaPrevisaoCompraConsumoEditar'
import TabelaPrevisaoCompraBotao from './TabelaPrevisaoCompraBotao'
import { contarMesesComCampoInformado, somarArray } from '../TabelaPrevisaoCompraUtils'

const TabelaPrevisaoCompraConsumo = ({
  item, atualizarDados, editavel, dadosPrevisaoConsumo,
}) => {
  const [abrirPrevisaoConsumo, setAbrirPrevisaoConsumo] = useState(false)
  const [editado, setEditado] = useState(false)
  const { t } = useTranslation()
  const consumosRealPreenchidos = contarMesesComCampoInformado(item.consumo, 'real')
  const somaReal = somarArray(item.consumo, 'real')

  function fecharTela(ano) {
    if (editado) {
      atualizarDados(ano)
    } else {
      setEditado(false)
    }
    setAbrirPrevisaoConsumo(false)
  }

  return (
    <>
      {abrirPrevisaoConsumo ? (
        <Dialog onClose={() => fecharTela(item.ano)} aria-labelledby="previsaoConsumoEdit" open fullWidth maxWidth="md">
          <TituloModal id="previsaoConsumoEdit" onClose={() => fecharTela(item.ano)}>
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR')}
          </TituloModal>
          <DialogContent id="previsaoConsumoEditBody">
            <TabelaPrevisaoCompraConsumoEditar
              ano={item.ano}
              setEditado={setEditado}
              abrir={setAbrirPrevisaoConsumo}
              dadosPrevisaoConsumo={dadosPrevisaoConsumo}
            />
          </DialogContent>
          <TabelaPrevisaoCompraBotao fecharTela={fecharTela} ano={item.ano} id="previsaoConsumoEdit" />
        </Dialog>
      ) : null}
      <tr>
        <SRCelula percentualLargura="6%" negrito altura="2">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CONSUMO')}</SRCelula>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CONSUMO_PLAN')}</SRCelula>
        {item.consumo.map(consumo => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(consumo.previsto, 2)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {formatarNumero(item.consumo.map(b => b.previsto)
            .reduce((a, b) => parseFloat(a) + parseFloat(b)) / 12, 2)}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}>
            <BotaoEditar evento={() => setAbrirPrevisaoConsumo(true)} />
          </SRCelula>
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CONSUMO_REAL')}</SRCelula>
        {item.consumo.map(consumo => (
          <SRCelula background={consumo.real > consumo.previsto ? 'vermelho' : ''} percentualLargura="6%" key={Math.random()}>{formatarNumero(consumo.real, 2)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaReal !== null ? somaReal === 0 ? '0' : formatarNumero(somaReal / consumosRealPreenchidos, 2) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}> </SRCelula>
        ) : null}
      </tr>
    </>
  )
}

TabelaPrevisaoCompraConsumo.propTypes = propsTabela
TabelaPrevisaoCompraConsumo.defaultProps = valuesTabela

export default TabelaPrevisaoCompraConsumo
