import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { materialTableStyle } from '../ConfigEstilo'
import inserirRegistro, {
  editarRegistro,
  opcoes,
  getColunasFormatadasPrevisaoChegadaCilindros,
  stringsConfigPrevisaoChegadaCilindros,
  opcoesPrevisaoChegadaCilindros,
} from '../ConfigUtils'
import { criarPrevisaoChegadaCilindros, editarPrevisaoChegadaCilindros } from '../ConfigService'
import { propsConfigPrevisaoChegadaCilindros, valueConfigPrevisaoChegadaCilindros } from '../ConfigPropTypes'
import ConfigGeralCardTitulo from './ConfigGeralCardTitulo'
import { ConfigToolbar } from '../../utils'

const ConfigPrevisaoChegadaCilindros = ({
  localizacao, laminadores, dados, setDados, visivel, clickHandleTitulo, carregando, simulador,
  setEditado, tipoDeCilindro, ano,
}) => {
  const { t } = useTranslation()
  const { nomesColunas } = stringsConfigPrevisaoChegadaCilindros(t)

  return (
    <MaterialTable
      id="tablePrevisaoChegadaCilindros"
      style={materialTableStyle}
      title={simulador ? t('config.config.LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS_SIMULADOR') : <ConfigGeralCardTitulo titulo={t('config.config.LABEL_TITULO_TABELA_PREVISAO_CHEGADA_CILINDROS')} flechaParaCima={visivel} clickHandle={clickHandleTitulo} />}
      columns={getColunasFormatadasPrevisaoChegadaCilindros(laminadores, nomesColunas,
        simulador, tipoDeCilindro, ano)}
      data={dados}
      localization={localizacao}
      options={simulador ? opcoesPrevisaoChegadaCilindros : opcoes}
      isLoading={carregando}
      editable={simulador && dados && dados.length > 11 ? {
        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          editarRegistro(newData, reject, resolve, dados, setDados,
            oldData, t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO_VAZIO'), t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO_VALOR_ERRADO'),
            t('config.config.LABEL_PREVISAO_CHEGADA_CILINDROS_GRAVAR_SUCESSO'), t('config.config.LABEL_PREVISAO_CHEGADA_CILINDROS_CASAS_DECIMAIS'),
            editarPrevisaoChegadaCilindros, simulador, setEditado, 0)
        }),
      } : {
        onRowAdd: newData => new Promise((resolve, reject) => {
          inserirRegistro(newData, reject, resolve, dados, setDados,
            t('config.config.LABEL_LAMINADOR_VAZIO'), t('config.config.LABEL_TIPO_CILINDRO_VAZIO'),
            t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO_VAZIO'), t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO_VALOR_ERRADO'),
            t('config.config.LABEL_PREVISAO_CHEGADA_CILINDROS_GRAVAR_SUCESSO'), t('config.config.LABEL_PREVISAO_CHEGADA_CILINDROS_CASAS_DECIMAIS'),
            criarPrevisaoChegadaCilindros, simulador, setEditado, 0)
        }),
        onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
          editarRegistro(newData, reject, resolve, dados, setDados,
            oldData, t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO_VAZIO'), t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO_VALOR_ERRADO'),
            t('config.config.LABEL_PREVISAO_CHEGADA_CILINDROS_GRAVAR_SUCESSO'), t('config.config.LABEL_PREVISAO_CHEGADA_CILINDROS_CASAS_DECIMAIS'),
            editarPrevisaoChegadaCilindros, simulador, setEditado, 0)
        }),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

ConfigPrevisaoChegadaCilindros.propTypes = propsConfigPrevisaoChegadaCilindros
ConfigPrevisaoChegadaCilindros.defaultProps = valueConfigPrevisaoChegadaCilindros

export default ConfigPrevisaoChegadaCilindros
