import React, { useContext, useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { Divider } from '@material-ui/core'
import { FiltroContext } from '../../layout/LayoutContext'
import UseStateSafely from '../../utils/UseStateSafely'
import {
  criarPrevisaoConsumo, editarPrevisaoConsumo, TabelaPrevisaoCompraConsumoEditarService,
} from '../TabelaPrevisaoCompraService'
import { propsConfigPrevisaoConsumo, valueConfigPrevisaoConsumo } from '../TabelaPrevisaoCompraPropTypes'
import {
  COR_PRIMARIA, obterLaminadorCompletoPorCodigo, ConfigTraducao, ConfigToolbar, ConfigGeralModal,
  formatarNumero,
} from '../../utils'
import {
  getColunasFormatadasCadastroPrevisaoConsumo, stringsConfigPrevisaoConsumo,
  salvarRegistroPrevisaoConsumo, optionsTabelaComUmaLinha,
} from '../TabelaPrevisaoCompraUtils'

let dados
let setDados

function TabelaPrevisaoCompraConsumoEditar({
  ano, setEditado, abrir, dadosPrevisaoConsumo,
}) {
  [dados, setDados] = UseStateSafely(undefined)
  const { t } = useTranslation()
  const context = useContext(FiltroContext)
  const { laminadores, laminador, tipoDeCilindro } = context
  const laminadorCompleto = obterLaminadorCompletoPorCodigo(laminador, laminadores)
  const { nomesColunas, msgs } = stringsConfigPrevisaoConsumo(t)
  const [valorMedia, setValorMedia] = useState(undefined)
  const [media, setMedia] = useState(undefined)

  if (dados && !media) {
    setMedia({
      ano: ano - 1,
      laminador: laminadorCompleto[0].siglaLaminador,
      tipo: tipoDeCilindro === 1 ? t('config.config.LABEL_BACKUP_ROLL') : t('config.config.LABEL_WORK_ROLL'),
      valor: formatarNumero(valorMedia, 2),
    })
  }
  const localizacao = ConfigTraducao(
    t('config.config.LABEL_PAGINACAO'),
    t('config.config.LABEL_LINHAS_SELECIONADAS'),
    t('config.config.LABEL_LINHAS_PAGINA'),
    t('config.config.LABEL_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_PAGINA_ANTERIOR'),
    t('config.config.LABEL_TOOLTIP_PAGINA_ANTERIOR'),
    t('config.config.LABEL_PROXIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PROXIMA_PAGINA'),
    t('config.config.LABEL_ULTIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_ULTIMA_PAGINA'),
    t('config.config.LABEL_REGISTROS_SELECIONADOS'),
    t('config.config.LABEL_BUSCAR'),
    t('config.config.LABEL_TOOLTIP_BUSCAR'),
    t('config.config.LABEL_NENHUM_REGISTRO'),
    t('config.config.LABEL_TOOLTIP_FILTRO'),
    t('config.config.LABEL_TOOLTIP_CANCELAR'),
    t('config.config.LABEL_TOOLTIP_SALVAR'),
    t('config.config.LABEL_ACOES'),
    t('config.config.LABEL_ADICIONAR'),
    t('config.config.LABEL_EDITAR'),
  )

  useEffect(() => {
    if (dadosPrevisaoConsumo) {
      let dadosTabela
      let dadosMedia
      if (dadosPrevisaoConsumo[0] === 'Erro') {
        dadosTabela = dadosPrevisaoConsumo
        dadosMedia = 0
      } else if ((typeof dadosPrevisaoConsumo[0].valor !== 'number')) {
        dadosMedia = dadosPrevisaoConsumo[0].mediaAnoAnterior
        dadosTabela = []
      } else {
        dadosMedia = dadosPrevisaoConsumo[0].mediaAnoAnterior
        dadosTabela = dadosPrevisaoConsumo
      }
      Promise.resolve().then(() => { setValorMedia(dadosMedia); setDados(dadosTabela) })
      return
    }
    setDados(undefined)
    setValorMedia(undefined)
    const filtro = { ano, laminador, tipoDeCilindro }
    async function conectar() {
      const dadosAPI = await TabelaPrevisaoCompraConsumoEditarService(filtro)
      if (dadosAPI[0] === 'Erro') {
        setDados(dadosAPI)
        setValorMedia(0)
      } else if (!dadosAPI[0].ano) {
        setValorMedia(dadosAPI[0].mediaAnoAnterior)
        setDados([])
      } else {
        setValorMedia(dadosAPI[0].mediaAnoAnterior)
        setDados(dadosAPI)
      }
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [ano, laminador, tipoDeCilindro, dadosPrevisaoConsumo])

  function callback() {
    setDados(false)
    abrir(false)
  }

  if (dados && dados[0] === 'Erro') {
    return (
      <ConfigGeralModal msg={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TITULO_EDITAR')} setMsg={() => callback()} key={Math.random()} />
    )
  }

  return (
    <>
      <MaterialTable
        id="tabelaCadastroPrevisaoConsumoId"
        style={{
          color: COR_PRIMARIA,
          boxShadow: 'none',
        }}
        title={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TITULO_EDITAR')}
        columns={getColunasFormatadasCadastroPrevisaoConsumo(
          laminadorCompleto, nomesColunas, { [ano]: ano },
          tipoDeCilindro,
        )}
        data={dados}
        isLoading={!dados}
        options={optionsTabelaComUmaLinha}
        localization={localizacao}
        editable={dados && dados.length < 1 ? {
          onRowAdd: newData => salvarRegistroPrevisaoConsumo(
            newData, dados, setDados, msgs, criarPrevisaoConsumo, setEditado,
          ),
          onRowUpdate: newData => salvarRegistroPrevisaoConsumo(
            newData, dados, setDados, msgs, editarPrevisaoConsumo, setEditado,
          ),
        } : {
          onRowUpdate: newData => salvarRegistroPrevisaoConsumo(
            newData, dados, setDados, msgs, editarPrevisaoConsumo, setEditado,
          ),
        }}
        components={{
          Toolbar: ConfigToolbar,
        }}
      />
      <br />
      { media ? (
        <p>
          {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MEDIA_1')}
          {' '}
          <b>{media.ano}</b>
          {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MEDIA_2')}
          {' '}
          <b>{media.laminador}</b>
          {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MEDIA_3')}
          {' '}
          <b>
            {media.tipo}
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MEDIA_4')}
            {media.valor}
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MM_KT')}
          </b>
        </p>
      ) : null}
      <Divider />
    </>
  )
}

TabelaPrevisaoCompraConsumoEditar.propTypes = propsConfigPrevisaoConsumo
TabelaPrevisaoCompraConsumoEditar.defaultProps = valueConfigPrevisaoConsumo

export default TabelaPrevisaoCompraConsumoEditar
