const PtBr = {
  graficoTCO: {
    LABEL_TITULO_GRAFICO: 'TCO',
    LABEL_COLUNA: 'R$/t',
    LABEL_TOTAL: 'Total',
    LABEL_MATERIAIS: 'Materiais',
    LABEL_TABELA: 'INFORMAÇÕES GRÁFICO TCO',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de TCO. Tente novamente mais tarde.',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
  },
  graficoTCOTabela: {
    LABEL_FORNECEDOR: 'Fornecedor',
    LABEL_MATERIAL: 'Material',
    LABEL_CAMPANHAS: 'Campanhas',
    LABEL_TONELAGEM: 'Tonelagem',
    LABEL_MM_TOTAL: 'mm Total',
    LABEL_MM_NORMAL: 'mm Normal',
    LABEL_CONSUMO_NORMAL: 'mm/kt Normal',
    LABEL_CONSUMO_TOTAL: 'mm/kt Total',
    LABEL_CUSTO: 'Custo Efetivo - R$',
    LABEL_REAIS_MM: 'R$/mm',
    LABEL_TCO: 'TCO - R$/t',
    LABEL_TODOS: 'TODOS',
  },
}

export default PtBr
