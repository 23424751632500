import React, { useContext } from 'react'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import CalendarToday from '@material-ui/icons/CalendarToday'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'
import { ThemeProvider } from '@material-ui/styles'
import { InputAdornment } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { valueDatePicker, propsDatePicker } from '../FiltroPropTypes'
import FiltroEstilo from '../FiltroEstilo'
import { defaultMaterialTheme } from '../FiltroUtils'
import { FiltroContext } from '../../layout/LayoutContext'

function FiltroDatePicker({
  id, label, defaultValue, evento, completo, futuro, somenteAno,
}) {
  const classes = FiltroEstilo()
  const context = useContext(FiltroContext)
  const { dataInicioRetifica } = context
  const dateInicio = new Date(dataInicioRetifica)
  dateInicio.setUTCDate(2)
  const { t } = useTranslation()
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <DatePicker
          id={id}
          label={label}
          format={completo ? 'DD / MM / YYYY' : somenteAno ? 'YYYY' : 'MMMM / YYYY'}
          minDate={somenteAno ? new Date(new Date().getFullYear() - 2, 0, 1) : dateInicio}
          maxDate={futuro ? new Date(new Date().getFullYear() + 1, 11, 31)
            : somenteAno ? new Date(new Date().getFullYear() + 2, 11, 31) : new Date()}
          views={completo ? undefined : somenteAno ? ['year'] : ['year', 'month']}
          value={defaultValue}
          cancelLabel={t('filtro.filtroDatePicker.BOTAO_CANCELAR')}
          onChange={evento}
          invalidDateMessage={t('filtro.filtroDatePicker.LABEL_DATA_INVALIDA')}
          minDateMessage={t('filtro.filtroDatePicker.LABEL_DATA_INFERIOR')}
          maxDateMessage={t('filtro.filtroDatePicker.LABEL_DATA_SUPERIOR')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.filtroCursorAsPointer} name="inputAdornmentFiltro">
                <ArrowDropDown />
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start" className={classes.filtroCursorAsPointer} name="inputAdornmentFiltro">
                <CalendarToday />
              </InputAdornment>
            ),
          }}
          className={classes.filtroDatePicker}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
FiltroDatePicker.propTypes = propsDatePicker
FiltroDatePicker.defaultProps = valueDatePicker

export default FiltroDatePicker
