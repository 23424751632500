const En = {
  layoutMenu: {
    ALT_PERFIL: 'profile',
    LABEL_HOME: 'HOME',
    ALT_HOME: 'home',
    LABEL_ESTOQUE: 'INVENTORY',
    ALT_ESTOQUE: 'inventory',
    LABEL_DESEMPENHO: 'PERFORMANCE - Cut',
    ALT_DESEMPENHO: 'performance - corte',
    LABEL_CUSTO: 'VALUE',
    ALT_CUSTO: 'value',
    LABEL_TCO: 'TCO',
    ALT_TCO: 'tco',
    LABEL_LOGOUT: 'LOGOUT',
    ALT_LOGOUT: 'logout',
  },
}

export default En
