import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

export const materialTableStyle = {
  color: COR_PRIMARIA,
  boxShadow: 'none',
}

const ExpansionPanel = withStyles({
  root: {
    margin: 0,
    textAlign: 'left',
    padding: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
      textAlign: 'left',
      padding: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanel)

export const ExpansionPanelSummary = withStyles({
  root: {
    margin: 0,
    textAlign: 'left',
    padding: 0,
    '&$expanded': {
      margin: 0,
      textAlign: 'left',
      padding: 0,
    },
  },
  content: {
    '&$expanded': {

    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

export const ExpansionPanelDetails = withStyles(() => ({
  root: {
    margin: 0,
    textAlign: 'left',
    padding: 0,
  },
}))(MuiExpansionPanelDetails)

export const tabelaConfig = makeStyles({
  titulo: {
    fontSize: '1.25rem',
    color: 'rgb(0, 64, 112)',
    cursor: 'pointer',
  },
})

export default ExpansionPanel
