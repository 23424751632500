import React from 'react'
import Spinner from 'react-spinkit'
import { Paper } from '@material-ui/core'
import propsLoading, { valueLoading } from '../LoadingPropTypes'
import { COR_PRIMARIA } from '../../utils'

const LoadingTabela = ({ loading, message, elevation }) => (loading ? (
  <Paper elevation={elevation} style={{ height: '400px' }}>
    <div className="grid-container-table">
      <div className="spinner">
        <Spinner
          name="line-spin-fade-loader"
          fadeIn="none"
          color={COR_PRIMARIA}
        />
      </div>
      <div className="label">
        {message}
      </div>
    </div>
  </Paper>
) : null)

LoadingTabela.propTypes = propsLoading
LoadingTabela.defaultProps = valueLoading

export default LoadingTabela
