import {
  ConectarApiPost, ConectarApiGet, ENDPOINT_USUARIOS, ENDPOINT_PERFIL, ENDPOINT_RESETAR_SENHA,
  ConectarApiPut, ENDPOINT_BUSCAR_USUARIOS,
} from '../utils'
import { ConectarApiDelete } from '../utils/ConectarApi'
import CadastroUsuariosRequisicao from './CadastroUsuariosRequisicao'

export default async function getUsuariosCadastrados(filtro) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPost(ENDPOINT_BUSCAR_USUARIOS, CadastroUsuariosRequisicao(filtro))

    return resultado.map(u => ({ ...u, codPerfil: u.perfil.codPerfil }))
  } catch (err) {
    return ['Erro', err]
  }
}

export async function getPerfisCadastrados(filtro) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPost(ENDPOINT_PERFIL, CadastroUsuariosRequisicao(filtro))

    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function resetarSenhaUsuario(usuario) {
  try {
    const resposta = await ConectarApiGet(ENDPOINT_RESETAR_SENHA + usuario)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function criarUsuario(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_USUARIOS, objeto)
    let { resultado } = resposta.data
    resultado = { ...resultado, codPerfil: resultado.perfil.codPerfil }
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function editarUsuarioInformado(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_USUARIOS, objeto)
    let { resultado } = resposta.data
    resultado = { ...resultado, codPerfil: resultado.perfil.codPerfil }
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function deletarUsuarioInformado(usuario) {
  try {
    const resposta = await ConectarApiDelete(`${ENDPOINT_USUARIOS}/${usuario}`)
    return resposta.data.resultado
  } catch (err) {
    return ['Erro', err]
  }
}
