// negócio
export const NOME_APP = 'Agile Rolls'
export const NOME_EMPRESA = 'Gerdau Summit'
export const INTERVALO_0_A_25 = 'de0a25'
export const INTERVALO_25_A_50 = 'de25a50'
export const INTERVALO_50_A_75 = 'de50a75'
export const INTERVALO_75_A_100 = 'de75a100'
export const UNIDADE_MEDIDA_PADRAO_CORTE = 'mm'

// estilo
export const COR_FUNDO = '#edf0f4'
export const COR_PRIMARIA = '#004070'

// segurança
export const AUTHORIZATION_KEY = process.env.REACT_APP_AUTHORIZATION_KEY
export const UM_SEGUNDO_EM_MILISSEGUNDOS = 1000
export const UM_MINUTO_EM_MILISSEGUNDOS = 60 * UM_SEGUNDO_EM_MILISSEGUNDOS
export const AUTHORIZATION_TOKEN_VALIDITY_TIME = UM_MINUTO_EM_MILISSEGUNDOS * 39
export const AUTHORIZATION_TOKEN_TIME_TO_REFRESH = UM_MINUTO_EM_MILISSEGUNDOS
export const PERMISSOES_BASICAS = 'PERMISSOES_BASICAS'
export const KEY_PERMISSAO_TROCAR_SENHA = 'KEY_PERMISSAO_TROCAR_SENHA'
export const KEY_PERMISSAO_INFORMATIVO = 'KEY_PERMISSAO_INFORMATIVO'
export const KEY_PAGINA_HOME_GRAFICO_EQUIVALENTE = 'KEY_PAGINA_HOME_GRAFICO_EQUIVALENTE'
export const KEY_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA = 'KEY_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA'
export const KEY_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO = 'KEY_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO'

// configurações da API
export const API_HOST_URL = process.env.REACT_APP_HOST_URL
export const API_PREFIX_URL = process.env.REACT_APP_API_PREFIX_URL
export const API_URL = API_HOST_URL + API_PREFIX_URL
export const ENDPOINT_LOGIN = `${API_URL}login`
export const ENDPOINT_FILTRO = 'filtro/buscarFiltro'
export const ENDPOINT_GRAFICO_DIAMETRO = 'diametros'
export const ENDPOINT_GRAFICO_SITUACAO = 'estoqueSituacao'
export const ENDPOINT_GRAFICO_EQUIVALENTE = 'equivalenteNovos'
export const ENDPOINT_GRAFICO_CORTE_TOTAL = 'corteTotal'
export const ENDPOINT_GRAFICO_DIAMETRO_TOTAL = 'diametroTotal'
export const ENDPOINT_USUARIOS_USERNAME = 'usuarios/buscarDadosUsuarioPorLogin/'
export const ENDPOINT_RESETAR_SENHA = 'usuarios/resetarSenha/'
export const ENDPOINT_ESTOQUE_MINIMO = 'estoqueMinimo'
export const ENDPOINT_ATUALIZAR_TOKEN = 'auth/atualizarToken'
export const ENDPOINT_ALTERAR_SENHA = 'auth/alterarSenha'
export const ENDPOINT_DESEMPENHO_CONSUMO_AGRUPADO = 'desempenhoConsumo/agrupado'
export const ENDPOINT_DESEMPENHO_CONSUMO_INDIVIDUAL = 'desempenhoConsumo/individual'
export const ENDPOINT_INFORMATIVO = 'informativo'
export const ENDPOINT_TONELAGEM_LAMINADA = 'tonelagemLaminada'
export const ENDPOINT_QUILOMETRAGEM_LAMINADA = 'quilometragemLaminada'
export const ENDPOINT_ANALISE_CORTE = 'analiseCorte'
export const ENDPOINT_PADRAO_CORTE = 'padraoCorte'
export const ENDPOINT_PREVISAO_CHEGADA_CILINDROS = 'previsaoChegadaCilindro'
export const ENDPOINT_RITMO_CHEGADA_CILINDRO = 'ritmoChegadaCilindro'
export const ENDPOINT_REAIS_POR_MM = 'custoRelativo/reaisPorMm'
export const ENDPOINT_REAIS_POR_TONELADA = 'custoRelativo/reaisPorTonelada'
export const ENDPOINT_CILINDRO = 'cilindro'
export const ENDPOINT_USUARIOS = 'usuarios'
export const ENDPOINT_BUSCAR_USUARIOS = 'usuarios/buscar'
export const ENDPOINT_PRODUCAO_PREVISTA_REAL = 'producaoPrevistaReal'
export const ENDPOINT_CONSUMO_PREVISTO_REAL = 'consumoPrevistoReal'
export const ENDPOINT_RECEBIMENTO_CILINDROS_PREVISTO_REAL = 'recebimentoCilindrosPrevistoReal'
export const ENDPOINT_CILINDROS_EQUIVALENTES_PREVISTO_REAL = 'equivalenteNovosPrevistoReal'
export const ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCA = 'previsaoChegadaCilindro/buscarRegistros'
export const ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCAR_POR_CLASSE_E_ANOS = 'previsaoChegadaCilindro/buscarPorClasseEAnos'
export const ENDPOINT_PREVISAO_PRODUCAO = 'previsaoProducao'
export const ENDPOINT_PREVISAO_CONSUMO = 'previsaoConsumo'
export const ENDPOINT_PREVISAO_PRODUCAO_BUSCA = 'previsaoProducao/buscarRegistros'
export const ENDPOINT_PREVISAO_PRODUCAO_BUSCAR_POR_CLASSE_E_ANOS = 'previsaoProducao/buscarPorClasseEAnos'
export const ENDPOINT_PREVISAO_CONSUMO_BUSCA = 'previsaoConsumo/buscarRegistros'
export const ENDPOINT_PREVISAO_CONSUMO_BUSCAR_POR_CLASSE_E_ANOS = 'previsaoConsumo/buscarPorClasseEAnos'
export const ENDPOINT_PREVISAO_CUSTO_RELATIVO = 'previsaoCustoRelativo'
export const ENDPOINT_CORTE_PREVISAO_REAL = 'cortePrevistoReal'
export const ENDPOINT_ESTOQUE_PREVISAO_REAL = 'estoquePrevistoReal'
export const ENDPOINT_PREVISAO_ESTOQUE_CILINDRO = 'previsaoEstoqueCilindro'
export const ENDPOINT_MINIMO_OPERACIONAL = 'minimoOperacional'
export const ENDPOINT_MINIMO_OPERACIONAL_BUSCAR_TODOS = 'minimoOperacional/buscarTodos'
export const ENDPOINT_PERFIL = 'perfil'
export const ENDPOINT_PAGINA_HOME_GRAFICO_EQUIVALENTE = 'home/equivalenteNovos'
export const ENDPOINT_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA = 'home/custoRelativo/reaisPorTonelada'
export const ENDPOINT_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO = 'home/desempenhoConsumo/agrupado'
export const ENDPOINT_TCO = 'tco'

// exceções
export const EXCEPTION_PREFIX = '[Exceção] Erro:'
export const ARGS_OBRIGATORIOS = 'Argumentos obrigatórios!'

// estrutural
export const KEY_PAGINA_HOME = 'paginaHome'
export const KEY_PAGINA_DESEMPENHO_INDIVIDUAL = 'paginaDesempenhoIndividual'
export const KEY_PAGINA_DESEMPENHO_AGRUPADO = 'paginaDesempenhoAgrupado'
export const KEY_PAGINA_DESEMPENHO_DISCRETIZADO = 'paginaDesempenhoDiscretizado'
export const KEY_PAGINA_CONFIGURACOES = 'paginaConfiguracoes'
export const KEY_PAGINA_ESTOQUE_CORRENTE = 'paginaEstoque'
export const KEY_PAGINA_ESTOQUE_HISTORICO = 'paginaEstoqueHistorico'
export const KEY_PAGINA_CUSTO_RELATIVO = 'paginaCustoRelativo'
export const KEY_PAGINA_CUSTO_PREVISAO = 'paginaCustoPrevisao'
export const KEY_PAGINA_CADASTRO_USUARIOS = 'paginaCadastroUsuarios'
export const KEY_PAGINA_ERRO = 'paginaErros'
export const KEY_PAGINA_TCO = 'tco'

export const permissoesPorFuncionalidade = {
  // As permissões abaixo são o mínimo necessário para um usuário
  // poder acessar o sistema.
  [PERMISSOES_BASICAS]: [
    ENDPOINT_USUARIOS_USERNAME,
    ENDPOINT_FILTRO,
    ENDPOINT_ATUALIZAR_TOKEN,
    ENDPOINT_ALTERAR_SENHA,
    ENDPOINT_INFORMATIVO,
    ENDPOINT_PAGINA_HOME_GRAFICO_EQUIVALENTE,
    ENDPOINT_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA,
    ENDPOINT_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO,
  ],
  // Permissões que dão acesso a cada uma das funcionalidades.
  [KEY_PAGINA_HOME]: [],
  [KEY_PAGINA_DESEMPENHO_INDIVIDUAL]: [
    ENDPOINT_DESEMPENHO_CONSUMO_INDIVIDUAL,
    ENDPOINT_TONELAGEM_LAMINADA,
    ENDPOINT_QUILOMETRAGEM_LAMINADA,
    ENDPOINT_GRAFICO_DIAMETRO_TOTAL,
    ENDPOINT_GRAFICO_CORTE_TOTAL,
  ],
  [KEY_PAGINA_DESEMPENHO_AGRUPADO]: [
    ENDPOINT_DESEMPENHO_CONSUMO_AGRUPADO,
  ],
  [KEY_PAGINA_DESEMPENHO_DISCRETIZADO]: [
    ENDPOINT_ANALISE_CORTE,
  ],
  [KEY_PAGINA_CONFIGURACOES]: [
    ENDPOINT_ESTOQUE_MINIMO,
    ENDPOINT_PADRAO_CORTE,
    ENDPOINT_PREVISAO_CHEGADA_CILINDROS,
    ENDPOINT_CILINDRO,
    ENDPOINT_PREVISAO_CUSTO_RELATIVO,
  ],
  [KEY_PAGINA_ESTOQUE_CORRENTE]: [
    ENDPOINT_GRAFICO_DIAMETRO,
    ENDPOINT_GRAFICO_SITUACAO,
  ],
  [KEY_PAGINA_ESTOQUE_HISTORICO]: [
    ENDPOINT_GRAFICO_EQUIVALENTE,
    ENDPOINT_RITMO_CHEGADA_CILINDRO,
  ],
  [KEY_PAGINA_CUSTO_RELATIVO]: [
    ENDPOINT_REAIS_POR_MM,
    ENDPOINT_REAIS_POR_TONELADA,
  ],
  [KEY_PAGINA_CUSTO_PREVISAO]: [
    ENDPOINT_PRODUCAO_PREVISTA_REAL,
    ENDPOINT_CORTE_PREVISAO_REAL,
    ENDPOINT_CONSUMO_PREVISTO_REAL,
    ENDPOINT_RECEBIMENTO_CILINDROS_PREVISTO_REAL,
    ENDPOINT_CILINDROS_EQUIVALENTES_PREVISTO_REAL,
    ENDPOINT_ESTOQUE_PREVISAO_REAL,
    ENDPOINT_PREVISAO_ESTOQUE_CILINDRO,
    ENDPOINT_MINIMO_OPERACIONAL_BUSCAR_TODOS,
    ENDPOINT_PREVISAO_PRODUCAO,
    ENDPOINT_PREVISAO_PRODUCAO_BUSCAR_POR_CLASSE_E_ANOS,
    ENDPOINT_PREVISAO_PRODUCAO_BUSCA,
    ENDPOINT_PREVISAO_CONSUMO_BUSCAR_POR_CLASSE_E_ANOS,
    ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCAR_POR_CLASSE_E_ANOS,
    ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCA,
    ENDPOINT_PREVISAO_CHEGADA_CILINDROS,
    ENDPOINT_MINIMO_OPERACIONAL,
    ENDPOINT_PREVISAO_CONSUMO_BUSCA,
    ENDPOINT_PREVISAO_CONSUMO,
  ],
  [KEY_PAGINA_TCO]: [
    ENDPOINT_TCO,
  ],
  [KEY_PAGINA_CADASTRO_USUARIOS]: [
    ENDPOINT_USUARIOS,
    ENDPOINT_PERFIL,
    ENDPOINT_RESETAR_SENHA,
  ],
  [KEY_PAGINA_ERRO]: [],
  // Outras permissões
  [KEY_PERMISSAO_TROCAR_SENHA]: [
    ENDPOINT_ALTERAR_SENHA,
  ],
  [KEY_PERMISSAO_INFORMATIVO]: [
    ENDPOINT_INFORMATIVO,
  ],
  [KEY_PAGINA_HOME_GRAFICO_EQUIVALENTE]: [
    ENDPOINT_PAGINA_HOME_GRAFICO_EQUIVALENTE,
  ],
  [KEY_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA]: [
    ENDPOINT_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA,
  ],
  [KEY_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO]: [
    ENDPOINT_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO,
  ],
}
