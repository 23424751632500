import React from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'
import propsTabela, { valueTabela } from '../GraficoTCOPropTypes'
import { styles } from '../GraficoTCOEstilo'
import {
  formatarNumero, SRCelula, unicoValor, obterQuantidadeFornecedor,
} from '../../utils'

export default function GraficoTCOTabela({ valores }) {
  const { t } = useTranslation()
  const classes = styles()
  const {
    fornecedores, campanhas, consumoTotal, consumoNormal, custoEfetivo,
    pesoProduzido, corteNormal, corteTotal, dados, materiais, reaisMM,
  } = valores
  let fornecedoresUnicos


  if (fornecedores) {
    fornecedoresUnicos = fornecedores.filter(unicoValor)
  }

  const largura = 100 / materiais ? materiais.length : 1

  const labels = {
    fornecedor: t('graficoTCO.graficoTCOTabela.LABEL_FORNECEDOR'),
    material: t('graficoTCO.graficoTCOTabela.LABEL_MATERIAL'),
    campanhas: t('graficoTCO.graficoTCOTabela.LABEL_CAMPANHAS'),
    tonelagem: t('graficoTCO.graficoTCOTabela.LABEL_TONELAGEM'),
    mmTotal: t('graficoTCO.graficoTCOTabela.LABEL_MM_TOTAL'),
    mmNormal: t('graficoTCO.graficoTCOTabela.LABEL_MM_NORMAL'),
    consumoTotal: t('graficoTCO.graficoTCOTabela.LABEL_CONSUMO_TOTAL'),
    consumoNormal: t('graficoTCO.graficoTCOTabela.LABEL_CONSUMO_NORMAL'),
    custo: t('graficoTCO.graficoTCOTabela.LABEL_CUSTO'),
    reaisMM: t('graficoTCO.graficoTCOTabela.LABEL_REAIS_MM'),
    tco: t('graficoTCO.graficoTCOTabela.LABEL_TCO'),
  }

  return (
    <TableContainer component={Paper}>
      <table width="100%" className={classes.borda} key={Math.random()}>
        <tbody>
          <tr>
            <SRCelula negrito percentualLargura={`${largura}%`}>{labels.fornecedor}</SRCelula>
            {fornecedoresUnicos ? fornecedoresUnicos.map(row => (
              <SRCelula
                largura={obterQuantidadeFornecedor(fornecedores, row)}
                percentualLargura={`${largura}%`}
                key={Math.random()}
              >
                {row}
              </SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito percentualLargura={`${largura}%`}>{labels.material}</SRCelula>
            {materiais ? materiais.map(row => (
              <SRCelula negrito percentualLargura={`${largura}%`} key={Math.random()}>{row}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.campanhas}</SRCelula>
            {campanhas ? campanhas.map(row => (
              <SRCelula key={Math.random()}>{row ? formatarNumero(row) : 0}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.tonelagem}</SRCelula>
            {pesoProduzido ? pesoProduzido.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.mmTotal}</SRCelula>
            {corteTotal ? corteTotal.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.mmNormal}</SRCelula>
            {corteNormal ? corteNormal.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.consumoTotal}</SRCelula>
            {consumoTotal ? consumoTotal.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.consumoNormal}</SRCelula>
            {consumoNormal ? consumoNormal.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.custo}</SRCelula>
            {custoEfetivo ? custoEfetivo.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.reaisMM}</SRCelula>
            {reaisMM ? reaisMM.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
          <tr>
            <SRCelula negrito>{labels.tco}</SRCelula>
            {dados ? dados.map(row => (
              <SRCelula key={Math.random()}>{formatarNumero(row, 2)}</SRCelula>
            )) : null}
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

GraficoTCOTabela.propTypes = propsTabela
GraficoTCOTabela.defaultProps = valueTabela
