import axios from 'axios'
import {
  ENDPOINT_LOGIN, ENDPOINT_USUARIOS_USERNAME, ENDPOINT_ATUALIZAR_TOKEN, ENDPOINT_ALTERAR_SENHA,
  PERMISSOES_BASICAS,
} from '../utils/Configuracoes'
import Security from '../utils/Security'
import { ConectarApiGet, ConectarApiPost, ConectarApiPut } from '../utils/ConectarApi'

export const TOKEN_SEM_PERMISSOES_BASICAS = 'SEM_PERMISSOES_BASICAS'

export default async function obterDadosUsuario(usuario) {
  try {
    const resposta = await ConectarApiGet(ENDPOINT_USUARIOS_USERNAME + usuario)
    const {
      nome, trocarSenha, codUsuario, perfil,
    } = resposta.data.resultado
    return {
      nome, trocarSenha, codUsuario, perfil,
    }
  } catch (err) {
    return err
  }
}

export async function DoLogin(usuario, senha) {
  try {
    const resposta = await axios.post(ENDPOINT_LOGIN, { usuario, senha })
    Security.setAuthorizationToken(
      resposta.headers[process.env.REACT_APP_AUTHORIZATION_HEADER_NAME_RESPONSE],
    )
    const dadosUsuario = await obterDadosUsuario(usuario)
    Security.setUserInformation(dadosUsuario)
    if (!Security.checkForPermissions(PERMISSOES_BASICAS)) {
      throw TOKEN_SEM_PERMISSOES_BASICAS
    }
    return [true, dadosUsuario.trocarSenha]
  } catch (err) {
    console.error(err)
    Security.unsetAuthorizationToken()
    return [false, err]
  }
}

export async function refreshToken() {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_ATUALIZAR_TOKEN)
    Security.setAuthorizationToken(
      resposta.headers[process.env.REACT_APP_AUTHORIZATION_HEADER_NAME_RESPONSE],
    )
    return
  } catch (err) {
    console.error(err)
    throw err
  }
}

export function IsLogged() {
  return Security.authorizationTokenExists()
}

export async function alterarSenha(dados) {
  let resposta
  try {
    resposta = await ConectarApiPut(ENDPOINT_ALTERAR_SENHA, dados)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err, resposta]
  }
}
