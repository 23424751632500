
import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../utils'

const estilo = makeStyles({
  root: {
    display: 'block',
  },
  header: {
    display: 'block',
    color: '#434A54',
    marginBottom: 30,
  },
  nomeUsuario: {
    color: COR_PRIMARIA,
  },
})

const HomeEstilo = { estilo }
export default HomeEstilo
