import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  obterNomeMes, SRCelula,
} from '../../utils'
import propsTabela, { valuesTabela } from '../TabelaPrevisaoCompraPropTypes'

const TabelaPrevisaoCompraCabecalho = ({
  item, editavel,
}) => {
  const { t } = useTranslation()
  const meses = {
    jan: t('geral.meses.jan'),
    fev: t('geral.meses.fev'),
    mar: t('geral.meses.mar'),
    abr: t('geral.meses.abr'),
    mai: t('geral.meses.mai'),
    jun: t('geral.meses.jun'),
    jul: t('geral.meses.jul'),
    ago: t('geral.meses.ago'),
    set: t('geral.meses.set'),
    out: t('geral.meses.out'),
    nov: t('geral.meses.nov'),
    dez: t('geral.meses.dez'),
  }

  return (
    <tr>
      <SRCelula percentualLargura="6%" negrito>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ANALISES')}</SRCelula>
      <SRCelula percentualLargura="10%" negrito>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADE')}</SRCelula>
      {item.tonelada.map(tonelada => (
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>{obterNomeMes(tonelada.mes, meses)}</SRCelula>
      ))}
      <SRCelula percentualLargura="6%" negrito>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TOTAL')}</SRCelula>
      {editavel ? (
        <SRCelula percentualLargura="5%" negrito>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR')}</SRCelula>
      ) : null}
    </tr>
  )
}

TabelaPrevisaoCompraCabecalho.propTypes = propsTabela
TabelaPrevisaoCompraCabecalho.defaultProps = valuesTabela

export default TabelaPrevisaoCompraCabecalho
