import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import { AlertaModal } from '../../alerta'
import TabelaPrevisaoCompraGraficoOpcoes from '../TabelaPrevisaoCompraGraficoOpcoes'
import { formatarSeriesGrafico, obterLabels, objGraficoVazio } from '../TabelaPrevisaoCompraUtils'
import { propsGrafico, valueGrafico } from '../TabelaPrevisaoCompraPropTypes'
import { ErroGrafico } from '../../utils'

function TabelaPrevisaoCompraGrafico({ dados, tipo }) {
  const { t } = useTranslation()

  const labels = obterLabels(t, tipo)

  const dadosGrafico = formatarSeriesGrafico(dados, tipo)

  const graficoVazio = objGraficoVazio(dadosGrafico)

  if (graficoVazio) {
    return (
      <ErroGrafico
        mensagem={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_NENHUM_REGISTRO')}
        nome={labels.titulo}
      />
    )
  }

  if (dadosGrafico && dados[0] !== 'Erro') {
    return (
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { height: '410px' } }}
        options={TabelaPrevisaoCompraGraficoOpcoes(dadosGrafico, labels, tipo)}
      />
    )
  }

  return (
    <AlertaModal
      url={t('geral.routes.URL_HOME')}
      mensagem={t('graficoRitmoEstoque.graficoRitmoEstoque.LABEL_ERRO')}
    />
  )
}

TabelaPrevisaoCompraGrafico.propTypes = propsGrafico
TabelaPrevisaoCompraGrafico.defaultProps = valueGrafico

export default TabelaPrevisaoCompraGrafico
