const PtBr = {
  cadastroUsuarios: {
    LABEL_PERFIL_NAO_INFORMADO: 'Perfil não informado',
    LABEL_NOME_NAO_INFORMADO: 'Nome não informado',
    LABEL_USUARIO_NAO_INFORMADO: 'Usuario não informado',
    LABEL_EMAIL_NAO_INFORMADO: 'Email não informado',
    LABEL_EMAIL_ERRADO: 'O valor informado não é um endereço de email válido',
    LABEL_USUARIO_SALVO: 'Usuário salvo com sucesso',
    LABEL_USUARIO_EXCLUIDO: 'Usuário excluído com sucesso',
    LABEL_ERRO: 'Ocorreu um erro ao processar a solicitação, tente novamente',
    LABEL_ERRO_USUARIO_LOGADO: 'Não é possível excluir o próprio usuário que está logado',
    LABEL_PERFIL: 'Tipo de Perfil',
    LABEL_NOME: 'Nome',
    LABEL_USUARIO: 'Usuário',
    LABEL_EMAIL: 'Email',
    LABEL_REDEFINIR_SENHA: 'Redefinir Senha',
    LABEL_CANCELAR: 'Cancelar',
    LABEL_SIM: 'Sim',
    LABEL_TITULO_MODAL: 'REDEFINIR SENHA PARA A PADRÃO',
    LABEL_MSG_MODAL: 'Tem certeza que deseja REDEFINIR a senha atual para a SENHA PADRÃO do sistema?',
    LABEL_TITULO: 'Lista de Usuários',
    LABEL_SENHA_ALTERADA: 'Senha redefinida ao padrão',
  },
}

export default PtBr
