import login from './login.png'

export { default as trabalhoAtivo } from './icons/trabalhoAtivo.svg'
export { default as trabalhoInativo } from './icons/trabalhoInativo.svg'
export { default as circleX } from './icons/circle-x.svg'
export { default as encostoInativo } from './icons/encostoInativo.svg'
export { default as encostoAtivo } from './icons/encostoAtivo.svg'
export { default as redefinirSenha } from './icons/redefinirSenha.svg'
export { default as custo } from './icons/custo.svg'
export { default as desempenho } from './icons/desempenho.svg'
export { default as estoque } from './icons/estoque.svg'
export { default as exportatPDF } from './icons/exportatPDF.svg'
export { default as home } from './icons/home.svg'
export { default as exportar } from './icons/exportar.svg'
export { default as info } from './icons/info.svg'
export { default as sucessoIMG } from './icons/sucesso.svg'
export { default as profile } from './icons/profile.svg'
export { default as logout } from './icons/logout.svg'
export { default as filtroIMG } from './icons/filtroIMG.png'
export { default as logo } from './Logo/logo.svg'
export { default as campanha } from './icons/legendas/campanha.png'
export { default as novo } from './icons/legendas/novo.png'
export { default as operacao } from './icons/legendas/operacao.png'
export { default as reparo } from './icons/legendas/reparo.png'
export { default as retificado } from './icons/legendas/retificado.png'
export { default as range } from './icons/legendas/range.png'
export { default as config } from './icons/config.svg'
export { default as seta } from './icons/seta.svg'
export { default as limpar } from './icons/limpar.svg'
export { default as close } from './icons/close.svg'
export { default as filtro } from './icons/filtro.svg'
export { default as infoCinza } from './icons/infoCinza.svg'
export { default as perfil } from './icons/perfil.svg'
export { default as erro } from './erro.png'
export { default as imgPaginaNaoEncontrada } from './tela_erro_url.png'
export default login
