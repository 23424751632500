const PtBr = {
  GraficoDesempenhoAgrupado: {
    LABEL_DESEMPENHO_TITULO: 'DESEMPENHO AGRUPADO',
    LABEL_DESEMPENHO_VERTICAL: 'Tonelada/mm',
    LABEL_DESEMPENHO_HORIZONTAL: 'Fornecedores',
    LABEL_DESEMPENHO_HORIZONTAL2: 'Materiais',
    LABEL_CONSUMO_TITULO: 'CONSUMO AGRUPADO',
    LABEL_CONSUMO_VERTICAL: 'mm/kt',
    LABEL_CONSUMO_HORIZONTAL: 'Fornecedores',
    LABEL_CONSUMO_HORIZONTAL2: 'Materiais',
    LABEL_TOOLTIP_FORNECEDORES: 'Qtd. Materiais',
    LABEL_TOOLTIP_MATERIAIS: 'Qtd. Campanhas',
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_CONSUMO: 'Consumo',
    LABEL_CAMPANHAS: 'Campanhas',
    LABEL_TOTAL_CAMPANHAS: 'Total de Campanhas =',
    LABEL_NORMAL: 't/mm Normal',
    LABEL_TOTAL: 't/mm Total',
    LABEL_TODOS: 'TODOS',
    LABEL_NORMAL_CONSUMO: 'mm/kt Normal',
    LABEL_NORMAL_CONSUMO_SIMPLIFICADO: 'Normal',
    LABEL_TOTAL_CONSUMO: 'mm/kt Total',
    LABEL_TOTAL_CONSUMO_SIMPLIFICADO: 'Total',
    LABEL_TOTAL_MATERIAIS: 'Total',
    LABEL_ACUMULADO_SIMPLIFICADO: 'Acumulado',
    LABEL_ERRO_DESEMPENHO: 'Houve um erro ao carregar o gráfico de desempenho agrupado. Tente novamente mais tarde.',
    LABEL_ERRO_CONSUMO: 'Houve um erro ao carregar o gráfico de consumo agrupado. Tente novamente mais tarde.',
    LABEL_TITULO_TABELA_DESEMPENHO: 'Tabela de Desempenho Agrupado',
    LABEL_TITULO_TABELA_CONSUMO: 'Tabela de Consumo Agrupado',
    LABEL_MATERIAL: 'Material',
    LABEL_FORNECEDOR: 'Fornecedor',
    LABEL_TONELAGEM: 'Tonelagem',
    LABEL_MM_TOTAL: 'mm Total',
    LABEL_MM_NORMAL: 'mm Normal',
    LABEL_MM: 'mm',
    LABEL_CANCELAR: 'Cancelar',
    LABEL_MILIMETROS: 'Consumo (mm)',
    LABEL_OCORRENCIAS: 'Ocorrências',
    LABEL_QUANTIDADE: 'Quantidade',
    TEXTO_MODAL_EXPORTAR_CONTEUDO: 'Você deseja exportar os gráficos para algum fornecedor específico?',
    TEXTO_SELECIONE_UM_FORNECEDOR: 'Selecione um fornecedor',
  },
}

export default PtBr
