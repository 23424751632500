import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Cabecalho from './LayoutCabecalho'
import Corpo from './LayoutCorpoPadrao'
import Filtro from '../../filtro'
import LayoutEstilo from '../LayoutEstilo'
import { propsPaginaPadrao, valuePaginaPadrao } from '../LayoutPropTypes'
import LayoutModalExpiracaoToken from './LayoutModalExpiracaoToken'
import LayoutProvider from './LayoutProvider'
import { AlertaSnack } from '../../alerta'

const LayoutPaginaPadrao = (props) => {
  const classes = LayoutEstilo()
  const {
    ocultarFiltro, titulo, children, contexto,
  } = props

  return (
    <LayoutProvider contexto={contexto}>
      <>
        <CssBaseline />
        <Cabecalho />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Filtro
              id="filtroPaginaPadrao"
              titulo={titulo}
              ocultarFiltro={ocultarFiltro}
              pageKey={children.key}
            />
          </Container>
          <Corpo
            {...props}
          />
          <LayoutModalExpiracaoToken />
          <AlertaSnack />
        </main>
      </>
    </LayoutProvider>
  )
}

LayoutPaginaPadrao.propTypes = propsPaginaPadrao
LayoutPaginaPadrao.defaultProps = valuePaginaPadrao

export default LayoutPaginaPadrao
