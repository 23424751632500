import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoEquivalenteNovoService from '../GraficoEquivalenteNovoService'
import GraficoEquivalenteNovoOpcoes from '../GraficoEquivalenteNovoOpcoes'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import {
  ErroGrafico, useStateSafely, formatarEstoqueAtual, formatarEstoqueMinimo,
} from '../../utils'
import { FiltroContext } from '../../layout/LayoutContext'

let estoque
let setEstoque
let datas
let setDatas
let estoqueMinimo
let setEstoqueMinimo
let loading
let setLoading

const GraficoEquivalenteNovo = ({ individual }) => {
  [estoque, setEstoque] = useStateSafely([]);
  [datas, setDatas] = useStateSafely([]);
  [estoqueMinimo, setEstoqueMinimo] = useStateSafely([]);
  [loading, setLoading] = useStateSafely(true)
  const { t } = useTranslation()
  const filtro = useContext(FiltroContext)

  useEffect(() => {
    setLoading(true)
    setEstoque([])
    setDatas([])
    setEstoqueMinimo([])
    async function conectar() {
      try {
        const resposta = await GraficoEquivalenteNovoService(filtro)
        setEstoque(formatarEstoqueAtual(resposta.estoque, individual))
        setDatas(resposta.datas)
        setEstoqueMinimo(formatarEstoqueMinimo(resposta.estoqueMinimo, individual))
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [filtro, individual])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (estoque && estoque.length === 0 && datas && datas.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_TITULO_GRAFICO')}
        />
      )
    }
    if (estoque && datas && estoqueMinimo) {
      return (
        <HighchartsReact
          highcharts={Highcharts}
          containerProps={{ style: { height: '410px' } }}
          options={GraficoEquivalenteNovoOpcoes(estoque, datas, estoqueMinimo,
            t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_TITULO_GRAFICO'),
            t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_COLUNA_ESTOQUE_MINIMO'),
            individual ? t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_COLUNA_CILINDROS')
              : t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_COLUNA_EQUIVALENTE'),
            individual ? t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_QTD_CILINDROS')
              : t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_QTD_PARES'))}
        />
      )
    } return (
      <AlertaModal
        url={t('geral.routes.URL_ESTOQUE_CORRENTE')}
        mensagem={t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

export default GraficoEquivalenteNovo
