import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoEstoqueSituacaoRequisicao from './GraficoEstoqueSituacaoRequisicao'
import { ENDPOINT_GRAFICO_SITUACAO } from '../utils/Configuracoes'
import { corGraficoSituacao, zipArray, ordenaCilindros } from '../utils'

export default async function GraficoEstoqueSituacaoService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_GRAFICO_SITUACAO,
      GraficoEstoqueSituacaoRequisicao(filtro))
    const { resultado } = resposta.data
    const resultadoOrdenado = ordenaCilindros(resultado)
    const quantidade = resultadoOrdenado.map(item => item.qtdCilindros)
    const codSituacao = resultadoOrdenado.map(item => item.codSituacao)
    const numCilindros = resultadoOrdenado.map(item => item.numCilindros.sort())
    const cor = corGraficoSituacao(codSituacao)
    const dados = zipArray(quantidade, cor, 'y', 'color')
    return { dados, codSituacao, numCilindros }
  } catch (err) {
    return ['Erro', err]
  }
}
