import React from 'react'
import LayoutContentList from './LayoutContentList'
import CadastroUsuarios from '../../cadastroUsuarios'

const LayoutPerfil = () => (
  <LayoutContentList>
    <CadastroUsuarios key="CadastroUsuarios" />
  </LayoutContentList>
)

export default LayoutPerfil
