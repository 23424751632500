import React from 'react'
import { useTranslation } from 'react-i18next'
import LayoutCardRangeData from './LayoutCardRangeData'
import GraficoCustoPorTonelada from '../../graficoCustoPorTonelada'
import {
  propsLayoutCustoRelativoHome,
  valueLayoutCustoRelativoHome,
} from '../LayoutPropTypes'
import { useStateSafely } from '../../utils'

function LayoutCustoRelativoHome({ filtro, siglaLaminador }) {
  const { t } = useTranslation()
  const [filtroAnual, setFiltroAnual] = useStateSafely(false)

  return (
    <LayoutCardRangeData
      titulo={`${t('graficoCustoPorTonelada.graficoCustoPorTonelada.LABEL_TITULO')} - ${siglaLaminador}`}
      filtro={filtro}
      marcarFiltroComoAnual={setFiltroAnual}
    >
      <GraficoCustoPorTonelada
        formatacao={{ customResizeValue: 250, tituloComNegrito: false }}
        graficoSimplificado
        subtitulo={t('config.config.LABEL_WORK_ROLL')}
        filtroAnual={filtroAnual}
      />
    </LayoutCardRangeData>
  )
}

LayoutCustoRelativoHome.propTypes = propsLayoutCustoRelativoHome
LayoutCustoRelativoHome.defaultProps = valueLayoutCustoRelativoHome

export default LayoutCustoRelativoHome
