import { useEffect, useState, useRef } from 'react'

export default function UseStateSafely(initialValue) {
  const [value, setValue] = useState(initialValue)
  const isSafe = useRef(true)

  useEffect(() => () => {
    isSafe.current = false
  }, [])

  const setValueSafely = (newValue) => {
    if (isSafe.current) {
      setValue(newValue)
    }
  }

  return [value, setValueSafely]
}
