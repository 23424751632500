import { formatarNumero } from '../utils/Functions'

export function formatarDadosCustoRelativo(dados) {
  if (!dados) {
    return null
  }
  const datas = dados.map(item => item.data)
  const custoEfetivo = dados.map(item => item.custoEfetivo)
  const custoPorToneladaReal = dados.map(item => item.custoPorToneladaReal)
  const custoPorToneladaPrevista = dados.map(item => item.custoPorToneladaPrevista)
  const pesoProduzido = dados.map(item => item.pesoProduzido)
  return {
    datas, custoEfetivo, custoPorToneladaReal, custoPorToneladaPrevista, pesoProduzido,
  }
}

function formatarTooltip(ponto, dados, labels) {
  if (!ponto || !dados || !labels) {
    return false
  }
  const posicao = ponto.point.index
  if (labels.previsto !== ponto.point.series.name) {
    return `${labels.custoRelativo}: ${formatarNumero(ponto.y, 2)} ${labels.quantidade}
    <br>${labels.custoEfetivo} R$ ${formatarNumero(dados.custoEfetivo[posicao], 2)}
    <br>${labels.peso} ${formatarNumero(dados.pesoProduzido[posicao])}${labels.tonelada}`
  } return `${labels.custoRelativo}: ${formatarNumero(ponto.y, 2)} ${labels.quantidade}`
}

export default formatarTooltip
