import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Botao from './Botao'
import { propsModal, valuesModal } from '../UtilsPropTypes'
import { styleDialogTitle, styleDialogContent } from '../UtilsEstilo'

const DialogTitle = withStyles(styleDialogTitle)((props) => {
  const {
    children, classes, onClose,
  } = props
  return (
    <MuiDialogTitle disableTypography className={classes.header}>
      <div className={props.classes.typography}>{children}</div>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(styleDialogContent)(props => (
  <div className={props.classes.root}>
    {props.img ? <div>{props.img}</div> : ''}
    {props.header ? <div className={props.classes.header}>{props.header}</div> : ''}
    {props.content ? typeof props.content === 'string'
      ? (
        <>
          <div className={props.classes.contentSenha}>
            <br />
            {props.content}
          </div>
          <br />
        </>
      )
      : (
        <div className={props.classes.content}>
          {props.content}
        </div>
      )
      : ''}
  </div>
))

export default function Modal({
  handleClose, modalIsOpen, handleConfirm, enableButton, title, header, content, icon, buttonText,
  maxWidth, onExited, cancelar,
}) {
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalIsOpen}
        maxWidth={maxWidth}
        onExited={onExited}
        fullWidth
      >
        <DialogTitle onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent
          img={icon ? <img src={icon} alt="" /> : ''}
          header={header}
          content={content}
          dividers
        />
        <DialogActions>
          <Grid container justify={cancelar ? 'flex-end' : 'center'} spacing={1}>
            {cancelar ? (
              <Grid item>
                <Botao
                  inverse
                  action={handleClose}
                  esconder={enableButton}
                  nome={cancelar}
                  tamanho="small"
                />
              </Grid>
            ) : null}
            <Grid item>
              <Botao
                action={handleConfirm}
                esconder={enableButton}
                nome={buttonText}
                tamanho="small"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

Modal.propTypes = propsModal

Modal.defaultProps = valuesModal
