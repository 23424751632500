import * as Utils from '../utils'
import * as ConfigService from './ConfigService'
import { publicar as ExibirAlerta } from '../utils/FilaDeMensagens'
/* eslint-disable import/no-self-import */
import * as ConfigUtils from './ConfigUtils'

const STR_PROBLEMA_COM_DADOS_ENTRADA = 'Problema com os dados de entrada'
const { anoAtual, anoSubsequente } = Utils.obterAnosProximos()

export function getColunaLaminador({ lblLaminador }, laminadores, headerStyle, cellStyle, prevPrd) {
  return {
    title: lblLaminador || 'lblLaminador',
    field: 'codLaminador',
    editable: prevPrd ? 'never' : 'always',
    initialEditValue: laminadores.length === 1 ? laminadores[0].codLaminador : undefined,
    lookup: Utils.objetoFormatado(laminadores, 'codLaminador', 'siglaLaminador'),
    headerStyle,
    cellStyle,
  }
}

export function getColunaTipoCilindro({ lblTipo, lblBkpRoll, lblWorkRoll },
  headerStyle, cellStyle, valorSelecionado) {
  return {
    title: lblTipo || 'lblTipo',
    field: 'codTipoCilindro',
    initialEditValue: valorSelecionado || undefined,
    lookup: {
      1: lblBkpRoll || 'Bur',
      2: lblWorkRoll || 'Wr',
    },
    editable: valorSelecionado ? 'never' : 'always',
    headerStyle,
    cellStyle,
  }
}

export function getColunaAno({ lblAno }, headerStyle, cellStyle, customSort, anoUnico) {
  return {
    title: lblAno || 'lblAno',
    field: 'ano',
    initialEditValue: anoUnico ? anoUnico[Object.keys(anoUnico)[0]] : anoAtual,
    defaultSort: anoUnico ? undefined : 'desc',
    editable: anoUnico ? 'never' : 'always',
    lookup: anoUnico || Utils.montarObjetoAnos(parseInt(Utils.LocalStorageProxy.getItem('AnoRetificaInicial'), 10), anoSubsequente),
    headerStyle,
    cellStyle,
    customSort,
  }
}

export function getColunaMes({ lblMes, lblMeses }, headerStyle, cellStyle, prevPrd, mesUnico) {
  return {
    title: lblMes || 'lblMes',
    field: 'mes',
    defaultSort: prevPrd ? undefined : 'desc',
    initialEditValue: mesUnico || ((new Date()).getMonth() + 1),
    editable: mesUnico ? 'never' : 'always',
    lookup: lblMeses || { 1: 'JAN' },
    headerStyle,
    cellStyle,
  }
}

export function getColunaValor({ lblValor, lblUnidadeDeMedida }, headerStyle, cellStyle) {
  return {
    title: lblValor || 'lblValor',
    field: 'valor',
    render: linha => (lblUnidadeDeMedida ? `${ConfigUtils.formatarValor(linha.valor)} ${lblUnidadeDeMedida}`
      : ConfigUtils.formatarValor(linha.valor)),
    headerStyle,
    cellStyle,
  }
}


export function getColunaSimulado({ lblValor, lblUnidadeDeMedida }, headerStyle, cellStyle) {
  return {
    title: lblValor || 'lblValor',
    field: 'simulado',
    render: linha => (lblUnidadeDeMedida ? `${ConfigUtils.formatarValor(linha.simulado)} ${lblUnidadeDeMedida}`
      : ConfigUtils.formatarValor(linha.simulado)),
    headerStyle,
    cellStyle,
  }
}

export function formatarValor(valor, lingua = 'pt-BR') {
  return (typeof valor === 'number') ? valor.toLocaleString(lingua) : '-'
}

export function getColunasFormatadas(laminadores, labels) {
  return [
    getColunaLaminador(labels, laminadores, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }),
    getColunaTipoCilindro(labels, { textAlign: 'left' }, { textAlign: 'left' }),
    getColunaAno(labels, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }),
    getColunaValor(labels, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }),
  ]
}

export function comparadorParaOrdenarPorAnoEMes(a, b) {
  if (a.ano === b.ano) return a.mes - b.mes
  return a.ano - b.ano
}

export function getColunasFormatadasPrevisaoChegadaCilindros(laminadores, labels, simulador,
  tipoCilindro, ano) {
  return [
    getColunaLaminador(labels, laminadores, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, simulador),
    getColunaTipoCilindro(labels, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }, tipoCilindro),
    getColunaAno(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, comparadorParaOrdenarPorAnoEMes, ano),
    getColunaMes(labels, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }, simulador),
    simulador ? getColunaSimulado(labels, { textAlign: 'left' }, { textAlign: 'left' })
      : getColunaValor(labels, { textAlign: 'left' }, { textAlign: 'left' }),
  ]
}

export function getColunasFormatadasCadastroIdSAP(laminadores, labels) {
  const {
    lblNumeroCilindroOCP, lblIdentificadorCilindroSAP,
  } = labels
  return [
    ConfigUtils.getColunaLaminador(labels, laminadores, { width: '15%', textAlign: 'left' }, { width: '15%', textAlign: 'left' }),
    ConfigUtils.getColunaTipoCilindro(labels, { width: '20%', textAlign: 'left' }, { width: '20%', textAlign: 'left' }),
    {
      title: lblNumeroCilindroOCP,
      field: 'numCilindro',
      defaultSort: 'asc',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
    },
    {
      title: lblIdentificadorCilindroSAP,
      field: 'numeroNotaFiscal',
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
    },
  ]
}

export function getColunasFormatadasCadastroPrevisaoCustoRelativo(laminadores, labels) {
  return ConfigUtils.getColunasFormatadasPrevisaoChegadaCilindros(laminadores, labels)
}

export const opcoes = {
  actionsHeaderStyle: { width: '10%', textAlign: 'left', alignItems: 'left' },
  addRowPosition: 'first',
  pageSizeOptions: [],
  searchFieldStyle: { marginRight: '0px', paddingRight: '0px' },
  actionsColumnIndex: -1,
}

export const opcoesPrevisaoChegadaCilindros = {
  actionsHeaderStyle: { width: '10%', textAlign: 'left', alignItems: 'left' },
  addRowPosition: 'first',
  pageSizeOptions: [],
  actionsColumnIndex: -1,
  pageSize: 6,
  search: false,
}

export function validarInsercaoPrevisaoChegada(newData, msgValor, nroMaximoCasasDecimais, msgTipo,
  msgValorErrado, msgDecimal, msgLaminador, simulador) {
  if (newData.codLaminador === '' || !newData.codLaminador) {
    ExibirAlerta({ text: msgLaminador, type: 'error' })
    return false
  }
  if (newData.codTipoCilindro === '' || !newData.codTipoCilindro) {
    ExibirAlerta({ text: msgTipo, type: 'error' })
    return false
  }
  if (simulador) {
    if (newData.simulado === null) return true
    if (newData.simulado === undefined) return true
    if (newData.simulado === '') return true
    if (typeof newData.simulado === 'string' && !Utils.verificaStringDeNumeroPtBr(newData.simulado)) {
      ExibirAlerta({ text: msgValorErrado, type: 'error', esquerda: true })
      return false
    }
    if (!Utils.isNumberPositive(newData.simulado.replace('.', '').replace(',', '.'))) {
      ExibirAlerta({ text: msgValorErrado, type: 'error', esquerda: true })
      return false
    }
    if (Utils.contarDecimais(parseFloat(newData.simulado.replace('.', '').replace(',', '.'))) > nroMaximoCasasDecimais) {
      ExibirAlerta({ text: msgDecimal, type: 'error', esquerda: true })
      return false
    }
  } else {
    if (newData.valor === '' || !newData.valor) {
      ExibirAlerta({ text: msgValor, type: 'error' })
      return false
    }
    if (!Utils.isNumberPositive(newData.valor.replace('.', '').replace(',', '.'))) {
      ExibirAlerta({ text: msgValorErrado, type: 'error' })
      return false
    }
    if (Utils.contarDecimais(parseFloat(newData.valor.replace('.', '').replace(',', '.'))) > nroMaximoCasasDecimais) {
      ExibirAlerta({ text: msgDecimal, type: 'error' })
      return false
    }
  }
  return true
}

export default function inserirRegistro(newData, reject, resolve,
  dados, setDados, msgLaminador, msgTipo, msgValor, msgValorErrado, msgSucesso,
  msgDecimal, inserirHandle, simulador, setEditado, nroMaximoCasasDecimais = 1) {
  if (!validarInsercaoPrevisaoChegada(newData, msgValor, nroMaximoCasasDecimais, msgTipo,
    msgValorErrado, msgDecimal, msgLaminador, simulador)) {
    reject('Erro ao gravar registro')
  } else {
    setTimeout(async () => {
      let novo = {}
      if (simulador) {
        novo = {
          ...newData,
          simulado: (
            newData.simulado === null
            || newData.simulado === undefined
            || newData.simulado === ''
          ) ? null : Number(newData.simulado.replace('.', '').replace(',', '.')),
        }
      } else {
        novo = { ...newData, valor: Number(newData.valor.replace('.', '').replace(',', '.')) }
      }
      try {
        const novoItem = await inserirHandle(novo)
        if (novoItem.ano) {
          const data = [...dados]
          data.push(novoItem)
          setDados(data)
          if (simulador) {
            setEditado(true)
            ExibirAlerta({ text: msgSucesso, type: 'success', esquerda: true })
          } else {
            ExibirAlerta({ text: msgSucesso, type: 'success' })
          }
          resolve()
        } else {
          reject('Erro ao gravar registro')
        }
      } catch (err) {
        reject(err)
      }
    }, 500)
  }
}

export function validarEdicaoPrevisaoChegadaCilindros(newData, nroMaximoCasasDecimais, simulador,
  msgValor, msgValorErrado, msgDecimal) {
  if (simulador) {
    if (newData.simulado === null) return true
    if (newData.simulado === undefined) return true
    if (newData.simulado === '') return true
    if (typeof newData.simulado === 'string') {
      if (!Utils.verificaStringDeNumeroPtBr(newData.simulado)
      || !Utils.isNumberPositive(newData.simulado.replace('.', '').replace(',', '.'))) {
        ExibirAlerta({ text: msgValorErrado, type: 'error', esquerda: true })
        return false
      }
    }
    if ((Utils.contarDecimais(parseFloat(newData.simulado)) > nroMaximoCasasDecimais
    || (typeof newData.simulado === 'string' && Utils.contarDecimais(parseFloat(newData.simulado.replace('.', '').replace(',', '.'))) > nroMaximoCasasDecimais))) {
      ExibirAlerta({ text: msgDecimal, type: 'error', esquerda: true })
      return false
    }
  } else {
    if (newData.valor === '' || !newData.valor) {
      ExibirAlerta({ text: msgValor, type: 'error' })
      return false
    }
    if (typeof newData.valor === 'string' && !Utils.isNumberPositive(newData.valor.replace('.', '').replace(',', '.'))) {
      ExibirAlerta({ text: msgValorErrado, type: 'error' })
      return false
    }
    if ((Utils.contarDecimais(parseFloat(newData.valor)) > nroMaximoCasasDecimais
  || (typeof newData.valor === 'string' && Utils.contarDecimais(parseFloat(newData.valor.replace('.', '').replace(',', '.'))) > nroMaximoCasasDecimais))) {
      ExibirAlerta({ text: msgDecimal, type: 'error' })
      return false
    }
  }
  return true
}
export function editarRegistro(newData, reject, resolve, dados, setDados, oldData,
  msgValor, msgValorErrado, msgSucesso, msgDecimal, editarHandle, simulador, setEditado,
  nroMaximoCasasDecimais = 1) {
  if (!validarEdicaoPrevisaoChegadaCilindros(newData, nroMaximoCasasDecimais, simulador,
    msgValor, msgValorErrado, msgDecimal)) {
    reject('Erro ao gravar registro')
  } else {
    setTimeout(async () => {
      let novo
      if (!simulador && typeof newData.valor === 'string') {
        novo = { ...newData, valor: Number(newData.valor.replace('.', '').replace(',', '.')) }
      } else if (simulador && typeof newData.simulado === 'string') {
        novo = { ...newData, simulado: !newData.simulado ? null : Number(newData.simulado.replace('.', '').replace(',', '.')) }
      } else if (simulador && (newData.simulado === null || newData.simulado === undefined)) {
        novo = { ...newData, simulado: null }
      } else {
        novo = newData
      }
      try {
        const novoItem = await editarHandle(novo)
        if (novoItem.ano) {
          const data = [...dados]
          data[data.indexOf(oldData)] = novoItem
          setDados(data)
          if (simulador) {
            setEditado(true)
            ExibirAlerta({ text: msgSucesso, type: 'success', esquerda: true })
          } else {
            ExibirAlerta({ text: msgSucesso, type: 'success' })
          }
          resolve()
        } else {
          reject('Erro ao gravar registro')
        }
      } catch (err) {
        reject(err)
      }
    }, 500)
  }
}

export function validaIdentificadorCilindroSAP(value, {
  msgIdentificadorVazio,
}) {
  if (!value || value.length === 0) {
    ExibirAlerta({ text: msgIdentificadorVazio, type: 'error' })
    return false
  }
  return true
}

export function validaInformacoesDoCilindro(dados, cilindros, {
  msgCilindroVazio, msgCilindroNaoExistente, msgCilindroTipoCilindroErrado,
  msgCilindroLaminadorErrado, msgTipoCilindroVazio, msgLaminadorVazio,
}) {
  if (!dados.codLaminador || dados.codLaminador === '') {
    ExibirAlerta({ text: msgLaminadorVazio, type: 'error' })
    return false
  }
  if (!dados.codTipoCilindro || dados.codTipoCilindro === '') {
    ExibirAlerta({ text: msgTipoCilindroVazio, type: 'error' })
    return false
  }
  if (!dados.numCilindro || dados.numCilindro.length === 0) {
    ExibirAlerta({ text: msgCilindroVazio, type: 'error' })
    return false
  }
  const cilindro = cilindros.find(c => c.numCilindro === dados.numCilindro)
  if (!cilindro) {
    ExibirAlerta({ text: msgCilindroNaoExistente, type: 'error' })
    return false
  }
  if (cilindro.codTipoCilindro !== +dados.codTipoCilindro) {
    ExibirAlerta({ text: msgCilindroTipoCilindroErrado, type: 'error' })
    return false
  }
  if (cilindro.codLaminador !== +dados.codLaminador) {
    ExibirAlerta({ text: msgCilindroLaminadorErrado, type: 'error' })
    return false
  }
  return cilindro
}

export function validaDadosIdentificadorCilindroSAP(dados, cilindros, msgs) {
  const cilindro = ConfigUtils.validaInformacoesDoCilindro(dados, cilindros, msgs)
  if (!cilindro) {
    return false
  }
  if (!ConfigUtils.validaIdentificadorCilindroSAP(dados.numeroNotaFiscal, msgs)) {
    return false
  }
  return cilindro
}

export function inserirRegistroIdentificadorCilindroSAP(newData, dados, setDados,
  msgs, inserirHandle, cilindros) {
  return ConfigUtils.editarRegistroIdentificadorCilindroSAP(newData, dados, setDados,
    msgs, inserirHandle, cilindros)
}

export function updateDadosIdentificadorSAP(dados, cilindro) {
  const linhas = [...dados]
  const novaLinha = {
    codCilindro: cilindro.codCilindro,
    numCilindro: cilindro.numCilindro,
    codTipoCilindro: cilindro.classeCilindro.tipoCilindro.wr ? 2 : 1,
    codLaminador: cilindro.classeCilindro.laminador.codLaminador,
    numeroNotaFiscal: cilindro.numeroNotaFiscal,
  }
  const index = linhas.findIndex(c => c.codCilindro === cilindro.codCilindro)
  if (index < 0) linhas.push(novaLinha)
  else linhas[index] = novaLinha
  return linhas
}

export function editarRegistroIdentificadorCilindroSAP(newData, dados, setDados,
  msgs, editarHandle, cilindros) {
  return new Promise(async (resolve, reject) => {
    const cilindro = ConfigUtils.validaDadosIdentificadorCilindroSAP(newData, cilindros, msgs)
    if (!cilindro) return reject(STR_PROBLEMA_COM_DADOS_ENTRADA)
    try {
      const resp = await editarHandle(
        { codCilindro: cilindro.codCilindro, numeroNotaFiscal: newData.numeroNotaFiscal },
      )
      setDados(ConfigUtils.updateDadosIdentificadorSAP(dados, resp))
      ExibirAlerta({ text: msgs.msgSucesso, type: 'success' })
      return resolve(resp)
    } catch (err) {
      console.log(err)
      return reject(err)
    }
  })
}

export function stringsConfigPrevisaoCustoRelativo(t) {
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('config.config.LABEL_TIPO_CILINDRO'),
    lblAno: t('config.config.LABEL_ANO'),
    lblMes: t('config.config.LABEL_MES'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblValor: t('config.config.LABEL_PREVISAO_DE_CUSTO_RELATIVO'),
    lblMeses: {
      1: t('config.config.LABEL_MES_JAN'),
      2: t('config.config.LABEL_MES_FEV'),
      3: t('config.config.LABEL_MES_MAR'),
      4: t('config.config.LABEL_MES_ABR'),
      5: t('config.config.LABEL_MES_MAI'),
      6: t('config.config.LABEL_MES_JUN'),
      7: t('config.config.LABEL_MES_JUL'),
      8: t('config.config.LABEL_MES_AGO'),
      9: t('config.config.LABEL_MES_SET'),
      10: t('config.config.LABEL_MES_OUT'),
      11: t('config.config.LABEL_MES_NOV'),
      12: t('config.config.LABEL_MES_DEZ'),
    },
  }

  return { nomesColunas }
}

export function stringsConfigPrevisaoChegadaCilindros(t) {
  const { nomesColunas } = ConfigUtils.stringsConfigPrevisaoCustoRelativo(t)
  nomesColunas.lblValor = t('config.config.LABEL_NUMERO_CILINDROS_PREVISTO')
  return { nomesColunas }
}

export function manipulaResultadoDaRequisicao(
  dados, setDados, setErro, tituloTabela, dadosDefault = [],
) {
  if (dados[0] === ConfigService.STR_ERRO) {
    setErro(tituloTabela)
    setDados(dadosDefault)
  } else {
    setDados(dados)
  }
}
