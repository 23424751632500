import { converteMaiusculaMinuscula } from '../utils'

export default function formatarMotivos(dados, labels) {
  let totalOcorrencias = 0
  if (!dados) {
    return null
  }
  if (dados.motivos) {
    totalOcorrencias = dados.motivos.map(item => item.qtdRetificas).reduce((a, b) => a + b)
  }
  const resposta = []
  resposta.push({
    name: `${labels.inicio}: ${new Date(dados.dataInicial).toLocaleDateString([])}`,
    y: parseFloat(dados.diametroInicial.toFixed(2)),
    color: '#4298D6',
  }, {
    name: labels.desgaste,
    y: -parseFloat(dados.desgasteTotal.toFixed(2)),
    color: '#F8A501',
  }, {
    name: labels.desbaste,
    y: -parseFloat(dados.desbasteTotal.toFixed(2)),
    color: '#F8A501',
  },
  {
    name: labels.corteTotal,
    y: parseFloat((dados.desbasteTotal + dados.desgasteTotal).toFixed(2)),
    media: ((dados.desbasteTotal + dados.desgasteTotal) / totalOcorrencias).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    totalOcorrencias,
    color: '#F8A501',
  })

  if (dados.motivos) {
    for (let i = 0; i < dados.motivos.length; i += 1) {
      resposta.push({
        name: converteMaiusculaMinuscula(dados.motivos[i].descMotivo),
        y: -parseFloat(dados.motivos[i].totalDesbaste.toFixed(2)),
        color: '#95C11C',
        qtd: dados.motivos[i].qtdRetificas,
        media: (dados.motivos[i].totalDesbaste / dados.motivos[i].qtdRetificas).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      })
    }
  }

  resposta.push({
    name: `${labels.fim}: ${new Date(dados.dataFinal).toLocaleDateString([])}`,
    y: -parseFloat(dados.diametroFinal.toFixed(2)),
    color: '#4298D6',
    media: ((dados.desbasteTotal + dados.desgasteTotal) / dados.totalCampanhas).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
  })

  return resposta
}

export function formatarTooltip(labels, dados, ponto) {
  let valor = ponto.y
  if (valor < 0) {
    valor *= -1
  }
  if (ponto.name.includes(labels.inicio)) {
    return `${labels.campInicial}: ${dados.campanhaInicial}<br>
          ${labels.diametro}: ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.mm}`
  }
  if (ponto.name.includes(labels.fim)) {
    return `${labels.campFinal}: ${dados.campanhaFinal}<br>
          ${labels.campanhas}: ${dados.totalCampanhas}<br>
          ${labels.diametro}: ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.mm}<br>
          ${labels.corteMedio}: ${ponto.options.media} ${labels.mm}`
  }
  if (ponto.name === labels.desgaste || ponto.name === labels.desbaste) {
    return `${labels.corte}: ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.mm}`
  }
  if (ponto.name === labels.corteTotal) {
    return `${labels.corte}: ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.mm}<br>
    ${labels.ocorrencias}: ${ponto.options.totalOcorrencias}<br>
    ${labels.corteMedio}: ${ponto.options.media} ${labels.mm}`
  }
  return `${labels.corte}: ${valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${labels.mm}<br>
        ${labels.ocorrencias}: ${ponto.options.qtd}<br>
        ${labels.corteMedio}: ${ponto.options.media} ${labels.mm}`
}

export function formatarDataLabel(ponto, dados) {
  const valorUtil = dados.diametroNovo - dados.diametroSucatado
  let valor = ponto
  if (valor < 0) {
    valor *= -1
  }
  if (valor > dados.diametroSucatado) {
    let valores = (valor - dados.diametroSucatado) / valorUtil * 100
    if (valores > 100) {
      valores = 100
    }
    return `${valores.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%`
  }
  return `${(valor / valorUtil * 100).toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}%`
}

export function formatarEixoY(diametroNovo, diametroSucatado) {
  if (!diametroNovo || !diametroSucatado || diametroNovo < diametroSucatado) {
    return null
  }
  const positions = []
  const increment = (diametroNovo - diametroSucatado) / 4
  let tick = diametroSucatado
  while (tick <= diametroNovo) {
    positions.push(parseFloat(tick.toFixed(0)))
    tick += increment
  }
  return positions
}
