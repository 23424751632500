import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, Dialog, Grid } from '@material-ui/core'
import {
  formatarNumero, SRCelula, TituloModal, BotaoEditar,
} from '../../utils'
import propsTabela, { valuesTabela } from '../TabelaPrevisaoCompraPropTypes'
import TabelaPrevisaoCompraBotao from './TabelaPrevisaoCompraBotao'
import TabelaPrevisaoCompraRecebimentoCilindrosEditar from './TabelaPrevisaoCompraRecebimentoCilindrosEditar'
import { somarArray } from '../TabelaPrevisaoCompraUtils'
import { infoCinza } from '../../../assets/images'
import Tooltip from '../../tooltip'
import { estilo } from '../TabelaPrevisaoCompraEstilo'

const TabelaPrevisaoCompraRecebimentoCilindros = ({
  item, editavel, atualizarDados, dadosChegadaCilindro,
}) => {
  const { t } = useTranslation()
  const [editado, setEditado] = useState(false)
  const [abrirPrevisaoChegada, setAbrirPrevisaoChegada] = useState(false)
  const somaUnidadePrevista = somarArray(item.recebimentoCilindros, 'previstoUnidade')
  const somaUnidadeReal = somarArray(item.recebimentoCilindros, 'realUnidade')
  const somaMmPrevisto = somarArray(item.recebimentoCilindros, 'previstoMm')
  const somaMmReal = somarArray(item.recebimentoCilindros, 'realMm')
  const classes = estilo()

  function fecharTela(ano) {
    if (editado) {
      atualizarDados(ano)
    } else {
      setEditado(false)
    }
    setAbrirPrevisaoChegada(false)
  }

  return (
    <>
      {abrirPrevisaoChegada ? (
        <Dialog onClose={() => fecharTela(item.ano)} aria-labelledby="previsaoRecebimentoEdit" open fullWidth maxWidth="md">
          <TituloModal id="previsaoRecebimentoEdit" onClose={() => fecharTela(item.ano)}>
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR')}
          </TituloModal>
          <DialogContent id="previsaoRecebimentoEditBody">
            <TabelaPrevisaoCompraRecebimentoCilindrosEditar
              ano={item.ano}
              setEditado={setEditado}
              abrir={setAbrirPrevisaoChegada}
              dadosChegadaCilindro={dadosChegadaCilindro}
            />
          </DialogContent>
          <TabelaPrevisaoCompraBotao fecharTela={fecharTela} ano={item.ano} id="previsaoRecebimentoEdit" />
        </Dialog>
      ) : null}
      <tr>
        <SRCelula percentualLargura="6%" negrito altura="4">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_CILINDROS_RECEBIDOS')}</SRCelula>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_PLANEJADAS')}</SRCelula>
        {item.recebimentoCilindros.map(recebimentoCilindros => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(recebimentoCilindros.previstoUnidade)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaUnidadePrevista !== null ? formatarNumero(somaUnidadePrevista) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}>
            <BotaoEditar evento={() => setAbrirPrevisaoChegada(true)} />
          </SRCelula>
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">
          <Grid container justify="space-around">
            <Grid item style={{ fontSize: '12.25px', display: 'flex', alignItems: 'center' }}>
              {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_UNIDADES_REAL')}
            </Grid>
            <Grid item>
              <Tooltip
                cinza={1}
                title={(
                  <>
                    <h6>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TOOLTIP_INICIO')}</h6>
                    <h6>{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_TOOLTIP_FIM')}</h6>
                  </>
              )}
              >
                <img src={infoCinza} alt={t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_ALT_INFORMATIVO')} />
              </Tooltip>
            </Grid>
          </Grid>
        </SRCelula>
        {item.recebimentoCilindros.map(recebimentoCilindros => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(recebimentoCilindros.realUnidade)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaUnidadeReal !== null ? formatarNumero(somaUnidadeReal) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}> </SRCelula>
        ) : null}
      </tr>
      <tr className={classes.bordaSuperior}>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MM_PLANEJADO')}</SRCelula>
        {item.recebimentoCilindros.map(recebimentoCilindros => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(recebimentoCilindros.previstoMm, 2)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaMmPrevisto !== null ? formatarNumero(somaMmPrevisto, 2) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()} />
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_MM_REAL')}</SRCelula>
        {item.recebimentoCilindros.map(recebimentoCilindros => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(recebimentoCilindros.realMm, 2)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaMmReal !== null ? formatarNumero(somaMmReal, 2) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}> </SRCelula>
        ) : null}
      </tr>
    </>
  )
}

TabelaPrevisaoCompraRecebimentoCilindros.propTypes = propsTabela
TabelaPrevisaoCompraRecebimentoCilindros.defaultProps = valuesTabela

export default TabelaPrevisaoCompraRecebimentoCilindros
