import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import HomeEstilo from '../HomeEstilo'
import LocalStorageProxy from '../../utils/LocalStorageProxy'
import HomePropTypes from '../HomePropTypes'
import { FiltroContext } from '../../layout/LayoutContext'
import {
  getConsumoAgrupadoWidgets,
  getEquivalenteNovosWidgets,
  getReaisPorToneladaWidgets,
} from '../HomeUtils'

export default function Home() {
  const classes = HomeEstilo.estilo()
  const { t } = useTranslation()
  const context = useContext(FiltroContext)
  const [dataCorrente] = useState(new Date())
  const [content, setContent] = useState([])
  const { laminadores, todosFornecedores, todosMateriais } = context

  useEffect(() => {
    if (laminadores.length > 0 && todosFornecedores.length > 0
      && todosMateriais.length > 0) {
      const todosCodFornecedores = [
        ...(new Set(todosFornecedores.map(fornecedor => fornecedor.codFornecedor)))]
      const todosCodMateriais = [
        ...(new Set(todosMateriais.map(fornecedor => fornecedor.codMaterial)))]
      setContent([
        [...getEquivalenteNovosWidgets(
          laminadores, todosCodFornecedores, todosCodMateriais, dataCorrente,
        )],
        [...getConsumoAgrupadoWidgets(
          laminadores, todosCodFornecedores, todosCodMateriais, dataCorrente,
        )],
        [...getReaisPorToneladaWidgets(
          laminadores, todosCodFornecedores, todosCodMateriais, dataCorrente,
        )],
      ])
    }
  }, [dataCorrente, laminadores, todosFornecedores, todosMateriais])

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {`${t('home.Home.OLA')}, `}
        <b className={classes.nomeUsuario}>{LocalStorageProxy.getItem('NomeUsuario')}</b>
        {`, ${t('home.Home.SEJA_BEM_VINDOA')}!`}
      </div>
      <div className="home-content">
        {content.map((group, i) => (group.length > 0 ? (
          // eslint-disable-next-line react/no-array-index-key
          <section className="home-content-group" key={`home-content-${i}`}>
            {group.map(({ component, props, key }) => (
              <Card className="home-content-card" key={key}>
                <CardContent>
                  {React.createElement(component, props, null)}
                </CardContent>
              </Card>
            ))}
          </section>
        ) : undefined))}
      </div>
    </div>
  )
}

Home.propTypes = HomePropTypes.props
Home.defaultProps = HomePropTypes.values
