
// eslint-disable-next-line import/no-self-import
import GraficoDesempenhoAgrupadoUtils from './GraficoDesempenhoAgrupadoUtils'
import { procurarMaiorValor, ordenarPorCampos } from '../utils'
import obterDadosMotivos from './GraficoDesempenhoAgrupadoUtilsView'

export function ziparArrayDados(nomes, campanhas, desempNormal,
  desempTotal, consNormal, consTotal, fornecedores, pesos, cortesPadrao, cortesTotal) {
  if (!nomes || !campanhas || !desempNormal || !desempTotal || !consNormal || !consTotal
    || !fornecedores || !pesos || !cortesPadrao || !cortesTotal) {
    return null
  }
  const dados = []

  for (let i = 0; i < nomes.length; i += 1) {
    dados.push({
      nome: nomes[i],
      qtdCampanhas: campanhas[i],
      desempenhoNormal: desempNormal[i],
      desempenhoTotal: desempTotal[i],
      consumoNormal: consNormal[i],
      consumoTotal: consTotal[i],
      fornecedor: fornecedores[i],
      peso: pesos[i],
      cortePadrao: cortesPadrao[i],
      corteTotal: cortesTotal[i],
    })
  }

  const dadosAjustados = procurarMaiorValor(dados, 'desempenhoTotal', 'fornecedor')

  const dadosFinais = dadosAjustados.sort(ordenarPorCampos(['-maximo', 'fornecedor', '-desempenhoTotal']))

  return dadosFinais
}

const nomesFakes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'D', 'E',
  'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
  'X', 'Y', 'Z']

export function getNomeFornecedorFakeFn(lblFornecedor, nomeDoFornecedor, mapaDeNomes) {
  if (mapaDeNomes.has(nomeDoFornecedor)) {
    return mapaDeNomes.get(nomeDoFornecedor)
  }
  const nomeFake = `${lblFornecedor} ${nomesFakes[mapaDeNomes.size]}`
  mapaDeNomes.set(nomeDoFornecedor, nomeFake)
  return nomeFake
}

export function escondeNomeDosFornecedoresFn(
  nomesDosFornecedores, mostrarSomenteOFornecedor, lblFornecedor,
) {
  const mapaDeNomes = new Map()
  return nomesDosFornecedores.map(nome => (nome === mostrarSomenteOFornecedor
    ? nome
    : GraficoDesempenhoAgrupadoUtils.getNomeFornecedorFake(lblFornecedor, nome, mapaDeNomes)))
}

export function obterdadosGRaficoMateriais(dados, total, todos, lblFornecedor,
  mostrarSomenteOFornecedor) {
  if (!dados || !total || !todos) {
    return null
  }
  const fornecedores = []
  for (let i = 0; i < dados.length; i += 1) {
    for (let a = 0; a < dados[i].materiais.length; a += 1) {
      fornecedores.push(dados[i].nomeFornecedor)
    }
  }

  const materiais = dados.map(dado => dado.materiais)

  const nomes = materiais.map(item => item.map(nome => nome.nomeMaterial))
    .reduce((acc, x) => acc.concat(x), [])
  const campanhas = materiais.map(item => item.map(nome => nome.qtdCampanhas))
    .reduce((acc, x) => acc.concat(x), [])
  const desempenhoNormal = materiais.map(item => item.map(nome => nome.desempenhoMaterialPadrao))
    .reduce((acc, x) => acc.concat(x), [])
  const desempenhoTotal = materiais.map(item => item.map(nome => nome.desempenhoMaterial))
    .reduce((acc, x) => acc.concat(x), [])
  const consumoNormal = materiais.map(item => item.map(nome => nome.consumoMaterialPadrao))
    .reduce((acc, x) => acc.concat(x), [])
  const consumoTotal = materiais.map(item => item.map(nome => nome.consumoMaterial))
    .reduce((acc, x) => acc.concat(x), [])
  const pesos = materiais.map(item => item.map(nome => nome.pesoProduzido))
    .reduce((acc, x) => acc.concat(x), [])
  const cortesPadrao = materiais.map(item => item.map(nome => nome.cortePadrao))
    .reduce((acc, x) => acc.concat(x), [])
  const cortesTotal = materiais.map(item => item.map(nome => nome.corteTotal))
    .reduce((acc, x) => acc.concat(x), [])

  const valoresFiltrados = ziparArrayDados(nomes, campanhas, desempenhoNormal, desempenhoTotal,
    consumoNormal, consumoTotal, fornecedores, pesos, cortesPadrao, cortesTotal)

  const nomesFinais = valoresFiltrados.map(item => item.nome)
  let fornecedoresFinais = valoresFiltrados.map(item => item.fornecedor)
  if (mostrarSomenteOFornecedor) {
    fornecedoresFinais = GraficoDesempenhoAgrupadoUtils.escondeNomeDosFornecedores(
      fornecedoresFinais, mostrarSomenteOFornecedor, lblFornecedor,
    )
  }
  const campanhasFinais = valoresFiltrados.map(item => item.qtdCampanhas)
  const desempenhosNormaisFinais = valoresFiltrados.map(item => item.desempenhoNormal)
  const desempenhosTotaisFinais = valoresFiltrados.map(item => item.desempenhoTotal)
  const consumosNormaisFinais = valoresFiltrados.map(item => item.consumoNormal)
  const consumosTotaisFinais = valoresFiltrados.map(item => item.consumoTotal)
  const pesosFinais = valoresFiltrados.map(item => Math.round(item.peso))
  const cortePadraoFinal = valoresFiltrados.map(item => (item.cortePadrao
    ? parseFloat(item.cortePadrao.toFixed(2)) : item.cortePadrao))
  const corteTotalFinal = valoresFiltrados.map(item => (item.corteTotal
    ? parseFloat(item.corteTotal.toFixed(2)) : item.corteTotal))

  const pesoTotal = pesosFinais.length > 0 ? pesosFinais
    .reduce((a, b) => a + b) : null
  const cortePadrao = cortePadraoFinal.length > 0 ? cortePadraoFinal
    .reduce((a, b) => a + b) : null
  const corteTotal = corteTotalFinal.length > 0 ? corteTotalFinal
    .reduce((a, b) => a + b) : null

  nomesFinais.push(total)
  campanhasFinais.push(campanhasFinais.length > 0 ? campanhasFinais.reduce((a, b) => a + b) : null)
  desempenhosNormaisFinais.push(Math.round(pesoTotal / cortePadrao))
  desempenhosTotaisFinais.push(Math.round(pesoTotal / corteTotal))
  consumosNormaisFinais.push(cortePadrao / pesoTotal * 1000)
  consumosTotaisFinais.push(corteTotal / pesoTotal * 1000)
  fornecedoresFinais.push(todos)
  pesosFinais.push(pesoTotal)
  cortePadraoFinal.push(cortePadrao)
  corteTotalFinal.push(corteTotal)

  const ocorrencias = obterDadosMotivos(materiais, nomesFinais, corteTotalFinal)

  return {
    nomesFinais,
    campanhasFinais,
    desempenhosNormaisFinais,
    desempenhosTotaisFinais,
    consumosNormaisFinais,
    consumosTotaisFinais,
    fornecedoresFinais,
    pesosFinais,
    cortePadraoFinal,
    corteTotalFinal,
    ocorrencias,
  }
}

export function formatarDatalabels(ponto, tipo, campanha) {
  if (ponto.point.series.userOptions.name === campanha) {
    return null
  }
  if (tipo === 'Consumo') {
    return ponto.y === 0 ? null : ponto.y.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
  return ponto.y === 0 ? null : ponto.y.toLocaleString('pt-BR', { maximumFractionDigits: 0 })
}

export function formatarTooltip(ponto, labels) {
  if (ponto.point.series.userOptions.name === labels.campanhas) {
    return `${labels.quantidade}: ${ponto.y.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}`
  }
  return false
}

export function mergeArrays(array1, array2) {
  if (!array1 || !array2 || array1.length !== array2.length) {
    return null
  }
  const array = []
  for (let i = 0; i < array1.length; i += 1) {
    array.push(array1[i])
    array.push(array2[i])
  }
  return array
}

const GraficoDesempenhoAgrupadoUtilsObj = {
  escondeNomeDosFornecedores: escondeNomeDosFornecedoresFn,
  getNomeFornecedorFake: getNomeFornecedorFakeFn,
}

export default GraficoDesempenhoAgrupadoUtilsObj
