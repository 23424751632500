export default class Navigation {
  static navigateTo(url) {
    window.location.replace(url)
  }

  static currentUrlWithoutPath() {
    return (`${window.location.protocol}//${window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ''}`)
  }
}
