import {
  DoLogout,
} from '../utils'

export const ULTIMO_MES = 'ultimomes'
export const ULTIMOS_3_MESES = 'ultimos3meses'
export const ULTIMOS_6_MESES = 'ultimos6meses'
export const ANUAL = 'anual'

export function onClickHandle() {
  DoLogout()
}

export const toggleDrawer = (setState, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return
  }
  setState(open)
}

export function handleClick(abrir, estado, fecharPrimeiroMenu, fecharSegundo, fecharTerceiroMenu) {
  abrir(!estado)
  fecharPrimeiroMenu(false)
  fecharSegundo(false)
  fecharTerceiroMenu(false)
}

export function abrirAlterarSenha(event, fecharMenu, contexto) {
  contexto.setOpen(true)
  fecharMenu(event)
}

export function modalOpen(expirationTime, setModalState) {
  setModalState({ isOpen: true, expirationTime, buttonDisabled: false })
}

export function modalClose(setModalState) {
  setModalState({ isOpen: false, expirationTime: 0, buttonDisabled: true })
}

export async function modalConfirm(setModal, refreshToken, modalCloseLambda) {
  try {
    setModal({ isOpen: true, expirationTime: 0, buttonDisabled: true })
    await refreshToken()
    modalCloseLambda(setModal)
  } catch (error) {
    console.log(error)
  }
}

export function getIntervaloDeDataParaRadioNoHome(opcaoDeIntervaloDeTempo, data) {
  switch (opcaoDeIntervaloDeTempo) {
    case ANUAL: return new Date(data.getFullYear(), 0, 1) // 1 de Janeiro do ano corrente
    case ULTIMO_MES: return new Date(data.getFullYear(), data.getMonth(), data.getDate() - 30)
    case ULTIMOS_3_MESES: return new Date(data.getFullYear(), data.getMonth() - 3, data.getDate())
    case ULTIMOS_6_MESES: return new Date(data.getFullYear(), data.getMonth() - 6, data.getDate())
    default: return new Date(data)
  }
}
