import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoDesempenhoIndividualRequisicao from './GraficoDesempenhoIndividualRequisicao'
import {
  ENDPOINT_DESEMPENHO_CONSUMO_INDIVIDUAL, ENDPOINT_QUILOMETRAGEM_LAMINADA,
  ENDPOINT_TONELAGEM_LAMINADA, ENDPOINT_GRAFICO_CORTE_TOTAL, ENDPOINT_GRAFICO_DIAMETRO_TOTAL,
} from '../utils/Configuracoes'


export default async function GraficoDesempenhoIndividualService(filtro, tipo) {
  if (tipo === 'Desempenho' || tipo === 'Consumo') {
    try {
      const resposta = await ConectarApiPost(ENDPOINT_DESEMPENHO_CONSUMO_INDIVIDUAL,
        GraficoDesempenhoIndividualRequisicao(filtro))
      const { resultado } = resposta.data
      return resultado
    } catch (err) {
      return ['Erro', err]
    }
  }
  if (tipo === 'Tonelagem') {
    try {
      const resposta = await ConectarApiPost(ENDPOINT_TONELAGEM_LAMINADA,
        GraficoDesempenhoIndividualRequisicao(filtro))
      const { resultado } = resposta.data
      return resultado
    } catch (err) {
      return ['Erro', err]
    }
  }
  if (tipo === 'Quilometragem') {
    try {
      const resposta = await ConectarApiPost(ENDPOINT_QUILOMETRAGEM_LAMINADA,
        GraficoDesempenhoIndividualRequisicao(filtro))
      const { resultado } = resposta.data
      return resultado
    } catch (err) {
      return ['Erro', err]
    }
  }
  if (tipo === 'Diametro') {
    try {
      const resposta = await ConectarApiPost(ENDPOINT_GRAFICO_DIAMETRO_TOTAL,
        GraficoDesempenhoIndividualRequisicao(filtro))
      const { resultado } = resposta.data
      return resultado
    } catch (err) {
      return ['Erro', err]
    }
  }
  if (tipo === 'Corte') {
    try {
      const resposta = await ConectarApiPost(ENDPOINT_GRAFICO_CORTE_TOTAL,
        GraficoDesempenhoIndividualRequisicao(filtro))
      const { resultado } = resposta.data
      return resultado
    } catch (err) {
      return ['Erro', err]
    }
  }
  return null
}
