import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { info } from '../../../assets/images'
import { useStateSafely } from '../../utils'
import LayoutInformativoService from '../LayoutInformativoService'
import Tooltip from '../../tooltip'
import LayoutEstilo from '../LayoutEstilo'

let dados
let setDados

const LayoutInformativo = () => {
  [dados, setDados] = useStateSafely(undefined)
  const { t } = useTranslation()
  const classes = LayoutEstilo()

  useEffect(() => {
    async function conectar() {
      try {
        const resposta = await LayoutInformativoService()
        setDados(resposta)
      } catch (err) {
        console.log(err)
      }
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [])

  const textoInformativo = item => (
    <React.Fragment key={item.nomeSistema}>
      <b>{item.nomeSistema}</b>
      {t('layout.layoutInformativo.LABEL_ATZ')}
      {new Date(item.dataAtualizacao).toLocaleDateString([])}
      <br />
    </React.Fragment>
  )

  if (!dados || dados[0] === 'Erro') {
    return null
  }

  return (
    <Tooltip cinza={1} title={(dados ? dados.map(item => textoInformativo(item)) : null)}>
      <img src={info} alt={t('layout.layoutInformativo.ALT_INFO')} className={classes.info} />
    </Tooltip>
  )
}

export default LayoutInformativo
