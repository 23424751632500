import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { useTranslation } from 'react-i18next'
import DropDownMultiplo from './FiltroDropDownMultiplo'
import { COR_PRIMARIA, KEY_PAGINA_DESEMPENHO_AGRUPADO } from '../../utils'
import FiltroEstilo, { FiltroDropDownMultiploComFiltroEstilo } from '../FiltroEstilo'
import { propsFiltroDropDownMultiploComFiltro, valueFiltroDropDownMultiploComFiltro } from '../FiltroPropTypes'

export default function FiltroDropDownMultiploComFiltro({
  id, label, erro, itemValue, itemDisplay, lista, handleChangeMultiplo, disabled, valor, pageKey,
}) {
  const [todos, setTodos] = useState(pageKey === KEY_PAGINA_DESEMPENHO_AGRUPADO)
  const { t } = useTranslation()
  const classes = FiltroDropDownMultiploComFiltroEstilo()
  const classesFiltro = FiltroEstilo()

  useEffect(() => {
    if (lista.length === valor.length) {
      setTodos(true)
    } else {
      setTodos(false)
    }
  }, [disabled, pageKey, lista.length, valor.length])

  const handleChange = () => {
    setTodos(!todos)
    if (document.getElementById('marcarTodosCilindros')) {
      document.getElementById('marcarTodosCilindros').blur()
    }
    if (!todos) {
      handleChangeMultiplo(lista)
    } else {
      handleChangeMultiplo([{ numCilindro: t('filtro.filtroDropDown.LABEL_DIGITE') }])
    }
  }

  if (lista.length === 1) {
    return (
      <DropDownMultiplo
        id={id}
        label={label}
        erro={erro}
        disabled={disabled}
        lista={lista}
        itemDisplay={itemDisplay}
        itemValue={itemValue}
        handleChangeMultiplo={() => {}}
        valor={(valor[0] && valor[0][itemValue]) ? [valor[0][itemValue]] : []}
      />
    )
  }

  function renderizarTags(item) {
    if (item.length < 1) {
      return t('filtro.filtroDropDown.LABEL_DIGITE')
    }
    if (item.length === 1) {
      return item[0][itemDisplay]
    }
    if (item.length === lista.length) {
      return t('filtro.filtroDropDown.LABEL_TODOS')
    }
    return item.length + t('filtro.filtroDropDown.LABEL_ITENS_SELECIONADOS')
  }

  return (
    <>
      <FormControl fullWidth error={erro} className={classes.formControlMaior}>
        <InputLabel shrink htmlFor={id} className={classes.filtroDropdown}>
          {label}
        </InputLabel>
        <Autocomplete
          clearText={t('filtro.filtroDropDown.LABEL_LIMPAR')}
          disabled={disabled}
          closeText={t('filtro.filtroDropDown.LABEL_FECHAR')}
          openText={t('filtro.filtroDropDown.LABEL_ABRIR')}
          noOptionsText={t('filtro.filtroDropDown.LABEL_NENHUM_REGISTRO')}
          multiple
          value={valor}
          id={id}
          options={[...lista]}
          disableCloseOnSelect
          onChange={(_, newValue) => {
            if (newValue && newValue[0] && !newValue[0][itemValue]) {
              newValue.shift()
            }
            handleChangeMultiplo(newValue)
            if (newValue.length === lista.length) {
              setTodos(true)
            } else {
              setTodos(false)
            }
          }}
          getOptionLabel={option => option[itemDisplay]}
          renderTags={item => renderizarTags(item)}
          filterOptions={createFilterOptions({ stringify: option => option[itemDisplay] })}
          renderOption={(option, { selected }) => (
            <div className={classes.optionItem}>
              <Checkbox checked={selected} style={{ color: COR_PRIMARIA }} />
              <div className={classes.text}>
                {option[itemDisplay]}
              </div>
            </div>
          )}
          style={{ marginTop: '15px' }}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
            />
          )}
        />
        <div
          disabled
          className={todos ? classesFiltro.desmarcar : classesFiltro.marcar}
          role="button"
          tabIndex={disabled ? -1 : 0}
          onClick={disabled ? undefined : handleChange}
          onKeyPress={disabled ? undefined : handleChange}
          hidden={disabled}
          id="marcarTodosCilindros"
        >
          {todos ? t('filtro.filtroDropDown.LABEL_DESMARCAR') : t('filtro.filtroDropDown.LABEL_MARCAR')}
        </div>
      </FormControl>
    </>
  )
}

FiltroDropDownMultiploComFiltro.propTypes = propsFiltroDropDownMultiploComFiltro
FiltroDropDownMultiploComFiltro.defaultProps = valueFiltroDropDownMultiploComFiltro
