import React from 'react'
import LayoutContentList from './LayoutContentList'
import TabelaPrevisaoCompra from '../../tabelaPrevisaoCompra'

const LayoutCustoPrevisao = () => (
  <LayoutContentList>
    <TabelaPrevisaoCompra key="TabelaPrevisaoCompra" />
  </LayoutContentList>
)

export default LayoutCustoPrevisao
