import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoEstoqueSituacaoService from '../GraficoEstoqueSituacaoService'
import GraficoEstoqueSituacaoOpcoes from '../GraficoEstoqueSituacaoOpcoes'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import {
  ErroGrafico, Legenda, formatarSituacao, zipArray, useStateSafely,
} from '../../utils'
import { FiltroContext } from '../../layout/LayoutContext'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

let quantidade
let setQuantidade
let codSituacao
let setCodSituacao
let numCilindros
let setNumCilindros
let loading
let setLoading

const GraficoEstoqueSituacao = () => {
  [quantidade, setQuantidade] = useStateSafely([]);
  [codSituacao, setCodSituacao] = useStateSafely([]);
  [numCilindros, setNumCilindros] = useStateSafely([]);
  [loading, setLoading] = useStateSafely(true)
  const { t } = useTranslation()
  const filtro = useContext(FiltroContext)
  let situacao = []
  let cilindros = []

  useEffect(() => {
    setLoading(true)
    setQuantidade([])
    setCodSituacao([])
    setNumCilindros([])
    async function conectar() {
      try {
        const resposta = await GraficoEstoqueSituacaoService(filtro)
        setQuantidade(resposta.dados)
        setCodSituacao(resposta.codSituacao)
        setNumCilindros(resposta.numCilindros)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [filtro])

  if (codSituacao && codSituacao.length > -1) {
    situacao = formatarSituacao(codSituacao, t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_NOVO'),
      t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_RETIFICADO'),
      t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_OPERACAO'),
      t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_CAMPANHA'),
      t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_REPARO'))

    cilindros = zipArray(situacao, numCilindros, 'situacao', 'cilindros')
  }

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (quantidade && quantidade.length === 0 && situacao && situacao.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_TITULO_GRAFICO')}
        />
      )
    }
    if (quantidade && situacao) {
      return (
        <div>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '410px' } }}
            options={GraficoEstoqueSituacaoOpcoes(quantidade, situacao, cilindros,
              t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_TITULO_GRAFICO'),
              t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_COLUNA_QUANTIDADE'),
              t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_SITUACAO'))}
          />
          <Legenda situacoes={codSituacao} />
        </div>
      )
    } return (
      <AlertaModal
        url={t('geral.routes.URL_ESTOQUE_CORRENTE')}
        mensagem={t('graficoEstoqueSituacao.graficoEstoqueSituacao.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return ['Erro', err]
  }
}

export default GraficoEstoqueSituacao
