const PtBr = {
  graficoRitmoChegadaCilindro: {
    LABEL_NENHUM_REGISTRO: 'Não foram encontrados registros na data informada.',
    LABEL_ERRO: 'Houve um erro ao carregar o gráfico de ritmo de chegada de cilindros. Tente novamente mais tarde.',
    LABEL_TITULO: 'RITMO DE CHEGADA DE CILINDROS',
    LABEL_QUANTIDADE: 'Quantidade de Cilindros',
    LABEL_PREVISTO: 'Previsto',
    LABEL_REAL: 'Real',
    LABEL_RITMO: 'Ritmo',
    LABEL_DATAS: 'Data',
    LABEL_CILINDROS: ' cilindros',
    LABEL_CILINDRO: ' cilindro',
  },
}

export default PtBr
