import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graficoEstoqueCilindroEquivalenteNovoEstilo } from '../GraficoEstoqueCilindroEquivalenteNovoEstilo'
import { HeatBar, makeCancelable } from '../../utils'
import GraficoEstoqueCilindroEquivalenteNovoPropTypes from '../GraficoEstoqueCilindroEquivalenteNovoPropTypes'
import GraficoEquivalenteNovoService from '../../graficoEquivalenteNovo/GraficoEquivalenteNovoService'
import LoadingGrafico from '../../loading'

export default function GraficoEstoqueCilindroEquivalenteNovo({ filtro, laminador }) {
  const [estoqueWR, setEstoqueWR] = useState(0)
  const [estoqueBR, setEstoqueBR] = useState(0)
  const [estoqueMinimoWR, setEstoqueMinimoWR] = useState(0)
  const [estoqueMinimoBR, setEstoqueMinimoBR] = useState(0)
  const [loading, setLoading] = useState(true)

  const classes = graficoEstoqueCilindroEquivalenteNovoEstilo()
  const { t } = useTranslation()

  useEffect(() => {
    if (!filtro || !filtro.fornecedores || !filtro.laminador
      || !filtro.materiais || !filtro.dataFim || !filtro.dataInicio) {
      return () => undefined
    }
    const cancelablePromise = makeCancelable(Promise.all([
      GraficoEquivalenteNovoService({ ...filtro, tipoDeCilindro: 1 }, true),
      GraficoEquivalenteNovoService({ ...filtro, tipoDeCilindro: 2 }, true),
    ]))
    cancelablePromise.promise.then(([respBR, respWR]) => {
      setEstoqueBR(respBR.estoque[0] / 2)
      setEstoqueMinimoBR(respBR.estoqueMinimo[0])
      setEstoqueWR(respWR.estoque[0] / 2)
      setEstoqueMinimoWR(respWR.estoqueMinimo[0])
      setLoading(false)
    }).catch((err) => {
      if (!err.isCanceled) { console.log(err) }
    })

    return cancelablePromise.cancel
  }, [filtro])

  function grafico() {
    return (
      <>
        <div className={classes.titulo}>
          {`${t('graficoEstoqueCilindroEquivalenteNovo.GraficoEstoqueCilindroEquivalenteNovo.TITULO_DO_GRAFICO')} - ${laminador}`}
        </div>
        <div className={classes.subTitulo}>
          {t('config.config.LABEL_WORK_ROLL')}
        </div>
        <div className={classes.grafico}>
          <HeatBar
            indicadorPrincipal={estoqueWR}
            indicadorBase={estoqueMinimoWR}
            limiteInferior={0}
          />
        </div>
        <div className={classes.subTitulo}>
          {t('config.config.LABEL_BACKUP_ROLL')}
        </div>
        <div className={classes.grafico}>
          <HeatBar
            indicadorPrincipal={estoqueBR}
            indicadorBase={estoqueMinimoBR}
            limiteInferior={0}
          />
        </div>
        <div className={classes.legenda}>
          <div>
            <span className={classes.dot} style={{ backgroundColor: '#951a80' }} />
            {t('graficoEquivalenteNovo.GraficoEquivalenteNovo.LABEL_COLUNA_ESTOQUE_MINIMO')}
          </div>
          <div>
            <span className={classes.dot} style={{ backgroundColor: '#39434e' }} />
            {t('graficoEstoqueCilindroEquivalenteNovo.GraficoEstoqueCilindroEquivalenteNovo.NUMERO_DE_PARES_EM_ESTOQUE')}
          </div>
        </div>
      </>
    )
  }

  return loading ? <LoadingGrafico graficoSimplificado /> : grafico()
}

GraficoEstoqueCilindroEquivalenteNovo
  .propTypes = GraficoEstoqueCilindroEquivalenteNovoPropTypes.props
GraficoEstoqueCilindroEquivalenteNovo
  .defaultProps = GraficoEstoqueCilindroEquivalenteNovoPropTypes.values
