import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {
  formatarNumero, SRCelula, BotaoEditar, TituloModal,
} from '../../utils'
import propsTabela, { valuesTabela } from '../TabelaPrevisaoCompraPropTypes'
import TabelaPrevisaoCompraProducaoEditar from './TabelaPrevisaoCompraProducaoEditar'
import TabelaPrevisaoCompraBotao from './TabelaPrevisaoCompraBotao'
import { somarArray } from '../TabelaPrevisaoCompraUtils'

const TabelaPrevisaoCompraProducao = ({
  item, atualizarDados, editavel, dadosPrevisaoProducao,
}) => {
  const [abrirPrevisaoCompraEditar, setAbrirPrevisaoCompraEditar] = useState(false)
  const [editado, setEditado] = useState(false)
  const { t } = useTranslation()

  const somaPrevista = somarArray(item.tonelada, 'prevista')
  const somaReal = somarArray(item.tonelada, 'real')

  function fecharTela(ano) {
    if (editado) {
      atualizarDados(ano)
    } else {
      setEditado(false)
    }
    setAbrirPrevisaoCompraEditar(false)
  }
  return (
    <>
      {abrirPrevisaoCompraEditar ? (
        <Dialog onClose={() => fecharTela(item.ano)} aria-labelledby="previsaoProducaoEdit" open fullWidth maxWidth="md">
          <TituloModal id="previsaoProducaoEdit" onClose={() => fecharTela(item.ano)}>
            {t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_EDITAR')}
          </TituloModal>
          <DialogContent id="previsaoProducaoEditBody">
            <TabelaPrevisaoCompraProducaoEditar
              ano={item.ano}
              setEditado={setEditado}
              abrir={setAbrirPrevisaoCompraEditar}
              dadosPrevisaoProducao={dadosPrevisaoProducao}
            />
          </DialogContent>
          <TabelaPrevisaoCompraBotao fecharTela={fecharTela} ano={item.ano} id="previsaoConsumoEdit" />
        </Dialog>
      ) : null}
      <tr>
        <SRCelula percentualLargura="6%" negrito altura="2">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PRODUCAO')}</SRCelula>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_PLANEJADA')}</SRCelula>
        {item.tonelada.map(tonelada => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(tonelada.prevista)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaPrevista !== null ? formatarNumero(somaPrevista) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}>
            <BotaoEditar evento={() => setAbrirPrevisaoCompraEditar(true)} />
          </SRCelula>
        ) : null}
      </tr>
      <tr>
        <SRCelula percentualLargura="10%">{t('tabelaPrevisaoCompra.tabelaPrevisaoCompra.LABEL_REAL')}</SRCelula>
        {item.tonelada.map(tonelada => (
          <SRCelula percentualLargura="6%" key={Math.random()}>{formatarNumero(tonelada.real)}</SRCelula>
        ))}
        <SRCelula percentualLargura="6%" negrito key={Math.random()}>
          {somaReal !== null ? formatarNumero(somaReal) : '-'}
        </SRCelula>
        {editavel ? (
          <SRCelula percentualLargura="5%" key={Math.random()}> </SRCelula>
        ) : null}
      </tr>
    </>
  )
}

TabelaPrevisaoCompraProducao.propTypes = propsTabela
TabelaPrevisaoCompraProducao.defaultProps = valuesTabela

export default TabelaPrevisaoCompraProducao
