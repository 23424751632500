import Highcharts from 'highcharts'
import { Traducao, COR_PRIMARIA, formatarData } from '../utils'
import { exportar } from '../../assets/images'
import formatarDatalabels, { formatarTooltip } from './GraficoRitmoChegadaCilindroUtils'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function GraficoRitmoChegadaCilindroOpcoes(dados, labels) {
  return ({
    chart: {
      type: 'column',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          const dadosExportacao = dadosDoGrafico
          dadosExportacao.dataRows[0][0] = labels.datas
        },
      },
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      split: false,
      shared: false,
      formatter() {
        return formatarTooltip(this, labels)
      },
    },
    legend: {
      itemStyle: {
        color: '#333333',
        fontWeight: 'normal',
      },
    },
    title: {
      text: labels.titulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: formatarData(dados.data),
      labels: {
        style: {
          fontSize: '11px',
        },
      },
    },
    yAxis: {
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      title: {
        text: labels.quantidade,
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
      series: {
        animation: false,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter() {
            return formatarDatalabels(this, labels.ritmo)
          },
        },
      },
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
    series: [{
      type: 'column',
      name: labels.previsto,
      data: dados.estoquePrevisto,
      color: '#CC4D49',
    }, {
      type: 'column',
      name: labels.real,
      data: dados.estoqueReal,
      color: '#FFC2B5',
    },
    {
      type: 'line',
      name: labels.ritmo,
      data: dados.ritmoChegadaCilindro,
      color: COR_PRIMARIA,
    },
    ],
    credits: {
      enabled: false,
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoRitmoChegadaCilindroOpcoes
