import Axios from 'axios'
import { API_URL } from './Configuracoes'
import Security from './Security'
import { error } from './Functions'
import Navigation from './Navigation'
import { publicar } from './FilaDeMensagens'

export function DoLogout() {
  Security.doLogout()
  const LOGIN_PAGE_URL = `${Navigation.currentUrlWithoutPath()}/${'login'}`
  Navigation.navigateTo(LOGIN_PAGE_URL)
}

export default class Conecta {
  static chamada(metodo, path, body, apiUrl = API_URL) {
    if (!path) { return Promise.reject(error('É necessário fornecer um valor de "path" válido para o método Conecta.chamada.')) }

    let resposta
    const config = { headers: { Authorization: Security.getFullAuthorizationTokenValue() } }
    const url = apiUrl + path

    switch (metodo) {
      case 'GET':
        resposta = Axios.get(url, config)
        break
      case 'POST':
        resposta = Axios.post(url, body, config)
        break
      case 'PUT':
        resposta = Axios.put(url, body, config)
        break
      case 'DELETE':
        resposta = Axios.delete(url, config)
        break
      default:
        resposta = Promise.reject(error('Método HTTP usado para conectar a API é inválido.'))
    }

    return resposta
  }

  static conecta(metodo, path, body) {
    if (!Security.authorizationTokenExists()) {
      return Promise.reject(error('Sem token de autorização.'))
    }

    const resposta = Conecta.chamada(metodo, path, body)

    return resposta.catch((err) => {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const { data } = err.response
        if (data.codigo !== 0) {
          publicar({ text: `utils.respostaServidor.${data.mensagem}`, type: 'error', translate: true })
        }
        console.log(data)
        console.log(err.response.status)
        console.log(err.response.headers)
      } else if (err.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(err.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', err.message)
      }

      console.log(err.config)

      if (err.response && err.response.status === 403) {
        DoLogout()
      }

      return err
    })
  }
}

export async function ConectarApiPost(path, body) {
  return Conecta.conecta('POST', path, body)
}

export async function ConectarApiGet(path) {
  return Conecta.conecta('GET', path)
}

export async function ConectarApiPut(path, body) {
  return Conecta.conecta('PUT', path, body)
}

export async function ConectarApiDelete(path) {
  return Conecta.conecta('DELETE', path)
}
