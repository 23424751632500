import alerta from '../alerta/locales/En'
import layout from '../layout/locales/En'
import autenticacao from '../autenticacao/locales/En'
import utils from '../utils/locales/En'
import graficoDiametro from '../graficoDiametro/locales/En'
import filtro from '../filtro/locales/En'
import config from '../config/locales/En'
import graficoEquivalenteNovo from '../graficoEquivalenteNovo/locales/PtBr'

const En = {
  translation: {
    layout,
    alerta,
    autenticacao,
    utils,
    graficoDiametro,
    filtro,
    config,
    graficoEquivalenteNovo,
    geral: {
      routes: {
        URL_DESEMPENHO: 'asdas',
        URL_LOGIN: 'login',
        URL_ESTOQUE_MINIMO: 'estoqueMinimo',
      },
    },
  },
}

export default En
