import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import GraficoDiametroService from '../GraficoDiametroService'
import GraficoDiametroOpcoes from '../GraficoDiametroOpcoes'
import LoadingGrafico from '../../loading'
import { AlertaModal } from '../../alerta'
import { ErroGrafico, Legenda, useStateSafely } from '../../utils'
import { FiltroContext } from '../../layout/LayoutContext'

let range
let setRange
let percentual
let setPercentual
let situacao
let setSituacao
let materiais
let setMateriais
let fornecedores
let setFornecedores
let cilindros
let setCilindros
let dados
let setDados
let loading
let setLoading

const GraficoDiametro = () => {
  [range, setRange] = useStateSafely([]);
  [percentual, setPercentual] = useStateSafely([]);
  [situacao, setSituacao] = useStateSafely([]);
  [materiais, setMateriais] = useStateSafely([]);
  [fornecedores, setFornecedores] = useStateSafely([]);
  [cilindros, setCilindros] = useStateSafely([]);
  [dados, setDados] = useStateSafely([]);
  [loading, setLoading] = useStateSafely(true)
  const { t } = useTranslation()
  const filtro = useContext(FiltroContext)

  useEffect(() => {
    setLoading(true)
    setRange([])
    setPercentual([])
    setSituacao([])
    setMateriais([])
    setFornecedores([])
    setCilindros([])
    setDados([])
    async function conectar() {
      const resposta = await GraficoDiametroService(filtro)
      setRange(resposta.range)
      setPercentual(resposta.percentual)
      setSituacao(resposta.situacao)
      setMateriais(resposta.materiais)
      setFornecedores(resposta.fornecedores)
      setCilindros(resposta.cilindros)
      setDados(resposta.dados)
      setLoading(false)
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [filtro])

  try {
    if (loading) {
      return <LoadingGrafico />
    }
    if (dados && dados.length === 0) {
      return (
        <ErroGrafico
          mensagem={t('graficoDiametro.graficoDiametro.LABEL_NENHUM_REGISTRO')}
          nome={t('graficoDiametro.graficoDiametro.LABEL_TITULO')}
        />
      )
    }
    if (dados) {
      return (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            containerProps={{ style: { height: '410px' } }}
            options={GraficoDiametroOpcoes(range, percentual, situacao,
              materiais, fornecedores, cilindros, dados,
              t('graficoDiametro.graficoDiametro.LABEL_TITULO'),
              t('graficoDiametro.graficoDiametro.LABEL_DIAMETRO'),
              t('graficoDiametro.graficoDiametro.LABEL_VIDA'),
              t('graficoDiametro.graficoDiametro.LABEL_CILINDROS'))}
          />
          <Legenda situacoes={situacao} range={range} />
        </>
      )
    } return (
      <AlertaModal
        url={t('geral.routes.URL_ESTOQUE_HISTORICO')}
        mensagem={t('graficoDiametro.graficoDiametro.LABEL_ERRO')}
      />
    )
  } catch (err) {
    return (err)
  }
}

export default GraficoDiametro
