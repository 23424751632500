import { ConectarApiPost } from './ConectarApi'

export { ConectarApiPost } from './ConectarApi'
export { default as Conecta } from './ConectarApi'
export { ConectarApiGet } from './ConectarApi'
export { DoLogout } from './ConectarApi'
export { ConectarApiPut } from './ConectarApi'
export { default as Security } from './Security'
export { default as Navigation } from './Navigation'
export { default as LocalStorageProxy } from './LocalStorageProxy'
export { default as Traducao } from './GraficosTraducao'
export { default as SRAccordion } from './views/SRAccordion'
export {
  ordenarArrayPorChave, error, zipArray, corGraficoSituacao, isNumberPositive, obterAnosProximos,
  obterJaneiroAtual, objetoFormatado, montarObjetoAnos, contarDecimais, formatarData, unicoValor,
  formatarSituacao, trocaPontoPorVirgula, makeCancelable, converteMaiusculaMinuscula, isFunction,
  and, formatarEstoqueMinimo, formatarEstoqueAtual, procurarMaiorValor, arrayZerado, formatarNumero,
  obterNomeMes, obterLaminadorCompletoPorCodigo, formatarDataMesApenas, formatarDataMesCompleto,
  obterQuantidadeFornecedor, ordenarPorCampos, procurarMenorValor, verificaStringDeNumeroPtBr,
} from './Functions'
export {
  NOME_APP, NOME_EMPRESA, COR_FUNDO, COR_PRIMARIA, AUTHORIZATION_KEY,
  API_HOST_URL, API_PREFIX_URL, API_URL, EXCEPTION_PREFIX, ENDPOINT_FILTRO,
  ENDPOINT_GRAFICO_DIAMETRO, INTERVALO_0_A_25, INTERVALO_25_A_50,
  INTERVALO_50_A_75, INTERVALO_75_A_100, KEY_PAGINA_HOME, KEY_PAGINA_DESEMPENHO_INDIVIDUAL,
  KEY_PAGINA_DESEMPENHO_AGRUPADO, KEY_PAGINA_CONFIGURACOES, KEY_PAGINA_ESTOQUE_CORRENTE,
  KEY_PAGINA_DESEMPENHO_DISCRETIZADO, ARGS_OBRIGATORIOS, KEY_PAGINA_CUSTO_PREVISAO,
  KEY_PAGINA_CUSTO_RELATIVO, UNIDADE_MEDIDA_PADRAO_CORTE, KEY_PAGINA_ESTOQUE_HISTORICO,
  KEY_PAGINA_CADASTRO_USUARIOS, ENDPOINT_USUARIOS, ENDPOINT_PERFIL, ENDPOINT_RESETAR_SENHA,
  KEY_PAGINA_ERRO, KEY_PERMISSAO_TROCAR_SENHA, KEY_PERMISSAO_INFORMATIVO, ENDPOINT_BUSCAR_USUARIOS,
  KEY_PAGINA_HOME_GRAFICO_EQUIVALENTE, KEY_PAGINA_HOME_GRAFICO_REAIS_POR_TONELADA,
  KEY_PAGINA_HOME_GRAFICO_DESEMPENHO_AGRUPADO, permissoesPorFuncionalidade,
  ENDPOINT_TCO, KEY_PAGINA_TCO,
} from './Configuracoes'

export { default as ConfigTraducao, ConfigTraducaoLocalizada } from './ConfigTraducao'

export { default as Botao } from './views/Botao'
export { default as ConfigGeralModal } from './views/ConfigGeralModal'
export { default as ConfigToolbar } from './views/ConfigToolbar'
export { default as TituloModal } from './views/TituloModal'
export { default as BotaoEditar } from './views/BotaoEditar'
export { default as SRCelula } from './views/SRCelula'
export { default as SRTextField } from './views/SRTextField'
export { default as Modal } from './views/Modal'
export { default as SRSwitch } from './views/SRSwitch'
export { default as ErroGrafico } from './views/ErroGrafico'
export { default as Legenda } from './views/GraficoLegenda'
export { default as LegendaUtils } from './views/GraficoLegendaUtils'
export { ordenaClasseDeCilindro, ordenaCilindros } from './views/GraficoLegendaUtils'
export { default as HeatBar } from './views/HeatBar'
export { default as FullScreenLoaging } from './views/FullScreenLoading'
export { default as useStateSafely } from './UseStateSafely'
export { default as SRCheckBox } from './views/SRCheckBox'
export { default as SRRadioButton } from './views/SRRadioButton'

export default ConectarApiPost
