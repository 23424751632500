import React from 'react'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import { materialTableStyle } from '../ConfigEstilo'
import {
  inserirRegistroIdentificadorCilindroSAP,
  editarRegistroIdentificadorCilindroSAP,
  opcoes,
  getColunasFormatadasCadastroIdSAP,
} from '../ConfigUtils'
import {
  propsConfigCadastroIdSAP,
  valueConfigCadastroIdSAP,
} from '../ConfigPropTypes'
import {
  editarIdentificadorCilindrosSAP,
} from '../ConfigService'
import ConfigGeralCardTitulo from './ConfigGeralCardTitulo'
import { ConfigToolbar } from '../../utils'

function ConfigCadastroIdSAP({
  laminadores, cilindros, dados, setDados, localizacao, visivel, clickHandleTitulo,
}) {
  const { t } = useTranslation()
  const nomesColunas = {
    lblLaminador: t('config.config.LABEL_LAMINADOR'),
    lblTipo: t('config.config.LABEL_TIPO_CILINDRO'),
    lblBkpRoll: t('config.config.LABEL_BACKUP_ROLL'),
    lblWorkRoll: t('config.config.LABEL_WORK_ROLL'),
    lblNumeroCilindroOCP: t('config.config.LABEL_NUMERO_CILINDRO_OCP'),
    lblIdentificadorCilindroSAP: t('config.config.LABEL_IDENTIFICADOR_CILINDRO_SAP'),
  }
  const msgs = {
    msgIdentificadorVazio: t('config.config.LABEL_IDENTIFICADOR_VAZIO_SAP'),
    msgValorErradoSAP: t('config.config.LABEL_VALOR_ERRADO_SAP'),
    msgSucesso: t('config.config.LABEL_SUCESSO_SAP'),
    msgLaminadorVazio: t('config.config.LABEL_LAMINADOR_VAZIO'),
    msgTipoCilindroVazio: t('config.config.LABEL_TIPO_CILINDRO_VAZIO'),
    msgCilindroVazio: t('config.config.LABEL_CILINDRO_VAZIO'),
    msgCilindroNaoExistente: t('config.config.LABEL_CILINDRO_NAO_EXISTENTE'),
    msgCilindroTipoCilindroErrado: t('config.config.LABEL_TIPO_CILINDRO_ERRADO'),
    msgCilindroLaminadorErrado: t('config.config.LABEL_TIPO_LAMINADOR_ERRADO'),
  }

  return (
    <MaterialTable
      id="tabelaCadastroIdentificadorSAP"
      style={materialTableStyle}
      title={<ConfigGeralCardTitulo titulo={t('config.config.LABEL_TITULO_IDENTIFICADOR_CILINDRO_SAP')} flechaParaCima={visivel} clickHandle={clickHandleTitulo} />}
      columns={getColunasFormatadasCadastroIdSAP(laminadores, nomesColunas)}
      data={dados}
      options={opcoes}
      localization={localizacao}
      editable={{
        onRowAdd: newData => inserirRegistroIdentificadorCilindroSAP(
          newData, dados, setDados, msgs, editarIdentificadorCilindrosSAP, cilindros,
        ),
        onRowUpdate: newData => editarRegistroIdentificadorCilindroSAP(
          newData, dados, setDados, msgs, editarIdentificadorCilindrosSAP, cilindros,
        ),
      }}
      components={{
        Toolbar: ConfigToolbar,
      }}
    />
  )
}

ConfigCadastroIdSAP.propTypes = propsConfigCadastroIdSAP
ConfigCadastroIdSAP.defaultProps = valueConfigCadastroIdSAP

export default ConfigCadastroIdSAP
