import { corGraficoSituacao } from '../utils/Functions'
import { ConectarApiPost } from '../utils/ConectarApi'
import GraficoDiametroRequisicao from './GraficoDiametroRequisicao'
import { ENDPOINT_GRAFICO_DIAMETRO } from '../utils/Configuracoes'
import { ordenaCilindros } from '../utils'
import formatarDados from './GraficoDiametroUtils'

export default async function GraficoDiametroService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_GRAFICO_DIAMETRO,
      GraficoDiametroRequisicao(filtro))
    const { resultado } = resposta.data
    const pares = ordenaCilindros(resultado.diametroParesCilindros)

    const { diametroInicialNom, diametroFinalNom } = resultado

    const cilindrosCompleto = pares.map(item => (
      {
        codSituacao: item.codSituacao,
        codSituacaoPar: item.codSituacaoPar ? item.codSituacaoPar : null,
        descMaterial: item.descMaterial,
        descSituacao: item.descSituacao,
        descSituacaoPar: item.descSituacaoPar ? item.descSituacaoPar : null,
        diametroAtual: item.diametroAtual,
        diametroAtualPar: item.diametroAtualPar ? item.diametroAtualPar : null,
        diametroUtilPerc: item.diametroUtilPerc,
        diametroUtilPercPar: item.diametroUtilPercPar ? item.diametroUtilPercPar : null,
        nomeFornecedor: item.nomeFornecedor,
        menorCilindro: item.menorCilindro,
        numPares: `${item.numPares[0]}<br>${item.numPares[1]}</br>`,
        pares: item.numPares,
      }
    ))

    const range = [diametroInicialNom, diametroFinalNom]
    const diametro = cilindrosCompleto.map(item => (parseFloat(item.diametroAtual.toFixed(2))))
    const diametroPar = cilindrosCompleto.map(item => (
      parseFloat(item.diametroAtualPar ? item.diametroAtualPar.toFixed(2) : null)))
    const percentual = cilindrosCompleto.map(item => (parseFloat(item.diametroUtilPerc.toFixed(1))))
    const situacao = cilindrosCompleto.map(item => (item.codSituacao))
    const situacaoPar = cilindrosCompleto.map(item => (item.codSituacaoPar))
    const materiais = cilindrosCompleto.map(item => (item.descMaterial))
    const fornecedores = cilindrosCompleto.map(item => (item.nomeFornecedor))
    const cilindros = cilindrosCompleto.map(item => (item.numPares))
    const menorCilindro = cilindrosCompleto.map(item => (item.menorCilindro))
    const cor = corGraficoSituacao(situacao)
    const corPar = corGraficoSituacao(situacaoPar)
    const paresCilindros = cilindrosCompleto.map(item => (item.pares))
    const dados = formatarDados(diametro, cor, corPar, diametroPar, menorCilindro, paresCilindros)

    const dadosCilindros = {
      range,
      percentual,
      situacao,
      materiais,
      fornecedores,
      cilindros,
      dados,
    }

    return dadosCilindros
  } catch (err) {
    return [[err], [], [], '']
  }
}
