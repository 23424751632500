import Highcharts from 'highcharts'
import Traducao from '../utils/GraficosTraducao'
import { COR_PRIMARIA } from '../utils'
import { formatarTooltip, formatarDataLabel, formatarEixoY } from './GraficoAnaliseCorteUtils'
import { exportar } from '../../assets/images'

require('highcharts/highcharts-more')(Highcharts)
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function GraficoAnaliseCorteOpcoes(dados, motivos, labels) {
  return ({
    chart: {
      type: 'waterfall',
      events: {
        exportData({ dataRows: [nomeDasColunas,,,,,,,,,, final] = [], target: { yAxis } } = {}) {
          // eslint-disable-next-line no-param-reassign
          nomeDasColunas[0] = labels.situacao
          // eslint-disable-next-line no-param-reassign
          nomeDasColunas[1] = yAxis[0].axisTitle.textStr
          // eslint-disable-next-line no-param-reassign
          final[1] = Math.abs(final[1])
        },
      },
    },

    title: {
      text: labels.titulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },

    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },

    yAxis: {
      tickPositioner() {
        return formatarEixoY(dados.diametroNovo, dados.diametroSucatado)
      },
      title: {
        text: labels.coluna,
      },
      min: dados.diametroSucatado,
      max: dados.diametroNovo,
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      formatter() {
        return formatarTooltip(labels, dados, this.point)
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter() {
            return formatarDataLabel(this.y, dados)
          },
        },
        borderWidth: 0,
      },
    },
    series: [{
      data: motivos,
    }],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      filename: labels.exportacao,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoAnaliseCorteOpcoes
