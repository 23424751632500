import Highcharts from 'highcharts'
import { Traducao, COR_PRIMARIA } from '../utils'
import { formatarTooltip } from './GraficoDiametroUtils'
import { exportar } from '../../assets/images'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function GraficoDiametroOpcoes(range, percentual, situacao, materiais, fornecedores,
  cilindros, dados, lblTitulo, lblDiametro, lblVida, lblcilindros) {
  return ({
    chart: {
      type: 'column',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          const dadosExportacao = dadosDoGrafico
          dadosExportacao.dataRows[0][0] = lblcilindros
          dadosExportacao.dataRows[0][2] = lblVida
          for (let i = 1; i < dadosExportacao.dataRows.length; i += 1) {
            dadosExportacao.dataRows[i][0] = (
              dadosExportacao.dataRows[i][0].replace('<br>', ' ').replace('</br>', ''))
            dadosExportacao.dataRows[i][2] = percentual[i - 1]
          }
        },
      },
    },
    title: {
      text: lblTitulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      type: 'category',
      categories: cilindros,
    },
    yAxis: [{
      tickPositioner() {
        const positions = []
        const increment = (range[0] - range[1]) / 4
        let tick = range[1]
        while (tick <= range[0]) {
          positions.push(parseFloat(tick.toFixed(0)))
          tick += increment
        }
        return positions
      },
      title: {
        text: lblDiametro,
      },
      max: range[0],
      min: range[1],
      startOnTick: false,
    }, {
      linkedTo: 0,
      opposite: true,
      title: {
        text: lblVida,
      },
      tickPositioner() {
        const positions = []
        const increment = (range[0] - range[1]) / 4
        let tick = range[1]
        while (tick <= range[0]) {
          positions.push(parseFloat(tick.toFixed(0)))
          tick += increment
        }
        return positions
      },
      labels: {
        formatter() {
          return parseFloat((((this.value - range[1]) / (range[0] - range[1])) * 100).toFixed(0))
        },
      },
    },
    ],
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter() {
            return `${percentual[this.point.x]}%`
          },
        },
      },
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      formatter() {
        return formatarTooltip(this)
      },
      shared: true,
    },
    series: [
      {
        name: lblDiametro,
        data: dados,
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoDiametroOpcoes
