import { makeStyles } from '@material-ui/core/styles'

export const estilo = makeStyles({
  borda: {
    fontSize: '12.25px',
    height: '33px',
    '& tbody': {
      borderBottom: '2px solid #868F9B',
      borderTop: '2px solid #868F9B',
      '& td': {
        border: '1px solid #ebe8e8',
      },
    },
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
  },
  bordaSuperior: { borderTop: '2px solid #868F9B' },
})

const TabelaPrevisaoCompraEstilo = estilo

export default TabelaPrevisaoCompraEstilo
