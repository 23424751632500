import React from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useTranslation } from 'react-i18next'
import {
  propsGraficoDesempenhoAgrupadoConteudoModalImprimir,
  valueGraficoDesempenhoAgrupadoConteudoModalImprimir,
} from '../GraficoDesempenhoAgrupadoPropTypes'

function GraficoDesempenhoAgrupadoConteudoModalImprimir({
  fornecedores, fornecedorEspecifico, setFornecedorEspecifico,
  exibirFornecedorEspecifico, setExibirFornecedorEspecifico,
  opcoes,
}) {
  const { t } = useTranslation()

  function alterarFornecedorEspecifico(value) {
    setFornecedorEspecifico(value)
  }

  function alterarExibirFornecedorEspecifico(value) {
    setExibirFornecedorEspecifico(value)
    if (value === opcoes.nao) setFornecedorEspecifico('')
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{t('layout.layoutMenu.TEXTO_MODAL_EXPORTAR_CONTEUDO')}</span>
      <RadioGroup name="esconderFornecedorExportacao" value={exibirFornecedorEspecifico} onChange={event => alterarExibirFornecedorEspecifico(event.target.value)} row>
        <FormControlLabel value={opcoes.sim} control={<Radio color="default" />} label={t('layout.layoutMenu.TEXTO_SIM')} labelPlacement="end" />
        <FormControlLabel value={opcoes.nao} control={<Radio color="default" />} label={t('layout.layoutMenu.TEXTO_NAO')} labelPlacement="end" />
      </RadioGroup>
      <div style={{ display: 'flex' }}>
        <NativeSelect
          value={fornecedorEspecifico}
          onChange={event => alterarFornecedorEspecifico(event.target.value)}
          hidden={exibirFornecedorEspecifico === opcoes.nao}
        >
          <option value="" disabled>
            {t('layout.layoutMenu.TEXTO_SELECIONE_O_FORNECEDOR')}
          </option>
          <option value={t('layout.layoutMenu.TEXTO_TODOS_OCULTOS')}>
            {t('layout.layoutMenu.TEXTO_TODOS_OCULTOS')}
          </option>
          {fornecedores.map(
            nomeFornecedor => <option value={nomeFornecedor} key={`consumoAgrupado-imprimir-pdf-${nomeFornecedor}`}>{nomeFornecedor}</option>,
          )}
        </NativeSelect>
      </div>
    </div>
  )
}

GraficoDesempenhoAgrupadoConteudoModalImprimir
  .propTypes = propsGraficoDesempenhoAgrupadoConteudoModalImprimir
GraficoDesempenhoAgrupadoConteudoModalImprimir
  .defaultProps = valueGraficoDesempenhoAgrupadoConteudoModalImprimir

export default GraficoDesempenhoAgrupadoConteudoModalImprimir
