import React, { useEffect, forwardRef } from 'react'
import AddBox from '@material-ui/icons/AddBox'
import MaterialTable from 'material-table'
import { useTranslation } from 'react-i18next'
import getUsuariosCadastrados, { getPerfisCadastrados } from '../CadastroUsuariosService'
import {
  COR_PRIMARIA, useStateSafely, ConfigTraducao, ConfigToolbar, objetoFormatado, Modal,
  LocalStorageProxy,
} from '../../utils'
import CadastroUsuariosRedefinirSenha from './CadastroUsuariosRedefinirSenha'
import { LoadingTabela } from '../../loading'
import ordenarUsuarios, {
  inserirUsuario, editarUsuario, deletarUsuario, resetarSenha,
} from '../CadastroUsuariosUtils'

let loading
let setLoading
let usuarios
let setUsuarios
let perfis
let setPerfis
let exibirModal
let setExibirModal
let codigoUsuario
let setCodigoUsuario
let alterando
let setAlterando

const CadastroUsuarios = () => {
  const { t } = useTranslation();
  [loading, setLoading] = useStateSafely(false);
  [perfis, setPerfis] = useStateSafely([]);
  [usuarios, setUsuarios] = useStateSafely([]);
  [exibirModal, setExibirModal] = useStateSafely(false);
  [alterando, setAlterando] = useStateSafely(false);
  [codigoUsuario, setCodigoUsuario] = useStateSafely(undefined)

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox style={{ color: '#4298D6' }} {...props} ref={ref} />),
  }

  const perfil = parseInt(LocalStorageProxy.getItem('CodigoPerfil'), 10)
  const usuario = parseInt(LocalStorageProxy.getItem('CodigoUsuario'), 10)

  const labels = {
    msgPerfil: t('cadastroUsuarios.cadastroUsuarios.LABEL_PERFIL_NAO_INFORMADO'),
    msgNome: t('cadastroUsuarios.cadastroUsuarios.LABEL_NOME_NAO_INFORMADO'),
    msgUsuario: t('cadastroUsuarios.cadastroUsuarios.LABEL_USUARIO_NAO_INFORMADO'),
    msgEmail: t('cadastroUsuarios.cadastroUsuarios.LABEL_EMAIL_NAO_INFORMADO'),
    msgEmailErrado: t('cadastroUsuarios.cadastroUsuarios.LABEL_EMAIL_ERRADO'),
    msgSucesso: t('cadastroUsuarios.cadastroUsuarios.LABEL_USUARIO_SALVO'),
    msgExclusao: t('cadastroUsuarios.cadastroUsuarios.LABEL_USUARIO_EXCLUIDO'),
    msgErro: t('cadastroUsuarios.cadastroUsuarios.LABEL_ERRO'),
    msgExcluirUsuarioLogado: t('cadastroUsuarios.cadastroUsuarios.LABEL_ERRO_USUARIO_LOGADO'),
  }

  const localizacao = ConfigTraducao(
    t('config.config.LABEL_PAGINACAO'),
    t('config.config.LABEL_LINHAS_SELECIONADAS'),
    t('config.config.LABEL_LINHAS_PAGINA'),
    t('config.config.LABEL_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PRIMEIRA_PAGINA'),
    t('config.config.LABEL_PAGINA_ANTERIOR'),
    t('config.config.LABEL_TOOLTIP_PAGINA_ANTERIOR'),
    t('config.config.LABEL_PROXIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_PROXIMA_PAGINA'),
    t('config.config.LABEL_ULTIMA_PAGINA'),
    t('config.config.LABEL_TOOLTIP_ULTIMA_PAGINA'),
    t('config.config.LABEL_REGISTROS_SELECIONADOS'),
    t('config.config.LABEL_BUSCAR'),
    t('config.config.LABEL_TOOLTIP_BUSCAR'),
    t('config.config.LABEL_NENHUM_REGISTRO'),
    t('config.config.LABEL_TOOLTIP_FILTRO'),
    t('config.config.LABEL_TOOLTIP_CANCELAR'),
    t('config.config.LABEL_TOOLTIP_SALVAR'),
    t('config.config.LABEL_ACOES'),
    t('config.config.LABEL_ADICIONAR'),
    t('config.config.LABEL_EDITAR'),
    t('config.config.LABEL_DELETAR'),
    t('config.config.LABEL_DELETAR_CONFIRMACAO'),
  )

  const colunas = [
    {
      title: t('cadastroUsuarios.cadastroUsuarios.LABEL_PERFIL'),
      field: 'codPerfil',
      editable: perfil === 2 ? 'never' : 'always',
      initialEditValue: perfil === 2 ? 3 : undefined,
      lookup: objetoFormatado(perfis, 'codPerfil', 'descricaoPerfil'),
    }, {
      title: t('cadastroUsuarios.cadastroUsuarios.LABEL_NOME'),
      field: 'nome',
    },
    {
      title: t('cadastroUsuarios.cadastroUsuarios.LABEL_USUARIO'),
      field: 'login',
    },
    {
      title: t('cadastroUsuarios.cadastroUsuarios.LABEL_EMAIL'),
      field: 'email',
    },
  ]

  function abrirModal(codigo) {
    setExibirModal(true)
    setCodigoUsuario(codigo)
  }

  useEffect(() => {
    setLoading(true)
    setPerfis([])
    setUsuarios({})
    async function conectar() {
      const dadosUsuarios = await getUsuariosCadastrados({ perfil, usuario })
      const dadosPerfis = await getPerfisCadastrados({ perfil, usuario })
      setUsuarios(ordenarUsuarios(dadosUsuarios))
      setPerfis(dadosPerfis)
      setLoading(false)
    }
    try {
      conectar()
    } catch (err) {
      console.log(err)
    }
  }, [perfil, usuario])

  if (!loading) {
    return (
      <>
        {exibirModal ? (
          <Modal
            cancelar={t('cadastroUsuarios.cadastroUsuarios.LABEL_CANCELAR')}
            modalIsOpen
            handleClose={() => setExibirModal(false)}
            title={t('cadastroUsuarios.cadastroUsuarios.LABEL_TITULO_MODAL')}
            content={t('cadastroUsuarios.cadastroUsuarios.LABEL_MSG_MODAL')}
            buttonText={t('cadastroUsuarios.cadastroUsuarios.LABEL_SIM')}
            handleConfirm={() => resetarSenha(codigoUsuario, setExibirModal, setAlterando)}
          />
        ) : null}
        <MaterialTable
          icons={tableIcons}
          style={{
            color: COR_PRIMARIA,
            boxShadow: 'none',
          }}
          actions={[
            {
              icon: () => <CadastroUsuariosRedefinirSenha />,
              tooltip: t('cadastroUsuarios.cadastroUsuarios.LABEL_REDEFINIR_SENHA'),
              onClick: (event, rowData) => abrirModal(rowData.codUsuario),
            },
          ]}
          title={t('cadastroUsuarios.cadastroUsuarios.LABEL_TITULO')}
          columns={colunas}
          data={usuarios}
          isLoading={alterando}
          localization={localizacao}
          options={{
            actionsHeaderStyle: { width: '10%', textAlign: 'left', alignItems: 'left' },
            addRowPosition: 'first',
            pageSizeOptions: [],
            searchFieldStyle: { marginRight: '0px', paddingRight: '0px' },
            actionsColumnIndex: -1,
            rowStyle: rowData => ({
              color: (rowData.codUsuario === parseInt(LocalStorageProxy
                .getItem('CodigoUsuario'), 10)) ? '#4298D6' : '#434A54',
            }),
          }}
          editable={{
            onRowAdd: newData => new Promise((resolve, reject) => {
              inserirUsuario(newData, resolve, reject, labels, usuarios, setUsuarios)
            }),
            onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
              editarUsuario(newData, oldData, resolve, reject, labels, usuarios, setUsuarios)
            }),
            onRowDelete: oldData => new Promise((resolve, reject) => {
              deletarUsuario(oldData, resolve, reject, labels, usuarios, setUsuarios)
            }),
          }}
          components={{
            Toolbar: ConfigToolbar,
          }}
        />
      </>
    )
  }
  return (
    <div>
      <LoadingTabela loading={loading} message={t('config.config.LABEL_LOADING')} elevation={0} />
    </div>
  )
}

export default CadastroUsuarios
