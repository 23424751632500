import alerta from '../alerta/locales/PtBr'
import layout from '../layout/locales/PtBr'
import autenticacao from '../autenticacao/locales/PtBr'
import utils from '../utils/locales/PtBr'
import graficoDiametro from '../graficoDiametro/locales/PtBr'
import filtro from '../filtro/locales/PtBr'
import config from '../config/locales/PtBr'
import graficoEquivalenteNovo from '../graficoEquivalenteNovo/locales/PtBr'
import graficoEstoqueSituacao from '../graficoEstoqueSituacao/locales/PtBr'
import graficoEstoqueCilindroEquivalenteNovo from '../graficoEstoqueCilindroEquivalenteNovo/locales/PtBr'
import graficoDesempenhoAgrupado from '../graficoDesempenhoAgrupado/locales/PtBr'
import home from '../home/locales/PtBr'
import graficoDesempenhoIndividual from '../graficoDesempenhoIndividual/locales/PtBr'
import graficoAnaliseCorte from '../graficoAnaliseCorte/locales/PtBr'
import graficoRitmoChegadaCilindro from '../graficoRitmoChegadaCilindro/locales/PtBr'
import graficoCustoPorMm from '../graficoCustoPorMm/locales/PtBr'
import graficoCustoPorTonelada from '../graficoCustoPorTonelada/locales/PtBr'
import tabelaPrevisaoCompra from '../tabelaPrevisaoCompra/locales/PtBr'
import cadastroUsuarios from '../cadastroUsuarios/locales/PtBr'
import graficoTCO from '../graficoTCO/locales/PtBr'

const PtBr = {
  translation: {
    layout,
    alerta,
    autenticacao,
    utils,
    graficoDiametro,
    filtro,
    config,
    graficoEquivalenteNovo,
    graficoEstoqueSituacao,
    graficoEstoqueCilindroEquivalenteNovo,
    graficoDesempenhoAgrupado,
    home,
    graficoDesempenhoIndividual,
    graficoAnaliseCorte,
    graficoRitmoChegadaCilindro,
    graficoCustoPorMm,
    graficoCustoPorTonelada,
    tabelaPrevisaoCompra,
    cadastroUsuarios,
    graficoTCO,
    geral: {
      meses: {
        jan: 'Janeiro',
        fev: 'Fevereiro',
        mar: 'Março',
        abr: 'Abril',
        mai: 'Maio',
        jun: 'Junho',
        jul: 'Julho',
        ago: 'Agosto',
        set: 'Setembro',
        out: 'Outubro',
        nov: 'Novembro',
        dez: 'Dezembro',
      },
      routes: {
        URL_HOME: '',
        URL_DESEMPENHO_INDIVIDUAL: 'desempenhoIndividual',
        URL_DESEMPENHO_AGRUPADO: 'desempenhoAgrupado',
        URL_LOGIN: 'login',
        URL_ESTOQUE_CORRENTE: 'estoque',
        URL_ESTOQUE_HISTORICO: 'estoqueHistorico',
        URL_CUSTO_RELATIVO: 'custoRelativo',
        URL_CUSTO_PREVISAO: 'custoPrevisao',
        URL_CONFIGURACAO: 'configuracoes',
        URL_DISCRETIZADO: 'desempenhoDiscretizado',
        URL_CADASTRO_USUARIOS: 'usuarios',
        URL_TCO: 'tco',
      },
    },
  },
}

export default PtBr
