import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from '../Configuracoes'
import { propsCheckbox, valueCheckbox } from '../UtilsPropTypes'

const CustomCheckBox = withStyles({
  root: {
    '&$checked': {
      color: COR_PRIMARIA,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

export default function SRCheckBox({
  label, valor, handleChange, estado, disabled, single, checked,
}) {
  return (
    <FormControlLabel
      control={(
        <CustomCheckBox
          checked={single ? checked : estado[valor]}
          onChange={single ? handleChange : () => handleChange(valor)}
          value={valor}
          disabled={disabled}
        />
          )}
      label={label}
    />
  )
}

SRCheckBox.propTypes = propsCheckbox
SRCheckBox.defaultProps = valueCheckbox
