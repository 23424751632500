import React from 'react'
import Menu from './LayoutMenu'
import LayoutCabecalhoEstilo from '../LayoutCabecalhoEstilo'

const LayoutListaSuspensa = (toggleDrawer, setState) => {
  const classes = LayoutCabecalhoEstilo()
  return (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(setState, false)}
    >
      <Menu evento={toggleDrawer(setState, false)} />
    </div>
  )
}

export default LayoutListaSuspensa
