import { COR_PRIMARIA } from '../utils'
import { exportar } from '../../assets/images'

function GraficoEstoqueSituacaoOpcoes(quantidade, situacao, cilindros, lblTitulo,
  lblQuantidade, labelSituacao) {
  return ({
    chart: {
      type: 'column',
      events: {
        exportData({ dataRows: [nomeDaColuna] = [[]], target: { yAxis } } = {}) {
          // eslint-disable-next-line no-param-reassign
          nomeDaColuna[0] = labelSituacao
          // eslint-disable-next-line no-param-reassign
          nomeDaColuna[1] = yAxis[0].axisTitle.textStr
          console.log(nomeDaColuna)
        },
      },
    },
    title: {
      text: lblTitulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: situacao,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        text: lblQuantidade,
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: true,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      enabled: true,
      style: {
        color: 'white',
      },
      formatter() {
        return cilindros.map(item => (item.situacao === this.points[0].x
          ? item.cilindros.join('<br>') : null))
      },
      shared: true,
    },
    series: [{
      data: quantidade,
    }],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

export default GraficoEstoqueSituacaoOpcoes
