import { ConectarApiPost, ConectarApiPut } from '../utils/ConectarApi'
import TabelaPrevisaoCompraProducaoRequisicao, {
  TabelaPrevisaoCompraProducaoEditarRequisicao,
  TabelaPrevisaoCompraConsumoEditarRequisicao,
  TabelaPrevisaoCompraCorteRequisicao,
  TabelaPrevisaoCompraEstoqueRequisicao,
  TabelaPrevisaoCompraPrevisaoEstoqueCilindrosRequisicao,
  TabelaPrevisaoCompraPrevisaoEstoqueCilindrosEditarRequisicao,
  TabelaPrevisaoCompraMinimoOperacionalRequisicao,
  TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosRequisicao,
  TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosRequisicao,
  TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosRequisicao,
} from './TabelaPrevisaoCompraRequisicao'
import {
  ENDPOINT_PRODUCAO_PREVISTA_REAL, ENDPOINT_PREVISAO_PRODUCAO_BUSCA, ENDPOINT_PREVISAO_PRODUCAO,
  ENDPOINT_CONSUMO_PREVISTO_REAL,
  ENDPOINT_PREVISAO_CONSUMO_BUSCA,
  ENDPOINT_PREVISAO_CONSUMO,
  ENDPOINT_CORTE_PREVISAO_REAL,
  ENDPOINT_RECEBIMENTO_CILINDROS_PREVISTO_REAL,
  ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCA,
  ENDPOINT_CILINDROS_EQUIVALENTES_PREVISTO_REAL,
  ENDPOINT_ESTOQUE_PREVISAO_REAL,
  ENDPOINT_PREVISAO_ESTOQUE_CILINDRO,
  ENDPOINT_MINIMO_OPERACIONAL_BUSCAR_TODOS,
  ENDPOINT_MINIMO_OPERACIONAL,
  ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCAR_POR_CLASSE_E_ANOS,
  ENDPOINT_PREVISAO_PRODUCAO_BUSCAR_POR_CLASSE_E_ANOS,
  ENDPOINT_PREVISAO_CONSUMO_BUSCAR_POR_CLASSE_E_ANOS,
} from '../utils/Configuracoes'

export default async function TabelaPrevisaoCompraProducaoService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PRODUCAO_PREVISTA_REAL,
      TabelaPrevisaoCompraProducaoRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraProducaoEditarService(filtro) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPost(ENDPOINT_PREVISAO_PRODUCAO_BUSCA,
      TabelaPrevisaoCompraProducaoEditarRequisicao(filtro))

    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function criarPrevisaoProducao(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PREVISAO_PRODUCAO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    throw err
  }
}

export async function editarPrevisaoProducao(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_PREVISAO_PRODUCAO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    throw err
  }
}

export async function TabelaPrevisaoCompraConsumoService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_CONSUMO_PREVISTO_REAL,
      TabelaPrevisaoCompraProducaoRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraConsumoEditarService(filtro) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPost(ENDPOINT_PREVISAO_CONSUMO_BUSCA,
      TabelaPrevisaoCompraConsumoEditarRequisicao(filtro))

    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function criarPrevisaoConsumo(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PREVISAO_CONSUMO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    throw err
  }
}

export async function editarPrevisaoConsumo(objeto) {
  if (!objeto) {
    return null
  }
  try {
    const resposta = await ConectarApiPut(ENDPOINT_PREVISAO_CONSUMO, objeto)
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    throw err
  }
}

export async function TabelaPrevisaoCompraCorteService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_CORTE_PREVISAO_REAL,
      TabelaPrevisaoCompraCorteRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}


export async function TabelaPrevisaoCompraRecebimentoCilindrosService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_RECEBIMENTO_CILINDROS_PREVISTO_REAL,
      TabelaPrevisaoCompraProducaoRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraRecebimentoCilindrosEditarService(filtro) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPost(ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCA,
      TabelaPrevisaoCompraConsumoEditarRequisicao(filtro))

    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraEquivalentesService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_CILINDROS_EQUIVALENTES_PREVISTO_REAL,
      TabelaPrevisaoCompraProducaoRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraEstoquePrevisaoRealService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_ESTOQUE_PREVISAO_REAL,
      TabelaPrevisaoCompraEstoqueRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado.reduce((acc, v) => { acc[v.ano] = v; return acc }, {})
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraPrevisaoEstoqueCilindroService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_PREVISAO_ESTOQUE_CILINDRO,
      TabelaPrevisaoCompraPrevisaoEstoqueCilindrosRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado.reduce((acc, v) => { acc[v.ano] = v; return acc }, {})
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraPrevisaoEstoqueCilindroEditarService(dados) {
  try {
    const {
      data: {
        resultado,
      },
    } = await ConectarApiPut(ENDPOINT_PREVISAO_ESTOQUE_CILINDRO,
      TabelaPrevisaoCompraPrevisaoEstoqueCilindrosEditarRequisicao(dados))

    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraMinimoOperacionalService(filtro) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_MINIMO_OPERACIONAL_BUSCAR_TODOS,
      TabelaPrevisaoCompraMinimoOperacionalRequisicao(filtro))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraMinimoOperacionalEditarService(
  filtro, dadosMinimoOperacional,
) {
  try {
    const resposta = await ConectarApiPost(ENDPOINT_MINIMO_OPERACIONAL,
      TabelaPrevisaoCompraMinimoOperacionalRequisicao(filtro, dadosMinimoOperacional))
    const { resultado } = resposta.data
    return resultado
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosService(filtro) {
  try {
    const resposta = await ConectarApiPost(
      ENDPOINT_PREVISAO_PRODUCAO_BUSCAR_POR_CLASSE_E_ANOS,
      TabelaPrevisaoCompraProducaoBuscarPorClasseEAnosRequisicao(filtro),
    )
    const { resultado } = resposta.data
    return resultado.reduce(
      (acc, v) => (acc[v.ano]
        ? { ...acc, [v.ano]: [...acc[v.ano], v] } : { ...acc, [v.ano]: [v] }), {},
    )
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosService(filtro) {
  try {
    const resposta = await ConectarApiPost(
      ENDPOINT_PREVISAO_CONSUMO_BUSCAR_POR_CLASSE_E_ANOS,
      TabelaPrevisaoCompraConsumoBuscarPorClasseEAnosRequisicao(filtro),
    )
    const { resultado } = resposta.data
    return resultado.reduce((acc, v) => { acc[v.ano] = v; return acc }, {})
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}

export async function TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosService(filtro) {
  try {
    const resposta = await ConectarApiPost(
      ENDPOINT_PREVISAO_RECEBIMENTO_CILINDROS_BUSCAR_POR_CLASSE_E_ANOS,
      TabelaPrevisaoCompraRecebimentoCilindrosBuscarPorClasseEAnosRequisicao(filtro),
    )
    const { resultado } = resposta.data
    return resultado.reduce(
      (acc, v) => (acc[v.ano]
        ? { ...acc, [v.ano]: [...acc[v.ano], v] } : { ...acc, [v.ano]: [v] }), {},
    )
  } catch (err) {
    console.log(err)
    return ['Erro', err]
  }
}
