import React from 'react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { logo } from '../../../assets/images'

export default function LayoutImagemCabecalho() {
  const { t } = useTranslation()
  const telaPequena = useMediaQuery('(min-width:600px)')
  if (telaPequena) {
    return <img src={logo} width="427px" height="49px" alt={t('layout.layoutImagemCabecalho.ALT_LOGO')} />
  }
  return <img src={logo} width="195px" height="22px" alt={t('layout.layoutImagemCabecalho.ALT_LOGO')} />
}
