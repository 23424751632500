import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../utils'
import { publicar } from '../../utils/FilaDeMensagens'
import { GraficoDesempenhoAgrupadoConteudoModalImprimir } from '..'
import {
  propsGraficoDesempenhoAgrupadoModalPrint,
  valueGraficoDesempenhoAgrupadoModalPrint,
} from '../GraficoDesempenhoAgrupadoPropTypes'

function GraficoDesempenhoAgrupadoModalPrint({
  isOpen, modalClose, alterarFornecedor, fornecedores,
}) {
  const [OPCAO_SIM, OPCAO_NAO] = ['sim', 'nao']
  const [fornecedorEspecifico, setFornecedorEspecifico] = useState('')
  const [exibirFornecedorEspecifico, setExibirFornecedorEspecifico] = useState(OPCAO_NAO)
  const [imprimir, setImprimir] = useState(false)
  const { t } = useTranslation()

  function opcaoConfirmar() {
    if (exibirFornecedorEspecifico === OPCAO_SIM && fornecedorEspecifico === '') {
      publicar({ text: t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.TEXTO_SELECIONE_UM_FORNECEDOR'), type: 'error', translate: false })
      return
    }
    alterarFornecedor(fornecedorEspecifico)
    setImprimir(true)
    setExibirFornecedorEspecifico(OPCAO_NAO)
    modalClose()
  }

  function fecharOModal() {
    alterarFornecedor('')
    setFornecedorEspecifico('')
    setImprimir(false)
    setExibirFornecedorEspecifico(OPCAO_NAO)
    modalClose()
  }

  function onExitHandle() {
    if (imprimir) {
      window.print()
    }
    setFornecedorEspecifico('')
  }

  return (
    <Modal
      modalIsOpen={isOpen}
      handleClose={fecharOModal}
      handleConfirm={opcaoConfirmar}
      title={t('layout.layoutMenu.LABEL_EXPORTAR_PDF')}
      buttonText={t('layout.layoutMenu.LABEL_EXPORTAR')}
      maxWidth="sm"
      onExited={onExitHandle}
      cancelar={t('graficoDesempenhoAgrupado.GraficoDesempenhoAgrupado.LABEL_CANCELAR')}
      content={(
        <GraficoDesempenhoAgrupadoConteudoModalImprimir
          fornecedores={fornecedores}
          fornecedorEspecifico={fornecedorEspecifico}
          setFornecedorEspecifico={setFornecedorEspecifico}
          exibirFornecedorEspecifico={exibirFornecedorEspecifico}
          setExibirFornecedorEspecifico={setExibirFornecedorEspecifico}
          opcoes={{ sim: OPCAO_SIM, nao: OPCAO_NAO }}
        />
      )}
    />
  )
}

GraficoDesempenhoAgrupadoModalPrint.propTypes = propsGraficoDesempenhoAgrupadoModalPrint
GraficoDesempenhoAgrupadoModalPrint.defaultProps = valueGraficoDesempenhoAgrupadoModalPrint

export default GraficoDesempenhoAgrupadoModalPrint
