import React from 'react'
import Spinner from 'react-spinkit'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

export const styleFullScreenLoaging = makeStyles({
  overlayContent: {
    display: 'flex',
    width: '100%',
    left: 0,
    top: 0,
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: '999999',
    background: 'rgba(0, 0, 0, 0.9)',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'inherit',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  message: {
    marginTop: 20,
    color: '#edf0f4',
    fontWeight: 'bold',
    fontSize: 18,
    textShadow: '0 2px 5px black',
  },
})

const FullScreenLoaging = ({ loading, message }) => {
  const classes = styleFullScreenLoaging()

  return (loading ? (
    <div className={classes.overlayContent} data-html2canvas-ignore="true">
      <div className={classes.wrapper}>
        <Spinner
          name="line-spin-fade-loader"
          fadeIn="none"
          color="#edf0f4"
        />
        <span className={classes.message}>
          <br />
          {message}
        </span>
      </div>
    </div>
  ) : null)
}

FullScreenLoaging.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
}

FullScreenLoaging.defaultProps = {
  loading: false,
  message: '',
}

export default FullScreenLoaging
