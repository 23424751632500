import propTypes from 'prop-types'

const propsTooltip = {
  cinza: propTypes.number,
  title: propTypes.oneOfType([propTypes.string, propTypes.array, propTypes.object]),
}

export const valueTooltip = {
  cinza: null,
}

export default propsTooltip
