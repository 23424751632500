import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { useTranslation } from 'react-i18next'
import FiltroEstilo from '../FiltroEstilo'
import { valueDropDown, propsDropDown } from '../FiltroPropTypes'

export default function FiltroAutoComplete({
  id, label, value, lista, itemDisplay, handleChange,
  hidden, disabled, erro,
}) {
  const classes = FiltroEstilo()
  const { t } = useTranslation()

  const filterOptions = createFilterOptions({
    stringify: option => option[itemDisplay],
  })

  const defaultProps = {
    options: lista,
    getOptionLabel: option => option[itemDisplay],
  }

  return (
    <FormControl className={classes.formControlAutoComplete} hidden={hidden} error={erro}>
      <InputLabel shrink htmlFor={id} className={classes.filtroDropdown}>
        {label}
      </InputLabel>
      <Autocomplete
        {...defaultProps}
        disabled={disabled}
        id={id}
        value={value}
        clearText={t('filtro.filtroDropDown.LABEL_LIMPAR')}
        closeText={t('filtro.filtroDropDown.LABEL_FECHAR')}
        openText={t('filtro.filtroDropDown.LABEL_ABRIR')}
        noOptionsText={t('filtro.filtroDropDown.LABEL_NENHUM_REGISTRO')}
        filterOptions={filterOptions}
        disableClearable
        onChange={(event, newValue) => handleChange(newValue)}
        renderInput={params => (
          <TextField {...params} id={`${id}-textField`} margin="normal" error={erro} fullWidth />
        )}
      />
    </FormControl>
  )
}

FiltroAutoComplete.propTypes = propsDropDown
FiltroAutoComplete.defaultProps = valueDropDown
