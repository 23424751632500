import Highcharts from 'highcharts'
import {
  Traducao, COR_PRIMARIA, formatarData, arrayZerado,
} from '../utils'
import { exportar } from '../../assets/images'
import formatarTooltip from './GraficoCustoPorToneladaUtils'


require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)


function GraficoCustoPorToneladaOpcoes(dados, labels, filtroAnual, graficoSimplificado = false) {
  function montarSeriePrevista() {
    if (graficoSimplificado && filtroAnual) {
      return [dados.custoPorToneladaPrevista.reduce(
        (acc, v) => acc + v, 0,
      ) / (dados.datas.length)]
    }
    if (graficoSimplificado && !filtroAnual) {
      return [dados.custoPorToneladaPrevista.reduce(
        (acc, v) => acc + v, 0,
      ) / (dados.datas.length - 1)]
    }
    return dados.custoPorToneladaPrevista
  }
  const graficoCompleto = [{
    type: 'column',
    name: labels.previsto,
    data: montarSeriePrevista(),
    color: '#8FCEFF',
  }, {
    type: 'column',
    name: labels.real,
    data: graficoSimplificado
      ? [dados.custoEfetivo.reduce(
        (acc, v) => acc + v, 0,
      ) / dados.pesoProduzido.reduce(
        (acc, v) => acc + v, 0,
      )] : dados.custoPorToneladaReal,
    color: COR_PRIMARIA,
  }]

  const graficoSimples = [{
    type: 'column',
    name: labels.real,
    data: dados.custoPorToneladaReal,
    color: COR_PRIMARIA,
  }]

  return ({
    chart: {
      type: 'column',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          const dadosExportacao = dadosDoGrafico
          dadosExportacao.dataRows[0][0] = labels.datas
        },
      },
    },

    title: {
      text: labels.titulo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },

    legend: {
      itemStyle: {
        color: '#333333',
        fontWeight: 'normal',
      },
    },

    tooltip: {
      enabled: !graficoSimplificado,
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      split: false,
      shared: false,
      formatter() {
        return formatarTooltip(this, dados, labels)
      },
    },

    yAxis: {
      labels: {
        style: {
          fontSize: '14px',
        },
        format: graficoSimplificado ? '{value:.2f}' : '{value}',
      },
      title: {
        text: labels.quantidade,
      },
    },

    xAxis: {
      categories: graficoSimplificado ? [labels.acumulado] : formatarData(dados.datas),
      labels: {
        style: {
          fontSize: graficoSimplificado ? '14px' : '11px',
        },
      },
    },

    exporting: {
      enabled: !graficoSimplificado,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },

    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: graficoSimplificado,
          format: '{point.y:.2f}',
        },
      },
      series: {
        animation: false,
        borderWidth: 0,
      },
    },

    series: (arrayZerado(dados.custoPorToneladaPrevista) && !graficoSimplificado)
      ? graficoSimples : graficoCompleto,

    credits: {
      enabled: false,
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoCustoPorToneladaOpcoes
