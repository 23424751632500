import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'
import { propsConfigCard, valuesConfigCard } from '../ConfigPropTypes'
import ConfigGeralCardTitulo from './ConfigGeralCardTitulo'

const StyledExpansionPanelSummary = withStyles({
  root: {
    padding: 'unset',
    minHeight: 'unset',
  },
  content: {
    margin: 0,
  },
})(ExpansionPanelSummary)

function ConfigCard({
  children, expandido, clickHandle, titulo,
}) {
  return (
    <ExpansionPanel expanded={expandido} style={{ boxShadow: 'none' }}>
      <Collapse in={!expandido}>
        <StyledExpansionPanelSummary onClick={clickHandle}>
          <ConfigGeralCardTitulo
            titulo={titulo}
            flechaParaCima={expandido}
            clickHandle={clickHandle}
          />
        </StyledExpansionPanelSummary>
      </Collapse>
      <ExpansionPanelDetails style={{ display: 'block', padding: 0 }}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

ConfigCard.propTypes = propsConfigCard
ConfigCard.defaultProps = valuesConfigCard

export default ConfigCard
