import Highcharts from 'highcharts'
import { Traducao, COR_PRIMARIA } from '../utils'
import { exportar } from '../../assets/images'
import { formatarDatalabels, formatarTooltip } from './GraficoDesempenhoAgrupadoUtils'

require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

function getSeries(
  tipo, labels, consumoNormal, desempenhoTotal, consumoTotal, desempenhoNormal,
  campanhas, graficoSimplificado,
) {
  const series = [{
    type: 'column',
    name: tipo === 'Desempenho' ? labels.total : labels.totalConsumo,
    data: tipo === 'Desempenho' ? desempenhoTotal : consumoTotal,
    color: tipo === 'Desempenho' ? '#F8A501' : '#FFCC00',
  }, {
    type: 'column',
    name: tipo === 'Desempenho' ? labels.normal : labels.normalConsumo,
    data: tipo !== 'Desempenho' ? consumoNormal : desempenhoNormal,
    color: tipo !== 'Desempenho' ? '#00B0DB' : '#951B81',
  }]

  if (!graficoSimplificado) {
    series.push({
      type: 'line',
      yAxis: 1,
      name: labels.campanhas,
      data: campanhas,
      color: COR_PRIMARIA,
    })
  }

  return series
}

function GraficoDesempenhoMateriaisOpcoes(materiais, campanhas, desempenhoNormal, desempenhoTotal,
  consumoNormal, consumoTotal, tipo, labels, graficoSimplificado) {
  return ({
    chart: {
      type: 'column',
      events: {
        exportData(dadosDoGrafico = { dataRows: [[]] }) {
          const dadosExportacao = dadosDoGrafico
          dadosExportacao.dataRows[0][0] = labels.materiais
        },
      },
    },
    legend: {
      itemStyle: {
        color: '#333333',
        fontWeight: 'normal',
      },
    },
    title: {
      text: tipo === 'Desempenho' ? labels.tituloDesempenho : labels.tituloConsumo,
      margin: 30,
      style: {
        color: COR_PRIMARIA,
        fontWeight: 'bold',
      },
    },
    xAxis: {
      categories: materiais,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
    },
    yAxis: [{
      min: 0,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      title: {
        text: tipo === 'Desempenho' ? labels.labelVerticalDesempenho : labels.labelVerticalConsumo,
      },
    }, {
      opposite: true,
      min: 0,
      labels: {
        style: {
          fontSize: '14px',
        },
      },
      title: {
        text: labels.quantidade,
      },
    }],
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
      },
      series: {
        animation: false,
        borderWidth: 0,
        dataLabels: {
          allowOverlap: true,
          enabled: true,
          formatter() {
            return formatarDatalabels(this, tipo, labels.campanhas)
          },
        },
      },
    },
    series: getSeries(
      tipo, labels, consumoNormal, desempenhoTotal, consumoTotal,
      desempenhoNormal, campanhas, graficoSimplificado,
    ),
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: '#636663',
      borderColor: '#636663',
      style: {
        color: 'white',
      },
      split: false,
      shared: false,
      formatter() {
        return formatarTooltip(this, labels)
      },
    },
    exporting: {
      enabled: !graficoSimplificado,
      buttons: {
        contextButton: {
          menuItems: ['downloadCSV'],
          symbol: `url(${exportar})`,
          width: 1,
          x: -17,
        },
      },
    },
  })
}

Highcharts.setOptions(Traducao())

export default GraficoDesempenhoMateriaisOpcoes
