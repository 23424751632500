import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { propsLayoutDesempenho, valueLayoutDesempenho } from '../LayoutPropTypes'
import GraficoDesempenhoIndividual from '../../graficoDesempenhoIndividual'
import LayoutContentList from './LayoutContentList'
import { SRSwitch } from '../../utils'

const LayoutDesempenhoIndividual = () => {
  const { t } = useTranslation()
  const [habilitaDiametroTotal, setHabilitaDiametroTotal] = useState({
    corteTotal: true,
    diametroTotal: false,
  })

  const handleHabilitaDiametro = habilita => (event) => {
    setHabilitaDiametroTotal({ ...habilitaDiametroTotal, [habilita]: event.target.checked })
  }

  return (
    <LayoutContentList>
      <section style={{ position: 'relative' }}>
        <div style={{
          position: 'absolute', right: '4%', zIndex: 1, marginTop: '2px',
        }}
        >
          <SRSwitch
            habilitaDiametroTotal={habilitaDiametroTotal}
            onChange={handleHabilitaDiametro('diametroTotal')}
            label={t('layout.layoutMenu.LABEL_VER_REDUCAO_DO_DIAMETRO')}
          />
        </div>
        {!habilitaDiametroTotal.diametroTotal
          ? <GraficoDesempenhoIndividual tipo="Corte" key="GraficoDesempenhoIndividualCorteId" />
          : <GraficoDesempenhoIndividual tipo="Diametro" key="GraficoDesempenhoIndividualDiametroId" />
        }
      </section>
      <GraficoDesempenhoIndividual tipo="Tonelagem" key="GraficoDesempenhoIndividualTonelagemId" />
      <GraficoDesempenhoIndividual tipo="Quilometragem" key="GraficoDesempenhoIndividualQuilometragemId" />
      <GraficoDesempenhoIndividual tipo="Desempenho" key="GraficoDesempenhoIndividualDesempenhoId" />
      <GraficoDesempenhoIndividual tipo="Consumo" key="GraficoDesempenhoIndividualConsumoId" />
    </LayoutContentList>
  )
}

LayoutDesempenhoIndividual.propTypes = propsLayoutDesempenho
LayoutDesempenhoIndividual.defaultProps = valueLayoutDesempenho

export default LayoutDesempenhoIndividual
