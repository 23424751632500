import React from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useTranslation } from 'react-i18next'
import { validarSenhas, handleMouseDownPassword } from '../AutenticacaoUtils'
import LoadingModal from '../../loading/views/LoadingModal'
import { Botao, useStateSafely, TituloModal } from '../../utils'
import { propsModal, valueModal } from '../AutenticacaoPropTypes'

const AutenticacaoAlterarSenhaForm = ({ handleClose, login, setSucesso }) => {
  const [senhaAntiga, setSenhaAntiga] = useStateSafely('')
  const [novaSenha, setNovaSenha] = useStateSafely('')
  const [confirmarSenha, setConfirmarSenha] = useStateSafely('')
  const [showSenhaNova, setShowSenhaNova] = useStateSafely(false)
  const [showConfirmacao, setShowConfirmacao] = useStateSafely(false)
  const [erroSenhaAntiga, setErroSenhaAntiga] = useStateSafely(false)
  const [erroSenhaNova, setErroSenhaNova] = useStateSafely(false)
  const [erroSenhaConfirmacao, setErroSenhaConfirmacao] = useStateSafely(false)
  const [loading, setLoading] = useStateSafely(false)
  const { t } = useTranslation()

  return (
    <div>
      <Dialog onClose={() => handleClose(false)} aria-labelledby="customized-dialog-title" open maxWidth="md" fullWidth>
        <TituloModal id="customized-dialog-title" onClose={() => handleClose(false)}>
          {t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_TITULO')}
        </TituloModal>
        <DialogContent dividers>
          <p hidden={login || loading}>{t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_INSTRUCOES')}</p>
          <p hidden={!login || loading}>{t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_INSTRUCOES_LOGIN')}</p>
          {loading
            ? <LoadingModal loading={loading} message={t('autenticacao.autenticacaoAlterarSenhaForm.LOADING')} />
            : (
              <Grid container justify="space-between">
                <Grid item>
                  <InputLabel error={erroSenhaAntiga} required htmlFor="senha-antiga">
                    <b>{t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_ANTIGA')}</b>
                  </InputLabel>
                  <OutlinedInput
                    placeholder={t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_PLACEHOLDER')}
                    id="senha-antiga"
                    autoFocus
                    type="password"
                    error={erroSenhaAntiga}
                    value={senhaAntiga}
                    onChange={valor => setSenhaAntiga(valor.target.value)}
                  />
                  <FormHelperText id="novaSenha-texto-help" hidden={!erroSenhaAntiga} error>
                    {t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_HELP')}
                  </FormHelperText>
                </Grid>
                <Grid item>
                  <InputLabel error={erroSenhaNova} required htmlFor="nova-senha">
                    <b>{t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_NOVA')}</b>
                  </InputLabel>
                  <OutlinedInput
                    placeholder={t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_PLACEHOLDER')}
                    id="nova-senha"
                    type={showSenhaNova ? 'text' : 'password'}
                    value={novaSenha}
                    error={erroSenhaNova}
                    onChange={valor => setNovaSenha(valor.target.value)}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          onClick={() => setShowSenhaNova(!showSenhaNova)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showSenhaNova ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  )}
                  />
                  <FormHelperText id="novaSenha-texto-help" hidden={!erroSenhaNova} error>
                    {t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_HELP')}
                  </FormHelperText>
                </Grid>
                <Grid item>
                  <InputLabel error={erroSenhaConfirmacao} required htmlFor="confirmar-senha">
                    <b>{t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_CONFIRMACAO')}</b>
                  </InputLabel>
                  <OutlinedInput
                    placeholder={t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_PLACEHOLDER')}
                    id="confirmar-senha"
                    type={showConfirmacao ? 'text' : 'password'}
                    value={confirmarSenha}
                    error={erroSenhaConfirmacao}
                    onChange={valor => setConfirmarSenha(valor.target.value)}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex="-1"
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmacao(!showConfirmacao)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirmacao ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                  )}
                  />
                  <FormHelperText id="novaSenha-texto-help" hidden={!erroSenhaConfirmacao} error>
                    {t('autenticacao.autenticacaoAlterarSenhaForm.LABEL_HELP')}
                  </FormHelperText>
                </Grid>
              </Grid>
            )}
        </DialogContent>
        <DialogActions>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <Botao
                id="botaoCancelarSenha"
                nome={t('autenticacao.autenticacaoAlterarSenhaForm.BOTAO_CANCELAR')}
                tamanho="small"
                variant="outlined"
                action={() => handleClose(false)}
                esconder={loading}
                inverse
              />
            </Grid>
            <Grid item>
              <Botao
                id="botaoConfirmarSenha"
                nome={t('autenticacao.autenticacaoAlterarSenhaForm.BOTAO_CONFIRMAR')}
                tamanho="small"
                variant="outlined"
                esconder={loading}
                action={() => validarSenhas(senhaAntiga, novaSenha, confirmarSenha,
                  setErroSenhaAntiga, setErroSenhaNova, setErroSenhaConfirmacao, setLoading,
                  setSucesso)}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

AutenticacaoAlterarSenhaForm.propTypes = propsModal
AutenticacaoAlterarSenhaForm.defaultProps = valueModal

export default AutenticacaoAlterarSenhaForm
