import propTypes from 'prop-types'

const propsAlerta = {
  mensagem: propTypes.string,
  tipo: propTypes.string,
  duracao: propTypes.number,
}

export const valueAlerta = {
  mensagem: null,
  tipo: 'error',
  duracao: 3000,
}

export const propsModal = {
  exibir: propTypes.bool,
  titulo: propTypes.string,
  mensagem: propTypes.string,
}

export const valueModal = {
  exibir: true,
  titulo: 'Erro',
}

export default propsAlerta
