/* eslint-disable no-bitwise */
/* eslint-disable no-underscore-dangle */
import {
  AUTHORIZATION_KEY, AUTHORIZATION_TOKEN_VALIDITY_TIME,
  permissoesPorFuncionalidade, PERMISSOES_BASICAS,
} from './Configuracoes'
import { error, and } from './Functions'
import LocalStorageProxy from './LocalStorageProxy'

const AUTHORIZATION_VALUE_PREFIX = 'Bearer'
const ERROR_AUTHORIZATION_VALUE_IS_INVALID = 'Valor usado para autorização é inválido.'
const TOKEN_EXPIRATION_TIME_KEY = 'tetk'
const ERROR_EXPIRATION_TIME_VALUE_IS_INVALID = 'Valor usado para expiração do token é inválido.'
const ERROR_DO_LOGOUT_HANDLER_VAZIO = 'Função para realizar o logout está vazia.'
const ERROR_DO_LOGIN_HANDLER_VAZIO = 'Função para realizar o login está vazia.'

const PERMISSIONS_KEY = 'Permissoes'
const USER_NAME_KEY = 'NomeUsuario'
const USER_CODE_KEY = 'CodigoUsuario'
const COD_PERFIL_KEY = 'CodigoPerfil'
const RETIFICA_INICIAL_KEY = 'AnoRetificaInicial'

class Security {
  static setAuthorizationToken(value) {
    if (!Security.authorizationTokenIsValid(value)) {
      Security.unsetAuthorizationToken()
      error(ERROR_AUTHORIZATION_VALUE_IS_INVALID)
    }

    const token = value.slice(7)
    LocalStorageProxy.setItem(AUTHORIZATION_KEY, token)
    const expirationTime = Date.now() + AUTHORIZATION_TOKEN_VALIDITY_TIME
    Security.setTokenExpirationTimeValue(expirationTime)
  }

  static setTokenExpirationTimeValue(value) {
    if (typeof value !== 'number') { error(ERROR_EXPIRATION_TIME_VALUE_IS_INVALID) }
    LocalStorageProxy.setItem(TOKEN_EXPIRATION_TIME_KEY, value)
  }

  static setUserInformation({ nome, codUsuario, perfil = { funcionalidades: [] } }) {
    const funcionalidades = perfil.funcionalidades.map(f => Security.urlToHash(f.descFuncionalidade)).join(',')
    LocalStorageProxy.setItem(USER_NAME_KEY, nome)
    LocalStorageProxy.setItem(USER_CODE_KEY, codUsuario)
    LocalStorageProxy.setItem(PERMISSIONS_KEY, funcionalidades)
    LocalStorageProxy.setItem(COD_PERFIL_KEY, perfil.codPerfil)
  }

  static authorizationTokenIsValid(value) {
    return (value
      && (typeof value === 'string' || value instanceof String)
      && value.length > 7
      && value.startsWith(AUTHORIZATION_VALUE_PREFIX))
  }

  static unsetAuthorizationToken() {
    LocalStorageProxy.removeItem(AUTHORIZATION_KEY)
    LocalStorageProxy.removeItem(USER_NAME_KEY)
    LocalStorageProxy.removeItem(USER_CODE_KEY)
    LocalStorageProxy.removeItem(PERMISSIONS_KEY)
    LocalStorageProxy.removeItem(TOKEN_EXPIRATION_TIME_KEY)
    LocalStorageProxy.removeItem(COD_PERFIL_KEY)
    LocalStorageProxy.removeItem(RETIFICA_INICIAL_KEY)
    this._permissions = undefined
    this._userMapPermissions = undefined
    this._appMapPermissions = undefined
    if (this._doLogoutHandler) this._doLogoutHandler()
  }

  static authorizationTokenExists() {
    return LocalStorageProxy.getItem(AUTHORIZATION_KEY) !== null
  }

  static getAuthorizationTokenValue() {
    const value = LocalStorageProxy.getItem(AUTHORIZATION_KEY)
    if (!value) { error(ERROR_AUTHORIZATION_VALUE_IS_INVALID) }
    return value
  }

  static getFullAuthorizationTokenValue() {
    return `${AUTHORIZATION_VALUE_PREFIX} ${Security.getAuthorizationTokenValue()}`
  }

  static getTokenExpirationTimeValue() {
    const value = LocalStorageProxy.getItem(TOKEN_EXPIRATION_TIME_KEY)
    return +value || -1
  }

  static setDoLogoutHandler(fn) {
    this._doLogoutHandler = fn
  }

  static setDoLoginHandler(fn) {
    this._doLoginHandler = fn
  }

  static doLogout() {
    this.unsetAuthorizationToken()
    if (this._doLogoutHandler) this._doLogoutHandler()
    else console.log(ERROR_DO_LOGOUT_HANDLER_VAZIO)
  }

  static doLogin() {
    if (this._doLoginHandler) this._doLoginHandler()
    else console.log(ERROR_DO_LOGIN_HANDLER_VAZIO)
  }

  static getUserPermissions() {
    const localStorageData = LocalStorageProxy.getItem(PERMISSIONS_KEY)
    if (!this._permissions && localStorageData) {
      this._permissions = new Set(localStorageData.split(',').map(x => parseInt(x, 10)))
    }
    return this._permissions
  }

  static getApplicationPermissions() {
    if (!this._appMapPermissions) {
      this._appMapPermissions = Object.keys(permissoesPorFuncionalidade).reduce(
        (acc, v) => {
          acc[v] = permissoesPorFuncionalidade[v].map(Security.urlToHash)
          return acc
        }, {},
      )
    }
    return this._appMapPermissions
  }

  static getUserMapPermissions() {
    if (!this._userMapPermissions) {
      const userPermissions = Security.getUserPermissions() || new Set([])
      const appPermissions = Security.getApplicationPermissions() || {}
      this._userMapPermissions = Object.keys(appPermissions).reduce(
        (acc, v) => {
          acc[v] = and(appPermissions[v].map(x => userPermissions.has(x)))
          return acc
        }, {},
      )
    }
    return this._userMapPermissions
  }

  static checkForPermissions(key) {
    return !!((Security.getUserMapPermissions() || {})[key])
  }

  static stringToHash(str) {
    let hash = 0
    if (typeof str !== 'string' || str.length === 0) {
      return hash
    }
    for (let i = 0; i < str.length; i += 1) {
      const char = str.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash &= hash
    }
    return hash
  }

  static getBasicPermissions() {
    return permissoesPorFuncionalidade[PERMISSOES_BASICAS]
  }

  static urlToHash(str) {
    return Security.stringToHash(str.split('/').filter(x => x).filter(x => !x.match(/{\w*}/)).join('.'))
  }
}

export default Security
