import React from 'react'
import Switch from '@material-ui/core/Switch'
import { grey, amber } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { valueIntegratedSwitch, propsIntegratedSwitch } from '../UtilsPropTypes'

const AmberSwitch = withStyles(theme => ({
  switchBase: {
    color: grey[100],
    '&$checked': {
      color: amber[500],
    },
    '&$checked + $track': {
      backgroundColor: amber[300],
    },
  },
  checked: {},
  track: {
    border: '1px solid #dddd',
    backgroundColor: '#aaaa',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))(Switch)

function SRSwitch({ habilitaDiametroTotal, onChange, label }) {
  return (
    <FormControlLabel
      value="start"
      control={(
        <AmberSwitch
          checked={habilitaDiametroTotal.diametroTotal}
          onChange={onChange}
          value="diametroTotal"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          style={{ alignSelf: 'flex-end' }}
        />
)}
      label={label}
      labelPlacement="start"
    />
  )
}

SRSwitch.propTypes = propsIntegratedSwitch
SRSwitch.defaultProps = valueIntegratedSwitch

export default SRSwitch
