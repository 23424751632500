import { makeStyles } from '@material-ui/core/styles'
import { COR_PRIMARIA } from './Configuracoes'

export const styleHeatBar = makeStyles({
  heatBar: {
    display: 'flex',
    alignItems: 'center',
  },
  textoLimiteInferior: {
    gridArea: 'txt1',
    marginRight: 2,
  },
  textoLimiteSuperior: {
    gridArea: 'txt2',
    marginLeft: 2,
  },
  grafico: {
    flex: 1,
    gridArea: 'barras',
    display: 'block',
  },
  indicadores: {
    display: 'grid',
    gridTemplateAreas: '"bar"',
    marginTop: 15,
    marginBottom: 15,
  },
  barras: {
    gridArea: 'bar',
    display: 'flex',
    height: 35,
    padding: '2px 0px 2px 0px',
  },
  barra: {
    flex: 1,
  },
  indicador: {
    gridArea: 'bar',
    display: 'flex',
  },
  indicador2: {
    position: 'relative',
    gridArea: 'bar',
    border: 'dashed',
    borderWidth: '0px 1px 0px 0px',
    borderColor: '#951a80',
    padding: '2px 0px 2px 0px',
  },
  fontAbsoluta: {
    position: 'absolute',
  },
  fontIndicador: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 12,
  },
  fontIndicador2: {
    left: 'calc(100% - 5px)',
    top: 'calc(100%)',
    color: '#951a80',
    fontWeight: 'bold',
  },
  fontIndicadorBarraVertical: {
    left: 'calc(100% - 10px)',
    top: 'calc(100% - 57px)',
    color: '#39434e',
    fontWeight: 'bold',
    fontSize: 16,
  },
  barraVertical: {
    position: 'relative',
    border: 'solid',
    borderWidth: '0px 1px 0px 0px',
    borderRightWidth: 3,
    borderColor: '#39434e',
  },
  transparencia: {
    backgroundColor: 'rgb(255, 255, 255, 0.5)',
    flexGrow: 1,
  },
  texto: {
    display: 'flex',
    flexDirection: 'row',
    color: '#868F9B',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 10,
  },
  textoItem1: {
    flex: 1,
  },
  textoItem2: {
    flex: 1,
    textAlign: 'center',
  },
  textoItem4: {
    flex: 1,
    textAlign: 'right',
  },
})

export const styleDialogTitle = theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E9EE',
  },
  closeButton: {
    position: 'relative',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    color: '#004070',
    fontSize: '18px',
    fontWeight: 'bold',
  },
})

export const styleDialogContent = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyItems: 'left',
    '& div': {
      margin: '5px 0',
    },
    borderBottom: '1px solid #E6E9EE',
  },
  header: {
    color: '#434A54',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    color: '#434A54',
    fontSize: '18px',
    textAlign: 'left',
    width: '100%',
    padding: '0 24px',
  },
  contentSenha: {
    color: '#434A54',
    fontSize: '18px',
    textAlign: 'center',
  },
})

export const styleDialogActions = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: '1px solid #E6E9EE',
  },
})

const UtilsEstilo = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
    color: '#ffff',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  erroGraficoHeader: {
    height: '62vh',
    fontWeight: 'bold',
    textAlign: 'center',
  },

  erroGraficoHeaderSimplificado: {
    fontWeight: 'bold',
    textAlign: 'center',
  },

  erroGraficoBody: {
    color: COR_PRIMARIA,
    textAlign: 'center',
  },

  erroGraficoTitle: {
    marginTop: '32px',
    fontWeight: 'bold',
    color: COR_PRIMARIA,
    textAlign: 'center',
  },

  checkboxErro: {
    color: '#f44336',
    marginBottom: '0px',
    fontSize: '12px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },

  checkbox: {
    marginBottom: '0px',
    fontSize: '12px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  },

  celulaNormal: {
    border: '1px solid #ebe8e8',
    fontSize: '12.25px',
    height: '33px',
  },

  celulaVermelha: {
    border: '1px solid #ebe8e8',
    fontSize: '12.25px',
    height: '33px',
    background: '#F6BCB2',
  },

  celulaAzul: {
    border: '1px solid #ebe8e8',
    fontSize: '12.25px',
    height: '33px',
    background: '#E9F0FA',
  },
}))

export const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
  },
}))

export const tituloModal = theme => ({
  header: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E6E9EE',
  },
  closeButton: {
    position: 'relative',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    color: '#004070',
    fontSize: '18px',
    fontWeight: 'bold',
  },
})

export default UtilsEstilo
